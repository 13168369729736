import "./VehicleCardFieldList.scss"

import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"

const VehicleCardFieldList = ({ withoutCheckbox }) => {
  const containerClass = classNames("vehicle-card-field-list-container", {
    "without-checkbox": withoutCheckbox,
  })
  return (
    <div className={containerClass}>
      <div className="field avatar">Vehicle img</div>
      <div className="field">Vehicle id</div>
      <div className="field">Status</div>
      <div className="field model">Model</div>
      <div className="field">Type</div>
      <div className="field">Year</div>
      <div className="field">Registration</div>
    </div>
  )
}

VehicleCardFieldList.propTypes = {
  withoutCheckbox: PropTypes.bool,
}

VehicleCardFieldList.defaultProps = {
  withoutCheckbox: false,
}

export default VehicleCardFieldList
