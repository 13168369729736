import { ThemeType } from "grommet"

export type ExtendedThemeType = ThemeType & {
  global: {
    edgeSize: {
      mediumLarge: string
    }
  }
}

export const textSize: ExtendedThemeType["text"] = {
  xsmall: {
    size: "12px",
    height: "18px",
  },
  small: {
    size: "14px",
    height: "20px",
  },
  medium: {
    size: "16px",
    height: "24px",
  },
  large: {
    size: "20px",
    height: "26px",
  },
  xlarge: {
    size: "26px",
    height: "32px",
  },
  xxlarge: {
    size: "34px",
    height: "40px",
  },
}

export const landlineTheme: ExtendedThemeType = {
  global: {
    colors: {
      brand: "#FAB500",
    },
    font: {
      family: "Source Sans Pro, sans-serif",
    },
    size: {
      xxsmall: "48px",
      xsmall: "96px",
      small: "192px",
      medium: "381px",
      large: "768px",
      xlarge: "996px",
      xxlarge: "1152px",
      full: "100%",
    },
    edgeSize: {
      none: "0px",
      hair: "1px",
      xxsmall: "3px",
      xsmall: "6px",
      small: "12px",
      medium: "21px",
      mediumLarge: "30px",
      large: "45px",
      xlarge: "96px",
      responsiveBreakpoint: "small",
    },
  },
  text: textSize,
  tab: {
    active: {
      background: "#F5F5F5",
    },
    background: "#D9D9D9",
    margin: {
      horizontal: "none",
      vertical: "none",
    },
    pad: {
      horizontal: "small",
      vertical: "none",
    },

    extend: `
      flex-grow: 1;
      padding-bottom: 0;
      border: 0;
      width: 180px;
    `,
  },
  tabs: {
    panel: {},
  },
  button: {
    style: {
      fontSize: "16px",
    },
    border: {
      radius: "8px",
    },
    secondary: {
      border: {
        color: "#000",
      },
    },
  },
  menu: {
    item: {
      style: {
        fontSize: "small",
      },
    },
  },
}
