import ACTIONS from "../../constants/ACTIONS"

const setSidebarState = sidebarState => {
  return async dispatch => {
    dispatch({ type: ACTIONS.IS_SIDEBAR_COLLAPSED, isSidebarCollapsed: sidebarState })
  }
}

// eslint-disable-next-line
export { setSidebarState }
