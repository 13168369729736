import "./DashboardPage.scss"

import classNames from "classnames"
import moment from "moment"
import PropTypes from "prop-types"
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import API from "../../../constants/API"
import {
  MAX_LETTER_DRIVER,
  MAX_LETTER_OPERATOR,
  MAX_LETTER_VEHICLE,
} from "../../../constants/common"
import { TWELVE_HOUR_WITH_LEADING_ZERO } from "../../../constants/dateFormats"
import { PAGE_LIMIT } from "../../../constants/offset"
import {
  TRIP_REQUEST_APPROVED_STATUS,
  TRIP_REQUEST_CANCELLED_STATUS,
  TRIP_REQUEST_DENIED_STATUS,
  TRIP_REQUEST_PENDING_STATUS,
  TRIP_REQUEST_STATUS,
  TRIP_SUGGESTION_APPROVED_STATUS,
  TRIP_SUGGESTION_CANCELLED_STATUS,
  TRIP_SUGGESTION_DENIED_STATUS,
  TRIP_SUGGESTION_PENDING_STATUS,
  TRIP_SUGGESTION_STATUS,
} from "../../../constants/status"
import { TRIP_REQUESTS_TABLE, TRIP_SUGGESTIONS_TABLE } from "../../../constants/tables"
import {
  COMPANY_DRIVER,
  COMPANY_REPRESENTATIVE,
  LANDLINE_AGENT,
} from "../../../constants/userRoles"
import {
  acceptTripSuggestion,
  approveTripRequest,
  cancelTripRequest,
  cancelTripSuggestion,
  denyTripRequest,
  denyTripSuggestion,
  getLastDayInfo,
  getNewTripRequests,
  getSuggestedTrips,
  getTripRequests,
  getTripSuggestions,
  sortTripTable,
} from "../../../redux/actions"
import apiUtils from "../../../utils/api/api-utils"
import { getCompanyIcon, getUserAvatar } from "../../../utils/common"
import { redirectDetails } from "../../../utils/commonFunctionality"
import { useOnClickOutside } from "../../../utils/hooks"
import AvatarName from "../../atoms/AvatarName"
import Button from "../../atoms/Button"
import InfoPopup from "../../atoms/InfoPopup"
import RowItem from "../../atoms/RowItem"
import StatusTableCell from "../../atoms/StatusTableCell"
import StatusTableCellWithPopup from "../../atoms/StatusTableCellWithPopup"
import SvgIcon from "../../atoms/SvgIcon"
import TablePopup from "../../atoms/TablePopup"
import TableTwoRow from "../../atoms/TableTwoRow"
import UserAvatarName from "../../atoms/UserAvatarName"
import RegularLayout from "../../layouts/RegularLayout"
import Modal from "../../organisms/Modal"
import NewRequestsTable from "../../organisms/NewRequestsTable"
import PendingRequestsTable from "../../organisms/PendingRequestsTable"
import RightSideBar from "../../organisms/RightSideBar"
import TableDropDown from "../../organisms/TableDropDown"
import TripRequestTable from "../../organisms/TripRequestTable"

const DashboardPage = ({ history }) => {
  const dispatch = useDispatch()
  const DashboardPageClass = classNames("dashboard-container")
  const [trips, currentUser, callsInProgress] = useSelector(({ trip, user, api }) => [
    trip,
    user,
    api.callsInProgress,
  ])
  const [isApprovedModalOpen, setIsApprovedModalOpen] = useState(false)
  const [isDeniedModalOpen, setIsDeniedModalOpen] = useState(false)
  const [tripRequestsToShow, setTripRequestsToShow] = useState("")
  const [tripSuggestionsToShow, setTripSuggestionsToShow] = useState("")
  const [selectedTripRequest, setSelectedTripRequest] = useState(null)
  const [selectedTripSuggestion, setSelectedTripSuggestion] = useState(null)
  const [isAcceptModalOpen, setIsAcceptModalOpen] = useState(false)
  const [isDeniedSuggestionModalOpen, setIsDeniedSuggestionModalOpen] = useState(false)
  const [tripRequestsOffset, setTripRequestsOffset] = useState(0)
  const [newRequestsOffset, setNewRequestsOffset] = useState(0)
  const [pendingSuggestionsOffset, setPendingSuggestionsOffset] = useState(0)
  const [suggestedTripsOffset, setSuggestedTripsOffset] = useState(0)
  const [selectedRequestsStatus, setSelectedRequestsStatus] = useState()
  const [selectedSuggestionsStatus, setSelectedSuggestionsStatus] = useState()
  const [isCancelTripModalOpen, setIsCancelTripModalOpen] = useState(false)
  const [popupOpen, setPopupOpen] = useState(false)
  const newRequestsScrollRef = useRef()
  const pendingSuggestionsScrollRef = useRef()
  const tripRequestsScrollRef = useRef()
  const tripSuggestionsScrollRef = useRef()
  const [excludeId, setExcludeId] = useState({
    NewTrips: null,
    TripRequests: null,
    PendingTripSuggestions: null,
    TripSuggestions: null,
  })

  const [checkedState, setCheckedState] = useState({
    isPendingChecked: false,
    isApprovedChecked: false,
    isDeniedChecked: false,
    isCancelledChecked: false,
  })
  const [userRole, setUserRole] = useState()

  const currentUserJwtData = useSelector(state => state.user.jwtData)
  useEffect(() => {
    if (currentUserJwtData) {
      setUserRole(currentUserJwtData.role)
    }
  })
  const [checkedSuggestedState, setCheckedSuggestedState] = useState({
    isPendingChecked: false,
    isApprovedChecked: false,
    isDeniedChecked: false,
    isCancelledChecked: false,
  })

  const getShorterName = (text, limit) => {
    let outputText
    if (text.length > limit) {
      outputText = `${text.substring(0, limit)}...`
      return outputText
    }
    return text
  }

  const changeExcludeId = params => {
    setExcludeId(prevState => ({ ...prevState, ...params }))
  }

  const changeCheckedState = params =>
    setCheckedState({
      isPendingChecked: false,
      isApprovedChecked: false,
      isDeniedChecked: false,
      isCancelledChecked: false,
      ...params,
    })

  const changeSuggestedCheckedState = params =>
    setCheckedSuggestedState({
      isPendingChecked: false,
      isApprovedChecked: false,
      isDeniedChecked: false,
      isCancelledChecked: false,
      ...params,
    })

  const resetTripRequestsTab = () => {
    changeCheckedState({})
    setSelectedRequestsStatus("")
    setTripRequestsToShow("")
    setTripRequestsOffset(0)
  }

  const resetTripSuggestionsTab = () => {
    changeSuggestedCheckedState({})
    setSelectedSuggestionsStatus("")
    setTripSuggestionsToShow("")
    setSuggestedTripsOffset(0)
  }

  const modalSuggestionAccept = useRef(null)
  const modalSuggestionDeny = useRef(null)

  const modalRequestApprove = useRef(null)
  const modalRequestDeny = useRef(null)

  useOnClickOutside(modalSuggestionAccept, () => setIsAcceptModalOpen(false))
  useOnClickOutside(modalSuggestionDeny, () => setIsDeniedSuggestionModalOpen(false))

  useOnClickOutside(modalRequestApprove, () => setIsApprovedModalOpen(false))
  useOnClickOutside(modalRequestDeny, () => setIsDeniedModalOpen(false))

  useEffect(() => {
    // for new requests table
    if (userRole === COMPANY_REPRESENTATIVE) {
      dispatch(getTripSuggestions())
    }
    if (userRole === LANDLINE_AGENT) {
      dispatch(getNewTripRequests())
    }

    // for all trip requests table
    if (userRole && userRole !== COMPANY_DRIVER) {
      dispatch(getTripRequests())
      dispatch(getSuggestedTrips())
      dispatch(getLastDayInfo())
    }
  }, [userRole])

  const tripRequestsLoading = useMemo(() => {
    return apiUtils.areCallsInProgress([API.GET_TRIP_REQUESTS], callsInProgress)
  }, [callsInProgress])

  const newTripRequestsLoading = useMemo(() => {
    return apiUtils.areCallsInProgress([API.GET_NEW_TRIP_REQUESTS], callsInProgress)
  }, [callsInProgress])

  const tripSuggestionsLoading = useMemo(() => {
    return apiUtils.areCallsInProgress([API.GET_SUGGESTED_TRIPS], callsInProgress)
  }, [callsInProgress])

  const pendingSuggestionsLoading = useMemo(() => {
    return apiUtils.areCallsInProgress([API.GET_PENDING_TRIP_SUGGESTIONS], callsInProgress)
  }, [callsInProgress])

  const loadMoreNewRequests = () => {
    if (!newTripRequestsLoading) {
      const currentOffset = newRequestsOffset + PAGE_LIMIT
      dispatch(getNewTripRequests(currentOffset, excludeId.NewTrips))
      setNewRequestsOffset(currentOffset)
    }
  }

  const loadMorePendingSuggestions = () => {
    if (!pendingSuggestionsLoading) {
      const currentOffset = pendingSuggestionsOffset + PAGE_LIMIT
      dispatch(getTripSuggestions(currentOffset, excludeId.PendingTripSuggestions))
      setPendingSuggestionsOffset(currentOffset)
    }
  }

  const loadMoreTripRequests = () => {
    if (!tripRequestsLoading) {
      const currentOffset = tripRequestsOffset + PAGE_LIMIT
      dispatch(getTripRequests(selectedRequestsStatus, currentOffset, excludeId.TripRequests))
      setTripRequestsOffset(currentOffset)
    }
  }

  const loadMoreTripSuggestions = () => {
    if (!tripSuggestionsLoading) {
      const currentOffset = suggestedTripsOffset + PAGE_LIMIT
      dispatch(
        getSuggestedTrips(selectedSuggestionsStatus, currentOffset, excludeId.TripSuggestions),
      )
      setSuggestedTripsOffset(currentOffset)
    }
  }

  const refreshTripRequests = () => {
    setTripRequestsOffset(0)

    dispatch(getTripRequests(selectedRequestsStatus))
  }

  const refreshTripSuggestions = () => {
    setSuggestedTripsOffset(0)
    dispatch(getSuggestedTrips(selectedSuggestionsStatus))
  }

  const sortTrip = useCallback(
    (sortBy, sortDirection, changeTable) => {
      let parsedSortBy = sortBy
      if (sortDirection === "Descending") {
        parsedSortBy = `-${parsedSortBy}`
      }
      if (sortDirection === "None") {
        parsedSortBy = undefined
      }

      dispatch(sortTripTable(parsedSortBy, changeTable))
    },
    [dispatch, sortTripTable],
  )

  const newRequestsColumns = [
    {
      id: 1,
      name: "name",
      component: tripRequest => (
        <RowItem>
          <div className="new-requests-avatar-wrapper">
            <UserAvatarName
              className="new-requests-company-representative-avatar"
              fname={tripRequest.company_representative_details?.first_name}
              lname={tripRequest.company_representative_details?.last_name}
              avatarType={getUserAvatar(tripRequest.company_representative_details?.avatar)}
              description={tripRequest.company?.name}
              companyColor={tripRequest.company?.color}
              avatarURL={tripRequest.company_representative_details?.avatar}
            />
            <StatusTableCellWithPopup reduceRate={tripRequest.reduced_rate} />
          </div>
        </RowItem>
      ),
    },
    {
      id: 2,
      name: "pnr",
      component: tripRequest => (
        <TableTwoRow
          className="new-requests-pnr-row"
          firstRow={tripRequest.trip_details?.pnr}
          secondRow="PNR"
        />
      ),
    },
    {
      id: 3,
      name: "date",
      component: tripRequest => (
        <TableTwoRow
          className="new-requests-date-row"
          firstRow={moment(tripRequest.trip_details.departure_time).format("MMM, DD, YYYY")}
          secondRow="DATE"
        />
      ),
    },
    {
      id: 4,
      name: "details",
      component: tripRequest => (
        <RowItem>
          <span
            role="button"
            onClick={() => {
              redirectDetails(tripRequest.trip_details, history, dispatch)
            }}
            className="new-requests-details-link"
          >
            Details →
          </span>
        </RowItem>
      ),
    },
    {
      id: 5,
      name: "approveButton",
      component: tripRequest => (
        <RowItem>
          <div
            className="customButton approveBtn"
            onClick={() => {
              setIsApprovedModalOpen(true)
              setSelectedTripRequest(tripRequest)
            }}
            role="button"
          >
            <SvgIcon icon="green-checkmark" width="16" height="16" margin="0px 8px 0px 0px" />
            <p>Approve</p>
          </div>
        </RowItem>
      ),
    },
    {
      id: 6,
      name: "denyButton",
      component: tripRequest => (
        <RowItem>
          <div
            className="customButton denyBtn"
            role="button"
            onClick={() => {
              setIsDeniedModalOpen(true)
              setSelectedTripRequest(tripRequest)
            }}
          >
            <SvgIcon icon="x-mark" width="16" height="16" margin="0px 8px 0px 0px" fill="#E35C55" />
            <p>Deny</p>
          </div>
        </RowItem>
      ),
    },
  ]

  const pendingTripSuggestions = [
    {
      id: 1,
      name: "name",
      component: tripSuggestion => (
        <RowItem>
          <div className="new-requests-avatar-wrapper">
            <UserAvatarName
              className="new-requests-company-representative-avatar"
              fname={tripSuggestion.landline_agent_details?.first_name}
              lname={tripSuggestion.landline_agent_details?.last_name}
              avatarType={getUserAvatar(tripSuggestion.landline_agent_details?.avatar)}
              avatarURL={tripSuggestion.landline_agent_details?.avatar}
              description="LANDLINE OPERATOR"
              companyColor={tripSuggestion.company_details?.logo}
              nameFontSize="14px"
            />
          </div>
        </RowItem>
      ),
    },
    {
      id: 2,
      name: "pnr",
      component: tripSuggestion => (
        <TableTwoRow
          className="new-requests-pnr-row"
          firstRow={tripSuggestion.trip_details?.pnr}
          secondRow="PNR"
        />
      ),
    },
    {
      id: 3,
      name: "date",
      component: tripSuggestion => (
        <TableTwoRow
          className="new-requests-date-row"
          firstRow={moment(tripSuggestion.trip_details.departure_time).format("MMM, DD, YYYY")}
          secondRow="DATE"
        />
      ),
    },
    {
      id: 4,
      name: "details",
      component: tripSuggestion => (
        <RowItem>
          <span
            className="new-requests-details-link"
            role="button"
            onClick={() => {
              redirectDetails(tripSuggestion.trip_details, history, dispatch)
            }}
          >
            Details →
          </span>
        </RowItem>
      ),
    },
    {
      id: 5,
      name: "approveButton",
      component: tripSuggestion => (
        <RowItem>
          <div
            className="customButton approveBtn"
            onClick={() => {
              setIsAcceptModalOpen(true)
              setSelectedTripRequest(tripSuggestion)
            }}
            role="button"
          >
            <SvgIcon icon="green-checkmark" width="16" height="16" margin="0px 8px 0px 0px" />
            <p>Accept</p>
          </div>
        </RowItem>
      ),
    },
    {
      id: 6,
      name: "denyButton",
      component: tripSuggestion => (
        <RowItem>
          <div
            className="customButton denyBtn"
            onClick={() => {
              setIsDeniedSuggestionModalOpen(true)
              setSelectedTripRequest(tripSuggestion)
            }}
            role="button"
          >
            <SvgIcon icon="x-mark" width="16" height="16" margin="0px 8px 0px 0px" />
            <p>Deny</p>
          </div>
        </RowItem>
      ),
    },
  ]

  const tripSuggestionsColumns = [
    {
      id: 1,
      name: "RLOC (PNR)",
      onClick: sortDirection => sortTrip("pnr", sortDirection, TRIP_SUGGESTIONS_TABLE),
      component: tripSuggestion => {
        return (
          <RowItem>
            <div
              className="body2-semibold pnrBtn"
              role="button"
              onClick={() => {
                redirectDetails(tripSuggestion.trip_details, history, dispatch)
              }}
            >
              <StatusTableCellWithPopup reduceRate={tripSuggestion.reduced_rate} />
              <div className="body2-semibold">{tripSuggestion.trip_details?.pnr}</div>
            </div>
          </RowItem>
        )
      },
    },
    {
      id: 2,
      name: "STATUS",
      onClick: sortDirection => sortTrip("status", sortDirection, TRIP_SUGGESTIONS_TABLE),
      component: tripSuggestion => (
        <StatusTableCell status={tripSuggestion.status} statusConst={TRIP_SUGGESTION_STATUS} />
      ),
    },
    {
      id: 3,
      name: "OPERATOR",
      onClick: sortDirection => sortTrip("company", sortDirection, TRIP_SUGGESTIONS_TABLE),
      component: tripSuggestion => (
        <TableDropDown
          status={tripSuggestion.status}
          display
          value={
            <AvatarName
              className="body2-semibold"
              fname={
                tripSuggestion.company_details ? tripSuggestion.company_details.name : "No Company"
              }
              avatarURL={tripSuggestion.company_details?.logo}
              avatarType={getCompanyIcon(
                tripSuggestion?.company_details,
                tripSuggestion.company_details?.logo,
              )}
              companyColor={tripSuggestion.company_details?.color}
            />
          }
        />
      ),
    },
    {
      id: 4,
      name: "DEPT. TIME",
      onClick: sortDirection => sortTrip("departure_date", sortDirection, TRIP_SUGGESTIONS_TABLE),
      component: tripSuggestion => (
        <TablePopup
          popupLabel={tripSuggestion.trip_details?.depart.time_zone}
          icon={<img src="./media/global-time-icon.png" alt="" />}
        >
          <TableTwoRow
            className="date-row"
            firstRow={moment(tripSuggestion.trip_details?.departure_time).format(
              TWELVE_HOUR_WITH_LEADING_ZERO,
            )}
            secondRow={moment(tripSuggestion.trip_details?.departure_time).format("MMM, DD, yyyy ")}
          />
        </TablePopup>
      ),
    },
    {
      id: 5,
      name: "DEPT. ARP",
      onClick: sortDirection =>
        sortTrip("departure_airport", sortDirection, TRIP_SUGGESTIONS_TABLE),
      component: tripSuggestion => <RowItem> {tripSuggestion.trip_details?.depart?.iata}</RowItem>,
    },
  ]

  const tripRequestsColumns = [
    {
      id: 1,
      name: "RLOC (PNR)",
      onClick: sortDirection => sortTrip("pnr", sortDirection, TRIP_REQUESTS_TABLE),
      component: tripRequest => {
        return (
          <RowItem>
            <div
              className="body2-semibold pnrBtn"
              role="button"
              onClick={() => {
                redirectDetails(tripRequest.trip_details, history, dispatch)
              }}
            >
              <div className="body2-semibold">{tripRequest.trip_details?.pnr}</div>
              <StatusTableCellWithPopup reduceRate={tripRequest.reduced_rate} />
            </div>
          </RowItem>
        )
      },
    },
    {
      id: 2,
      name: "STATUS",
      onClick: sortDirection => sortTrip("status", sortDirection, TRIP_REQUESTS_TABLE),
      component: tripRequest => (
        <StatusTableCell status={tripRequest.status} statusConst={TRIP_REQUEST_STATUS} />
      ),
    },
    {
      id: 3,
      name: "OPERATOR",
      onClick: sortDirection => sortTrip("company", sortDirection, TRIP_REQUESTS_TABLE),
      component: tripRequest => (
        <InfoPopup
          disabled={tripRequest.company.name.length <= MAX_LETTER_OPERATOR}
          popupText={tripRequest.company.name}
          noArrow
          position={{ left: "0", top: "-15px" }}
        >
          <TableDropDown
            status={tripRequest.status}
            display
            value={
              <AvatarName
                className="body2-semibold"
                fname={
                  tripRequest.company
                    ? getShorterName(tripRequest.company.name, MAX_LETTER_OPERATOR)
                    : "No Company"
                }
                avatarURL={tripRequest.company?.logo}
                avatarType={getCompanyIcon(tripRequest?.company, tripRequest.company?.logo)}
                companyColor={tripRequest.company?.color}
              />
            }
          />
        </InfoPopup>
      ),
    },
    {
      id: 4,
      name: "DRIVER",
      onClick: sortDirection => sortTrip("driver", sortDirection, TRIP_REQUESTS_TABLE),
      component: tripRequest => {
        return (
          <InfoPopup
            disabled={
              !tripRequest.driver_details?.first_name ||
              !tripRequest.driver_details?.last_name ||
              getShorterName(
                `${tripRequest.driver_details?.first_name} ${tripRequest.driver_details?.last_name}`,
                MAX_LETTER_DRIVER,
              ).length !==
                MAX_LETTER_DRIVER + 3
            }
            // + 3 is for those 3 dots at the end
            popupText={`${tripRequest.driver_details?.first_name} ${tripRequest.driver_details?.last_name}`}
            noArrow
            position={{ left: "0", top: "-15px" }}
          >
            <RowItem>
              {tripRequest.driver_details?.first_name || tripRequest.driver_details?.last_name
                ? getShorterName(
                    `${tripRequest.driver_details?.first_name} ${tripRequest.driver_details?.last_name}`,
                    MAX_LETTER_DRIVER,
                  )
                : "No Driver"}
            </RowItem>
          </InfoPopup>
        )
      },
    },
    {
      id: 5,
      name: "VEHICLE",
      onClick: sortDirection => sortTrip("vehicle", sortDirection, TRIP_REQUESTS_TABLE),
      component: tripRequest => {
        const getShorterText = text => {
          const shorterName =
            text.length > MAX_LETTER_VEHICLE ? `${text.substring(0, MAX_LETTER_VEHICLE)}...` : text
          return shorterName
        }

        return (
          <RowItem>
            {tripRequest.vehicle_details?.vehicle_id ? (
              <div
                className="vehicle-text"
                onMouseEnter={() => setPopupOpen(tripRequest.id)}
                onMouseLeave={() => setPopupOpen(false)}
              >
                {getShorterText(tripRequest.vehicle_details?.vehicle_id)}
              </div>
            ) : (
              <div className="no-vehicle-text">No vehicle</div>
            )}

            {popupOpen === tripRequest.id &&
              tripRequest.vehicle_details?.vehicle_id.length > MAX_LETTER_VEHICLE && (
                <p className="dashboard-popup-element">{tripRequest.vehicle_details?.vehicle_id}</p>
              )}
          </RowItem>
        )
      },
    },
    {
      id: 6,
      name: "DEPT. TIME",
      onClick: sortDirection => sortTrip("departure_date", sortDirection, TRIP_REQUESTS_TABLE),
      component: tripRequest => (
        <TablePopup
          popupLabel={tripRequest.trip_details?.depart.time_zone}
          icon={<img src="./media/global-time-icon.png" alt="" />}
        >
          <TableTwoRow
            firstRow={moment(tripRequest.trip_details?.departure_time).format(
              TWELVE_HOUR_WITH_LEADING_ZERO,
            )}
            secondRow={moment(tripRequest.trip_details?.departure_time).format("MMM, DD, yyyy ")}
          />
        </TablePopup>
      ),
    },
    {
      id: 7,
      name: "DEPT. ARP",
      onClick: sortDirection => sortTrip("departure_airport", sortDirection, TRIP_REQUESTS_TABLE),
      component: tripRequest => <RowItem>{tripRequest.trip_details?.depart?.iata}</RowItem>,
    },
  ]

  const getSuggestionCancelComponent = trip => {
    const isEnabled = trip.status === TRIP_SUGGESTION_PENDING_STATUS && userRole === LANDLINE_AGENT

    return (
      <>
        <div
          className="three-dots-button"
          role="button"
          onClick={() => {
            if (isEnabled) {
              setSelectedTripSuggestion(trip)
              setIsCancelTripModalOpen(true)
            }
          }}
        >
          <InfoPopup popupText="Cancel" position={{ right: "21px", top: "-5px" }}>
            {isEnabled && <SvgIcon className="closeBtn" icon="close" width="16" height="16" />}
          </InfoPopup>
        </div>
      </>
    )
  }

  const getRequestCancelComponent = trip => {
    const isEnabled =
      trip.status === TRIP_REQUEST_PENDING_STATUS && userRole === COMPANY_REPRESENTATIVE

    return (
      <>
        <div
          className="three-dots-button"
          role="button"
          onClick={() => {
            if (isEnabled) {
              setSelectedTripRequest(trip)
              setIsCancelTripModalOpen(true)
            }
          }}
        >
          <InfoPopup popupText="Cancel" position={{ right: "21px", top: "-5px" }}>
            {isEnabled && <SvgIcon icon="close" width="16" height="16" />}
          </InfoPopup>
        </div>
      </>
    )
  }

  const tripRequestsTabs = [
    {
      checked: checkedState.isPendingChecked,
      name: "Pending",
      onClick: () => {
        tripRequestsScrollRef.current.scrollTo(0, 0)
        changeCheckedState({ isPendingChecked: !checkedState.isPendingChecked })
        setTripRequestsOffset(0)
        if (!checkedState.isPendingChecked) {
          dispatch(getTripRequests(TRIP_REQUEST_PENDING_STATUS))
          setTripRequestsToShow("Pending")
          setSelectedRequestsStatus(TRIP_REQUEST_PENDING_STATUS)
        } else {
          dispatch(getTripRequests())
          setTripRequestsToShow("")
          setSelectedRequestsStatus(undefined)
        }
      },
    },
    {
      checked: checkedState.isApprovedChecked,
      name: "Approved",
      onClick: () => {
        tripRequestsScrollRef.current.scrollTo(0, 0)
        changeCheckedState({ isApprovedChecked: !checkedState.isApprovedChecked })
        setTripRequestsOffset(0)
        if (!checkedState.isApprovedChecked) {
          dispatch(getTripRequests(TRIP_REQUEST_APPROVED_STATUS))
          setTripRequestsToShow("Approved")
          setSelectedRequestsStatus(TRIP_REQUEST_APPROVED_STATUS)
        } else {
          dispatch(getTripRequests())
          setTripRequestsToShow("")
          setSelectedRequestsStatus(undefined)
        }
      },
    },
    {
      checked: checkedState.isDeniedChecked,
      name: "Denied",
      onClick: () => {
        tripRequestsScrollRef.current.scrollTo(0, 0)
        setTripRequestsOffset(0)
        changeCheckedState({ isDeniedChecked: !checkedState.isDeniedChecked })
        if (!checkedState.isDeniedChecked) {
          dispatch(getTripRequests(TRIP_REQUEST_DENIED_STATUS))
          setTripRequestsToShow("Denied")
          setSelectedRequestsStatus(TRIP_REQUEST_DENIED_STATUS)
        } else {
          dispatch(getTripRequests())
          setTripRequestsToShow("")
          setSelectedRequestsStatus(undefined)
        }
      },
    },
    {
      checked: checkedState.isCancelledChecked,
      name: "Cancelled",
      onClick: () => {
        tripRequestsScrollRef.current.scrollTo(0, 0)
        setTripRequestsOffset(0)
        changeCheckedState({ isCancelledChecked: !checkedState.isCancelledChecked })
        dispatch(getTripRequests(TRIP_REQUEST_CANCELLED_STATUS))
        if (!checkedState.isCancelledChecked) {
          dispatch(getTripRequests(TRIP_REQUEST_CANCELLED_STATUS))
          setTripRequestsToShow("Cancelled")
          setSelectedRequestsStatus(TRIP_REQUEST_CANCELLED_STATUS)
        } else {
          dispatch(getTripRequests())
          setTripRequestsToShow("")
          setSelectedRequestsStatus(undefined)
        }
      },
    },
  ]

  const tripSuggestionsTabs = [
    {
      checked: checkedSuggestedState.isPendingChecked,
      name: "Pending",
      onClick: () => {
        changeSuggestedCheckedState({ isPendingChecked: !checkedSuggestedState.isPendingChecked })
        setSuggestedTripsOffset(0)
        if (!checkedSuggestedState.isPendingChecked) {
          dispatch(getSuggestedTrips(TRIP_SUGGESTION_PENDING_STATUS))
          setTripSuggestionsToShow("Pending")
          setSelectedSuggestionsStatus(TRIP_SUGGESTION_PENDING_STATUS)
        } else {
          dispatch(getSuggestedTrips())
          setTripSuggestionsToShow("")
          setSelectedSuggestionsStatus(undefined)
        }
      },
    },
    {
      checked: checkedSuggestedState.isApprovedChecked,
      name: "Approved",
      onClick: () => {
        changeSuggestedCheckedState({ isApprovedChecked: !checkedSuggestedState.isApprovedChecked })
        setSuggestedTripsOffset(0)
        if (!checkedSuggestedState.isApprovedChecked) {
          dispatch(getSuggestedTrips(TRIP_SUGGESTION_APPROVED_STATUS))
          setTripSuggestionsToShow("Approved")
          setSelectedSuggestionsStatus(TRIP_SUGGESTION_APPROVED_STATUS)
        } else {
          dispatch(getSuggestedTrips())
          setTripSuggestionsToShow("")
          setSelectedSuggestionsStatus(undefined)
        }
      },
    },
    {
      checked: checkedSuggestedState.isDeniedChecked,
      name: "Denied",
      onClick: () => {
        changeSuggestedCheckedState({ isDeniedChecked: !checkedSuggestedState.isDeniedChecked })
        setSuggestedTripsOffset(0)
        if (!checkedSuggestedState.isDeniedChecked) {
          dispatch(getSuggestedTrips(TRIP_SUGGESTION_DENIED_STATUS))
          setTripSuggestionsToShow("Denied")
          setSelectedSuggestionsStatus(TRIP_SUGGESTION_DENIED_STATUS)
        } else {
          dispatch(getSuggestedTrips())
          setTripSuggestionsToShow("")
          setSelectedSuggestionsStatus(undefined)
        }
      },
    },
    {
      checked: checkedSuggestedState.isCancelledChecked,
      name: "Cancelled",
      onClick: () => {
        changeSuggestedCheckedState({
          isCancelledChecked: !checkedSuggestedState.isCancelledChecked,
        })
        setSuggestedTripsOffset(0)
        if (!checkedSuggestedState.isCancelledChecked) {
          dispatch(getSuggestedTrips(TRIP_SUGGESTION_CANCELLED_STATUS))
          setTripSuggestionsToShow("Cancelled")
          setSelectedSuggestionsStatus(TRIP_SUGGESTION_CANCELLED_STATUS)
        } else {
          dispatch(getSuggestedTrips())
          setTripSuggestionsToShow("")
          setSelectedSuggestionsStatus(undefined)
        }
      },
    },
  ]

  return (
    <RegularLayout history={history} withTopMenu={false} backgroundColor="">
      <div className={DashboardPageClass}>
        {userRole === LANDLINE_AGENT ? (
          <>
            <div className="welcome-message-container">
              <span>Hello {currentUser.first_name}!</span>
              {trips.NewTrips?.count > 0 && (
                <h2>
                  You have {trips.NewTrips?.count} pending request
                  {trips.NewTrips?.count > 1 && "s"}
                </h2>
              )}
              {trips.NewTrips?.count < 1 && <h2>You have no pending requests</h2>}
            </div>

            <NewRequestsTable
              requestsData={trips.NewTrips?.results}
              columns={newRequestsColumns}
              loadMore={loadMoreNewRequests}
              hasMore={trips.NewTrips?.next}
              initialLoading={
                (newTripRequestsLoading && !newRequestsOffset) || trips.NewTrips?.initialLoading
              }
              newRequestsScrollRef={newRequestsScrollRef}
            />
          </>
        ) : null}
        {userRole === COMPANY_REPRESENTATIVE ? (
          <>
            <div className="welcome-message-container">
              <span>Hello {currentUser.first_name}!</span>
              {trips.PendingTripSuggestions?.count > 0 && (
                <h2>
                  You have {trips.PendingTripSuggestions?.count} suggested booking
                  {trips.PendingTripSuggestions?.count > 1 && "s"}
                </h2>
              )}
              {trips.PendingTripSuggestions?.count < 1 && <h2>You have no suggested bookings</h2>}
            </div>

            <PendingRequestsTable
              requestsData={trips.PendingTripSuggestions?.results}
              columns={pendingTripSuggestions}
              loadMore={loadMorePendingSuggestions}
              hasMore={trips.PendingTripSuggestions?.next}
              initialLoading={
                (pendingSuggestionsLoading && !pendingSuggestionsOffset) ||
                trips.PendingTripSuggestions?.initialLoading
              }
              pendingSuggestionsScrollRef={pendingSuggestionsScrollRef}
            />
          </>
        ) : null}

        <TripRequestTable
          initialRequestsLoading={
            (tripRequestsLoading && !tripRequestsOffset) || trips.TripRequests?.initialLoading
          }
          initialSuggestionsLoading={
            (tripSuggestionsLoading && !suggestedTripsOffset) ||
            trips.TripSuggestions?.initialLoading
          }
          tripSuggestionsColumns={tripSuggestionsColumns}
          tripRequestsTabs={tripRequestsTabs}
          tripSuggestionsTabs={tripSuggestionsTabs}
          tripRequests={trips[`${tripRequestsToShow}TripRequests`]?.results}
          tripSuggestions={trips[`${tripSuggestionsToShow}TripSuggestions`]?.results}
          columns={tripRequestsColumns}
          loadMoreTripRequests={loadMoreTripRequests}
          loadMoreTripSuggestions={loadMoreTripSuggestions}
          hasMoreRequests={!!trips[`${tripRequestsToShow}TripRequests`]?.next}
          hasMoreSuggestions={!!trips[`${tripSuggestionsToShow}TripSuggestions`]?.next}
          tripRequestsScrollRef={tripRequestsScrollRef}
          tripSuggestionsScrollRef={tripSuggestionsScrollRef}
          getRequestCancelComponent={getRequestCancelComponent}
          getSuggestionCancelComponent={getSuggestionCancelComponent}
        />
      </div>

      <Modal
        render={() => (
          <div className="buttonsContainer" ref={modalRequestApprove}>
            <div>
              <Button
                onClick={() => {
                  dispatch(approveTripRequest(selectedTripRequest.id))
                  setIsApprovedModalOpen(false)
                }}
                buttonType="landlineColor"
                buttonSize="modalButtonSize"
                type="button"
                margin="44px 0px 0px 0px"
              >
                Yes
              </Button>
            </div>
            <div>
              <Button
                onClick={() => {
                  setIsApprovedModalOpen(false)
                }}
                buttonType="grayColor"
                buttonSize="modalButtonSize"
                type="button"
                margin="44px 0px 0px 12px"
              >
                No
              </Button>
            </div>
          </div>
        )}
        isOpen={isApprovedModalOpen}
        style={{
          overlay: {
            position: "fixed",
            display: "flex",
            backgroundColor: "rgba(0, 0, 0, 0.2)",
            zIndex: 99999,
            overflow: "none",
          },
          content: {
            width: 318,
            height: 160,
            display: "flex",
            overflow: "none",
            padding: "84px 24px 88px 24px",
            boxShadow: "0px 0px 16px 4px rgba(66, 64, 62, 0.12)",
            borderRadius: "4px",
            border: "none",
            alignItems: "center",
          },
        }}
        title="Are you sure you want to approve this request?"
      />
      <Modal
        render={() => (
          <div className="buttonsContainer" ref={modalRequestDeny}>
            <div>
              <Button
                onClick={() => {
                  dispatch(denyTripRequest(selectedTripRequest.id))
                  setIsDeniedModalOpen(false)
                }}
                buttonType="landlineColor"
                buttonSize="modalButtonSize"
                type="button"
                margin="44px 0px 0px 0px"
              >
                Yes
              </Button>
            </div>
            <div>
              <Button
                onClick={() => {
                  setIsDeniedModalOpen(false)
                }}
                buttonType="grayColor"
                buttonSize="modalButtonSize"
                type="button"
                margin="44px 0px 0px 12px"
              >
                No
              </Button>
            </div>
          </div>
        )}
        isOpen={isDeniedModalOpen}
        style={{
          overlay: {
            position: "fixed",
            display: "flex",
            backgroundColor: "rgba(0, 0, 0, 0.2)",
            zIndex: 99999,
            overflow: "none",
          },
          content: {
            width: 318,
            height: 160,
            display: "flex",
            overflow: "none",
            padding: "84px 24px 88px 24px",
            boxShadow: "0px 0px 16px 4px rgba(66, 64, 62, 0.12)",
            borderRadius: "4px",
            border: "none",
            alignItems: "center",
          },
        }}
        title="Are you sure you want to deny this request?"
      />

      <Modal
        render={() => (
          <div className="buttonsContainer" ref={modalSuggestionAccept}>
            <div>
              <Button
                onClick={() => {
                  dispatch(acceptTripSuggestion(selectedTripRequest.id))
                  setIsAcceptModalOpen(false)
                }}
                buttonType="landlineColor"
                buttonSize="modalButtonSize"
                type="button"
                margin="44px 0px 0px 0px"
              >
                Yes
              </Button>
            </div>
            <div>
              <Button
                onClick={() => {
                  setIsAcceptModalOpen(false)
                }}
                buttonType="grayColor"
                buttonSize="modalButtonSize"
                type="button"
                margin="44px 0px 0px 12px"
              >
                No
              </Button>
            </div>
          </div>
        )}
        isOpen={isAcceptModalOpen}
        style={{
          overlay: {
            position: "fixed",
            display: "flex",
            backgroundColor: "rgba(0, 0, 0, 0.2)",
            zIndex: 99999,
            overflow: "none",
          },
          content: {
            width: 318,
            height: 160,
            display: "flex",
            overflow: "none",
            padding: "84px 24px 88px 24px",
            boxShadow: "0px 0px 16px 4px rgba(66, 64, 62, 0.12)",
            borderRadius: "4px",
            border: "none",
            alignItems: "center",
          },
        }}
        title="Are you sure you want to accept this suggestion?"
      />
      <Modal
        render={() => (
          <div className="buttonsContainer" ref={modalSuggestionDeny}>
            <div>
              <Button
                onClick={() => {
                  dispatch(denyTripSuggestion(selectedTripRequest.id))
                  setIsDeniedSuggestionModalOpen(false)
                }}
                buttonType="landlineColor"
                buttonSize="modalButtonSize"
                type="button"
                margin="44px 0px 0px 0px"
              >
                Yes
              </Button>
            </div>
            <div>
              <Button
                onClick={() => {
                  setIsDeniedSuggestionModalOpen(false)
                }}
                buttonType="grayColor"
                buttonSize="modalButtonSize"
                type="button"
                margin="44px 0px 0px 12px"
              >
                No
              </Button>
            </div>
          </div>
        )}
        isOpen={isDeniedSuggestionModalOpen}
        style={{
          overlay: {
            position: "fixed",
            display: "flex",
            backgroundColor: "rgba(0, 0, 0, 0.2)",
            zIndex: 99999,
            overflow: "none",
          },
          content: {
            width: 318,
            height: 160,
            display: "flex",
            overflow: "none",
            padding: "84px 24px 88px 24px",
            boxShadow: "0px 0px 16px 4px rgba(66, 64, 62, 0.12)",
            borderRadius: "4px",
            border: "none",
            alignItems: "center",
          },
        }}
        title="Are you sure you want to deny this suggestion?"
      />
      <Modal
        render={() => (
          <div className="buttonsContainer" ref={modalSuggestionAccept}>
            <div>
              <Button
                onClick={() => {
                  if (userRole === COMPANY_REPRESENTATIVE) {
                    dispatch(cancelTripRequest(selectedTripRequest, refreshTripRequests))
                  } else {
                    dispatch(cancelTripSuggestion(selectedTripSuggestion, refreshTripSuggestions))
                  }
                  setIsCancelTripModalOpen(false)
                }}
                buttonType="landlineColor"
                buttonSize="modalButtonSize"
                type="button"
                margin="44px 0px 0px 0px"
              >
                Yes
              </Button>
            </div>
            <div>
              <Button
                onClick={() => {
                  setIsCancelTripModalOpen(false)
                }}
                buttonType="grayColor"
                buttonSize="modalButtonSize"
                type="button"
                margin="44px 0px 0px 12px"
              >
                No
              </Button>
            </div>
          </div>
        )}
        isOpen={isCancelTripModalOpen}
        style={{
          overlay: {
            position: "fixed",
            display: "flex",
            backgroundColor: "rgba(0, 0, 0, 0.2)",
            zIndex: 99999,
            overflow: "none",
          },
          content: {
            width: 318,
            height: 160,
            display: "flex",
            overflow: "none",
            padding: "84px 24px 88px 24px",
            boxShadow: "0px 0px 16px 4px rgba(66, 64, 62, 0.12)",
            borderRadius: "4px",
            border: "none",
            alignItems: "center",
          },
        }}
        title={`Are you sure you want to cancel trip ${
          userRole === COMPANY_REPRESENTATIVE ? "request" : "suggestion"
        }?`}
      />
      <RightSideBar
        lastDayData={trips.lastDayInfo}
        changeExcludeId={changeExcludeId}
        resetTripRequestsTab={resetTripRequestsTab}
        resetTripSuggestionsTab={resetTripSuggestionsTab}
        tripRequestsScrollRef={tripRequestsScrollRef}
        newRequestsScrollRef={newRequestsScrollRef}
        pendingSuggestionsScrollRef={pendingSuggestionsScrollRef}
        tripSuggestionsScrollRef={tripSuggestionsScrollRef}
        tripRequestsShouldReset={!!tripRequestsToShow}
        tripSuggestionsShouldReset={!!tripSuggestionsToShow}
      />
    </RegularLayout>
  )
}

DashboardPage.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
}

DashboardPage.defaultProps = {}

export default DashboardPage
