import "./AvatarImage.scss"

import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"

const AvatarImage = ({ image, alt, isActive }) => {
  const avatarImageClass = classNames("avatar-image-container")
  const imageActiveClass = classNames("notActive", { imageWraper: isActive })
  return (
    <div className={avatarImageClass}>
      <div className={imageActiveClass}>
        <img src={image} alt={alt} />
      </div>
    </div>
  )
}

AvatarImage.propTypes = {
  image: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
}

AvatarImage.defaultProps = {}

export default AvatarImage
