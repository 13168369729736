import "./DriverStatus.scss"

import PropTypes from "prop-types"
import React, { useState } from "react"

import { DRIVER_STATUS } from "../../../constants/driverStatuses"
import SvgIcon from "../SvgIcon"

const DriverStatus = ({ type }) => {
  const [popUpVisible, setPopUpVisible] = useState(false)
  return (
    <div className="driver-status-container">
      <div onMouseEnter={() => setPopUpVisible(true)} onMouseLeave={() => setPopUpVisible(false)}>
        <SvgIcon icon={DRIVER_STATUS[type]} width="32" height="32" />
      </div>
      {popUpVisible && <div className="driver-status-popup">{DRIVER_STATUS[type]}</div>}
    </div>
  )
}

DriverStatus.propTypes = {
  type: PropTypes.number.isRequired,
}

DriverStatus.defaultProps = {}

export default DriverStatus
