import ACTIONS from "../../constants/ACTIONS"

const initialState = {
  notes: [],
}

export default function notesReducer(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.SAVE_NOTE_SUCCESS:
      return {
        ...state,
        notes: action.payload,
      }
    case ACTIONS.GET_TRIP_SUCCESS:
      return {
        ...state,
        notes: action.tripDetails.notes,
      }
    default:
      return { ...state }
  }
}
