import "./LoginPage.scss"

import PropTypes from "prop-types"
import React, { useEffect, useMemo, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { toast } from "react-toastify"

import API from "../../../constants/API"
import { ROUTES } from "../../../constants/routes"
import { loginUser } from "../../../redux/user/userActions"
import apiUtils from "../../../utils/api/api-utils"
import Button from "../../atoms/Button"
import CarAnimation from "../../atoms/carAnimation"
import Loading from "../../atoms/Loading"
import SvgIcon from "../../atoms/SvgIcon"
import TextInput from "../../atoms/TextInput"

const LoginPage = ({ history }) => {
  const dispatch = useDispatch()
  const [formError, setFormError] = useState("")
  const [showPassword, setShowPassword] = useState(false)
  const [callsInProgress, formData, currentUser] = useSelector(({ api, forms, user }) => [
    api.callsInProgress,
    forms.formData,
    user,
  ])

  const [isDisabled, setIsDisabled] = useState(true)

  const emailRef = useRef()
  const isChrome = !!window.chrome

  useEffect(() => {
    const tokenId = localStorage.getItem("accessToken")

    if (currentUser.jwtData || tokenId) {
      toast.warning("You must log out first")
      history.push("/")
    }
  }, [])

  useEffect(() => {
    if (emailRef.current && isChrome) {
      setTimeout(() => {
        if (emailRef.current?.matches(":-internal-autofill-selected")) {
          setIsDisabled(false)
        }
      }, 100)
    }
  }, [])

  useEffect(() => {
    setIsDisabled(!formData.email || !formData.password)
  }, [formData.email, formData.password])

  const loading = useMemo(() => {
    return apiUtils.areCallsInProgress([API.LOGIN_USER], callsInProgress)
  }, [callsInProgress])

  const onLogin = async e => {
    e.preventDefault()
    const actionFormError = await dispatch(loginUser(history))
    setFormError(actionFormError)
  }
  const handlePasswordToggle = () => {
    setShowPassword(!showPassword)
  }
  return (
    <div className="login-page-container">
      <div className="login-page-left">
        <div className="login-page-left-container">
          <SvgIcon icon="landline-gray-logo" width="200" height="33" margin="0px 0px 16px 0px" />
          <h2>Bus Operation and Security System</h2>
          <h4>Login to Your Account</h4>
          <form>
            <TextInput
              label="Email"
              placeholder="Enter email"
              field="email"
              inputType="email"
              fullWidth
              inputRef={emailRef}
            />
            <div className="form-input">
              <TextInput
                field="password"
                label="Password"
                fullWidth
                inputType={showPassword ? "text" : "password"}
                placeholder="Enter password"
                margin="16px 0px 0px 0px"
              />
              <div
                className="form-input-toggleBtn"
                onClick={() => handlePasswordToggle()}
                role="button"
              >
                <SvgIcon icon={showPassword ? "eye-show" : "eye-hide"} />
              </div>
            </div>
            <div className="checkboxForgotPWContainer">
              <a href={ROUTES.PASSWORD_RECOVERY_PAGE}>Forgot password?</a>
            </div>
            <div className="form-error-container">{formError}</div>
            <Button
              disabled={isDisabled}
              onClick={e => onLogin(e)}
              fullWidth
              margin="56px 0px 0px 0px"
            >
              {!loading ? "Login" : <Loading className="login-loading" />}
            </Button>
          </form>
        </div>
      </div>
      <div className="login-page-right">
        <CarAnimation />
      </div>
    </div>
  )
}

LoginPage.propTypes = {
  history: PropTypes.instanceOf(Object),
}

LoginPage.defaultProps = {
  history: {},
}

export default LoginPage
