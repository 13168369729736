import "./NewRequestsTable.scss"

import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"

import EmptyState from "../../molecules/EmptyState"
import Table from "../Table"
import TableBody from "../TableBody"

const NewRequestsTable = ({
  requestsData,
  columns,
  loadMore,
  hasMore,
  initialLoading,
  newRequestsScrollRef,
}) => {
  const newRequestsTableClass = classNames("new-requests-table-container")
  return (
    <div className={newRequestsTableClass}>
      {(initialLoading || requestsData.length > 0) && (
        <Table id="newRequestsTable" title="PENDING TRIP REQUESTS">
          <TableBody
            columns={columns}
            data={requestsData}
            loadMore={loadMore}
            hasMore={hasMore}
            initialLoading={initialLoading}
            scrollRef={newRequestsScrollRef}
            tableBodyType="newTripRequests"
          />
        </Table>
      )}
      {!requestsData.length && !initialLoading && <EmptyState />}
    </div>
  )
}

NewRequestsTable.propTypes = {
  requestsData: PropTypes.instanceOf(Array),
  columns: PropTypes.instanceOf(Array),
  loadMore: PropTypes.func,
  hasMore: PropTypes.bool,
  initialLoading: PropTypes.bool,
  newRequestsScrollRef: PropTypes.instanceOf(Element),
}

NewRequestsTable.defaultProps = {
  requestsData: [],
  columns: [],
  loadMore: () => {},
  hasMore: false,
  initialLoading: false,
  newRequestsScrollRef: false,
}

export default NewRequestsTable
