export const OVERLAPPING_RIDE = 1
export const OUTSIDE_OF_THE_SHIFT = 2
export const OVER_DRIVE_LIMIT = 3
export const OVER_DUTY_LIMIT = 4
export const DRIVER_NOT_AVAILABLE = 5
export const OVER_ROLLING_DUTY_LIMIT = 6
export const OVER_WAIT_LIMIT = 7

export const DRIVER_ERRORS = {
  [OVERLAPPING_RIDE]: "Overlapping with other trip",
  [OUTSIDE_OF_THE_SHIFT]: "Outside of the shift",
  [OVER_DRIVE_LIMIT]: "Drive limit",
  [OVER_DUTY_LIMIT]: "Duty limit",
  [DRIVER_NOT_AVAILABLE]: "",
  [OVER_ROLLING_DUTY_LIMIT]: "Duty limit 8 days rolling period",
  [OVER_WAIT_LIMIT]: "Over wait time limit",
}
