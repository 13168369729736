import "./TableOperatorActions.scss"

import classNames from "classnames"
import PropTypes from "prop-types"
import React, { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import API from "../../../constants/API"
import { PAGE_LIMIT } from "../../../constants/offset"
import {
  APPROVED_STATUS,
  CANCELLED_STATUS,
  NEW_STATUS,
  UNASSIGNED_STATUS,
} from "../../../constants/status"
import { LANDLINE_AGENT } from "../../../constants/userRoles"
import {
  approveTrip,
  changeToNewTrip,
  getCompanies,
  suggestTrip,
  unassignTrip,
} from "../../../redux/actions"
import apiUtils from "../../../utils/api/api-utils"
import { getCompanyIcon } from "../../../utils/common"
import AvatarName from "../../atoms/AvatarName"
import Button from "../../atoms/Button"
import ListView from "../../molecules/ListView"
import TabLayout from "../../molecules/TabLayout"

const TableOperatorActions = ({ row, toggleMenu, shouldShowOnTop }) => {
  const dispatch = useDispatch()
  const TableOperatorActionsClass = classNames("table-operator-operations-container", {
    "show-on-top": shouldShowOnTop,
  })

  const [allCompanies, userRole, callsInProgress] = useSelector(({ company, user, api }) => [
    company.allCompanies,
    user.jwtData?.role,
    api.callsInProgress,
  ])

  const loading = useMemo(() => {
    return apiUtils.areCallsInProgress([API.GET_COMPANIES], callsInProgress)
  }, [callsInProgress])

  const [operatorToAssing, setOperatorToAssing] = useState(row.company_details?.id)
  const [tabIndex, setTabIndex] = useState(0)
  const [offset, setOffset] = useState(0)

  useEffect(() => {
    dispatch(getCompanies())
  }, [tabIndex])

  const loadMore = () => {
    if (!loading) {
      const currentOffset = offset + PAGE_LIMIT
      dispatch(getCompanies({ offset: currentOffset }))
      setOffset(currentOffset)
    }
  }

  const companyToDisplayFirst = row.company_details

  const getCompaniesToDisplay = () => {
    if (companyToDisplayFirst && allCompanies.results.length > 0) {
      const companiesToDisplay = [...allCompanies.results]

      const selectedCompanyIndex = allCompanies.results.findIndex(
        company => company.id === companyToDisplayFirst.id,
      )

      companiesToDisplay.unshift(companiesToDisplay.splice(selectedCompanyIndex, 1)[0])
      return companiesToDisplay
    }

    return allCompanies.results
  }

  const checkIfSuggested = operatorId => {
    return row.trip_suggestions.find(trip => trip.company_details.id === operatorId)
  }

  const checkIfAssigned = operatorId => {
    if (companyToDisplayFirst && operatorId === companyToDisplayFirst.id) {
      return true
    }
    return false
  }

  const getInitialCheckedState = () => {
    const initialCheckedState = {}

    allCompanies.results.forEach(company => {
      initialCheckedState[company.id] = false
    })

    return initialCheckedState
  }
  const initialCheckedState = getInitialCheckedState()

  const [suggestCheckedState, setSuggestCheckedState] = useState(initialCheckedState)
  const [assignCheckedState, setAssignCheckedState] = useState(initialCheckedState)

  const [unassignedChecked, setUnassignedChecked] = useState(false)
  const [newBookingChecked, setNewBookingChecked] = useState(false)

  const handleUnassignedClick = () => {
    setUnassignedChecked(!unassignedChecked)
    setAssignCheckedState(initialCheckedState)
  }

  const handleNewBookingClick = () => {
    setNewBookingChecked(!newBookingChecked)
    setAssignCheckedState(initialCheckedState)
  }

  const changeSuggestCheckedState = params => {
    setSuggestCheckedState(prevState => ({ ...prevState, ...params }))
  }

  const changeAssignCheckedState = params => {
    setUnassignedChecked(false)
    setNewBookingChecked(false)
    setAssignCheckedState({ ...initialCheckedState, ...params })
  }

  const getOperatorToAssign = () => {
    const selectedCompany = allCompanies.results.find(company => assignCheckedState[company.id])
    return selectedCompany.id
  }

  const getOperatorsToSuggest = () => {
    const selectedCompanies = allCompanies.results.filter(
      company => suggestCheckedState[company.id],
    )
    return selectedCompanies.map(company => company.id)
  }

  const onConfirm = () => {
    if (tabIndex === 0) {
      if (unassignedChecked) {
        dispatch(unassignTrip(row.id))
        toggleMenu(false)
        return
      }
      if (newBookingChecked) {
        dispatch(changeToNewTrip(row.id))
        toggleMenu(false)
        return
      }
      dispatch(approveTrip(row.id, getOperatorToAssign()))
      toggleMenu(false)
    } else {
      dispatch(suggestTrip(row.id, getOperatorsToSuggest()))
      toggleMenu(false)
    }
  }

  const getListViewContent = () => {
    if (row.status === NEW_STATUS || row.status === CANCELLED_STATUS) {
      return []
    }
    return getCompaniesToDisplay()
  }

  const onCancel = () => {
    toggleMenu(false)
  }

  const isConfirmDisabled = () => {
    if (tabIndex === 0) {
      return (
        Object.keys(assignCheckedState).every(prop => !assignCheckedState[prop]) &&
        !unassignedChecked &&
        !newBookingChecked
      )
    }

    return (
      Object.keys(suggestCheckedState).every(prop => !suggestCheckedState[prop]) ||
      row.is_past_trip ||
      row.status === APPROVED_STATUS
    )
  }

  useEffect(() => {
    setUnassignedChecked(false)
    setNewBookingChecked(false)
    setSuggestCheckedState(initialCheckedState)
    setAssignCheckedState(initialCheckedState)
  }, [tabIndex])

  return (
    <div
      role="button"
      className={TableOperatorActionsClass}
      onClick={e => {
        e.stopPropagation()
      }}
    >
      <TabLayout
        tabIndex={tabIndex}
        setTabIndex={setTabIndex}
        headingPadding="0px 24px"
        titles={["Assign to", row.status !== NEW_STATUS && "Suggest to"]}
        contents={[
          <ListView
            type="checkbox"
            onUnassignedClick={handleUnassignedClick}
            onNewBookingClick={handleNewBookingClick}
            unassignedChecked={unassignedChecked}
            newBookingChecked={newBookingChecked}
            content={getListViewContent()}
            mark={operatorToAssing}
            checkedState={assignCheckedState}
            onChecked={changeAssignCheckedState}
            setMark={setOperatorToAssing}
            checkIfAssigned={checkIfAssigned}
            markField="id"
            hasUnassignedField={row.status !== UNASSIGNED_STATUS && row.status !== CANCELLED_STATUS}
            hasNewBookingField={
              (row.status === UNASSIGNED_STATUS || row.status === CANCELLED_STATUS) &&
              userRole === LANDLINE_AGENT
            }
            unassignedColumn={toggleMark => (
              <AvatarName
                className="avatar-under-operator"
                onClick={toggleMark}
                id={0}
                fname="Unassigned"
                avatarType="unassignedCompany"
              />
            )}
            newTripColumn={toggleMark => (
              <AvatarName
                className="avatar-under-operator"
                onClick={toggleMark}
                id={0}
                fname="New booking"
                avatarType="newBooking"
              />
            )}
            rowRender={(column, toggleMark) => (
              <AvatarName
                className="avatar-under-operator"
                onClick={toggleMark}
                id={column.id}
                fname={column.name}
                avatarURL={column.logo}
                avatarType={getCompanyIcon(column, column.logo)}
                companyColor={column.color}
              />
            )}
            loading={false}
            loadMore={loadMore}
            hasMore={!!allCompanies.next}
            hideSearch={row.status === NEW_STATUS}
            resetCheckedState={() => {
              setAssignCheckedState(initialCheckedState)
              setNewBookingChecked(false)
              setUnassignedChecked(false)
            }}
          />,
          <>
            {row.is_past_trip || row.status === APPROVED_STATUS ? (
              <div className="suggestion-not-allowed-text">
                {`You are not allowed to suggest ${
                  row.is_past_trip ? "dates in the past" : "already assigned trip"
                }`}
              </div>
            ) : (
              <ListView
                type="checkbox"
                content={getCompaniesToDisplay()}
                mark={operatorToAssing}
                setMark={setOperatorToAssing}
                checkedState={suggestCheckedState}
                onChecked={changeSuggestCheckedState}
                checkIfSuggested={checkIfSuggested}
                markField="id"
                rowRender={(column, toggleMark) => (
                  <AvatarName
                    className="avatar-under-operator"
                    onClick={toggleMark}
                    id={column.id}
                    fname={column.name}
                    avatarURL={column.logo}
                    avatarType={getCompanyIcon(column, column.logo)}
                    companyColor={column.color}
                  />
                )}
                loading={false}
                loadMore={loadMore}
                hasMore={!!allCompanies.next}
                resetCheckedState={() => setSuggestCheckedState(initialCheckedState)}
              />
            )}
          </>,
        ]}
      />

      <div className="bottom-buttons">
        <Button
          onClick={onCancel}
          buttonSize="smallSize"
          margin="0px 12px 0px 0px"
          buttonType="outlineColor"
        >
          Cancel
        </Button>
        <Button
          onClick={onConfirm}
          buttonSize="smallSize"
          buttonType="landlineColor"
          disabled={isConfirmDisabled()}
        >
          Confirm
        </Button>
      </div>
    </div>
  )
}

TableOperatorActions.propTypes = {
  row: PropTypes.instanceOf(Object),
  toggleMenu: PropTypes.func,
  shouldShowOnTop: PropTypes.bool,
}

TableOperatorActions.defaultProps = {
  row: {},
  toggleMenu: () => {},
  shouldShowOnTop: false,
}

export default TableOperatorActions
