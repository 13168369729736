import "./VehicleCard.scss"

import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"

import { VEHICLE_OUT_OF_SERVICE, VEHICLE_STATUS, VEHICLE_TYPE } from "../../../constants/vehicles"
import Checkbox from "../Checkbox"
import InfoPopup from "../InfoPopup"
import SvgIcon from "../SvgIcon"

const VehicleCard = ({
  vehicle,
  checked,
  setChecked,
  withoutCheckbox,
  disabledCheckbox,
  onEditClick,
  showEditVehicleButton,
}) => {
  const vehicleCard = classNames("vehicle-card-container")
  const statusStyle = classNames("field-wrapper", {
    secondary: vehicle.status === VEHICLE_OUT_OF_SERVICE,
  })
  return (
    <div
      role="button"
      className={vehicleCard}
      onClick={withoutCheckbox || disabledCheckbox ? () => {} : () => setChecked()}
    >
      {!withoutCheckbox && (
        <div className="item">
          <Checkbox checked={checked} disabled={disabledCheckbox} />
        </div>
      )}

      <div className="fields-container">
        <div className="field avatar">
          {vehicle.vehicle_image ? (
            <img src={vehicle.vehicle_image} width={40} height={40} alt="Avatar" />
          ) : (
            <SvgIcon icon="unassigned-car" width="40" height="40" />
          )}
        </div>

        <div className="field id">{vehicle.vehicle_id}</div>
        <div className="field">
          <div className={statusStyle}>{VEHICLE_STATUS[vehicle.status]}</div>
        </div>
        <div className="field model">{vehicle.vehicle_model}</div>
        <div className="field">
          <div className="field-wrapper secondary">{VEHICLE_TYPE[vehicle.vehicle_type]}</div>
        </div>
        <div className="field">{vehicle.production_year}</div>
        <div className="field">{vehicle.registration}</div>
        {showEditVehicleButton && (
          <div className="icon-wrapper" role="button" onClick={e => onEditClick(e)}>
            <InfoPopup popupText="Edit" position={{ right: "21px", top: "-5px" }}>
              <SvgIcon icon="edit-black-pen" width="16" height="16" />
            </InfoPopup>
          </div>
        )}
      </div>
    </div>
  )
}

VehicleCard.propTypes = {
  vehicle: PropTypes.instanceOf(Object),
  checked: PropTypes.bool,
  setChecked: PropTypes.func,
  withoutCheckbox: PropTypes.bool,
  disabledCheckbox: PropTypes.bool,
  onEditClick: PropTypes.func,
  showEditVehicleButton: PropTypes.bool.isRequired,
}

VehicleCard.defaultProps = {
  vehicle: {},
  checked: false,
  setChecked: () => {},
  withoutCheckbox: false,
  disabledCheckbox: false,
  onEditClick: () => {},
}

export default VehicleCard
