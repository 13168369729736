import { DateTime } from "luxon"
import { SortingRule } from "react-table"
import { landlineApi } from "./calls"
import { LandlineRouteDelayCode } from "./getDelayCodes"
import { PaginationEnvelope } from "./types"

export type LandlineRouteDelay = {
  id: number
  created: string
  modified: string
  route_uuid: string
  delay_amount: number
  code: LandlineRouteDelayCode
  delay_type: "departure" | "arrival"
  note: string
}

export type LandlineRouteResponse = {
  uuid: string
  vehicle: string
  flight: string
  actual_start: string
  actual_stop: string
  name: string
  notes: string
  samsara_id: string
  scheduled_start: string
  scheduled_stop: string
  origin: string
  destination: string
  delays: Array<LandlineRouteDelay>
}

export type RoutesWithDelayResult = Omit<
  LandlineRouteResponse,
  "actual_start" | "actual_stop" | "scheduled_start" | "scheduled_stop"
> & {
  actual_start: DateTime
  actual_stop: DateTime
  scheduled_start: DateTime
  scheduled_stop: DateTime
}

interface GetRoutesWithDelayProps {
  page?: number
  sort?: SortingRule<RoutesWithDelayResult>
}

export async function getRoutesWithDelay({
  page,
  sort,
}: GetRoutesWithDelayProps): Promise<PaginationEnvelope<RoutesWithDelayResult>> {
  try {
    let ordering = ""
    if (sort) {
      ordering = `&ordering=${sort.desc ? "-" : ""}${sort.id}`
    }

    const { data } = await landlineApi.get<PaginationEnvelope<LandlineRouteResponse>>(
      `v2/bossadmin/route_delays/?page=${page ?? 1}${ordering}`,
    )

    const results: Array<RoutesWithDelayResult> = data.results.map(result => {
      return {
        ...result,
        actual_start: DateTime.fromISO(result.actual_start, { setZone: true }),
        actual_stop: DateTime.fromISO(result.actual_stop, { setZone: true }),
        scheduled_start: DateTime.fromISO(result.scheduled_start, { setZone: true }),
        scheduled_stop: DateTime.fromISO(result.scheduled_stop, { setZone: true }),
      }
    })

    return {
      ...data,
      results,
    }
  } catch (e) {
    console.log("Unable to fetch data", e)
    return null
  }
}
