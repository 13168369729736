import "./ChangeAppSettingsTab.scss"

import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import { getUserDetails, updateUserDetails } from "../../../redux/actions"
import Button from "../../atoms/Button"
import ColorPicker from "../../atoms/ColorPicker"
import RowPreview from "../../atoms/RowPreview"
import SvgIcon from "../../atoms/SvgIcon"

const ChangeAppSettingsTab = () => {
  const dispatch = useDispatch()

  const [userData] = useSelector(({ user }) => [user])
  const { userDetails, jwtData } = userData
  const [expressPreviewVisible, setExpressPreviewVisible] = useState(false)
  const [selectPreviewVisible, setSelectPreviewVisible] = useState(false)
  const [expressPopUpVisible, setExpressPopUpVisible] = useState(false)
  const [selectPopUpVisible, setSelectPopUpVisible] = useState(false)

  const [appSettings, setAppSettings] = useState({})

  const changeAppSettings = params => {
    setAppSettings(prevState => ({ ...prevState, ...params }))
  }

  useEffect(() => {
    if (userData.jwtData && userData.id) {
      dispatch(getUserDetails(jwtData?.role, userData.id))
    }
  }, [jwtData, userData.id])

  useEffect(() => {
    setAppSettings(userDetails.app_settings || {})
  }, [userDetails.app_settings])

  return (
    <div className="change-app-settings-container">
      <div className="color-title-wrapper">
        <div className="color-title">Select ( door-to-door ) row background</div>
        <div
          className="icon-wrapper"
          role="button"
          onClick={() => setSelectPreviewVisible(!selectPreviewVisible)}
          onMouseEnter={() => setSelectPopUpVisible(true)}
          onMouseLeave={() => setSelectPopUpVisible(false)}
        >
          <SvgIcon icon={selectPreviewVisible ? "eye-show" : "eye-show-gray"} />
          {selectPreviewVisible && (
            <>
              <RowPreview isSelect color={appSettings.select_door_to_door_color} />
            </>
          )}
          {selectPopUpVisible && <div className="preview-popup">Preview</div>}
        </div>
      </div>

      <ColorPicker
        color={appSettings.select_door_to_door_color}
        setColor={value => changeAppSettings({ select_door_to_door_color: value })}
      />

      <div className="color-title-wrapper top-margin">
        <div className="color-title">Express row background</div>
        <div
          className="icon-wrapper"
          role="button"
          onClick={() => setExpressPreviewVisible(!expressPreviewVisible)}
          onMouseEnter={() => setExpressPopUpVisible(true)}
          onMouseLeave={() => setExpressPopUpVisible(false)}
        >
          <SvgIcon icon={expressPreviewVisible ? "eye-show" : "eye-show-gray"} />
          {expressPreviewVisible && (
            <>
              <RowPreview color={appSettings.express_home_service_color} />
            </>
          )}
          {expressPopUpVisible && <div className="preview-popup">Preview</div>}
        </div>
      </div>
      <ColorPicker
        color={appSettings.express_home_service_color}
        setColor={value => changeAppSettings({ express_home_service_color: value })}
      />

      <div className="buttons-wrapper">
        <Button
          buttonType="grayColor"
          buttonSize="settings-size"
          margin="0 16px 0 0"
          onClick={() => setAppSettings(userDetails.app_settings || {})}
        >
          Cancel
        </Button>
        <Button
          buttonType="landlineColor"
          buttonSize="settings-size"
          onClick={() => {
            dispatch(updateUserDetails(jwtData?.role, userData.id, { app_settings: appSettings }))
          }}
        >
          Save changes
        </Button>
      </div>
    </div>
  )
}

ChangeAppSettingsTab.propTypes = {}

ChangeAppSettingsTab.defaultProps = {}

export default ChangeAppSettingsTab
