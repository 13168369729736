import "./FiltersDropdown.scss"

import classNames from "classnames"
import PropTypes from "prop-types"
import React, { useRef, useState } from "react"

import { useOnClickOutside } from "../../../utils/hooks"
import Checkbox from "../Checkbox"
import SvgIcon from "../SvgIcon"

const FiltersDropdown = ({ filterItems, clearAllFilters, calendarFilters, clearFiltersText }) => {
  const [isOpen, setIsOpen] = useState(false)

  const ref = useRef()

  const clearAllWrapperClass = classNames("clear-all-button-wrapper", { calendarFilters })

  useOnClickOutside(ref, () => setIsOpen(false))

  return (
    <div className="filter-dropdown-container" ref={ref}>
      <div
        role="button"
        tabIndex={0}
        onClick={() => setIsOpen(!isOpen)}
        className="dropdown-button"
      >
        <div className="label-filter-text">Filter by</div>
        <div className="arrow-filter-wrapper">
          <SvgIcon icon="medium-size-down-arrow" width="16" height="16" fill="black" />
        </div>
      </div>

      {isOpen && (
        <div className="activeFilterList">
          {filterItems.map((filter, index) => {
            const isLast = index === filterItems.length - 1

            const filterItemContainerStyle = classNames("filter-item-container", {
              "filter-open": filter.isOpen,
              "filter-open-next-open": filter.isOpen && !isLast && filterItems[index + 1].isOpen,
              "filter-closed-next-open": !filter.isOpen && !isLast && filterItems[index + 1].isOpen,
            })

            return (
              <div className={filterItemContainerStyle}>
                <div className="filter-item">
                  <div className="filter-wrapper">
                    <div
                      role="button"
                      onClick={() => {
                        filter.setIsChecked()
                      }}
                      className="filter-checkbox-container"
                    >
                      <div>
                        <Checkbox checked={filter.isChecked} />
                      </div>
                    </div>
                    <div
                      className="filter-name-container"
                      onClick={() => {
                        filter.setIsOpen()
                      }}
                      role="button"
                    >
                      {filter.name}
                      <SvgIcon
                        icon={filter.isOpen ? "minus" : "plus"}
                        width="10px"
                        height="10px"
                        margin="4px 0 0 0"
                      />
                    </div>
                  </div>
                </div>
                {filter.isOpen && <div>{filter.content}</div>}
              </div>
            )
          })}
          <div className={clearAllWrapperClass}>
            <span role="button" onClick={clearAllFilters} className="clear-all-button">
              {clearFiltersText}
            </span>
          </div>
        </div>
      )}
    </div>
  )
}

FiltersDropdown.propTypes = {
  filterItems: PropTypes.instanceOf(Array).isRequired,
  clearAllFilters: PropTypes.func.isRequired,
  calendarFilters: PropTypes.bool,
  clearFiltersText: PropTypes.string,
}

FiltersDropdown.defaultProps = {
  calendarFilters: false,
  clearFiltersText: "Clear all",
}

export default FiltersDropdown
