import "./MixedPnrPopup.scss"

import classNames from "classnames"
import PropTypes from "prop-types"
import React, { useState } from "react"

import MixedPnrContent from "../../atoms/MixedPnrContent"

const MixedPnrPopup = ({ legs, shouldShowOnTop }) => {
  const [activeIndex, setActiveIndex] = useState(0)
  const containerClass = classNames("mixed-pnr-popup-container", { "show-on-top": shouldShowOnTop })

  return (
    <div className={containerClass} role="button" onClick={e => e.stopPropagation()}>
      <MixedPnrContent data={legs[activeIndex]} />
      <div className="mixed-pnr-dots-container">
        {legs.length > 1 &&
          legs.map((leg, index) => {
            const isLast = index === legs.length - 1
            const isActive = index === activeIndex
            const buttonClass = classNames("mixed-pnr-popup-dot", { isLast, isActive })
            return (
              // eslint-disable-next-line jsx-a11y/control-has-associated-label
              <div
                role="button"
                className={buttonClass}
                onClick={e => {
                  e.stopPropagation()
                  setActiveIndex(index)
                }}
              />
            )
          })}
      </div>
      <div className="invisible-hover-box" />
    </div>
  )
}

MixedPnrPopup.propTypes = {
  legs: PropTypes.instanceOf(Array),
  shouldShowOnTop: PropTypes.bool,
}

MixedPnrPopup.defaultProps = {
  legs: [],
  shouldShowOnTop: false,
}

export default MixedPnrPopup
