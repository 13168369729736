import "./PasswordRecovery.scss"

import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { toast } from "react-toastify"

import { ROUTES } from "../../../constants/routes"
import { passwordRecovery } from "../../../redux/user/userActions"
import config from "../../../utils/config"
import SvgIcon from "../../atoms/SvgIcon"
import Illustration from "./Illustration"

function PasswordRecovery({ history }) {
  const dispatch = useDispatch()
  const [email, setEmail] = useState("")
  const [emailError, setEmailError] = useState(false)
  const [formSending] = useSelector(({ forms }) => [forms.formSending])
  const [formResponse] = useSelector(({ forms }) => [forms.formResponse])
  const [currentUser] = useSelector(({ user }) => [user])

  useEffect(() => {
    const tokenId = localStorage.getItem("accessToken")
    if (currentUser.jwtData || tokenId) {
      toast.warning("You must log out first")
      history.push("/")
    }
  }, [])

  const emailValidation = () => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  }

  const handleSubmit = () => {
    if (emailValidation()) {
      const data = {
        email,
        reset_password_url: `${config.PUBLIC_URL}${ROUTES.PASSWORD_RESET_PAGE}/`,
        is_back_office: true,
      }
      dispatch(passwordRecovery(data))

      setEmailError(false)
    } else {
      setEmailError(true)
    }
  }

  return (
    <div className="passwordRecovery">
      <div className="passwordRecovery--backgroundSplit">
        <div className="whiteBox" />
        <div className="yellowBox" />
      </div>
      <div className="passwordRecovery__content">
        <div className="passwordRecovery__content--innerContent">
          <SvgIcon icon="landline-gray-logo" width="200" height="33" margin="0px 0px 16px 0px" />
          {formResponse ? (
            <div className="emailSent">
              <img src="/paper-plane.png" alt="paper-plane" />
              <p>Thank you.</p>
              <p>
                We sent a confirmation email to <span>{email}.</span>
              </p>
              <p>Please check your inbox. Click the link to complete your reset password.</p>
              <div className="backToLogin__wrapper">
                <Link to="/login" className="backToLoginBtn">
                  Back to login
                </Link>
              </div>
            </div>
          ) : (
            <div className="sendEmail">
              <h5 className="pageTitle">Password assistance</h5>
              <div className="illustration">
                <Illustration angle={email.length} />
              </div>
              <p className="subText">Enter the email address registered against your account</p>
              <div className="recoveryActions">
                <p className="label">Email</p>
                <input
                  type="text"
                  placeholder="Enter email"
                  onChange={e => {
                    setEmail(e.target.value)
                  }}
                  onKeyDown={e => {
                    if (e.key === "Enter") {
                      handleSubmit()
                    }
                  }}
                />
                {emailError && <div className="wrongEmailError">Invalid email format</div>}
                <button className="continueBtn" onClick={handleSubmit}>
                  {formSending ? "loading..." : "Continue"}
                </button>
              </div>
              <div className="backBtnWrapper">
                <Link className="backBtn" to="/login">
                  Back to login
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
PasswordRecovery.propTypes = {
  history: PropTypes.instanceOf(Object),
}

PasswordRecovery.defaultProps = {
  history: {},
}
export default PasswordRecovery
