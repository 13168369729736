import ACTIONS from "../../constants/ACTIONS"
import API from "../../constants/API"
import { landlineApi } from "../../utils/api/calls"
import { apiError, startApiCall } from "../api/apiActions"

const getAddressAutocomplete = queryParams => {
  return async dispatch => {
    try {
      dispatch(startApiCall(API.GET_ADDRESS_AUTOCOMPLETE))
      const response = await landlineApi.get(
        `v2/booking/autocomplete_addresses/${queryParams.address}/MSX/`,
      )
      dispatch({
        type: ACTIONS.GET_ADDRESS_AUTOCOMPLETE_SUCCESS,
        addressAutocomplete: response.data,
      })
    } catch (error) {
      dispatch(apiError(API.GET_ADDRESS_AUTOCOMPLETE, error))
    }
  }
}

// eslint-disable-next-line
export { getAddressAutocomplete }
