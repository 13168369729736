import "./ProgressBar.scss"

import * as d3 from "d3"
import { scaleLinear } from "d3-scale"
import PropTypes from "prop-types"
import React, { useEffect, useRef } from "react"

const ProgressBar = ({ value, width, height, color, scale }) => {
  const ref = useRef(null)

  const drawProgressBar = () => {
    d3.select(ref.current)
      .select("svg")
      .remove()

    const myScale = scaleLinear()
      .domain([0, scale])
      .range([0, typeof width === "number" ? width : ref.current.offsetWidth])

    const svg = d3
      .select(ref.current)
      .append("svg")
      .attr("width", width)
      .attr("height", height)

    svg
      .append("rect")
      .attr("width", width)
      .attr("height", height)
      .attr("fill", "#F5F5F5")

    svg
      .append("rect")
      .attr("height", height)
      .transition()
      .duration(800)
      .attr("width", myScale(value))
      .attr("fill", color)
  }

  useEffect(() => {
    drawProgressBar()
  }, [value, scale])

  return <div className="progress-bar" ref={ref} />
}

ProgressBar.propTypes = {
  value: PropTypes.number,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.string,
  color: PropTypes.string,
  scale: PropTypes.number,
}

ProgressBar.defaultProps = {
  value: 0,
  width: "200",
  height: "10",
  color: "#fab500",
  scale: 100,
}
export default ProgressBar
