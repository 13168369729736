import "./THeadItem.scss"

import classNames from "classnames"
import PropTypes from "prop-types"
import queryString from "query-string"
import React, { useEffect, useRef, useState } from "react"
import { useParams } from "react-router"

import { useOnClickOutside } from "../../../utils/hooks"
import SvgIcon from "../SvgIcon"

const THeadItem = ({
  title,
  style,
  setSortBy,
  sortBy,
  onClick,
  sortDirection,
  setSortDirection,
}) => {
  const tHeadItemClass = classNames("THeadItem-container label")

  const titles = {
    status: "STATUS",
    ticket__booking_reference: "RLOC(PNR)",
    departure_time__date: "D.DATE",
    departure_time__time: "D.TIME",
    depart: "D.ARP",
    arrive: "A.ARP",
    company: "OPERATOR",
    driver: "DRIVER",
    vehicle: "VEHICLE#",
  }

  const { tripFilters } = useParams()
  const tripFiltersObj = queryString.parse(tripFilters)

  useEffect(() => {
    if (tripFiltersObj?.ordering) {
      if (tripFiltersObj.ordering.charAt(0) === "-") {
        setSortBy(titles[tripFiltersObj.ordering.substring(1)])
        setSortDirection("Descending")
      } else {
        setSortBy(titles[tripFiltersObj.ordering])
        setSortDirection("Ascending")
      }
    }
  }, [])

  const ref = useRef()

  const [isOpen, setIsOpen] = useState(false)

  useOnClickOutside(ref, () => setIsOpen(false))

  const isActiveSorting = sortBy === title

  return (
    <div ref={ref} className={tHeadItemClass} style={style}>
      <div
        className="THeadItem-title-wrapper"
        role="button"
        onClick={() => {
          setIsOpen(!isOpen)
        }}
      >
        {title}

        {title.length > 0 && (
          <SvgIcon
            fill={isActiveSorting ? "#3c3835" : "#e5e5e5"}
            icon={
              isActiveSorting && sortDirection === "Descending"
                ? "medium-size-up-arrow"
                : "medium-size-down-arrow"
            }
            width="16"
            height="16"
            margin="0px 0px 0px 5px"
          />
        )}
      </div>
      {isOpen && (
        <div className="sorting-popup-list">
          <div
            role="button"
            onClick={() => {
              setSortBy(title)
              setSortDirection("Ascending")
              onClick("Ascending")
              setIsOpen(false)
            }}
            className="sorting-popup-item"
          >
            Ascending
          </div>
          <div
            role="button"
            onClick={() => {
              setSortBy(title)
              onClick("Descending")
              setSortDirection("Descending")
              setIsOpen(false)
            }}
            className="sorting-popup-item"
          >
            Descending
          </div>
          {isActiveSorting && (
            <div
              role="button"
              onClick={() => {
                setSortBy(undefined)
                onClick("None")
                setSortDirection("None")
                setIsOpen(false)
              }}
              className="sorting-popup-item"
            >
              None
            </div>
          )}
        </div>
      )}
    </div>
  )
}

THeadItem.propTypes = {
  title: PropTypes.string,
  style: PropTypes.instanceOf(Object),
  setSortBy: PropTypes.func,
  sortBy: PropTypes.string,
  onClick: PropTypes.func,
  sortDirection: PropTypes.string,
  setSortDirection: PropTypes.func,
}

THeadItem.defaultProps = {
  title: "",
  style: {},
  setSortBy: () => {},
  sortBy: "",
  onClick: () => {},
  sortDirection: "",
  setSortDirection: () => {},
}

export default THeadItem
