export const DRIVER_ACTIVE = 1
export const DRIVER_NOT_ACTIVE = 2
export const DRIVER_RIDE = 3
export const DRIVER_VACATION_PAID = 4
export const DRIVER_VACATION_UNPAID = 5
export const DRIVER_SICK_LEAVE = 6
export const DRIVER_PERSONAL_MEDICAL_EMERGENCY = 7
export const DRIVER_FAMILY_MEDICAL_EMERGENCY = 8
export const DRIVER_MATERNITY_LEAVE = 9
export const DRIVER_PATERNITY_LEAVE = 10
export const DRIVER_PAID_TIME_OFF = 11
export const DRIVER_JURY_DUTY = 12

export const DRIVER_STATUS = {
  [DRIVER_ACTIVE]: "Active",
  [DRIVER_NOT_ACTIVE]: "Not active",
  [DRIVER_RIDE]: "Ride",
  [DRIVER_VACATION_PAID]: "Vacation paid",
  [DRIVER_VACATION_UNPAID]: "Vacation unpaid",
  [DRIVER_SICK_LEAVE]: "Sick leave",
  [DRIVER_PERSONAL_MEDICAL_EMERGENCY]: "Personal Medical Emergency",
  [DRIVER_FAMILY_MEDICAL_EMERGENCY]: "Family Medical Emergency",
  [DRIVER_MATERNITY_LEAVE]: "Maternity leave",
  [DRIVER_PATERNITY_LEAVE]: "Paternity leave",
  [DRIVER_PAID_TIME_OFF]: "Paid time off",
  [DRIVER_JURY_DUTY]: "Jury Duty",
}

export const DRIVER_STATUS_COLORS = {
  [DRIVER_ACTIVE]: "#67D19E",
  [DRIVER_NOT_ACTIVE]: "#E35C55",
  [DRIVER_RIDE]: "#67D19E",
  [DRIVER_VACATION_PAID]: "#69BDEC",
  [DRIVER_VACATION_UNPAID]: "#69BDEC",
  [DRIVER_SICK_LEAVE]: "#FAB500",
  [DRIVER_PERSONAL_MEDICAL_EMERGENCY]: "#FAB500",
  [DRIVER_FAMILY_MEDICAL_EMERGENCY]: "#FAB500",
  [DRIVER_MATERNITY_LEAVE]: "#BF73E2",
  [DRIVER_PATERNITY_LEAVE]: "#BF73E2",
  [DRIVER_PAID_TIME_OFF]: "#006BC9",
  [DRIVER_JURY_DUTY]: "#FF9800",
}

export const FULL_DAY_OFF_STATUSES = [
  DRIVER_PATERNITY_LEAVE,
  DRIVER_MATERNITY_LEAVE,
  DRIVER_SICK_LEAVE,
  DRIVER_PAID_TIME_OFF,
  DRIVER_VACATION_PAID,
  DRIVER_VACATION_UNPAID,
]

export const DROPDOWN_DRIVER_STATUSES = [
  { value: DRIVER_VACATION_PAID, name: DRIVER_STATUS[DRIVER_VACATION_PAID] },
  { value: DRIVER_VACATION_UNPAID, name: DRIVER_STATUS[DRIVER_VACATION_UNPAID] },
  { value: DRIVER_SICK_LEAVE, name: DRIVER_STATUS[DRIVER_SICK_LEAVE] },
  {
    value: DRIVER_PERSONAL_MEDICAL_EMERGENCY,
    name: DRIVER_STATUS[DRIVER_PERSONAL_MEDICAL_EMERGENCY],
  },
  { value: DRIVER_FAMILY_MEDICAL_EMERGENCY, name: DRIVER_STATUS[DRIVER_FAMILY_MEDICAL_EMERGENCY] },
  { value: DRIVER_MATERNITY_LEAVE, name: DRIVER_STATUS[DRIVER_MATERNITY_LEAVE] },
  { value: DRIVER_PATERNITY_LEAVE, name: DRIVER_STATUS[DRIVER_PATERNITY_LEAVE] },
  { value: DRIVER_PAID_TIME_OFF, name: DRIVER_STATUS[DRIVER_PAID_TIME_OFF] },
  { value: DRIVER_JURY_DUTY, name: DRIVER_STATUS[DRIVER_JURY_DUTY] },
]
