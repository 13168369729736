import "./BarChart.scss"

import PropTypes from "prop-types"
import React from "react"
import CountUp from "react-countup"

import ProgressBar from "../ProgressBar"

const BarChart = ({ items, valueType, labelWidth }) => {
  const maxValue = Math.max(...items.map(item => item.value), 0)
  const scale = maxValue + maxValue * 0.3

  return (
    <div className="bar-chart-container">
      {items.map(item => (
        <div className="bar-chart-item">
          <div className="bar-chart-item-label" style={{ width: labelWidth }}>
            {item?.label}
          </div>
          <ProgressBar
            width="100%"
            height="14"
            scale={scale > 0 ? scale : 100}
            value={item?.value}
            color={item.color}
          />

          <div className="bar-chart-item-value">
            <CountUp end={item.value} duration={0.8} /> {valueType}
          </div>
        </div>
      ))}
    </div>
  )
}

BarChart.propTypes = {
  items: PropTypes.instanceOf(Array),
  valueType: PropTypes.string,
  labelWidth: PropTypes.number,
}

BarChart.defaultProps = {
  items: [],
  valueType: "",
  labelWidth: 120,
}
export default BarChart
