import "./D2DQuotingPage.scss"

import classNames from "classnames"
import PropTypes from "prop-types"
import React, { useCallback, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import { COMPANY_DRIVER } from "../../../constants/userRoles"
import { getQuote } from "../../../redux/quoting/quotingActions"
import AddressAutocomplete from "../../atoms/AddressAutocomplete"
import Checkbox from "../../atoms/Checkbox"
import CurrentDate from "../../atoms/CurrentDate"
import NotificationButton from "../../atoms/NotificationButton"
import RegularLayout from "../../layouts/RegularLayout"
import GoogleMap from "../../organisms/GoogleMap"
import {round} from "lodash";

const D2DQuotingPage = ({ history }) => {
  const dispatch = useDispatch()
  const quotingPageClass = classNames("quoting-page-container")
  const [pointAAddress, setPointAAddress] = useState("")
  const [pointBAddress, setPointBAddress] = useState("")
  const [quoteLoading, setQuoteLoading] = useState(false)
  const [addressOtherThanMSP, setAddressOtherThanMSP] = useState(false)
  const [
    pointA,
    pointB,
    distance,
    pricePerMile,
    pricePerMinute,
    durationInSeconds,
    markup,
    syRoyalty,
    priceTotal,
  ] = useSelector(({ quote }) => [
    quote.pointA,
    quote.pointB,
    quote.distance,
    quote.pricePerMile,
    quote.pricePerMinute,
    quote.durationInSeconds,
    quote.markup,
    quote.syRoyalty,
    quote.priceTotal,
  ])

  const [userRole] = useSelector(({ user }) => [user.jwtData?.role])

  const loadQuoteCallback = useCallback(async () => {
    setQuoteLoading(true)
    const params = {
      pickup_address: pointAAddress,
      drop_off_address: pointBAddress,
      drop_off_airport: addressOtherThanMSP ? null : "MSX",
    }

    await dispatch(getQuote(params))
    setQuoteLoading(false)
  }, [pointAAddress, pointBAddress, addressOtherThanMSP])

  const points = useCallback(() => {
    const formattedPoints = []

    if (pointA) {
      formattedPoints.push({
        lat: pointA.lat,
        lng: pointA.lng,
        label: "A",
      })
    }

    if (pointB) {
      formattedPoints.push({
        lat: pointB.lat,
        lng: pointB.lng,
        label: "B",
      })
    }

    return formattedPoints
  }, [pointA, pointB])

  return (
    <RegularLayout withTopMenu={false} history={history} backgroundColor="#ffffff">
      <div className={quotingPageClass}>
        <div className="quoting-container">
          <div className="quoting-text">Door-to-Door Quoting</div>
          <div className="right-side-content">
            {userRole !== COMPANY_DRIVER && (
              <div className="notification-button">
                <NotificationButton onClick={() => history.push("/")} />
              </div>
            )}
            <div className="current-date">
              <CurrentDate />
            </div>
          </div>
        </div>
      </div>
      <div className="quote-route-container">
        <div className="quote-input-container">
          <div className="quote-input">
            <div className="search-style search-size">
              <AddressAutocomplete
                address={pointAAddress}
                onChangeAddress={address => {
                  setPointAAddress(address)
                }}
                placeholder="Point A"
                itemClass="autocomplete-item"
              />
            </div>
            <div className="quoting-to-from">To / From</div>
            <div className="search-style search-size">
              {!addressOtherThanMSP ? (
                <div className="placeholder-point">
                  <span>Minneapolis−Saint Paul</span>
                </div>
              ) : (
                <AddressAutocomplete
                  address={pointBAddress}
                  onChangeAddress={address => {
                    setPointBAddress(address)
                  }}
                  placeholder="Point B"
                  itemClass="autocomplete-item"
                />
              )}
            </div>
            <div className="quote-input-footer">
              <div role="button" onClick={() => setAddressOtherThanMSP(!addressOtherThanMSP)}>
                <Checkbox checked={addressOtherThanMSP} value="Address other than MSP" />
              </div>
              <div className="get-quote-button-container">
                <button
                  className="get-quote-button"
                  onClick={loadQuoteCallback}
                  disabled={quoteLoading}
                >
                  {!quoteLoading ? "Get Quote" : "Loading"}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="quote-output-container">
            <div className="quote-output">
              <div className="quote-output-line-item quote-output-line-item-price">
                <div className="quote-output-line-item-label">Price</div>
                <div className="quote-output-line-item-value">${priceTotal}</div>
              </div>
              <div className="quote-output-line-item">
                <div className="quote-output-line-item-label">Distance</div>
                <div className="quote-output-line-item-value">{distance} miles</div>
              </div>
              <div className="quote-output-line-item">
                <div className="quote-output-line-item-label">Price Per Mile</div>
                <div className="quote-output-line-item-value">${pricePerMile}/m</div>
              </div>
              <div className="quote-output-line-item">
                <div className="quote-output-line-item-label">Duration</div>
                <div className="quote-output-line-item-value">
                  {round(durationInSeconds / 60 || 0, 2)} Minutes
                </div>
              </div>
              <div className="quote-output-line-item">
                <div className="quote-output-line-item-label">Price Per Minute</div>
                <div className="quote-output-line-item-value">${pricePerMinute}/m</div>
              </div>
              <div className="quote-output-line-item">
                <div className="quote-output-line-item-label">Markup</div>
                <div className="quote-output-line-item-value">${markup}</div>
              </div>
              <div className="quote-output-line-item">
                <div className="quote-output-line-item-label">SunCountry Royalty</div>
                <div className="quote-output-line-item-value">${syRoyalty}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="quoting-tool-map-container">
        <GoogleMap points={points()} />
      </div>
    </RegularLayout>
  )
}

D2DQuotingPage.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
}

D2DQuotingPage.defaultProps = {}

export default D2DQuotingPage
