import "./StatusTableCell.scss"

import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"

import Checkbox from "../Checkbox"
import RowItem from "../RowItem"
import SvgIcon from "../SvgIcon"

const StatusTableCell = ({
  status,
  statusConst,
  missingInfo,
  isChecked,
  onCheckClick,
  showCheckbox,
  checkboxDisabled,
}) => {
  const statusTableCellClass = classNames("statusTableCell-inner-container", {
    [statusConst[status]]: status,
  })
  return (
    <RowItem className="statusTableCell-container">
      {showCheckbox && (
        <div
          className="testing-box"
          role="button"
          onClick={e => {
            e.stopPropagation()
            if (!checkboxDisabled) {
              onCheckClick()
            }
          }}
        >
          <Checkbox checked={isChecked} disabled={checkboxDisabled} />
        </div>
      )}
      {missingInfo && (
        <div className="missing-info-container">
          <SvgIcon icon="missing-info-icon" width="13" height="13" />
        </div>
      )}
      <div className={statusTableCellClass}>
        <SvgIcon icon={`${statusConst[status]}-icon`} width="12" height="12" />
        <span>{statusConst[status]}</span>
      </div>
    </RowItem>
  )
}

StatusTableCell.propTypes = {
  statusConst: PropTypes.instanceOf(Array),
  status: PropTypes.bool.isRequired,
  missingInfo: PropTypes.bool,
  isChecked: PropTypes.bool,
  onCheckClick: PropTypes.func,
  showCheckbox: PropTypes.bool,
  checkboxDisabled: PropTypes.bool,
}

StatusTableCell.defaultProps = {
  statusConst: [],
  missingInfo: false,
  isChecked: false,
  onCheckClick: () => {},
  showCheckbox: false,
  checkboxDisabled: false,
}

export default StatusTableCell
