import React, { useEffect, useState, useContext, useMemo } from "react"
import { UseFormReturn, FieldValues, Path } from "react-hook-form"
import { Row } from "react-table"
import { EditingContext } from "../EditableDataTable"

interface EditableCellProps<TRow extends object, TFieldValues extends FieldValues> {
  row: Row<TRow>
  form: UseFormReturn<TFieldValues>
  EditCell: React.ElementType
  DisplayCell: React.ElementType
}

export function EditableCell<TRow extends object, TFieldValues extends FieldValues>({
  row,
  form,
  EditCell,
  DisplayCell,
}: EditableCellProps<TRow, TFieldValues>) {
  const { editingRow } = useContext(EditingContext)

  const { setValue } = form

  const isEditing = useMemo(() => row.index === editingRow, [editingRow])

  useEffect(() => {
    if (row.index === editingRow) {
      Object.keys(row.values).forEach(key => {
        const value = row.values[key]
        setValue(key as Path<TFieldValues>, value)
      })
    }
  }, [editingRow])

  return isEditing ? <EditCell /> : <DisplayCell />
}
