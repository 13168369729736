import * as React from "react"
import { DateTime } from "luxon"
import { Column } from "react-table"
import { LandlineRouteDelay, RoutesWithDelayResult } from "../../../../utils/api/getRoutesWithDelay"
import { Cell } from "../../../tables/cells/Cell"
import { DateTimeCell } from "../../../tables/cells/DateTimeCell"
import { DelayFormatCell, DelayFormatCellProps } from "../cells/DelayFormatCell"
import { Box } from "grommet"
import { TZSelectorCell } from "../cells/TZSelectorCell"

export function filterDelayCodes(type: "departure" | "arrival") {
  return ({ delay_type }: LandlineRouteDelay) => delay_type === type
}

export function delayAmountReducer() {
  return (acc: number, { delay_amount }: LandlineRouteDelay) => acc + delay_amount
}

function delayCodeFormatter() {
  return ({ delay_amount, code }: LandlineRouteDelay) => `${delay_amount} ${code.code}`
}

export function calculateDelay(scheduled: DateTime, actual: DateTime) {
  const result = actual.diff(scheduled, "minutes").toObject()
  return Math.floor(result.minutes)
}

const llGoldLighter = "rgba(250, 181, 0, 0.06)"
const llGoldLight = "rgba(250, 181, 0, 0.12)"

const elevatedCell: React.CSSProperties = {
  boxShadow: "8px 8px 8px rgba(0, 0, 0, 0.1)",
  backgroundColor: "#FAFAFA",
  border: "1px solid #DBD8D8",
  flexGrow: 1,
  justifyContent: "center",
  alignItems: "center",
}

const hiddenCell: React.CSSProperties = {
  width: 0,
  padding: 0,
}

const localTZ = DateTime.now().zoneName

export function getRouteDelayTableColumns(
  onClickDelay: (row: RoutesWithDelayResult, delayType: "arrival" | "departure") => void,
  airportMap: Record<string, string>,
  useLocalTZ?: boolean,
  onChangeUseLocalTZ?: (useLocalTZ: boolean) => void,
): Array<Column<RoutesWithDelayResult>> {
  return [
    {
      id: "main",
      Header: "",
      style: {
        gridColumn: "span 6",
      },
      columns: [
        {
          id: "scheduled_start",
          accessor: "scheduled_start",
          Cell: props => {
            const { origin } = props.row.original
            return <DateTimeCell {...props} tz={useLocalTZ ? localTZ : airportMap[origin]} />
          },
          Header: "date",
          defaultCanSort: true,
        },
        // {
        //   // #TODO include once we have airline data
        //   accessor: () => "-",
        //   Cell,
        //   Header: "AIRL",
        // },
        {
          accessor: "vehicle",
          Cell,
          Header: "VEH #",
          defaultCanSort: false,
        },
        {
          accessor: "origin",
          Cell,
          Header: "ORIG",
          defaultCanSort: false,
        },
        {
          accessor: "destination",
          Cell,
          Header: "DEST",
          defaultCanSort: false,
        },
        {
          accessor: "flight",
          Cell,
          Header: "Flight",
          containerStyle: {
            background: llGoldLighter,
          },
          gapCellStyle: {
            background: llGoldLighter,
          },
          defaultCanSort: false,
        },
        {
          id: "date-timezone",
          accessor: "actual_start",
          Header: <TZSelectorCell onChange={onChangeUseLocalTZ} />,
          Cell: props => {
            const { origin } = props.row.original
            return <DateTimeCell {...props} tz={useLocalTZ ? localTZ : airportMap[origin]} />
          },
          cellProps: {
            format: "ZZZZ",
          },
          defaultCanSort: false,
        },
      ],
    },
    {
      id: "preorigin-filler",
      Header: () => <Box background="#D9D9D9" height="1px" margin={{ vertical: "auto" }} />,
      style: {
        gridColumn: "span 2",
      },
      columns: [
        {
          id: "preorigin-hidden",
          Cell,
          Header: "",
          style: hiddenCell,
          containerStyle: hiddenCell,
          cellProps: {
            boxProps: {
              style: hiddenCell,
            },
          },
        },
      ],
    },
    {
      id: "departure-header",
      Header: "Departure",
      style: {
        color: "#000",
        gridColumn: "span 1",
      },
      columns: [
        {
          id: "departure_scheduled_start",
          accessor: "scheduled_start",
          Header: "SCHED",
          Cell: props => {
            const { origin } = props.row.original
            return <DateTimeCell {...props} tz={useLocalTZ ? localTZ : airportMap[origin]} />
          },
          cellProps: {
            format: "hh:mm a (ZZZZ)",
            boxProps: {
              style: {
                ...elevatedCell,
                borderRight: 0,
                paddingLeft: 15,
              },
            },
          },
          containerStyle: {
            paddingRight: 0,
          },
          style: {
            paddingLeft: "25px",
          },
          defaultCanSort: false,
        },
        {
          id: "departure_actual_start",
          accessor: "actual_start",
          Header: "DEP",
          Cell: props => {
            const { origin } = props.row.original
            return <DateTimeCell {...props} tz={useLocalTZ ? localTZ : airportMap[origin]} />
          },
          cellProps: {
            format: "hh:mm a",
            boxProps: {
              style: {
                ...elevatedCell,
                alignItems: "flex-start",
                borderLeft: 0,
                borderRight: 0,
                paddingLeft: 10,
                paddingRight: 10,
              },
            },
          },
          containerStyle: {
            paddingLeft: 0,
            paddingRight: 0,
          },
          defaultCanSort: false,
        },
        {
          id: "departure-calculated-delay",
          accessor: d => calculateDelay(d.scheduled_start, d.actual_start),
          Header: "DELAY",
          Cell: (props: DelayFormatCellProps) => (
            <DelayFormatCell
              {...props}
              delayMismatchColor="#FF9800"
              delayMatchesEntered={
                props.row.original.delays
                  .filter(filterDelayCodes("departure"))
                  .reduce(delayAmountReducer(), 0) === props.value
              }
              onClick={d => onClickDelay(d, "departure")}
            />
          ),
          cellProps: {
            boxProps: {
              style: {
                ...elevatedCell,
                borderLeft: 0,
              },
            },
          },
          containerStyle: {
            paddingLeft: 0,
          },
          defaultCanSort: false,
        },
        {
          id: "depature-entered-delay",
          accessor: d =>
            d.delays.filter(filterDelayCodes("departure")).reduce(delayAmountReducer(), 0),
          Header: "Entered Delay",
          cellProps: {
            background: llGoldLight,
          },
          containerStyle: {
            marginLeft: 10,
            background: llGoldLight,
          },
          gapCellStyle: {
            marginLeft: 10,
            background: llGoldLight,
          },
          style: {
            marginLeft: 10,
            whiteSpace: "nowrap",
          },
          Cell: (props: DelayFormatCellProps) => {
            return (
              <Cell
                boxProps={{
                  style: {
                    ...elevatedCell,
                    width: 80,
                    alignSelf: "center",
                  },
                }}
                value={
                  <DelayFormatCell
                    {...props}
                    delayMatchesEntered={
                      calculateDelay(
                        props.row.original.scheduled_start,
                        props.row.original.actual_start,
                      ) === props.value
                    }
                    onClick={d => onClickDelay(d, "departure")}
                  />
                }
              />
            )
          },
          defaultCanSort: false,
        },
        {
          id: "departure-delay-codes",
          accessor: d =>
            d.delays
              .filter(filterDelayCodes("departure"))
              .map(delayCodeFormatter())
              .join(", "),
          Header: "Codes",
          Cell,
          cellProps: {
            wrap: true,
            boxProps: {
              overflow: "hidden",
            },
            style: { width: "70px" },
          },
          containerStyle: {
            width: 80,
          },
          defaultCanSort: false,
        },
      ],
    },
    {
      id: "postorigin-filler",
      style: {
        gridColumn: "span 4",
        paddingRight: 0,
        position: "relative",
      },
      Header: () => <Box background="#D9D9D9" height="1px" margin={{ vertical: "auto" }} />,
      columns: [
        {
          id: "postorigin-hidden",
          Cell: "",
          Header: "",
          style: {
            ...hiddenCell,
            width: "auto",
          },
          containerStyle: {
            padding: 0,
          },
          cellProps: {
            boxProps: {
              style: hiddenCell,
            },
          },
        },
      ],
    },
    {
      id: "predestination-filler",
      Header: () => <Box background="#D9D9D9" height="1px" margin={{ vertical: "auto" }} />,
      style: {
        gridColumn: "span 2",
      },
      columns: [
        {
          id: "predestination-hidden",
          Cell,
          Header: "",
          style: hiddenCell,
          containerStyle: hiddenCell,
          cellProps: {
            boxProps: {
              style: hiddenCell,
            },
          },
        },
      ],
    },
    {
      Header: "Arrival",
      style: {
        color: "#000",
        gridColumn: "span 1",
      },
      columns: [
        {
          accessor: "scheduled_stop",
          Header: "SCHED",
          Cell: props => {
            const { origin } = props.row.original
            return <DateTimeCell {...props} tz={useLocalTZ ? localTZ : airportMap[origin]} />
          },
          cellProps: {
            format: "hh:mm a (ZZZZ)",
            boxProps: {
              style: {
                ...elevatedCell,
                borderRight: 0,
                paddingLeft: 15,
              },
            },
          },
          style: {
            paddingLeft: 25,
          },
          containerStyle: {
            paddingRight: 0,
          },
          defaultCanSort: false,
        },
        {
          accessor: "actual_stop",
          Header: "ARR",
          Cell: props => {
            const { origin } = props.row.original
            return <DateTimeCell {...props} tz={useLocalTZ ? localTZ : airportMap[origin]} />
          },
          cellProps: {
            format: "hh:mm a",
            boxProps: {
              style: {
                ...elevatedCell,
                alignItems: "flex-start",
                borderLeft: 0,
                borderRight: 0,
                paddingLeft: 10,
                paddingRight: 10,
              },
            },
          },
          defaultCanSort: false,
          containerStyle: {
            paddingLeft: 0,
            paddingRight: 0,
          },
        },
        {
          id: "arrival-calculated-delay",
          accessor: d => calculateDelay(d.scheduled_stop, d.actual_stop),
          Header: "DELAY",
          Cell: (props: DelayFormatCellProps) => (
            <DelayFormatCell
              {...props}
              delayMismatchColor="#FF9800"
              delayMatchesEntered={
                props.row.original.delays
                  .filter(filterDelayCodes("arrival"))
                  .reduce(delayAmountReducer(), 0) === props.value
              }
              onClick={d => onClickDelay(d, "arrival")}
            />
          ),
          cellProps: {
            boxProps: {
              style: {
                ...elevatedCell,
                borderLeft: 0,
              },
            },
          },
          containerStyle: {
            paddingLeft: 0,
          },
          defaultCanSort: false,
        },
        {
          id: "arrival-entered-delay",
          accessor: d =>
            d.delays.filter(filterDelayCodes("arrival")).reduce(delayAmountReducer(), 0),
          Header: "Entered Delay",
          cellProps: {
            background: llGoldLight,
          },
          containerStyle: {
            marginLeft: 10,
            background: llGoldLight,
            whiteSpace: "nowrap",
          },
          gapCellStyle: {
            marginLeft: 10,
            background: llGoldLight,
          },
          Cell: (props: DelayFormatCellProps) => (
            <Cell
              boxProps={{
                style: {
                  ...elevatedCell,
                  width: 80,
                  alignSelf: "center",
                },
              }}
              value={
                <DelayFormatCell
                  {...props}
                  delayMatchesEntered={
                    calculateDelay(
                      props.row.original.scheduled_stop,
                      props.row.original.actual_stop,
                    ) === props.value
                  }
                  onClick={d => onClickDelay(d, "arrival")}
                />
              }
            />
          ),
          defaultCanSort: false,
        },
        {
          id: "arrival-delay-codes",
          accessor: d =>
            d.delays
              .filter(filterDelayCodes("arrival"))
              .map(delayCodeFormatter())
              .join(", "),
          Header: "Codes",
          Cell,
          cellProps: {
            wrap: true,
            boxProps: {
              overflow: "hidden",
            },
          },
          containerStyle: {
            width: 80,
          },
          defaultCanSort: false,
        },
      ],
    },
    {
      id: "postdestination-filler",
      style: {
        gridColumn: "span 4",
        paddingRight: 0,
      },
      Header: () => <Box background="#D9D9D9" height="1px" margin={{ vertical: "auto" }} />,
      columns: [
        {
          id: "postdestination-hidden",
          Cell: "",
          Header: "",
          style: {
            ...hiddenCell,
            width: "auto",
          },
          containerStyle: {
            padding: 0,
          },
          cellProps: {
            boxProps: {
              style: hiddenCell,
            },
          },
        },
      ],
    },
  ]
}
