import ACTIONS from "../../constants/ACTIONS"

const initialState = {
  unread: {
    results: [],
  },
  activeNotification: {},
}

export default function notificationsReducer(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.GET_UNREAD_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        unread: action.payload,
      }
    case ACTIONS.GET_MORE_UNREAD_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        unread: {
          count: action.payload.count,
          previous: action.payload.previous,
          next: action.payload.next,
          results: [...state.unread.results, ...action.payload.results],
        },
      }

    case ACTIONS.SET_ACTIVE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        activeNotification: action.payload,
      }
    case ACTIONS.MARK_NOTIFICATION_AS_READ:
      // todo check; const newResults = [...state.unread.results] throws mutation error
      // eslint-disable-next-line no-case-declarations
      const newResults = JSON.parse(JSON.stringify(state.unread.results))
      newResults[action.payload].unread = false
      return {
        ...state,
        unread: {
          count: state.unread.count - 1,
          results: newResults,
        },
      }
    case ACTIONS.HIGHLIGIHT_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        highlightNotifications: true,
      }
    case ACTIONS.RESET_HIGHLIGHT_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        highlightNotifications: false,
      }
    case ACTIONS.MARK_ALL_NOTIFICATIONS_AS_READ_SUCCESS:
      return {
        ...initialState,
        unread: {
          count: 0,
        },
      }
    default:
      return { ...state }
  }
}
