import "./UserCard.scss"

import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"

import { getUserAvatar } from "../../../utils/common"
import SvgIcon from "../SvgIcon"
import UserAvatarName from "../UserAvatarName"

const UserCard = ({ history, user, showDetails, isLandline, companyId }) => {
  const userCardContainer = classNames("user-card-container")

  return (
    <div className={userCardContainer}>
      <div className="user-container">
        <div>
          <UserAvatarName
            avatarURL={user.avatar}
            avatarType={getUserAvatar(user.avatar)}
            fname={user.first_name}
            lname={user.last_name}
            initialsFontSize="16px"
            nameFontSize="16px"
            avatarHeight="40px"
            avatarWidth="40px"
          />
        </div>
      </div>

      <div className="user-phone-info">
        <SvgIcon icon="phone" margin="0px 8px 0px 0px" fill="#F5F5F5" width={24} height={24} />
        <span className="user-phone-text">Phone:</span>
        {user.phone_number}
      </div>
      <div className="right-container">
        <div className="user-email-info">
          <SvgIcon icon="e-mail" margin="0px 8px 0px 0px" width={24} height={24} />
          <span className="user-email-text">Email:</span>
          {user.email}
        </div>
      </div>

      {showDetails && (
        <span
          role="button"
          onClick={() => {
            history.push(
              `${isLandline ? "/landline" : `/operators/${companyId}`}/drivers/${user.id}`,
            )
          }}
          className="driver-info"
        >
          Driver Info
          <SvgIcon icon="arrow-right" width="16px" height="16px" margin="0px 8px 0px 8px" />
        </span>
      )}
    </div>
  )
}

UserCard.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
  user: PropTypes.instanceOf(Object),
  showDetails: PropTypes.bool,
  isLandline: PropTypes.bool,
  companyId: PropTypes.string,
}

UserCard.defaultProps = {
  user: {},
  showDetails: false,
  isLandline: false,
  companyId: "",
}

export default UserCard
