import React, { useCallback, useEffect, useMemo, useState } from "react"
import { Box, Button, Layer, Text } from "grommet"
import { useForm } from "react-hook-form"
import { LandlineRouteDelay } from "../../../../utils/api/getRoutesWithDelay"
import { getDelayCodes, LandlineRouteDelayCode } from "../../../../utils/api/getDelayCodes"
import { EditableDataTable } from "../../../tables/EditableDataTable"
import { getDelayCodeEntryTableColumns } from "../columns/DelayCodeEntryTableColumns"
import { upsertRouteDelay } from "../../../../utils/api/upsertRouteDelay"
import { deleteRouteDelay } from "../../../../utils/api/deleteRouteDelay"
import { AnimatedTabs } from "./AnimatedTabs"

interface DelayEntryModalProps {
  open: boolean
  data: Array<LandlineRouteDelay>
  routeUuid: string
  departureTotalDelay: number
  arrivalTotalDelay: number
  flight: string
  origin: string
  destination: string
  initialTab: "arrival" | "departure"
  onRequestClose: () => void
}

export function DelayEntryModal({
  data,
  routeUuid,
  departureTotalDelay,
  arrivalTotalDelay,
  flight,
  origin,
  destination,
  initialTab = "departure",
  onRequestClose,
}: DelayEntryModalProps) {
  const [delayCodes, setDelayCodes] = useState<Array<LandlineRouteDelayCode>>([])
  const form = useForm<LandlineRouteDelay>({
    defaultValues: {
      code: {
        id: -1,
      },
      note: "",
      delay_amount: 0,
    },
  })
  const [editingRow, setEditingRow] = useState(-1)
  const [editableData, setEditableData] = useState(data)
  const [activeTab, setActiveTab] = useState(initialTab === "departure" ? 0 : 1)
  const delayType = activeTab === 0 ? "departure" : "arrival"

  const filteredRows = useMemo(() => {
    return editableData.filter(d => d.delay_type === delayType || d.code.id === -1)
  }, [editableData, delayType])

  useEffect(() => {
    setEditingRow(filteredRows.length - 1)
  }, [activeTab, filteredRows])

  // Fetch delay codes to populate the dropdown
  useEffect(() => {
    getDelayCodes().then(setDelayCodes)
  }, [setDelayCodes])

  const emptyDelay = ({
    code: {
      id: -1,
    },
    delay_amount: 0,
    route_uuid: routeUuid,
  } as unknown) as LandlineRouteDelay

  const handleUpdateRouteDelay = useCallback(
    async (update: Omit<LandlineRouteDelay, "created" | "modified">) => {
      // Find item to update
      let itemIndex = editableData.findIndex(n => n.id && n.id === update.id)

      if (itemIndex >= 0) {
        const item = editableData[itemIndex]
        editableData[itemIndex] = {
          ...item,
          ...update,
        }
      } else {
        // Ensure the empty item is still on the bottom
        editableData.pop()

        editableData.push(
          {
            ...update,
            // Creating a temporary ID to hook into when editing unsaved rows
            id: 10 * -(editableData.length + 1),
            delay_type: delayType,
            created: "",
            modified: "",
          },
          emptyDelay,
        )
      }

      // Clone the array to ensure we trigger an update
      const cleanEditableData = Array.prototype.slice.call(editableData)

      setEditableData(cleanEditableData)
      setEditingRow(cleanEditableData.length - 1)
    },
    [editableData, delayType, setEditableData, setEditingRow],
  )

  const totalDelay = useMemo(() => {
    if (!editableData) {
      return 0
    }

    return editableData
      .filter(d => d.delay_type === delayType)
      .reduce((acc, delay) => {
        return acc + delay.delay_amount
      }, 0)
  }, [editableData, delayType])

  useEffect(() => {
    if (!data) {
      setEditableData([emptyDelay])
      setEditingRow(0)
      return
    }

    setEditableData([...data, emptyDelay])
    setEditingRow(data.length)
  }, [data, setEditingRow])

  const handlePersistRouteDelay = useCallback(async () => {
    try {
      await Promise.all(
        editableData
          .filter(data => data.code.id !== -1)
          .map(data =>
            upsertRouteDelay({
              ...data,
              // Strip temporary ids
              id: data.id < 0 ? undefined : data.id,
              code: data.code.id,
            }),
          ),
      )
    } catch (e) {
      console.log("Unable to update route delay", e)
      return
    }

    onRequestClose()
  }, [editableData])

  const handleDeleteRouteDelay = useCallback(
    async (routeDelay: LandlineRouteDelay) => {
      if (routeDelay.id >= 0) {
        await deleteRouteDelay(routeDelay.id)
      }
      setEditableData(editableData.filter(data => data.id !== routeDelay.id))
    },
    [editableData, setEditableData],
  )

  const activeTotalDelay = activeTab === 0 ? departureTotalDelay : arrivalTotalDelay

  return (
    <Layer onEsc={onRequestClose}>
      <Box
        pad="small"
        elevation="medium"
        border={{
          size: "1px",
        }}
        style={{
          borderRadius: "4px",
        }}
        width="xlarge"
        height="medium"
      >
        <Box
          pad={{ horizontal: "large", top: "medium" }}
          margin={{ bottom: "17px" }}
          direction="row"
          justify="between"
        >
          <Text size="large">Delays</Text>
          <Box direction="row">
            <Text size="small">
              {origin} - {destination}
            </Text>
            <Text size="small" weight="bold" margin={{ left: "xsmall" }}>
              {flight}
            </Text>
          </Box>
        </Box>
        <Box border={{ side: "bottom" }} />
        <Box pad={{ horizontal: "large", top: "mediumLarge", bottom: "xsmall" }} flex={{ grow: 1 }}>
          <AnimatedTabs
            onActive={setActiveTab}
            initialTab={initialTab === "departure" ? 0 : 1}
            tabs={[
              {
                title: "DEPARTURE",
                children: (
                  <Box height="100%" style={{ overflow: "auto" }} flex={{ grow: 1 }}>
                    <EditableDataTable
                      editingRow={editingRow}
                      columns={getDelayCodeEntryTableColumns(
                        form,
                        delayCodes,
                        setEditingRow,
                        handleUpdateRouteDelay,
                        handleDeleteRouteDelay,
                      )}
                      data={editableData.filter(n => n.delay_type === "departure" || !n.delay_type)}
                      rowHeight={40}
                    />
                  </Box>
                ),
              },
              {
                title: "ARRIVAL",
                children: (
                  <Box height="100%" style={{ overflow: "auto" }} flex={{ grow: 1 }}>
                    <EditableDataTable
                      editingRow={editingRow}
                      columns={getDelayCodeEntryTableColumns(
                        form,
                        delayCodes,
                        setEditingRow,
                        handleUpdateRouteDelay,
                        handleDeleteRouteDelay,
                      )}
                      data={editableData.filter(n => n.delay_type === "arrival" || !n.delay_type)}
                      rowHeight={45}
                    />
                  </Box>
                ),
              },
            ]}
          />
        </Box>
        <Box flex="grow" justify="end">
          <Box direction="row" justify="between">
            <Box justify="center">
              <Text size="large">
                Total Departure Delay:{" "}
                <Text color={totalDelay > activeTotalDelay ? "red" : undefined}>
                  {totalDelay > 0 ? totalDelay : "---"} /{" "}
                  {activeTab === 0 ? departureTotalDelay : arrivalTotalDelay}
                </Text>
              </Text>
            </Box>
            <Box direction="row" gap="small">
              <Button label="Cancel" secondary onClick={onRequestClose} />
              <Button
                label="Save & Submit"
                disabled={totalDelay > activeTotalDelay}
                primary
                onClick={handlePersistRouteDelay}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Layer>
  )
}
