import ACTIONS from "../../constants/ACTIONS"

const initalState = {
  userDetails: {},
}

export default function userReducer(state = initalState, action) {
  switch (action.type) {
    case ACTIONS.DECODE_JWT_SUCCESS:
      return {
        ...state,
        jwtData: action.jwtDecoded,
      }
    case ACTIONS.GET_USER_PROFILE_SUCCESS:
      return {
        ...state,
        ...action.response,
      }
    case ACTIONS.LOGOUT_USER:
      return initalState

    case ACTIONS.UPDATE_USER_DETAILS_SUCCESS:
    case ACTIONS.GET_USER_DETAILS_SUCCESS:
      return {
        ...state,
        userDetails: action.userDetails,
      }

    default:
      return state
  }
}
