import jwtDecode from "jwt-decode"
import { toast } from "react-toastify"

import ACTIONS from "../../constants/ACTIONS"
import API from "../../constants/API"
import { COMPANY_DRIVER, COMPANY_REPRESENTATIVE, LANDLINE_AGENT } from "../../constants/userRoles"
import { landlineApi } from "../../utils/api/calls"
import { apiError, startApiCall } from "../api/apiActions"
import { formResponse, formSent, formSentReset } from "../forms/formsActions"

const decodeJWT = () => {
  return async dispatch => {
    try {
      dispatch(startApiCall(API.DECODE_JWT))

      const accessToken = localStorage.getItem("accessToken")
      if (!accessToken) {
        return
      }
      const jwtDecoded = jwtDecode(accessToken)

      dispatch({
        type: ACTIONS.DECODE_JWT_SUCCESS,
        jwtDecoded,
      })
      // eslint-disable-next-line
      return jwtDecoded
    } catch (error) {
      dispatch(apiError(API.DECODE_JWT, error))
    }
  }
}

const getUserProfile = () => {
  return async dispatch => {
    try {
      dispatch(startApiCall(API.GET_USER_PROFILE))

      const response = await landlineApi.get("v2/bossadmin/users/current_user")
      dispatch({
        type: ACTIONS.GET_USER_PROFILE_SUCCESS,
        response: response.data,
      })
    } catch (error) {}
  }
}

const loginUser = history => {
  return async (dispatch, getState) => {
    try {
      dispatch(startApiCall(API.LOGIN_USER))

      const { email, password } = getState().forms.formData

      const response = await landlineApi.post("sessions/boss/", { email, password })

      localStorage.setItem("accessToken", response.data.access)
      localStorage.setItem("refreshToken", response.data.refresh)

      const jwtDecoded = await dispatch(decodeJWT())

      sessionStorage.setItem("showLoginMessage", true)

      dispatch({
        type: ACTIONS.LOGIN_USER_SUCCESS,
      })

      if (jwtDecoded.role === COMPANY_DRIVER) {
        history.push("/planning")
      } else {
        history.push("/")
      }

      return undefined
    } catch (error) {
      dispatch(apiError(API.LOGIN_USER, error))
      return error.response?.data?.detail
    }
  }
}

const logoutUser = (history, warning) => {
  return async dispatch => {
    dispatch({
      type: ACTIONS.LOGOUT_USER,
    })

    if (history) {
      history.push("/login")
    }

    localStorage.removeItem("accessToken")
    localStorage.removeItem("refreshToken")

    if (warning) {
      toast.warning(warning, { toastId: "logout-warning" })
    }
  }
}

const setShowDefaultLoginMessage = showDefaultMessage => {
  return async dispatch => {
    dispatch({
      type: ACTIONS.SET_SHOW_DEFAULT_LOGIN_MESSAGE_SUCCESS,
      showDefaultMessage,
    })
  }
}

const refreshAccessToken = () => {
  return async dispatch => {
    try {
      dispatch(startApiCall(API.REFRESH_ACCESS_TOKEN))

      const refreshToken = localStorage.getItem("refreshToken")
      if (!refreshToken) {
        return false
      }
      const response = await landlineApi.post("refresh_sessions/", { refresh: refreshToken })

      localStorage.setItem("accessToken", response.data.access)
      localStorage.setItem("refreshToken", response.data.refresh)

      dispatch({
        type: ACTIONS.REFRESH_ACCESS_TOKEN_SUCCESS,
      })
      return true
    } catch (error) {
      dispatch(apiError(API.REFRESH_ACCESS_TOKEN, error))
      return false
    }
  }
}

const passwordRecovery = data => {
  return async dispatch => {
    try {
      dispatch(formSent())
      const response = await landlineApi.post("recoveries/", data)
      if (response.status === 200) {
        dispatch(formResponse())
      }
      dispatch(formSentReset())
    } catch (error) {
      if (error) {
        dispatch(formSentReset())
      }
    }
  }
}
const passwordReset = (data, history) => {
  return async dispatch => {
    try {
      dispatch(formSent())
      const response = await landlineApi.put(`recoveries/${data.code}/`, data)

      dispatch(formSentReset())
      if (response.status === 200) {
        history.push("/login")
        toast.success(response.data)
      }
    } catch (error) {
      if (error) {
        dispatch(formSentReset())
      }
    }
  }
}

const getUserDetails = (userRole, id) => {
  return async dispatch => {
    try {
      dispatch(startApiCall(API.GET_USER_DETAILS))
      let userCategory

      switch (userRole) {
        case LANDLINE_AGENT:
          userCategory = "landline_agents"
          break
        case COMPANY_REPRESENTATIVE:
          userCategory = "company_representatives"
          break
        case COMPANY_DRIVER:
          userCategory = "company_drivers"
          break
        default:
          userCategory = "landline_agents"
      }

      const response = await landlineApi.get(`v2/bossadmin/users/${userCategory}/${id}/`)

      dispatch({
        type: ACTIONS.GET_USER_DETAILS_SUCCESS,
        userDetails: response.data,
      })
    } catch (error) {
      dispatch(apiError(API.GET_USER_DETAILS, error))
    }
  }
}

const updateUserDetails = (userRole, id, data) => {
  return async dispatch => {
    try {
      dispatch(startApiCall(API.UPDATE_USER_DETAILS))

      let userCategory

      switch (userRole) {
        case LANDLINE_AGENT:
          userCategory = "landline_agents"
          break
        case COMPANY_REPRESENTATIVE:
          userCategory = "company_representatives"
          break
        case COMPANY_DRIVER:
          userCategory = "company_drivers"
          break
        default:
          userCategory = "landline_agents"
      }

      const response = await landlineApi.patch(`v2/bossadmin/users/${userCategory}/${id}/`, data)

      toast.success("User details updated successfully.")

      dispatch({
        type: ACTIONS.UPDATE_USER_DETAILS_SUCCESS,
        userDetails: response.data,
      })
    } catch (error) {
      dispatch(apiError(API.UPDATE_USER_DETAILS, error))
    }
  }
}

const changeUserPassword = data => {
  return async dispatch => {
    try {
      dispatch(startApiCall(API.CHANGE_USER_PASSWORD))

      const response = await landlineApi.put(`v2/accounts/password/change/`, data)

      toast.success("Password changed successfully.")

      dispatch({
        type: ACTIONS.CHANGE_USER_PASSWORD_SUCCESS,
        userDetails: response.data,
      })
    } catch (error) {
      dispatch(apiError(API.CHANGE_USER_PASSWORD, error))
    }
  }
}

export {
  refreshAccessToken,
  loginUser,
  getUserProfile,
  decodeJWT,
  logoutUser,
  passwordRecovery,
  passwordReset,
  getUserDetails,
  updateUserDetails,
  changeUserPassword,
  setShowDefaultLoginMessage,
}
