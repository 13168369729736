import ACTIONS from "../../constants/ACTIONS"

const initialState = {}

export default function companyReducer(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.GET_COMPANIES_SUCCESS:
      if (action.offset) {
        return {
          ...state,
          allCompanies: {
            ...action.companies,
            results: [...state.allCompanies.results, ...action.companies.results],
          },
        }
      }
      return {
        ...state,
        //  list of companies
        allCompanies: action.companies,
      }

    case ACTIONS.GET_COMPANY_SUCCESS: {
      return {
        ...state,
        selectedCompany: action.company,
      }
    }

    case ACTIONS.UPDATE_COMPANIES:
      return {
        ...state,
        //  update companies
        allCompanies: action.companies,
      }

    case ACTIONS.GET_LANDLINE_INFO_SUCCESS:
      return {
        ...state,
        landline: action.landline,
      }
    default:
      return { ...state }
  }
}
