export default {
  // token actions
  REFRESH_ACCESS_TOKEN: "REFRESH_ACCESS_TOKEN",
  DECODE_JWT: "DECODE_JWT",

  // auth actions
  LOGIN_USER: "LOGIN_USER",
  LOGOUT_USER: "LOGOUT_USER",
  REGISTER_USER: "REGISTER_USER",
  CHANGE_USER_PASSWORD: "CHANGE_USER_PASSWORD",
  FORGOT_PASSWORD: "FORGOT_PASSWORD",
  VERIFY_FORGOT_PASSWORD_CODE: "VERIFY_FORGOT_PASSWORD_CODE",
  RESET_PASSWORD: "RESET_PASSWORD",

  // user actions
  GET_USER_PROFILE: "GET_USER_PROFILE",
  UPDATE_USER_PROFILE: "UPDATE_USER_PROFILE",
  GET_REFERRAL_DATA: "GET_REFERRAL_DATA",
  GET_USER_DETAILS: "GET_USER_DETAILS",
  UPDATE_USER_DETAILS: "UPDATE_USER_DETAILS",

  // trip actions
  GET_TRIP_REQUESTS: "GET_TRIP_REQUESTS",
  GET_TRIPS: "GET_TRIPS",
  GET_TRIP: "GET_TRIP",
  PATCH_TRIP: "PATCH_TRIP",
  APPROVE_TRIP: "APPROVE_TRIP",
  UNASSIGN_TRIP: "UNASSIGN_TRIP",
  UNASSIGN_MULTIPLE_TRIPS: "UNASSIGN_MULTIPLE_TRIPS",
  CHANGE_TO_NEW_TRIP: "CHANGE_TO_NEW_TRIP",
  GET_SUGGESTED_TRIPS: "GET_SUGGESTED_TRIPS",
  SUGGEST_TRIP: "SUGGEST_TRIP",
  REQUEST_TRIP: "REQUEST_TRIP",
  APPROVE_TRIP_REQUEST: "APPROVE_TRIP_REQUEST",
  DENY_TRIP_REQUEST: "DENY_TRIP_REQUEST",
  UPDATE_TRIP: "UPDATE_TRIP",
  CANCEL_TRIP: "CANCEL_TRIP",
  GET_LAST_DAY_INFO: "GET_LAST_DAY_INFO",
  ACCEPT_TRIP_SUGGESTION: "ACCEPT_TRIP_SUGGESTION",
  DENY_TRIP_SUGGESTION: "DENY_TRIP_SUGGESTION",
  REFRESH_VIDECOM_TICKET: "REFRESH_VIDECOM_TICKET",

  GET_PENDING_TRIP_SUGGESTIONS: "GET_PENDING_TRIP_SUGGESTIONS",
  GET_PENDING_TRIP_SUGGESTIONS_SUCCESS: "GET_PENDING_TRIP_SUGGESTIONS_SUCCESS",
  GET_NEXT_TRIPS_PAGE: "GET_NEXT_TRIPS_PAGE",
  GET_NEW_TRIP_REQUESTS: "GET_NEW_TRIP_REQUESTS",
  CANCEL_TRIP_REQUEST: "CANCEL_TRIP_REQUEST",
  CANCEL_TRIP_SUGGESTION: "CANCEL_TRIP_SUGGESTION",

  // driver actions
  GET_COMPANY_DRIVERS: "GET_COMPANY_DRIVERS",
  GET_ALL_DRIVERS: "GET_ALL_DRIVERS",
  GET_DRIVER: "GET_DRIVER",
  NOTIFY_DRIVERS: "NOTIFY_DRIVERS",
  ASSIGN_LANDLINE_DRIVER: "ASSIGN_LANDLINE_DRIVER",
  GET_DRIVER_DETAILS: "GET_DRIVER_DETAILS",
  UPDATE_DRIVER_DETAILS: "UPDATE_DRIVER_DETAILS",
  UPDATE_DRIVER_VEHICLE_LICENCE: "UPDATE_DRIVER_VEHICLE_LICENCE",
  GET_DRIVER_EVENTS: "GET_DRIVER_EVENTS",
  CHECK_DRIVER_EVENT: "CHECK_DRIVER_EVENT",
  CREATE_DRIVER_EVENT: "CREATE_DRIVER_EVENT",
  CANCEL_DRIVER_EVENT: "CANCEL_DRIVER_EVENT",
  GET_TRIP_DRIVERS: "GET_TRIP_DRIVERS",
  NOTIFY_CUSTOMERS: "NOTIFY_CUSTOMERS",

  // vehicle actions
  GET_COMPANY_VEHICLES: "GET_COMPANY_VEHICLES",
  GET_ALL_VEHICLES: "GET_ALL_VEHICLES",
  GET_VEHICLE: "GET_VEHICLE",
  ADD_VEHICLE: "ADD_VEHICLE",
  PATCH_VEHICLE: "PATCH_VEHICLE",
  UPDATE_VEHICLE: "UPDATE_VEHICLE",
  SET_VEHICLE_STATUS: "SET_VEHICLE_STATUS",

  // company actions
  GET_COMPANIES: "GET_COMPANIES",
  GET_COMPANY: "GET_COMPANY",
  GET_LANDLINE_INFO: "GET_LANDLINE_INFO",

  // notifications actions
  GET_UNREAD_NOTIFICATIONS: "GET_UNREAD_NOTIFICATIONS",
  MARK_ALL_NOTIFICATIONS_AS_READ: "MARK_ALL_NOTIFICATIONS_AS_READ",
  MARK_NOTIFICATION_AS_READ: "MARK_NOTIFICATION_AS_READ",
  REMOVE_SINGLE_NOTIFICATION: "REMOVE_SINGLE_NOTIFICATION",

  // airport actions
  GET_ALL_AIRPORTS: "GET_ALL_AIRPORTS",

  // quoting actions
  GET_QUOTE: "GET_QUOTE",
  GET_ADDRESS_AUTOCOMPLETE: "GET_ADDRESS_AUTOCOMPLETE",

  // representative actions
  GET_COMPANY_REPRESENTATIVES: "GET_COMPANY_REPRESENTATIVES",
  // notes actions
  SAVE_NOTE: "SAVE_NOTE",
  DELETE_NOTE: "DELETE_NOTE",
  PATCH_NOTE: "PATCH_NOTE",

  // agents actions
  GET_AGENTS: "GET_AGENTS",

  // cities actions
  GET_CITIES: "GET_CITIES",

  // filters actions
  GET_TRIP_FILTER_VALUE: "GET_TRIP_FILTER_VALUE",

  // manifest actions
  GET_MANIFEST: "GET_MANIFEST"
}
