import "./UploadImage.scss"

import PropTypes from "prop-types"
import React, { useEffect, useRef } from "react"

import SvgIcon from "../SvgIcon"

const UploadImage = ({ onChange, value, onRemoveImage }) => {
  const fileInputRef = useRef(null)
  const imagePreview = useRef(null)

  function previewPicture() {
    const file = fileInputRef.current.files[0]
    const reader = new FileReader()
    const preview = imagePreview.current

    reader.addEventListener(
      "load",
      () => {
        // convert image file to base64 string
        preview.src = reader.result
      },
      false,
    )

    if (file) {
      reader.readAsDataURL(file)
    }
  }

  const handleOnChange = e => {
    const file = e.target.files[0]
    if (file) {
      onChange(file)
    }
  }

  useEffect(() => {
    if (value) {
      previewPicture()
    }
  }, [value])

  return (
    <div>
      <div className="cloud-upload-icon-wrapper">
        <input
          ref={fileInputRef}
          type="file"
          accept="image/png, image/jpeg"
          className="custom-file-input"
          onChange={e => {
            handleOnChange(e)
          }}
          name="picture"
        />
        {value && (
          <div className="remove-image-icon-wrapper" role="button" onClick={onRemoveImage}>
            <SvgIcon icon="remove-image" width="18" height="18" />
          </div>
        )}

        {value ? (
          <img
            ref={imagePreview}
            src={value}
            height="200"
            alt="Preview"
            className="image-preview"
          />
        ) : (
          <SvgIcon icon="cloud-upload" width={24} height={24} />
        )}
      </div>
      <div className="upload-text">
        Upload your image here or{" "}
        <span className="upload-browse" role="button" onClick={() => fileInputRef.current.click()}>
          Browse Files
        </span>
      </div>
    </div>
  )
}

UploadImage.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.instanceOf(Object),
  onRemoveImage: PropTypes.func.isRequired,
}

UploadImage.defaultProps = {
  onChange: () => null,
  value: "",
}

export default UploadImage
