import moment from "moment"

import { MONTH_DAY_YEAR } from "../constants/dateFormats"
import { DRIVER_RIDE, DRIVER_STATUS, FULL_DAY_OFF_STATUSES } from "../constants/driverStatuses"

const formatEventDate = event => {
  if (moment(event.start_date_time).isSame(event.end_date_time, "day")) {
    return `${moment(event.start_date_time).format(MONTH_DAY_YEAR)} `
  }

  return `${moment(event.start_date_time).format(MONTH_DAY_YEAR)}-${moment(
    event.end_date_time,
  ).format(MONTH_DAY_YEAR)} `
}

const formatEventTime = event => {
  return `${moment(event.start_date_time).format("hh:mma")}-${moment(event.end_date_time).format(
    "hh:mma",
  )}`
}

const formatEventName = event => {
  if (event.event_type === DRIVER_RIDE) {
    return `${event.related_object?.depart.iata}-${event.related_object?.arrive.iata}`
  }

  return DRIVER_STATUS[event.event_type]
}

export const formatEventText = (event, withDate) => {
  if (event.event_type === DRIVER_RIDE || !FULL_DAY_OFF_STATUSES.includes(event.event_type)) {
    return `${(withDate && formatEventDate(event)) || ""}${formatEventName(
      event,
    )} ${formatEventTime(event)}`
  }

  return `${(withDate && formatEventDate(event)) || ""}${DRIVER_STATUS[event.event_type]}`
}

export const formatEventTextTimeFirst = event => {
  if (event.event_type === DRIVER_RIDE || !FULL_DAY_OFF_STATUSES.includes(event.event_type)) {
    return `${formatEventTime(event)} ${formatEventName(event)}`
  }

  return DRIVER_STATUS[event.event_type]
}
