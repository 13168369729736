import ACTIONS from "../../constants/ACTIONS"
import API from "../../constants/API"
import { landlineApi } from "../../utils/api/calls"
import { apiError, startApiCall } from "../api/apiActions"

const getCities = (departureCode, arrivalCode) => {
  return async dispatch => {
    try {
      dispatch(startApiCall(API.GET_CITIES))
      const departureCities = await landlineApi.get(
        `v2/landline/cities/?limit=1000&airport=${departureCode}`,
      )
      const arrivalCities = await landlineApi.get(
        `v2/landline/cities/?limit=1000&airport=${arrivalCode}`,
      )

      dispatch({
        type: ACTIONS.GET_CITIES_SUCCESS,
        departureCities: departureCities.data.results,
        arrivalCities: arrivalCities.data.results,
      })

      return {
        departureCities: departureCities.data.results,
        arrivalCities: arrivalCities.data.results,
      }
    } catch (error) {
      dispatch(apiError(API.GET_CITIES, error))
      return false
    }
  }
}

// eslint-disable-next-line
export { getCities }
