import "./AddressAutocomplete.scss"

import { throttle } from "lodash"
import PropTypes from "prop-types"
import React, { useCallback, useRef } from "react"
import Autocomplete from "react-autocomplete"
import { useDispatch, useSelector } from "react-redux"

import ACTIONS from "../../../constants/ACTIONS"
import { getAddressAutocomplete } from "../../../redux/addressAutocomplete/addressAutocompleteActions"

const AddressAutocomplete = ({
  address,
  onInputChange,
  onChangeAddress,
  onSelect,
  onFocus,
  onBlur,
  placeholder,
  itemClass,
  threshold,
  menuStyle,
}) => {
  const dispatch = useDispatch()

  const [predictions] = useSelector(({ addressAutocomplete }) => [addressAutocomplete.predictions])

  const autocompleteSearch = useRef(
    throttle(async (addressSearch, searchThreshold) => {
      if (addressSearch && addressSearch.length >= searchThreshold) {
        await dispatch(getAddressAutocomplete({ address: addressSearch }))
      }
    }, 300),
  )

  const clearPredictions = useCallback(() => {
    dispatch({ type: ACTIONS.GET_ADDRESS_AUTOCOMPLETE_CLEAR_PREDICTIONS })
  }, [])

  const handleChange = useCallback(
    e => {
      const val = e.target.value
      onChangeAddress(val)

      if (onInputChange) {
        onInputChange(val)
      }

      autocompleteSearch.current(address, threshold)
    },
    [autocompleteSearch, onChangeAddress],
  )

  const handleOnBlur = useCallback(
    e => {
      clearPredictions()

      if (onBlur) {
        onBlur(e)
      }
    },
    [onBlur, clearPredictions],
  )

  const handleOnFocus = useCallback(
    e => {
      clearPredictions()
      autocompleteSearch.current(address, threshold)

      if (onFocus) {
        onFocus(e)
      }
    },
    [onFocus, clearPredictions, autocompleteSearch],
  )

  const handleOnSelect = useCallback(
    val => {
      onChangeAddress(val)

      if (onSelect) {
        onSelect(val)
      }
    },
    [onSelect, onChangeAddress],
  )

  return (
    <Autocomplete
      getItemValue={item => item.description}
      items={predictions}
      renderItem={item => <div className={itemClass}>{item.description}</div>}
      value={address}
      onChange={handleChange}
      onSelect={handleOnSelect}
      menuStyle={menuStyle}
      inputProps={{
        placeholder,
        onBlur: handleOnBlur,
        onFocus: handleOnFocus,
      }}
    />
  )
}

AddressAutocomplete.propTypes = {
  address: PropTypes.string.isRequired,
  onChangeAddress: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  itemClass: PropTypes.string,
  onSelect: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onInputChange: PropTypes.func,
  threshold: PropTypes.number,
  menuStyle: PropTypes.shape({
    borderRadius: PropTypes.string,
    boxShadow: PropTypes.string,
    background: PropTypes.string,
    padding: PropTypes.string,
    fontSize: PropTypes.string,
    position: PropTypes.string,
    overflow: PropTypes.string,
    width: PropTypes.string,
    maxHeight: PropTypes.string,
  }),
}

AddressAutocomplete.defaultProps = {
  placeholder: "",
  threshold: 3,
  menuStyle: {
    borderRadius: "3px",
    boxShadow: "0px 5px 5px rgba(1, 1, 1, .5)",
    background: "rgba(255, 255, 255, 0.9)",
    padding: "2px 0",
    fontSize: "90%",
    position: "fixed",
    overflow: "auto",
    width: "auto",
    maxHeight: "50%",
  },
}

export default AddressAutocomplete
