import "./TransportationTypeCheckbox.scss"

import PropTypes from "prop-types"
import React from "react"

import Checkbox from "../Checkbox"

const TransportationTypeCheckbox = ({ name, checked, setChecked }) => {
  return (
    <div
      className="transportation-type-checkbox-container"
      role="button"
      onClick={() => setChecked(!checked)}
    >
      <Checkbox checked={checked} />
      <div className="transportation-type-name">{name}</div>
    </div>
  )
}

TransportationTypeCheckbox.propTypes = {
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  setChecked: PropTypes.func.isRequired,
}

TransportationTypeCheckbox.defaultProps = {}

export default TransportationTypeCheckbox
