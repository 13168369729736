import queryString from "query-string"

import ACTIONS from "../../constants/ACTIONS"
import API from "../../constants/API"
import { landlineApi } from "../../utils/api/calls"
import { apiError, startApiCall } from "../api/apiActions"

const getManifest = (flightNumber, flightDate="") => {
  return async dispatch => {
    try {
      dispatch(startApiCall(API.GET_MANIFEST))
      const manifest = await landlineApi.post('v2/bossadmin/manifest/', {'flight': flightNumber, 'date': flightDate})

      dispatch({
        type: ACTIONS.GET_MANIFEST_SUCCESS,
        data: manifest.data
      })
    } catch (error) {
      dispatch(apiError(API.GET_MANIFEST, error))
    }
  }
}

export {
    getManifest
}