import "./UserAvatarName.scss"

import classNames from "classnames"
import PropTypes from "prop-types"
import React, { useRef, useState } from "react"

import {
  OUTSIDE_OF_THE_SHIFT,
  OVER_DRIVE_LIMIT,
  OVER_DUTY_LIMIT,
  OVER_ROLLING_DUTY_LIMIT,
  OVER_WAIT_LIMIT,
  OVERLAPPING_RIDE,
} from "../../../constants/tripDriverErrors"
import { getFirstLetter } from "../../../utils/common"
import SvgIcon from "../SvgIcon"

const UserAvatarName = ({
  avatarURL,
  avatarWidth,
  avatarHeight,
  initialsFontSize,
  nameFontSize,
  fname,
  lname,
  className,
  style,
  onClick,
  avatarType,
  id,
  description,
  hideFullName,
  withIcon,
  driverErrors,
  setPopupVisible,
  setWrapperPosition,
  setErrorToShow,
  onAvatarClick,
  setAvatarPopupVisible,
  setAvatarPosition,
  valueDisplay,
  setNamePopupRowID,
  rowID,
  customCursor,
}) => {
  const avatarNameClass = classNames([
    "avatar-name-container",
    className,
    { hideFullName, withIcon },
  ])

  const avatarImageWrapperClass = classNames({ "avatar-image-wrapper": customCursor })
  const [assignedAvatarPopupVisible, setAssignedAvatarPopupVisible] = useState(false)
  const [assignedAvatarPosition, setAssignedAvatarPosition] = useState()

  const wrapperRef = useRef()
  const avatarWrapperRef = useRef()

  const avatar = {
    lettersWithColor: (
      <div
        className="avatarWithLetters"
        style={{
          width: avatarWidth,
          height: avatarHeight,
          fontSize: initialsFontSize,
        }}
      >
        <div>{`${getFirstLetter(fname)}${getFirstLetter(lname)}`}</div>
      </div>
    ),
    withAvatar: <img src={avatarURL} width={avatarWidth} height={avatarHeight} alt="Avatar" />,
    unassignedAvatar: <SvgIcon icon="empty-profile" width="24" height="24" />,
  }[avatarType]

  const getIcon = error => {
    const iconName = `driver-error-${error[0]}-small`
    let iconWidth
    let iconHeight

    switch (error[0]) {
      case OVERLAPPING_RIDE:
        iconWidth = 21
        iconHeight = 12
        break
      case OUTSIDE_OF_THE_SHIFT:
        iconWidth = 15
        iconHeight = 14
        break
      case OVER_DRIVE_LIMIT:
        iconWidth = 17
        iconHeight = 9
        break
      case OVER_DUTY_LIMIT:
        iconWidth = 14
        iconHeight = 14
        break
      case OVER_ROLLING_DUTY_LIMIT:
        iconWidth = 17
        iconHeight = 15
        break
      case OVER_WAIT_LIMIT:
        iconWidth = 9
        iconHeight = 14
        break

      default:
        break
    }

    return <SvgIcon icon={iconName} width={iconWidth} height={iconHeight} />
  }

  return (
    <div
      role="button"
      className={avatarNameClass}
      style={style}
      onClick={() => onClick(id)}
      ref={wrapperRef}
      onMouseEnter={() => {
        setWrapperPosition(wrapperRef?.current?.getBoundingClientRect())
      }}
    >
      <div className="avatar-name-wrapper">
        {avatarType === "unassignedAvatar" ? (
          avatar
        ) : (
          <div
            className={avatarImageWrapperClass}
            role="button"
            ref={avatarWrapperRef}
            onMouseEnter={() => {
              if (valueDisplay) {
                setAssignedAvatarPopupVisible(true)
                setAssignedAvatarPosition(avatarWrapperRef?.current?.getBoundingClientRect())
              } else {
                setAvatarPosition(avatarWrapperRef?.current?.getBoundingClientRect())
                setAvatarPopupVisible(true)
              }
            }}
            onClick={e => {
              e.stopPropagation()
              onAvatarClick()
            }}
            onMouseLeave={() => {
              if (valueDisplay) {
                setAssignedAvatarPopupVisible(false)
              } else {
                setAvatarPopupVisible(false)
              }
            }}
          >
            {avatar}
          </div>
        )}

        {assignedAvatarPopupVisible && (
          <div
            style={{
              left: assignedAvatarPosition.left - 50,
              top: assignedAvatarPosition.top - 40,
            }}
            className="driver-avatar-popup"
          >{`${"Driver's schedule"}`}</div>
        )}

        {!hideFullName && (
          <div
            className="avatar-text-container"
            onMouseEnter={() => setNamePopupRowID(rowID)}
            onMouseLeave={() => setNamePopupRowID(-1)}
          >
            <span style={{ fontSize: nameFontSize }}>
              {fname} {lname}
            </span>
            {description && <span>{description}</span>}
          </div>
        )}
      </div>
      {withIcon && (
        <div className="icons-container">
          {driverErrors.errors.map(error => (
            <div
              className="icon-wrapper"
              onMouseEnter={() => {
                setPopupVisible(true)
                setErrorToShow(error)
              }}
              onMouseLeave={() => setPopupVisible(false)}
            >
              {getIcon(error)}
            </div>
          ))}

          {driverErrors.new_ride_deadleg && (
            <div className="icon-wrapper">
              <SvgIcon icon="dead-leg" width="16" height="16" />
            </div>
          )}
        </div>
      )}
    </div>
  )
}

UserAvatarName.propTypes = {
  avatarURL: PropTypes.string,
  fname: PropTypes.string,
  lname: PropTypes.string,
  avatarWidth: PropTypes.string,
  avatarHeight: PropTypes.string,
  initialsFontSize: PropTypes.string,
  nameFontSize: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.instanceOf(Object),
  onClick: PropTypes.func,
  avatarType: PropTypes.oneOf(["lettersWithColor", "withAvatar", "unassignedAvatar"]),
  id: PropTypes.number,
  description: PropTypes.string,
  hideFullName: PropTypes.bool,
  withIcon: PropTypes.bool,
  driverErrors: PropTypes.instanceOf(Array),
  setPopupVisible: PropTypes.func,
  setWrapperPosition: PropTypes.func,
  setErrorToShow: PropTypes.func,
  onAvatarClick: PropTypes.func,
  setAvatarPopupVisible: PropTypes.func,
  setAvatarPosition: PropTypes.func,
  valueDisplay: PropTypes.bool,
  setNamePopupRowID: PropTypes.func,
  rowID: PropTypes.number,
  customCursor: PropTypes.bool,
}

UserAvatarName.defaultProps = {
  avatarURL: "/media/empty_profile.png",
  fname: "",
  lname: "",
  avatarWidth: "32px",
  avatarHeight: "32px",
  initialsFontSize: "16px",
  nameFontSize: "16px",
  className: "",
  style: {},
  onClick: () => {},
  avatarType: "",
  id: 0,
  description: "",
  hideFullName: false,
  withIcon: false,
  driverErrors: [],
  setPopupVisible: () => {},
  setWrapperPosition: () => {},
  setErrorToShow: () => {},
  onAvatarClick: () => {},
  setAvatarPopupVisible: () => {},
  setAvatarPosition: () => {},
  valueDisplay: false,
  setNamePopupRowID: () => {},
  rowID: -1,
  customCursor: false,
}

export default UserAvatarName
