import ACTIONS from "../../constants/ACTIONS"

const initialState = {
  tripFiltersValues: {},
  calendarFilters: {
    filters: {
      status: undefined,
      vehicle_type: undefined,
    },
  },
}

export default function notesReducer(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.UPDATE_FILTERS_SUCCESS:
      return {
        ...state,
        [action.filterType]: {
          ...state[action.filterType],
          filters: {
            ...state[action.filterType].filters,
            ...action.filters,
          },
        },
      }
    case ACTIONS.SET_INITIAL_FILTERS_SUCCESS:
      return {
        ...state,
        [action.filterType]: {
          ...state[action.filterType],
          initialFilters: action.filters,
        },
      }
    case ACTIONS.RESET_FILTERS_SUCCESS:
      return {
        ...state,
        [action.filterType]: initialState[action.filterType],
      }
    case ACTIONS.GET_TRIP_FILTER_VALUE_SUCCESS:
      return {
        ...state,
        tripFiltersValues: {
          ...state.tripFiltersValues,
          [action.field]: action.value,
        },
      }
    default:
      return { ...state }
  }
}
