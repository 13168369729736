import "./OperatorsProfilePage.scss"

import PropTypes from "prop-types"
import React, { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useLocation, useParams } from "react-router-dom"

import API from "../../../constants/API"
import { ROUTES } from "../../../constants/routes"
import { COMPANY_DRIVER, LANDLINE_AGENT } from "../../../constants/userRoles"
import { getCompany, getLandlineInfo } from "../../../redux/actions"
import apiUtils from "../../../utils/api/api-utils"
import { getKeyByValue } from "../../../utils/common"
import CurrentDate from "../../atoms/CurrentDate"
import NavigationDetails from "../../atoms/NavigationDetails"
import NotificationButton from "../../atoms/NotificationButton"
import RegularLayout from "../../layouts/RegularLayout"
import TabLayout from "../../molecules/TabLayout"
import ContactInfoTab from "../../organisms/ContactInfoTab"
import DriversTab from "../../organisms/DriversTab"
import VehiclesTab from "../../organisms/VehiclesTab"

const OperatorsProfilePage = ({ history }) => {
  const location = useLocation()
  const isLandline = location.pathname.includes("/landline")
  const { companyId, tab } = useParams()
  const dispatch = useDispatch()

  const [
    companies,
    selectedCompanyDrivers,
    selectedCompanyVehicles,
    callsInProgress,
    userData,
  ] = useSelector(({ company, driver, vehicle, api, user }) => [
    company,
    driver.selectedCompanyDrivers,
    vehicle.selectedCompanyVehicles,
    api.callsInProgress,
    user.jwtData,
  ])

  const companyLoading = useMemo(() => {
    return apiUtils.areCallsInProgress([API.GET_COMPANY, API.GET_LANDLINE_INFO], callsInProgress)
  }, [callsInProgress])

  const { selectedCompany, landline } = companies

  const [tabIndex, setTabIndex] = useState(0)

  useEffect(() => {
    if (isLandline) {
      dispatch(getLandlineInfo())
    } else {
      dispatch(getCompany(companyId))
    }
  }, [isLandline])

  const contactInfoTabIndex = 0
  const driversTabIndex = 1
  const vehiclesIndex = 2

  const tabs = {
    [contactInfoTabIndex]: "",
    [driversTabIndex]: "drivers",
    [vehiclesIndex]: "vehicles",
  }

  useEffect(() => {
    if (!tab) {
      setTabIndex(contactInfoTabIndex)
    } else {
      setTabIndex(+getKeyByValue(tabs, tab))
    }
  }, [tab])

  const getTabName = () => {
    switch (tabIndex) {
      case 0:
        return "Contact info"
      case 1:
        return "Drivers"
      case 2:
        return "Vehicles"
      default:
        return ""
    }
  }

  const getPathStrings = () => {
    if (companyLoading) {
      return []
    }

    return isLandline
      ? [{ name: "Landline" }, { name: getTabName() }]
      : selectedCompany && [
          {
            name: "Operators",
            onClick: () => {
              history.push(ROUTES.OPERATORS_PAGE)
            },
          },
          { name: selectedCompany.name },
          { name: getTabName() },
        ]
  }

  return (
    <RegularLayout withTopMenu={false} history={history} backgroundColor="#ffffff">
      <div className="operators-profile-page-container">
        <div className="operators-container">
          <NavigationDetails paths={getPathStrings()} />
          <div className="right-side-content">
            {userData?.role !== COMPANY_DRIVER && (
              <div className="notification-button">
                <NotificationButton onClick={() => history.push("/")} />
              </div>
            )}
            <div className="current-date">
              <CurrentDate />
            </div>
          </div>
        </div>
        <div className="profile-page-tab-container">
          <TabLayout
            tabTitleStyle="title-style"
            tabIndex={tabIndex}
            setTabIndex={index => {
              history.replace(
                `${isLandline ? "/landline" : `/operators/${companyId}`}${
                  index !== 0 ? `/${tabs[index]}` : ""
                }`,
              )
            }}
            titles={[
              "Contact Info",
              (userData?.role === LANDLINE_AGENT || !isLandline) && "Drivers",
              (userData?.role === LANDLINE_AGENT || !isLandline) && "Vehicles",
            ]}
            contents={[
              <ContactInfoTab
                isLandline={isLandline}
                selectedCompany={isLandline ? landline : selectedCompany}
                companyLoading={companyLoading}
              />,
              (userData?.role === LANDLINE_AGENT || !isLandline) && (
                <DriversTab
                  company={isLandline ? landline : selectedCompany}
                  companyDrivers={selectedCompanyDrivers}
                  isLandline={isLandline}
                  history={history}
                />
              ),
              (userData?.role === LANDLINE_AGENT || !isLandline) && (
                <VehiclesTab
                  selectedCompany={isLandline ? landline : selectedCompany}
                  selectedCompanyVehicles={selectedCompanyVehicles}
                  isLandline={isLandline}
                />
              ),
            ]}
          />
        </div>
      </div>
    </RegularLayout>
  )
}

OperatorsProfilePage.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
}

OperatorsProfilePage.defaultProps = {}

export default OperatorsProfilePage
