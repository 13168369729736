import "./carAnimation.scss"

import React from "react"

document.body.classList.add("js-loading")
function showPage() {
  document.body.classList.remove("js-loading")
}

window.addEventListener("load", showPage, false)

function CarAnimation() {
  return (
    <div className="car-animation-wrapper  ">
      <div className="car-animation-content ">
        <img className="animationItem-car animationItem" src="/media/carAnimation/car.png" alt="" />
        <img
          className="animationItem-tire1 animationItem"
          src="/media/carAnimation/tire1.png"
          alt=""
        />
        <img
          className="animationItem-tire2 animationItem"
          src="/media/carAnimation/tire1.png"
          alt=""
        />
        <img
          className="animationItem-shade animationItem"
          src="/media/carAnimation/shade.png"
          alt=""
        />
      </div>
    </div>
  )
}

export default CarAnimation
