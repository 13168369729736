import ACTIONS from "../../constants/ACTIONS"

const initialState = { isSidebarCollapsed: false }

export default function appReducer(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.IS_SIDEBAR_COLLAPSED:
      return {
        ...state,
        // sidebar collapsed state
        isSidebarCollapsed: action.isSidebarCollapsed,
      }

    default:
      return { ...state }
  }
}
