import "./CalendarComponent.scss"
import "react-calendar/dist/Calendar.css"

import moment from "moment"
import PropTypes from "prop-types"
import React from "react"
import Calendar from "react-calendar"

const CalendarComponent = ({
  value,
  onChange,
  view,
  selectRange,
  className,
  onClickDay,
  activeStartDate,
}) => {
  return (
    <Calendar
      value={value}
      formatShortWeekday={(locale, dateV) =>
        ["Sun", "M", "T", "W", "T", "F", "Sat"][dateV.getDay()]
      }
      formatMonth={(locale, dateV) => moment(dateV).format("MMM")}
      onDateChange={dateV => onChange(dateV)}
      onChange={dateV => onChange(dateV)}
      onClickMonth={dateV => {
        if (view === "year") {
          onChange(dateV)
        }
      }}
      view={view}
      onClickDay={dateV => onClickDay(dateV)}
      selectRange={selectRange}
      className={className}
      defaultActiveStartDate={activeStartDate}
    />
  )
}

CalendarComponent.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  view: PropTypes.string,
  selectRange: PropTypes.bool,
  className: PropTypes.string,
  onClickDay: PropTypes.func,
  activeStartDate: PropTypes.string,
}

CalendarComponent.defaultProps = {
  onChange: () => {},
  view: "",
  selectRange: false,
  className: "",
  onClickDay: () => {},
  activeStartDate: "",
}

export default CalendarComponent
