import "./DateChange.scss"

import moment from "moment"
import PropTypes from "prop-types"
import React, { useState } from "react"

import SvgIcon from "../SvgIcon"

const DateChange = ({ dateValue, onDateChange, onDateClick, setShowCalendar, isYearView }) => {
  const [dateState, setDateState] = useState(new Date())

  const initialDate = dateValue || dateState

  const changeDate = dateV => {
    if (isYearView) {
      const newMonth = moment(initialDate)
        .startOf("month")
        .add(dateV, "months")

      onDateChange(moment(newMonth).toDate())
      setDateState(newMonth)
    } else {
      initialDate.setDate(initialDate.getDate() + dateV)
      onDateChange(initialDate)
      setDateState(initialDate)
    }

    setShowCalendar(false)
  }

  return (
    <div className="date-container">
      <div role="button" className="date-wrapper" onClick={onDateClick}>
        <div
          className="prev"
          onClick={e => {
            e.stopPropagation()
            changeDate(-1)
          }}
          role="button"
        >
          <SvgIcon height="15" width="15" fill="black" icon="left-arrow" margin="3px 0 0 2px" />
        </div>
        <p className="date">
          {moment(initialDate.toString()).format(isYearView ? "MMMM YYYY" : "D MMM YYYY")}
        </p>
        <div
          className="next"
          onClick={e => {
            e.stopPropagation()
            changeDate(1)
          }}
          role="button"
        >
          <SvgIcon height="15" width="15" fill="black" icon="right-arrow" margin="3px 0 0 3px" />
        </div>
      </div>
    </div>
  )
}

DateChange.propTypes = {
  dateValue: PropTypes.instanceOf(Date),
  onDateChange: PropTypes.func,
  onDateClick: PropTypes.func,
  setShowCalendar: PropTypes.func,
  isYearView: PropTypes.bool,
}

DateChange.defaultProps = {
  dateValue: undefined,
  onDateChange: undefined,
  onDateClick: () => {},
  setShowCalendar: () => {},
  isYearView: false,
}

export default DateChange
