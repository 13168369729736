import "./LandlineCard.scss"

import PropTypes from "prop-types"
import React from "react"

import Loading from "../Loading"
import SvgIcon from "../SvgIcon"

const LandlineCard = ({ company, loading }) => {
  const firstLetter = company?.name?.substring(0, 1).toUpperCase()
  const facebookThumbnail = company.social_networks?.find(item => item.name === "facebook")
  const twitterThumbnail = company.social_networks?.find(item => item.name === "twitter")
  const instagramThumbnail = company.social_networks?.find(item => item.name === "instagram")

  function handleThumbnailClick(url) {
    window.open(url, "_blank").focus()
  }

  return (
    <div className="landline-company-card-container">
      {loading ? (
        <Loading className="company-loading" />
      ) : (
        <>
          <div className="landline-wrapper">
            {company.logo ? (
              <div className="avatar-landline-image">
                <img src={company.logo} width={88} height={88} alt="Logo" />
              </div>
            ) : (
              <div className="avatar-image" style={{ backgroundColor: company.color }}>
                <div
                  className="avatar-text"
                  style={{ color: company.color === "#FFFFFF" ? "#28221E" : "#FFFFFF" }}
                >
                  {firstLetter}
                </div>
              </div>
            )}
            <div className="landline-name">{company.name}</div>
          </div>
          <div className="landline-info-wrapper">
            <div className="landline-info">
              <div className="landline-information">
                <SvgIcon icon="location" width={24} height={24} margin="0px 8px 0px 0px" />
                {company.address}
              </div>
              <div className="landline-information">
                <SvgIcon
                  icon="phone"
                  margin="0px 8px 0px 0px"
                  width={24}
                  height={24}
                  fill="#F5F5F5"
                />
                <span className="landline-infotmation-text">Phone:</span>
                {company.phone_number}
              </div>
              <div className="landline-information">
                <SvgIcon icon="e-mail" margin="0px 8px 0px 0px" width={24} height={24} />
                <span className="landline-infotmation-text">Email:</span>
                {company.email}
              </div>
            </div>
            <div className="landline-info-row">
              <div className="landline-information">
                <div
                  className="thumbnail-wrapper"
                  role="button"
                  onClick={() => handleThumbnailClick(facebookThumbnail?.link)}
                >
                  <SvgIcon icon="facebook" margin="0px 8px 0px 0px" width={24} height={24} />
                  {facebookThumbnail?.account}
                </div>
              </div>
              <div className="landline-information">
                <div
                  className="thumbnail-wrapper"
                  role="button"
                  onClick={() => handleThumbnailClick(twitterThumbnail?.link)}
                >
                  <SvgIcon icon="twitter" margin="0px 8px 0px 0px" width={24} height={24} />
                  {twitterThumbnail?.account}
                </div>
              </div>
              <div className="landline-information">
                <div
                  className="thumbnail-wrapper"
                  role="button"
                  onClick={() => handleThumbnailClick(instagramThumbnail?.link)}
                >
                  <SvgIcon icon="instagram" margin="0px 8px 0px 0px" width={24} height={24} />
                  {instagramThumbnail?.account}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

LandlineCard.propTypes = {
  company: PropTypes.instanceOf(Object),
  loading: PropTypes.bool,
}

LandlineCard.defaultProps = {
  company: {},
  loading: false,
}

export default LandlineCard
