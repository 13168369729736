import PropTypes from "prop-types"
import React from "react"

function Illustration({ angle }) {
  const calcEyeMovement = () => {
    const eyeLimit = 1
    if (angle < 20) {
      const move = (-12 + angle) / 10
      return move
    }
    return eyeLimit
  }

  const eyeMovement = `translate(${calcEyeMovement()}, 0)`
  return (
    <div>
      <svg width="239" height="165" viewBox="0 0 239 165" fill="none">
        <g>
          <path
            d="M38.0103 158.064C45.6511 151.803 46.6393 141.281 45.5572 131.985C45.375 130.433 45.4137 128.78 44.5524 127.476C43.6912 126.172 41.8693 125.381 40.5112 126.155C39.4071 126.796 38.9875 128.183 38.4244 129.349C37.6673 130.916 36.4849 132.237 35.0125 133.163C34.0519 133.759 32.8374 134.174 31.7994 133.715C30.3806 133.085 30.0714 131.245 29.9555 129.698C29.7347 126.813 29.5175 123.926 29.3041 121.038C29.2677 119.482 28.9806 117.943 28.4539 116.479C27.8466 115.053 26.6596 113.776 25.1414 113.489C23.6232 113.201 21.8289 114.196 21.6523 115.738C21.6452 116.385 21.682 117.031 21.7627 117.672C21.794 117.993 21.7314 118.316 21.5824 118.601C21.4333 118.887 21.2044 119.123 20.9235 119.28C20.3162 119.513 19.6537 119.126 19.1458 118.728C17.4013 117.302 16.137 115.379 14.95 113.461C13.7631 111.544 12.5982 109.593 10.9916 107.99C9.38509 106.387 7.24303 105.227 4.99055 105.282C2.73807 105.337 0.45799 106.912 0.0715354 109.151C-0.314919 111.389 1.14809 113.489 2.69391 115.136C4.99623 117.568 7.63648 119.657 10.5334 121.336C11.0886 121.584 11.5507 122.002 11.8529 122.53C12.278 123.536 11.3726 124.641 10.3733 125.066C9.23603 125.547 7.95521 125.531 6.74616 125.774C5.53711 126.017 4.25629 126.658 3.88639 127.835C3.37848 129.493 4.85253 131.018 6.25481 131.997C9.08079 133.955 12.219 135.418 15.5352 136.324C16.7222 136.644 17.9644 136.909 18.9581 137.633C19.9519 138.357 20.6144 139.7 20.1561 140.839C19.6979 141.977 18.2735 142.441 17.0479 142.32C15.8223 142.198 14.6906 141.64 13.5036 141.308C11.2953 140.684 8.53489 141.06 7.28719 143.011C6.91825 143.676 6.71054 144.418 6.68081 145.179C6.65109 145.939 6.80017 146.695 7.11605 147.387C7.75868 148.764 8.67112 149.996 9.79915 151.013C13.5569 154.79 18.2463 157.505 23.3913 158.882C28.5256 160.181 33.1907 160.264 38.0103 158.064Z"
            fill="#EBEBEB"
          />
          <path
            d="M38.6778 164.967C37.8843 160.68 36.6769 156.481 35.0728 152.427C33.0906 147.476 30.8214 142.646 28.2767 137.959L26.2837 134.268C25.6322 133.052 24.9863 131.847 24.2797 130.709C22.9254 128.527 21.4509 126.422 19.863 124.403C18.3559 122.464 16.8487 120.673 15.4464 119.015C14.0441 117.357 12.686 115.837 11.3776 114.5C9.34162 112.386 7.14314 110.435 4.80236 108.664C3.97424 108.034 3.31175 107.559 2.86456 107.261L2.35113 106.907C2.2925 106.867 2.23167 106.83 2.16895 106.796C2.16895 106.796 2.22415 106.846 2.33457 106.929L2.83696 107.299C3.27862 107.62 3.94112 108.095 4.74715 108.736C7.06117 110.53 9.23698 112.495 11.2561 114.616C12.5425 115.954 13.8896 117.479 15.3029 119.142C16.7162 120.806 18.2068 122.596 19.7195 124.536C21.3068 126.551 22.7813 128.652 24.1361 130.83C24.8373 131.963 25.4777 133.163 26.1291 134.378L28.1056 138.076C30.6291 142.755 32.8888 147.572 34.874 152.505C36.48 156.543 37.7001 160.725 38.5177 164.994"
            fill="#E0E0E0"
          />
          <path
            d="M24.2364 130.681C24.2882 130.46 24.3178 130.234 24.3248 130.007C24.3689 129.576 24.4131 128.94 24.4573 128.161C24.5511 126.603 24.6063 124.448 24.6615 122.082C24.7167 119.717 24.8051 117.551 24.932 116.003C24.9873 115.224 25.048 114.6 25.0921 114.163C25.1243 113.94 25.1391 113.714 25.1363 113.489C25.0681 113.705 25.0218 113.927 24.9983 114.152C24.9265 114.583 24.8437 115.213 24.7664 115.987C24.5953 117.545 24.4793 119.7 24.4241 122.066C24.3689 124.431 24.3303 126.559 24.2972 128.145C24.2696 128.891 24.253 129.515 24.2364 129.99C24.2142 130.22 24.2142 130.452 24.2364 130.681Z"
            fill="#E0E0E0"
          />
          <path
            d="M3.79297 128.625H4.00276H4.60452C5.12348 128.625 5.8743 128.625 6.81284 128.653C8.67334 128.692 11.2295 128.835 14.0727 129.128C16.9159 129.421 19.4444 129.797 21.2773 130.123C22.1937 130.283 22.9335 130.427 23.4469 130.526L24.0321 130.642C24.1702 130.642 24.2419 130.676 24.2419 130.642C24.1798 130.609 24.1126 130.587 24.0432 130.576L23.4635 130.416C22.9611 130.289 22.2213 130.117 21.3049 129.941C19.4775 129.57 16.9269 129.167 14.0947 128.868C11.2626 128.57 8.68438 128.46 6.81284 128.465C5.87982 128.465 5.1566 128.465 4.60452 128.515L4.00828 128.553C3.93318 128.566 3.86051 128.59 3.79297 128.625Z"
            fill="#E0E0E0"
          />
          <path
            d="M34.8193 152.074C34.8627 151.99 34.8997 151.903 34.9298 151.814C35.007 151.621 35.0954 151.372 35.2113 151.068C35.4542 150.416 35.7634 149.46 36.1719 148.305C36.9504 145.945 37.9275 142.651 38.9654 138.999C40.0034 135.346 40.9474 132.052 41.6872 129.681C42.0571 128.521 42.3552 127.57 42.576 126.874C42.6754 126.564 42.7527 126.321 42.8134 126.111C42.8473 126.024 42.8714 125.933 42.8852 125.84C42.836 125.92 42.797 126.005 42.7693 126.095C42.6975 126.293 42.6036 126.542 42.4877 126.846C42.2448 127.498 41.9356 128.449 41.5326 129.609C40.7487 131.969 39.7715 135.263 38.7391 138.916C37.7067 142.569 36.7737 145.873 36.0118 148.233C35.6475 149.394 35.3438 150.344 35.1285 151.035C35.0291 151.35 34.9518 151.588 34.8856 151.803C34.8547 151.891 34.8325 151.982 34.8193 152.074Z"
            fill="#E0E0E0"
          />
          <path
            d="M7.35938 145.757C7.44488 145.798 7.53607 145.826 7.62989 145.84L8.43041 146.028L11.3785 146.702C13.8629 147.255 17.2857 148.1 21.0675 149.029C24.8492 149.957 28.2776 150.764 30.773 151.295C32.0207 151.56 33.031 151.764 33.7321 151.892L34.5382 152.035C34.6325 152.057 34.7286 152.07 34.8252 152.074C34.7375 152.034 34.6446 152.006 34.5492 151.991L33.7487 151.803L30.8061 151.129C28.3218 150.576 24.8989 149.731 21.1227 148.802C17.3465 147.874 13.9125 147.067 11.4171 146.537C10.1695 146.271 9.15915 146.067 8.45801 145.94L7.64646 145.79C7.55183 145.772 7.45581 145.76 7.35938 145.757Z"
            fill="#E0E0E0"
          />
          <path
            d="M110.537 0.151252L70.6211 12.1689L78.7133 39.1007L118.629 27.083L110.537 0.151252Z"
            fill="white"
          />
          <path
            d="M118.636 27.0566C118.636 27.0566 118.575 26.8853 118.475 26.5648C118.376 26.2443 118.227 25.7579 118.034 25.139C117.653 23.89 117.09 22.0608 116.378 19.7287C114.953 15.0479 112.916 8.33339 110.432 0.159933L110.575 0.237301L70.671 12.3013L70.7704 12.1189L78.8528 39.0543L78.6872 38.9659L107.439 30.3724L115.671 27.9353L117.879 27.2887L118.431 27.123L118.63 27.0732L118.453 27.134L117.901 27.3109L115.737 27.974L107.555 30.4774L78.759 39.2146L78.632 39.2533L78.5933 39.1262C76.1256 30.9196 73.3762 21.7956 70.4888 12.1963L70.4502 12.0581L70.5827 12.0195L110.503 0.0272973L110.614 -0.00585938L110.647 0.10467C113.093 8.32234 115.097 15.07 116.499 19.7729C117.184 22.105 117.725 23.9287 118.089 25.1832C118.271 25.7911 118.404 26.2608 118.503 26.5924C118.602 26.924 118.636 27.0566 118.636 27.0566Z"
            fill="#E0E0E0"
          />
          <path
            d="M110.542 0.143099C110.528 0.185242 110.509 0.225938 110.487 0.264675L110.299 0.60731C110.117 0.922311 109.869 1.35889 109.56 1.91153L106.772 6.70286L97.4193 22.58L97.3531 22.685L97.2426 22.6408L96.0226 22.1711L78.0414 15.1471L72.6034 12.9918L71.1349 12.3895L70.7539 12.2292C70.7084 12.214 70.6655 12.1916 70.627 12.1629C70.675 12.1671 70.7218 12.1802 70.765 12.2016L71.1514 12.3342L72.6476 12.8868L78.1131 14.9592L96.1219 21.9003L97.3475 22.3756L97.1709 22.4364C100.87 16.2192 104.193 10.6487 106.6 6.59787L109.466 1.85626C109.797 1.30363 110.062 0.889155 110.25 0.574153L110.465 0.248097C110.509 0.176254 110.537 0.137573 110.542 0.143099Z"
            fill="#E0E0E0"
          />
          <path
            d="M226.689 107.741C226.689 107.741 224.1 105.177 219.054 111.582C216.408 114.824 213.979 118.238 211.783 121.8C211.783 121.8 210.524 123.105 209.696 121.922C208.868 120.739 208.012 117.689 206.168 117.844C204.324 117.998 203.264 119.899 203.016 122.933C202.767 125.967 201.989 132.604 201.912 133.201C201.834 133.798 201.36 134.577 200.647 133.864C199.935 133.151 196.352 127.084 193.564 128.951C190.776 130.819 191.483 139.407 191.544 140.341C191.604 141.275 191.35 141.629 190.887 141.762C190.423 141.894 189.402 139.739 188.303 138.053C187.204 136.368 185.642 134.594 184.129 135.815C182.616 137.037 181.01 143.044 184.83 154.08L186.934 159.053L192.316 159.822C203.949 160.756 209.359 157.7 210.16 155.926C210.96 154.152 208.862 153.079 206.952 152.438C205.042 151.797 202.701 151.333 202.718 150.863C202.734 150.394 203.005 150.051 203.927 149.874C204.849 149.697 213.312 148.216 214.416 145.061C215.52 141.905 208.757 139.927 207.891 139.429C207.024 138.932 207.664 138.252 208.216 138.037C208.768 137.821 214.996 135.395 217.872 134.4C220.749 133.406 222.333 131.958 222.007 130.068C221.682 128.178 218.529 128.183 217.177 127.669C215.824 127.155 216.774 125.619 216.774 125.619C219.682 122.606 222.388 119.403 224.873 116.031C229.803 109.532 226.59 107.636 226.59 107.636"
            fill="#EBEBEB"
          />
          <path
            d="M222.139 113.444C222.115 113.493 222.085 113.539 222.051 113.582L221.77 113.98C221.505 114.334 221.135 114.837 220.665 115.478C219.699 116.777 218.291 118.645 216.536 120.944C213.03 125.541 208.128 131.853 202.684 138.794L188.761 156.594L184.559 162.004L183.411 163.469L183.107 163.845C183.036 163.927 183.003 163.972 182.997 163.966C183.022 163.917 183.051 163.871 183.085 163.828L183.367 163.436L184.471 161.932C185.432 160.634 186.845 158.766 188.595 156.467C192.101 151.869 197.009 145.558 202.458 138.617C207.907 131.676 212.804 125.387 216.376 120.816C218.137 118.545 219.572 116.705 220.577 115.412C221.063 114.787 221.449 114.306 221.72 113.942L222.029 113.566C222.095 113.483 222.134 113.439 222.139 113.444Z"
            fill="#E0E0E0"
          />
          <path
            d="M207.195 122.16C207.315 122.634 207.384 123.119 207.399 123.608C207.482 124.503 207.554 125.746 207.603 127.122C207.653 128.498 207.675 129.637 207.703 130.637C207.743 131.123 207.743 131.611 207.703 132.096C207.583 131.622 207.517 131.137 207.504 130.648C207.432 129.747 207.372 128.504 207.322 127.133C207.272 125.763 207.239 124.619 207.2 123.619C207.156 123.133 207.155 122.645 207.195 122.16Z"
            fill="#E0E0E0"
          />
          <path
            d="M208.166 132.289C210.055 131.984 211.959 131.775 213.869 131.664C217.021 131.405 219.588 131.256 219.594 131.333C217.705 131.635 215.801 131.842 213.891 131.952C210.739 132.217 208.177 132.366 208.166 132.289Z"
            fill="#E0E0E0"
          />
          <path
            d="M199.007 142.944C199.502 142.983 199.993 143.062 200.475 143.182C201.381 143.353 202.623 143.591 204.003 143.812C205.383 144.033 206.637 144.199 207.547 144.315C208.044 144.354 208.537 144.434 209.022 144.552C208.525 144.595 208.025 144.584 207.531 144.519C206.609 144.453 205.323 144.32 203.959 144.094C202.595 143.867 201.326 143.602 200.431 143.381C199.942 143.287 199.465 143.14 199.007 142.944Z"
            fill="#E0E0E0"
          />
          <path
            d="M196.114 133.98C196.343 134.381 196.529 134.805 196.666 135.246C196.959 136.047 197.351 137.153 197.77 138.379C198.19 139.606 198.543 140.723 198.792 141.541C198.947 141.973 199.062 142.419 199.134 142.872C198.902 142.472 198.716 142.045 198.582 141.601C198.284 140.806 197.897 139.7 197.478 138.473C197.058 137.247 196.705 136.125 196.457 135.312C196.294 134.882 196.18 134.436 196.114 133.98Z"
            fill="#E0E0E0"
          />
          <path
            d="M186.834 142.358C187.1 142.883 187.325 143.428 187.508 143.988C187.888 145.011 188.396 146.425 188.943 147.995C189.489 149.564 189.964 150.99 190.301 152.023C190.509 152.576 190.669 153.146 190.781 153.726C190.511 153.193 190.286 152.638 190.108 152.068C189.727 151.051 189.219 149.636 188.672 148.067C188.126 146.497 187.646 145.071 187.314 144.032C187.108 143.489 186.947 142.929 186.834 142.358Z"
            fill="#E0E0E0"
          />
          <path
            d="M190.765 153.428C191.394 153.464 192.019 153.545 192.636 153.671C193.785 153.853 195.397 154.102 197.136 154.334C198.875 154.566 200.492 154.743 201.646 154.887C202.275 154.928 202.9 155.011 203.518 155.135C202.888 155.174 202.257 155.159 201.63 155.091C200.465 155.014 198.869 154.87 197.097 154.632C195.325 154.395 193.74 154.119 192.598 153.892C191.975 153.787 191.362 153.632 190.765 153.428Z"
            fill="#E0E0E0"
          />
          <path
            d="M202.32 53.3745L201.012 96.2334L232.3 97.1903L233.608 54.3314L202.32 53.3745Z"
            fill="#F5F5F5"
          />
          <path
            d="M196.554 59.0664L195.246 101.925L226.534 102.882L227.842 60.0233L196.554 59.0664Z"
            fill="white"
          />
          <path
            d="M226.533 102.873C226.53 102.803 226.53 102.733 226.533 102.663C226.533 102.519 226.533 102.32 226.533 102.06C226.533 101.508 226.561 100.734 226.589 99.717C226.644 97.6667 226.721 94.688 226.82 90.9135C227.036 83.3701 227.339 72.6766 227.698 60.0158L227.825 60.1484L196.539 59.2034L196.688 59.0652C196.186 75.0916 195.727 89.8967 195.352 101.922L195.236 101.8L217.96 102.558L224.281 102.779L225.937 102.845L226.517 102.873H225.965L224.308 102.834L218.015 102.674L195.219 102.038H195.104V101.922C195.462 89.8912 195.904 75.0695 196.384 59.0597V58.916H196.533L227.814 59.8887H227.947V60.0213C227.533 72.7319 227.185 83.4419 226.936 91.002C226.809 94.7599 226.704 97.733 226.638 99.7723C226.6 100.778 226.572 101.552 226.55 102.088C226.55 102.342 226.55 102.541 226.522 102.679C226.495 102.817 226.533 102.873 226.533 102.873Z"
            fill="#E0E0E0"
          />
          <path
            d="M215.647 68.9796C215.647 69.0625 212.24 69.0183 208.045 68.8912C203.849 68.7641 200.442 68.5927 200.442 68.5154C200.442 68.438 203.854 68.4767 208.05 68.6038C212.246 68.7309 215.647 68.9022 215.647 68.9796Z"
            fill="#E0E0E0"
          />
          <path
            d="M223.729 72.9586C223.729 73.036 218.485 72.9586 212.014 72.7431C205.544 72.5276 200.299 72.3176 200.305 72.2402C200.31 72.1628 205.549 72.2402 212.025 72.4557C218.501 72.6713 223.735 72.8757 223.729 72.9586Z"
            fill="#E0E0E0"
          />
          <path
            d="M223.614 76.8434C223.614 76.9208 218.364 76.8434 211.893 76.6279C205.423 76.4123 200.184 76.2023 200.184 76.125C200.184 76.0476 205.434 76.125 211.904 76.3405C218.375 76.556 223.614 76.7605 223.614 76.8434Z"
            fill="#E0E0E0"
          />
          <path
            d="M223.492 80.7287C223.492 80.806 218.247 80.7287 211.777 80.5131C205.307 80.2976 200.067 80.0876 200.067 80.0102C200.067 79.9329 205.318 80.0102 211.788 80.2258C218.258 80.4413 223.498 80.6623 223.492 80.7287Z"
            fill="#E0E0E0"
          />
          <path
            d="M223.376 84.6139C223.376 84.6913 218.125 84.6139 211.66 84.3984C205.196 84.1829 199.945 83.9729 199.945 83.8955C199.945 83.8181 205.196 83.8955 211.666 84.111C218.136 84.3265 223.376 84.531 223.376 84.6139Z"
            fill="#E0E0E0"
          />
          <path
            d="M223.26 88.4987C223.26 88.576 218.01 88.4987 211.54 88.2832C205.069 88.0676 199.83 87.8576 199.83 87.7803C199.83 87.7029 205.08 87.7802 211.551 87.9958C218.021 88.2113 223.26 88.3992 223.26 88.4987Z"
            fill="#E0E0E0"
          />
          <path
            d="M223.139 92.3834C223.139 92.4608 217.888 92.3834 211.423 92.1679C204.959 91.9524 199.708 91.7424 199.714 91.665C199.719 91.5877 204.959 91.665 211.429 91.8805C217.899 92.0961 223.144 92.3005 223.139 92.3834Z"
            fill="#E0E0E0"
          />
          <path
            d="M212.186 95.9369C212.186 96.0143 209.365 95.9921 205.886 95.8871C202.408 95.7822 199.593 95.6274 199.593 95.5501C199.593 95.4727 202.414 95.4948 205.892 95.5998C209.37 95.7048 212.186 95.854 212.186 95.9369Z"
            fill="#E0E0E0"
          />
          <path
            d="M134.894 30.5798L125.104 55.1938L155.858 67.4511L165.648 42.8371L134.894 30.5798Z"
            fill="white"
          />
          <path
            d="M165.645 42.8347L165.496 42.7794L165.066 42.6081L163.41 41.9505L157.127 39.4526L134.9 30.6104H134.966C132.051 37.9383 128.717 46.3384 125.184 55.2247L125.15 55.1529L155.901 67.4214H155.841C158.8 60.0105 161.251 53.8653 162.968 49.5713L164.961 44.5976L165.513 43.2823C165.629 42.9894 165.695 42.8347 165.695 42.8347C165.695 42.8347 165.64 42.9839 165.524 43.2768C165.408 43.5697 165.237 44.0173 165.011 44.5865C164.558 45.736 163.906 47.4215 163.04 49.5602L155.951 67.4545V67.4987H155.907L125.106 55.2413H125.057V55.1916C128.595 46.3052 131.936 37.9107 134.851 30.5828V30.5386H134.9L157.127 39.4194L163.404 41.9339L165.06 42.6026L165.485 42.7739L165.645 42.8347Z"
            fill="#E0E0E0"
          />
          <path
            d="M135.317 32.5745L126.76 54.0874L155.355 65.4841L163.912 43.9711L135.317 32.5745Z"
            fill="#EBEBEB"
          />
          <path
            d="M126.873 54.1521L142.177 44.7573L145.036 55.589L151.015 52.7926L155.465 65.5474L126.873 54.1521Z"
            fill="#E0E0E0"
          />
          <path
            d="M151.644 46.6309C151.467 47.0768 151.162 47.4603 150.767 47.7327C150.373 48.0052 149.906 48.1544 149.427 48.1614C148.948 48.1685 148.477 48.0332 148.075 47.7725C147.672 47.5118 147.356 47.1375 147.166 46.697C146.976 46.2564 146.921 45.7694 147.008 45.2974C147.094 44.8255 147.319 44.3898 147.653 44.0456C147.987 43.7013 148.415 43.4639 148.884 43.3634C149.352 43.2629 149.84 43.3038 150.286 43.4808C150.883 43.7183 151.362 44.1836 151.617 44.7743C151.871 45.3651 151.881 46.0329 151.644 46.6309Z"
            fill="#E0E0E0"
          />
          <path
            d="M54.6889 70.0847C54.8967 70.1705 55.1217 70.2065 55.3459 70.1897C55.6146 70.1588 55.8643 70.0358 56.0526 69.8415C56.271 69.5389 56.4648 69.2192 56.6322 68.8855L60.2152 62.6297L61.4077 60.5628L62.0426 59.4575C62.1531 59.2814 62.2491 59.0966 62.3297 58.9049C62.3877 58.714 62.3877 58.5101 62.3297 58.3191C62.28 58.1226 62.1699 57.9467 62.015 57.8162C61.8381 57.6909 61.6537 57.5765 61.4629 57.4736L60.2318 56.7883L57.6701 55.3515L46.2642 48.9464C46.0392 48.826 45.7776 48.7932 45.5299 48.8544C45.2821 48.9157 45.0658 49.0666 44.9227 49.278L44.067 50.748C43.5149 51.7207 42.9628 52.6878 42.4107 53.6328L39.2087 59.1094C39.0817 59.3249 38.9547 59.5404 38.8333 59.756C38.7103 59.9427 38.6467 60.1623 38.6511 60.386C38.6598 60.6042 38.7392 60.8137 38.8774 60.9828C39.0342 61.1455 39.2219 61.2751 39.4295 61.3641L41.8421 62.7347L46.1593 65.1884L52.4088 68.7639L54.1037 69.7476L54.5399 70.0073C54.6392 70.0681 54.6889 70.1013 54.6834 70.1068L54.5288 70.035L54.0761 69.7973L52.3592 68.8578L46.06 65.3652L41.7427 62.917L39.3246 61.5631C39.0903 61.4595 38.8784 61.3112 38.7008 61.1265C38.5283 60.913 38.4295 60.6493 38.4192 60.3749C38.4074 60.1015 38.4807 59.8311 38.629 59.6012C38.7505 59.3857 38.8774 59.1702 39.0044 58.9546L42.1678 53.4891L43.824 50.6043L44.6908 49.1178C44.8736 48.8466 45.1503 48.653 45.4675 48.5744C45.7847 48.4957 46.1197 48.5377 46.4078 48.6922L57.8026 55.1194L60.3643 56.5673L61.5844 57.2636C61.7951 57.3708 61.998 57.4927 62.1917 57.6283C62.3842 57.7928 62.5211 58.0129 62.5836 58.2583C62.6499 58.4932 62.6499 58.7419 62.5836 58.9767C62.4956 59.1859 62.3904 59.3874 62.2689 59.5791L61.623 60.6844L60.4195 62.7457C58.8792 65.3541 57.6315 67.4707 56.7592 68.9573C56.5781 69.2915 56.3714 69.6112 56.1409 69.9134C55.9338 70.1127 55.6651 70.2355 55.379 70.2615C55.2056 70.2743 55.0315 70.2459 54.8711 70.1786C54.8063 70.1559 54.745 70.1243 54.6889 70.0847Z"
            fill="#E0E0E0"
          />
          <path
            d="M51.7732 58.7561L53.1645 61.2319L50.2937 61.2816L47.4229 61.3369L48.9024 58.8113L50.382 56.2803L51.7732 58.7561Z"
            fill="#E0E0E0"
          />
          <path
            d="M239 164.11C239 164.193 186.194 164.26 121.076 164.26C55.9585 164.26 3.13574 164.204 3.13574 164.11C3.13574 164.016 55.9309 163.956 121.076 163.956C186.221 163.956 239 164.038 239 164.11Z"
            fill="#263238"
          />
          <path
            d="M61.512 129.012L44.8447 112.251L53.2253 96.8435C61.6058 86.1003 79.3496 86.7911 79.3496 86.7911C88.1034 86.1954 96.8973 86.736 105.513 88.3992C119.607 91.1624 118.47 100.48 118.47 100.48L120.358 135.058L122.384 163.215L70.4059 164.265L61.512 129.034"
            fill="#FAB500"
          />
          <path
            d="M89.5136 162.618L42.9568 163.171C40.3921 163.2 37.8619 162.577 35.6038 161.36C33.3458 160.142 31.434 158.37 30.048 156.209C28.662 154.049 27.8473 151.571 27.6805 149.009C27.5136 146.447 28.0001 143.885 29.0942 141.563L49.0021 104.608L67.1378 116.876L54.7658 142.557L92.4506 143.232L89.5136 162.618Z"
            fill="#FAB500"
          />
          <path
            d="M116.825 94.8433L154.184 152.582L132.388 154.008C132.388 154.008 119.37 133.478 119.663 133.898C120.182 134.672 116.825 94.8433 116.825 94.8433Z"
            fill="#FAB500"
          />
          <g opacity="0.37">
            <path
              opacity="0.37"
              d="M66.1996 120.198C66.201 120.096 66.2177 119.996 66.2493 119.899C66.3045 119.678 66.3652 119.402 66.4481 119.059C66.6192 118.335 66.8732 117.285 67.1326 115.981C67.3921 114.677 67.6847 113.107 67.8669 111.361C68.0911 109.469 68.0911 107.556 67.8669 105.664C67.5674 103.787 66.9135 101.984 65.9401 100.353C65.1579 99.0093 64.2324 97.7548 63.1798 96.6113C62.4703 95.8297 61.7144 95.0915 60.9162 94.4008C60.612 94.1625 60.3208 93.9079 60.0439 93.6382C60.1338 93.6828 60.2189 93.7365 60.2979 93.7984C60.4525 93.9145 60.7009 94.0748 60.988 94.3124C61.8182 94.9685 62.6005 95.6833 63.3288 96.4511C64.417 97.5861 65.3727 98.8413 66.1775 100.192C67.1851 101.849 67.8601 103.687 68.165 105.603C68.3836 107.519 68.3688 109.454 68.1209 111.367C67.9344 112.923 67.6691 114.469 67.3259 115.998C67.0388 117.308 66.7738 118.352 66.5474 119.07C66.4481 119.43 66.3597 119.706 66.299 119.894C66.2757 119.998 66.2425 120.1 66.1996 120.198Z"
              fill="#263238"
            />
          </g>
          <g opacity="0.52">
            <path
              opacity="0.52"
              d="M64.6973 122.188C64.6176 122.475 64.5104 122.755 64.3771 123.022C64.1563 123.575 63.8251 124.315 63.4 125.232C62.5553 127.106 61.3573 129.681 59.9771 132.5C58.5969 135.318 57.294 137.844 56.3278 139.684C55.842 140.59 55.4445 141.342 55.163 141.823C55.0285 142.09 54.8735 142.347 54.6992 142.591C54.7984 142.309 54.9202 142.035 55.0636 141.773C55.3452 141.22 55.7095 140.48 56.1677 139.59L59.7397 132.406C61.0923 129.604 62.3234 127.073 63.2399 125.177C63.6705 124.287 64.0238 123.547 64.2998 122.967C64.4103 122.696 64.5433 122.436 64.6973 122.188Z"
              fill="#263238"
            />
          </g>
          <g opacity="0.45">
            <path
              opacity="0.45"
              d="M89.5136 162.618C89.5136 162.618 89.5136 162.585 89.5136 162.524C89.5136 162.463 89.5136 162.353 89.5136 162.237C89.5412 161.971 89.5799 161.601 89.624 161.131C89.7344 160.148 89.889 158.75 90.0822 156.987C90.4908 153.411 91.076 148.382 91.7385 142.452L91.871 142.596H78.1187L54.0978 142.557L46.8104 142.513L44.8284 142.486H44.3095H44.1328C44.1915 142.478 44.2508 142.478 44.3095 142.486H44.8284L46.8104 142.458L54.0978 142.414L78.1574 142.375H91.9096H92.0587V142.519C91.3299 148.443 90.7171 153.472 90.2755 157.042C90.0546 158.799 89.8725 160.197 89.7234 161.181C89.6645 161.645 89.6148 162.014 89.5743 162.286L89.5302 162.568C89.5246 162.585 89.5191 162.618 89.5136 162.618Z"
              fill="#263238"
            />
          </g>
          <g opacity="0.51">
            <path
              opacity="0.51"
              d="M55.0912 142.966C54.7578 142.598 54.448 142.208 54.1638 141.8C52.6759 139.93 50.8334 138.373 48.7424 137.219C48.2958 137.002 47.8609 136.763 47.4395 136.5C47.9295 136.615 48.4001 136.802 48.8362 137.053C51.0141 138.13 52.8975 139.721 54.3239 141.69C54.6373 142.078 54.8955 142.507 55.0912 142.966Z"
              fill="#263238"
            />
          </g>
          <g opacity="0.5">
            <path
              opacity="0.5"
              d="M121.132 146.625C121.091 146.467 121.065 146.306 121.054 146.144C121.016 145.807 120.966 145.348 120.9 144.768C120.773 143.574 120.602 141.845 120.419 139.706C120.038 135.423 119.602 129.499 119.216 122.956C118.829 116.412 118.559 110.477 118.404 106.183C118.327 104.033 118.272 102.315 118.25 101.093C118.25 100.513 118.25 100.054 118.222 99.7117C118.214 99.5516 118.214 99.3911 118.222 99.231C118.253 99.3895 118.269 99.5503 118.272 99.7117C118.272 100.049 118.316 100.513 118.349 101.093C118.41 102.32 118.493 104.05 118.598 106.172C118.802 110.466 119.105 116.39 119.492 122.939C119.878 129.488 120.287 135.401 120.596 139.689C120.762 141.806 120.894 143.558 120.988 144.762C121.032 145.315 121.065 145.801 121.093 146.139C121.12 146.299 121.133 146.462 121.132 146.625Z"
              fill="#263238"
            />
          </g>
          <path
            d="M79.5708 115.207C79.5732 114.954 79.5935 114.701 79.6315 114.45C79.6978 113.925 79.7806 113.223 79.8855 112.361C80.1284 110.538 80.4375 108.04 80.8461 105.144L81.0559 105.276L77.4839 107.78L73.8513 110.311L73.5918 110.493L73.636 110.178C74.0776 107.31 74.453 104.834 74.7401 102.994C74.8837 102.149 74.9941 101.452 75.0824 100.933C75.112 100.684 75.1638 100.438 75.237 100.198C75.2373 100.45 75.2152 100.701 75.1707 100.949C75.1045 101.469 75.0217 102.165 74.9113 103.022C74.6628 104.834 74.315 107.316 73.9175 110.206L73.7077 110.073L77.3349 107.531L80.9123 105.039L81.1718 104.856L81.1276 105.171C80.7025 108.056 80.3326 110.554 80.0676 112.356C79.9296 113.212 79.8192 113.914 79.7364 114.434C79.7044 114.696 79.649 114.955 79.5708 115.207Z"
            fill="#E0E0E0"
          />
          <path
            d="M100.351 117.446C100.152 117.287 99.9672 117.112 99.7988 116.921C99.44 116.534 98.9542 116.02 98.3579 115.39L93.4279 110.04L93.6652 109.958C93.5493 111.312 93.4279 112.782 93.3009 114.307C93.1739 115.832 93.0414 117.346 92.9255 118.728L92.8979 119.048L92.6881 118.811L87.8905 113.395L86.5214 111.814C86.3471 111.634 86.1918 111.435 86.0576 111.223C86.2562 111.392 86.4408 111.577 86.6097 111.776L88.0341 113.301L92.9034 118.645L92.666 118.728C92.7819 117.352 92.9034 115.86 93.0359 114.307C93.1684 112.754 93.2898 111.312 93.4058 109.958L93.4334 109.643L93.6432 109.875L98.5014 115.296L99.8872 116.882C100.056 117.058 100.211 117.246 100.351 117.446Z"
            fill="#E0E0E0"
          />
          <path
            d="M110.807 116.705C110.704 116.938 110.579 117.16 110.432 117.368L109.328 119.148L105.386 125.271L105.253 125.061L109.537 124.32L113.899 123.585L114.213 123.53L114.064 123.768L110.034 129.769L108.82 131.505C108.685 131.717 108.533 131.918 108.367 132.107C108.476 131.881 108.601 131.663 108.742 131.455L109.846 129.686L113.794 123.607L113.932 123.817L109.57 124.575L105.27 125.304L104.955 125.354L105.132 125.094L109.156 119.015L110.338 117.274C110.483 117.075 110.639 116.885 110.807 116.705Z"
            fill="#E0E0E0"
          />
          <path
            d="M96.9005 127.415C96.7967 127.646 96.671 127.866 96.5251 128.073L95.421 129.858L91.4847 135.975L91.3467 135.765L95.6418 135.025L100.009 134.284L100.323 134.235L100.141 134.494L96.1111 140.501L94.9241 142.226C94.7923 142.438 94.6407 142.638 94.4714 142.822C94.5819 142.597 94.7073 142.379 94.8468 142.17C95.1339 141.728 95.5093 141.137 95.951 140.407L99.8983 134.328L100.036 134.538L95.6749 135.296L91.3742 136.02L91.0596 136.075L91.2362 135.81L95.2609 129.731C95.7467 129.012 96.1442 128.426 96.4478 127.99C96.5847 127.787 96.736 127.595 96.9005 127.415Z"
            fill="#E0E0E0"
          />
          <path
            d="M126.95 116.583C126.841 116.811 126.715 117.031 126.575 117.241L125.47 119.026L121.534 125.144L121.396 124.934L125.691 124.193L130.058 123.453L130.373 123.403L130.196 123.663L126.161 129.67C125.675 130.377 125.277 130.958 124.974 131.389C124.843 131.604 124.691 131.806 124.521 131.991C124.632 131.765 124.76 131.547 124.902 131.339L126.006 129.576L129.953 123.497L130.091 123.707L125.73 124.459L121.429 125.188L121.12 125.243L121.291 124.978L125.316 118.899L126.503 117.158C126.633 116.953 126.783 116.76 126.95 116.583Z"
            fill="#E0E0E0"
          />
          <path
            d="M56.5877 107.708C56.4797 107.937 56.3542 108.156 56.2123 108.366L55.1082 110.151L51.1719 116.274L51.0339 116.064L55.329 115.318L59.6959 114.583L60.0106 114.528L59.834 114.793L55.7983 120.794L54.6168 122.519C54.4827 122.73 54.3313 122.93 54.1641 123.116C54.2699 122.889 54.3956 122.673 54.5395 122.469L55.6437 120.701C56.6429 119.17 58.0121 117.07 59.591 114.622L59.7291 114.831L55.3677 115.589L51.067 116.318L50.7578 116.368L50.929 116.108C52.5465 113.676 53.9378 111.576 54.9536 110.029L56.1406 108.288C56.2741 108.083 56.4237 107.889 56.5877 107.708Z"
            fill="#E0E0E0"
          />
          <path
            d="M34.7588 149.034C34.9448 149.202 35.1148 149.387 35.2667 149.587L36.6138 151.206L41.1905 156.859L40.9476 156.926C41.1463 155.583 41.3561 154.124 41.5825 152.604C41.8088 151.084 42.0297 149.587 42.2339 148.222L42.2781 147.907L42.4769 148.161L46.9266 153.864L48.1909 155.522C48.3515 155.714 48.4956 155.919 48.6215 156.135C48.4402 155.964 48.2722 155.78 48.1191 155.583L46.7941 153.969L42.2947 148.327L42.5376 148.266C42.3388 149.631 42.1235 151.112 41.8972 152.648C41.6708 154.185 41.4555 155.622 41.2512 156.964L41.2071 157.279L41.0083 157.031L36.5034 151.317L35.217 149.659C35.0504 149.461 34.8973 149.252 34.7588 149.034Z"
            fill="#E0E0E0"
          />
          <path
            d="M84.6113 122.276C84.364 122.329 84.113 122.362 83.8605 122.375L81.7626 122.558L74.5083 123.11L74.5966 122.878L77.7766 125.863L80.9952 128.902L81.2271 129.123H80.9068L73.6912 129.521L71.6043 129.609C71.3545 129.631 71.1033 129.631 70.8535 129.609C71.0993 129.56 71.3484 129.529 71.5988 129.516L73.6802 129.35L80.8903 128.852L80.8075 129.084L77.5778 126.056L74.4089 123.061L74.177 122.84H74.4917L81.7515 122.403L83.8494 122.298C84.1025 122.272 84.3571 122.264 84.6113 122.276Z"
            fill="#E0E0E0"
          />
          <path
            d="M72.377 150.128C72.5612 150.302 72.7345 150.486 72.8959 150.681L74.2706 152.273L78.9688 157.832L78.7258 157.899C78.897 156.55 79.0847 155.091 79.2779 153.571C79.4711 152.052 79.6699 150.543 79.83 149.178L79.8742 148.857L80.0729 149.106L84.6441 154.715L85.9415 156.373C86.1023 156.566 86.2499 156.769 86.3832 156.981C86.197 156.812 86.0252 156.627 85.8697 156.429L84.5116 154.842L79.8742 149.316L80.1116 149.244C79.9404 150.615 79.7527 152.096 79.5595 153.638C79.3663 155.18 79.173 156.622 79.0074 157.97L78.9632 158.285L78.7645 158.042L74.1436 152.416L72.8297 150.758C72.6584 150.564 72.5067 150.353 72.377 150.128Z"
            fill="#E0E0E0"
          />
          <path
            d="M50.5091 129.703C50.2621 129.757 50.0109 129.791 49.7583 129.803L47.6659 129.979L40.4116 130.532L40.4944 130.294L43.6799 133.284L46.8985 136.324L47.1304 136.545H46.8102L39.5946 136.943L37.5077 137.031C37.2634 137.052 37.0178 137.052 36.7734 137.031C37.0187 136.978 37.2681 136.947 37.5187 136.937L39.6001 136.771L46.8102 136.268L46.7274 136.506L43.4977 133.478L40.3233 130.482L40.0969 130.261H40.4116L47.6715 129.819L49.7694 129.72C50.0152 129.696 50.2625 129.69 50.5091 129.703Z"
            fill="#E0E0E0"
          />
          <path
            d="M69.2968 149.504C69.0497 149.556 68.7985 149.588 68.546 149.598L66.4481 149.78L59.1938 150.333L59.2821 150.095L62.4621 153.085L65.6807 156.124L65.9126 156.345H65.5979L58.3767 156.738L56.2899 156.832C56.0401 156.853 55.7889 156.853 55.5391 156.832C55.7843 156.779 56.0337 156.747 56.2844 156.738L58.3657 156.572L65.5758 156.069L65.493 156.307L62.2634 153.278L59.0944 150.294L58.8626 150.078H59.1773L66.4371 149.636L68.5405 149.537C68.7911 149.503 69.0442 149.492 69.2968 149.504Z"
            fill="#E0E0E0"
          />
          <path
            d="M111.701 99.1257C111.454 99.1788 111.203 99.2121 110.95 99.2252L108.852 99.4021L101.604 99.9547L101.686 99.7226L104.866 102.707L108.085 105.746L108.317 105.967H108.002L100.781 106.365L98.6942 106.454C98.4444 106.475 98.1932 106.475 97.9434 106.454C98.1886 106.401 98.438 106.369 98.6887 106.36L100.77 106.194L107.986 105.697L107.897 105.929L104.679 102.906L101.51 99.9105L101.278 99.6894H101.593L108.852 99.2473L110.956 99.1479C111.203 99.1218 111.453 99.1144 111.701 99.1257Z"
            fill="#E0E0E0"
          />
          <path
            d="M91.0759 149.504C91.0759 149.504 100.362 146.824 103.437 146.039C104.635 145.735 109.764 148.443 113.308 150.698C113.308 150.698 118.188 153.594 117.796 154.483C117.36 155.467 115.588 155.268 112.69 153.931C112.69 153.931 113.694 156.843 111.724 156.777C111.724 156.777 112.701 159.927 110.857 160.253C109.013 160.579 108.582 156.561 106.959 156.346C105.336 156.13 101.99 155.909 101.99 155.909C101.99 155.909 99.1914 160.551 97.0217 160.883C94.8521 161.214 89.8447 160.623 89.8447 160.623L91.0759 149.504Z"
            fill="#FFBE9D"
          />
          <path
            d="M106.926 150.145C107.835 150.55 108.704 151.041 109.521 151.61C110.387 152.1 111.205 152.674 111.961 153.323C110.183 152.431 108.496 151.367 106.926 150.145Z"
            fill="#EB996E"
          />
          <path
            d="M106.38 151.952C107.441 152.286 108.471 152.71 109.46 153.218C110.483 153.634 111.469 154.135 112.409 154.715C111.347 154.385 110.317 153.963 109.328 153.455C108.303 153.041 107.317 152.538 106.38 151.952Z"
            fill="#EB996E"
          />
          <path
            d="M107.373 156.268C108.776 156.263 110.172 156.449 111.525 156.821C110.122 156.825 108.726 156.639 107.373 156.268Z"
            fill="#EB996E"
          />
          <path
            d="M78.9914 53.5832C79.1007 53.9413 79.1023 54.3238 78.9959 54.6828C78.8895 55.0417 78.6798 55.3614 78.3931 55.6019C78.1063 55.8424 77.7552 55.993 77.3834 56.035C77.0117 56.077 76.6359 56.0085 76.3028 55.838C75.0441 55.2025 74.8839 53.4617 75.1324 52.0745C75.3808 50.6874 75.8501 49.2008 75.2704 47.9132C74.8232 46.9295 73.8571 46.305 73.1063 45.5313C72.4437 44.8589 71.9458 44.0418 71.6518 43.1443C71.3577 42.2469 71.2754 41.2934 71.4114 40.3587C71.5705 39.4268 71.9836 38.557 72.6051 37.8452C73.2266 37.1333 74.0324 36.6071 74.9336 36.3245C76.7831 35.8105 79.0632 36.3797 80.4544 35.0368C81.4537 34.0642 81.5144 32.4781 82.2873 31.3176C83.4853 29.516 86.0194 29.2287 88.1614 29.5215C90.3035 29.8144 92.4787 30.5052 94.5766 29.9802C96.3267 29.5436 97.7621 28.3223 99.4072 27.5707C101.2 26.7503 103.205 26.5113 105.14 26.8872C107.076 27.263 108.845 28.235 110.202 29.6671C111.558 31.0992 112.434 32.9199 112.706 34.8746C112.978 36.8292 112.632 38.82 111.719 40.5687C111.109 41.8484 110.072 42.8747 108.787 43.47"
            fill="#263238"
          />
          <path
            d="M89.6464 99.2365C91.0543 99.2338 92.4476 98.9513 93.7455 98.4055C95.0435 97.8597 96.2204 97.0615 97.2079 96.057C98.1954 95.0526 98.9739 93.8619 99.4983 92.5541C100.023 91.2462 100.283 89.8472 100.263 88.4381L100.125 78.2807C100.125 78.2807 108.649 77.4241 109.212 68.9688C109.775 60.5135 109.372 41 109.372 41C104.682 38.6708 99.456 37.636 94.2331 38.0022C89.0102 38.3684 83.9794 40.1224 79.6593 43.0834L78.3564 43.9842L78.9913 88.7144C79.0248 91.5196 80.1626 94.1982 82.1577 96.1684C84.1528 98.1386 86.8439 99.2411 89.6464 99.2365Z"
            fill="#FFBE9D"
          />
          <path
            transform={eyeMovement}
            id="right-eye"
            d="M107.141 56.3463C106.833 56.3108 106.549 56.1631 106.342 55.9312C106.136 55.6994 106.021 55.3996 106.021 55.089C106.021 54.7784 106.136 54.4787 106.342 54.2469C106.549 54.015 106.833 53.8673 107.141 53.8318C107.208 53.8152 107.278 53.8152 107.346 53.8318C107.654 53.8673 107.938 54.015 108.145 54.2469C108.351 54.4787 108.465 54.7784 108.465 55.089C108.465 55.3996 108.351 55.6994 108.145 55.9312C107.938 56.1631 107.654 56.3108 107.346 56.3463C107.278 56.3573 107.209 56.3573 107.141 56.3463Z"
            fill="#263238"
          />
          <path
            d="M108.797 53.6163C108.659 53.76 107.864 53.0637 106.699 53.0029C105.534 52.9421 104.657 53.4892 104.541 53.3345C104.425 53.1797 104.624 53.0084 105.01 52.7487C105.526 52.417 106.135 52.26 106.748 52.3008C107.36 52.3416 107.943 52.578 108.411 52.9753C108.759 53.2847 108.869 53.5832 108.797 53.6163Z"
            fill="#263238"
          />
          <path
            transform={eyeMovement}
            id="left-eye"
            d="M95.3323 53.9037C95.5326 54.0425 95.6873 54.2377 95.7767 54.4645C95.8661 54.6914 95.8863 54.9396 95.8347 55.178C95.7831 55.4163 95.6621 55.6339 95.4868 55.8034C95.3116 55.9728 95.0901 56.0863 94.8504 56.1297C94.6107 56.173 94.3635 56.1442 94.1401 56.0469C93.9167 55.9495 93.7272 55.7881 93.5956 55.5829C93.464 55.3777 93.3961 55.138 93.4006 54.8942C93.4051 54.6504 93.4818 54.4134 93.6209 54.2132C93.8071 53.9453 94.0918 53.7622 94.4127 53.7042C94.7335 53.6462 95.0643 53.7179 95.3323 53.9037Z"
            fill="#263238"
          />
          <path
            d="M96.0993 53.9532C95.9558 54.0969 95.1608 53.4006 94.0015 53.3398C92.8421 53.279 91.9588 53.8261 91.8373 53.6714C91.7766 53.5995 91.9257 53.3453 92.3066 53.0856C92.823 52.7539 93.4321 52.5969 94.0443 52.6377C94.6565 52.6785 95.2395 52.9149 95.7074 53.3122C96.0552 53.6216 96.1656 53.8924 96.0993 53.9532Z"
            fill="#263238"
          />
          <path
            d="M100.263 63.8236C100.263 63.7352 100.986 63.6246 102.167 63.5252C102.471 63.5252 102.719 63.4533 102.747 63.2931C102.769 62.9883 102.71 62.6832 102.576 62.4088C102.316 61.6186 102.057 60.8228 101.781 59.9883C100.677 56.5454 99.9535 53.7104 100.125 53.6551C100.296 53.5999 101.323 56.3464 102.41 59.7894C102.664 60.6294 102.913 61.4473 103.15 62.2209C103.325 62.6233 103.36 63.0729 103.249 63.4975C103.2 63.6122 103.124 63.7135 103.028 63.7933C102.932 63.873 102.819 63.9288 102.697 63.9562C102.525 63.9947 102.349 64.0077 102.173 63.9949C100.98 63.9728 100.263 63.9175 100.263 63.8236Z"
            fill="#263238"
          />
          <path
            d="M96.3979 64.9067C98.1724 65.8186 100.063 66.4824 102.018 66.8796C101.59 67.4689 100.974 67.8956 100.273 68.0903C99.5709 68.285 98.824 68.2361 98.1535 67.9517C95.6637 66.8852 96.3979 64.9067 96.3979 64.9067Z"
            fill="white"
          />
          <path
            d="M100.158 78.1591C96.2494 78.0196 92.4711 76.7192 89.3037 74.4233C89.3037 74.4233 91.5783 80.6515 100.053 80.3531L100.158 78.1591Z"
            fill="#EB996E"
          />
          <path
            d="M109.443 43.1383C109.443 43.1383 104.336 45.7357 97.5735 42.5194C93.1569 40.4083 87.9839 39.4302 86.8687 40.342C85.7535 41.2538 85.6044 45.7855 81.2762 47.1947C81.2762 47.1947 81.7454 55.8489 78.2894 55.6058C74.8334 55.3626 76.7326 41.917 76.7326 41.917L85.0855 37.6286L94.2555 35.7109L102.951 36.7665L109.697 38.4741L109.443 43.1383Z"
            fill="#263238"
          />
          <path
            d="M78.9192 52.8263C78.7812 52.7544 73.2052 50.787 73.0782 56.5842C72.9512 62.3813 78.7867 61.3147 78.7977 61.1489C78.8088 60.9831 78.9192 52.8263 78.9192 52.8263Z"
            fill="#FFBE9D"
          />
          <path
            d="M77.0757 58.9106C77.0757 58.9106 76.9708 58.9825 76.7997 59.0543C76.5572 59.1529 76.2858 59.1529 76.0433 59.0543C75.436 58.8167 74.9392 57.833 74.9392 56.7885C74.9385 56.2906 75.0458 55.7984 75.2538 55.3461C75.3133 55.1666 75.4151 55.0041 75.5506 54.8723C75.6861 54.7405 75.8513 54.6434 76.0323 54.589C76.1491 54.5678 76.2697 54.5848 76.3761 54.6375C76.4825 54.6903 76.5691 54.776 76.623 54.8819C76.6948 55.0422 76.6561 55.1582 76.6837 55.1693C76.7113 55.1803 76.8107 55.0754 76.7721 54.8377C76.7417 54.6931 76.664 54.5628 76.5512 54.4675C76.3949 54.3445 76.197 54.287 75.9991 54.3072C75.7601 54.3485 75.5356 54.4504 75.3472 54.6033C75.1587 54.7561 75.0125 54.9548 74.9226 55.1804C74.6676 55.6779 74.5351 56.2293 74.5361 56.7885C74.5361 57.9546 75.1213 59.0654 75.9495 59.3251C76.1043 59.3724 76.2677 59.3851 76.428 59.3621C76.5882 59.3392 76.7415 59.2813 76.877 59.1925C77.0757 59.0543 77.0757 58.9217 77.0757 58.9106Z"
            fill="#EB996E"
          />
          <path
            d="M75.9707 34.6831C76.6313 34.4892 77.3369 34.5146 77.9818 34.7557C78.6267 34.9968 79.1763 35.4406 79.5481 36.0205C79.0334 35.6303 78.4781 35.2968 77.8919 35.0258C77.2651 34.845 76.6213 34.7302 75.9707 34.6831Z"
            fill="#263238"
          />
          <path
            d="M79.4551 31.2124C79.56 31.2124 79.3999 32.4337 79.5987 33.8816C79.7705 34.7521 80.0428 35.5997 80.4102 36.4072C80.4102 36.4072 80.1673 36.2027 79.9244 35.7716C79.6127 35.198 79.4069 34.5727 79.3171 33.9258C79.1563 33.0226 79.2035 32.0946 79.4551 31.2124Z"
            fill="#263238"
          />
          <path
            d="M103.244 50.8204C103.211 50.754 103.459 50.5496 103.929 50.3617C105.174 49.8794 106.557 49.8933 107.793 50.4004C108.257 50.5993 108.505 50.8038 108.472 50.8701C108.401 51.0248 107.285 50.5883 105.855 50.5883C104.426 50.5883 103.31 50.9917 103.244 50.8204Z"
            fill="#263238"
          />
          <path
            d="M90.3252 49.6154C90.2921 49.5491 90.5405 49.3502 91.0098 49.1568C91.6275 48.9177 92.2853 48.7995 92.9476 48.8086C93.6126 48.8084 94.2712 48.9399 94.8853 49.1954C95.3491 49.3944 95.592 49.5989 95.5644 49.6707C95.5368 49.7425 94.3774 49.3888 92.942 49.3833C91.5066 49.3778 90.4025 49.7702 90.3252 49.6154Z"
            fill="#263238"
          />
          <path
            d="M102.244 51.7482C102.256 51.8621 102.256 51.977 102.244 52.0909C102.244 52.334 102.244 52.6435 102.272 53.0248C102.299 53.4061 102.272 53.9201 102.305 54.4727C102.284 55.1105 102.377 55.7469 102.581 56.3517C102.847 57.1362 103.308 57.8403 103.92 58.398C104.532 58.9557 105.276 59.3487 106.081 59.5404C106.543 59.6534 107.02 59.6944 107.494 59.662C107.988 59.6397 108.475 59.5371 108.935 59.358C109.917 58.9896 110.754 58.313 111.32 57.4293C111.856 56.5854 112.126 55.5993 112.093 54.5998C112.093 53.6106 112.093 52.6601 112.093 51.7482L112.226 51.8809L105.049 51.8145L102.967 51.7759H102.415H102.211C102.447 51.7496 102.685 51.7385 102.923 51.7427L104.971 51.704L112.226 51.6377H112.408V51.7759C112.408 52.6877 112.408 53.6382 112.408 54.6275C112.445 55.6814 112.162 56.7219 111.596 57.6117C110.996 58.5473 110.109 59.2629 109.068 59.6509C108.579 59.839 108.062 59.9454 107.538 59.9659C107.039 59.9956 106.539 59.949 106.053 59.8277C105.209 59.6234 104.431 59.205 103.795 58.6126C103.159 58.0203 102.685 57.274 102.421 56.4456C102.222 55.8135 102.14 55.1507 102.178 54.4893C102.178 53.9367 102.178 53.4227 102.205 53.0027C102.233 52.5827 102.205 52.323 102.233 52.0798C102.228 51.9692 102.232 51.8583 102.244 51.7482Z"
            fill="#263238"
          />
          <path
            d="M88.7126 51.4613C88.7126 51.4613 88.7126 51.5884 88.7126 51.8039V52.7379C88.7126 53.1468 88.7126 53.6331 88.7402 54.1913C88.7257 54.8287 88.8191 55.464 89.0163 56.0703C89.2706 56.7979 89.688 57.4577 90.2364 57.9989C90.8696 58.615 91.6574 59.0485 92.5164 59.2534C93.4663 59.4825 94.4631 59.419 95.3762 59.0711C96.3555 58.7031 97.19 58.0286 97.7557 57.1479C98.2935 56.3046 98.5648 55.3185 98.5341 54.3184C98.5341 53.3292 98.5341 52.3731 98.5341 51.4613L98.6666 51.5939L91.4896 51.5276L89.4138 51.4945H88.8617H88.6574C88.892 51.4683 89.1281 51.4572 89.3641 51.4613L91.4123 51.4281L98.6666 51.3618H98.7991V51.4945C98.7991 52.4063 98.7991 53.3624 98.7991 54.3516C98.8324 55.4059 98.5477 56.4458 97.982 57.3358C97.382 58.2682 96.497 58.9815 95.459 59.3695C94.9698 59.5571 94.4532 59.6635 93.9298 59.6845C93.4288 59.714 92.9262 59.6675 92.4392 59.5463C91.537 59.3239 90.7125 58.8597 90.0542 58.2034C89.4847 57.6307 89.0574 56.9322 88.8065 56.1642C88.6082 55.5321 88.5259 54.8693 88.5636 54.2079C88.5636 53.6553 88.5636 53.1413 88.5967 52.7213C88.6298 52.3013 88.5967 52.0416 88.5967 51.7984C88.6169 51.6805 88.656 51.5666 88.7126 51.4613Z"
            fill="#263238"
          />
          <path
            d="M102.366 52.1516C101.103 52.3434 99.8192 52.3434 98.5566 52.1516C99.8186 51.9527 101.104 51.9527 102.366 52.1516Z"
            fill="#263238"
          />
          <path
            d="M78.1797 52.2235C79.9349 52.077 81.6967 52.0235 83.4575 52.0633C85.2197 52.0133 86.9832 52.0557 88.7409 52.1904C86.9861 52.3437 85.224 52.3972 83.4631 52.3506C81.701 52.407 79.9371 52.3646 78.1797 52.2235Z"
            fill="#263238"
          />
          <path opacity="0.45" d="M62.418 162.955L89.5139 162.624" stroke="#212121" />
          <path
            d="M184.229 59.1114L167.793 53.1982L151.518 59.0285C149.442 60.0122 147.621 60.598 147.621 62.5378V72.1812C147.589 78.9553 149.445 85.604 152.981 91.3797C156.5 97.2295 161.725 101.859 167.954 104.643C174.606 102.477 179.575 96.7127 182.611 91.7832C186.144 86.0061 188.001 79.3583 187.972 72.5847V63.9083C187.972 61.1064 186.509 59.758 184.229 59.1114Z"
            fill="#FAB500"
          />
          <g opacity="0.3">
            <path
              opacity="0.3"
              d="M167.955 99.0075C163.023 96.7785 158.889 93.0936 156.107 88.4467C153.312 83.8773 151.843 78.6185 151.867 73.2603V66.4905C151.867 64.9597 152.789 63.5229 154.628 62.6663L167.8 57.7368V53.1831L151.525 59.0134C149.819 59.7595 147.627 60.5829 147.627 62.5226V72.1661C147.595 78.9401 149.452 85.5888 152.988 91.3646C156.506 97.2143 161.732 101.843 167.96 104.628L167.955 99.0075Z"
              fill="black"
            />
          </g>
          <g opacity="0.4">
            <path
              opacity="0.4"
              d="M167.954 104.603C169.069 104.236 170.15 103.772 171.183 103.215C173.718 101.776 176.022 99.9632 178.018 97.8384C179.828 95.8859 181.42 93.7419 182.766 91.4444C186.35 85.6277 188.16 78.8897 187.972 72.058V64.0835C187.972 60.1211 185.935 59.8282 184.223 59.0821L167.788 53.1689V57.7227L181.115 62.7295C182.948 63.5861 183.876 65.0229 183.876 66.5593V73.3235C183.899 78.6834 182.431 83.944 179.636 88.5154C176.917 93.1399 172.834 96.8079 167.948 99.0155L167.954 104.603Z"
              fill="black"
            />
          </g>
          <path
            opacity="0.5"
            d="M172.155 72.8204V71.5162C172.115 70.4105 171.648 69.3634 170.853 68.5953C170.057 67.8272 168.995 67.3979 167.89 67.3979C166.785 67.3979 165.722 67.8272 164.927 68.5953C164.132 69.3634 163.665 70.4105 163.625 71.5162V72.8425C163.059 72.9272 162.542 73.2125 162.168 73.6466C161.794 74.0807 161.588 74.6347 161.588 75.2078V82.8728C161.588 83.5074 161.84 84.1161 162.288 84.5648C162.736 85.0136 163.344 85.2657 163.978 85.2657H171.939C172.573 85.2657 173.181 85.0136 173.63 84.5648C174.078 84.1161 174.33 83.5074 174.33 82.8728V75.2023C174.33 74.6052 174.106 74.0298 173.704 73.589C173.301 73.1483 172.749 72.8742 172.155 72.8204ZM169.046 80.9662H166.96L167.285 79.4631C167.098 79.347 166.944 79.1843 166.839 78.9908C166.733 78.7972 166.68 78.5796 166.684 78.3592C166.688 78.1388 166.749 77.9232 166.862 77.7337C166.974 77.5441 167.134 77.387 167.325 77.2779C167.516 77.1688 167.733 77.1113 167.953 77.1112C168.173 77.1111 168.39 77.1683 168.581 77.2772C168.772 77.3861 168.932 77.543 169.045 77.7325C169.157 77.9219 169.219 78.1374 169.223 78.3578C169.225 78.5657 169.176 78.7708 169.08 78.955C168.983 79.1392 168.843 79.2966 168.671 79.4133L169.046 80.9662ZM171.255 72.8093H164.542V71.5162C164.577 70.6508 164.945 69.8325 165.569 69.2327C166.193 68.6328 167.025 68.2978 167.89 68.2978C168.755 68.2978 169.587 68.6328 170.211 69.2327C170.835 69.8325 171.203 70.6508 171.238 71.5162L171.255 72.8093Z"
            fill="white"
          />
          <path
            d="M154.527 72.7266C154.39 72.2929 154.309 71.8435 154.285 71.3893C154.162 70.3033 154.117 69.21 154.152 68.1177C154.152 67.4766 154.213 66.8632 154.268 66.3161C154.295 65.8102 154.42 65.3145 154.638 64.8571C154.795 64.5463 155.046 64.293 155.356 64.1332C155.576 64.0282 155.72 64.0337 155.72 64.0503C155.322 64.2347 155.001 64.5536 154.815 64.9511C154.642 65.3937 154.546 65.863 154.533 66.3382C154.489 66.8908 154.456 67.4877 154.439 68.1232C154.401 69.3942 154.439 70.5437 154.483 71.3727C154.537 71.8219 154.552 72.2749 154.527 72.7266Z"
            fill="white"
          />
          <path
            d="M154.77 76.9268C154.693 76.9268 154.621 76.5344 154.61 76.0426C154.599 75.5507 154.654 75.1528 154.737 75.1528C154.82 75.1528 154.886 75.5452 154.897 76.0371C154.908 76.5289 154.853 76.9268 154.77 76.9268Z"
            fill="white"
          />
          <path
            d="M137.428 116.711L119.105 162.077H86.0576V164.121H184.482L204.616 116.711H137.428Z"
            fill="#263238"
          />
          <path
            d="M165.44 143.492C164.778 145.774 162.846 147.217 161.123 146.719C159.401 146.222 158.545 143.956 159.208 141.674C159.87 139.391 161.808 137.949 163.53 138.446C165.253 138.944 166.108 141.209 165.44 143.492Z"
            fill="#FAFAFA"
          />
        </g>
        <defs>
          <clipPath id="clip0">
            <rect width="239" height="165" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  )
}
Illustration.propTypes = {
  angle: PropTypes.number,
}

Illustration.defaultProps = {
  angle: 0,
}
export default Illustration
