import "./BookingRequestModal.scss"

import classNames from "classnames"
import PropTypes from "prop-types"
import React, { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import API from "../../../constants/API"
import { PAGE_LIMIT } from "../../../constants/offset"
import {
  getCompanyDrivers,
  getCompanyVehicles,
  getDriver,
  requestTrip,
} from "../../../redux/actions"
import apiUtils from "../../../utils/api/api-utils"
import { getUserAvatar } from "../../../utils/common"
import AvatarName from "../../atoms/AvatarName"
import Button from "../../atoms/Button"
import Checkbox from "../../atoms/Checkbox"
import SvgIcon from "../../atoms/SvgIcon"
import UserAvatarName from "../../atoms/UserAvatarName"
import TableDropDown from "../../organisms/TableDropDown"

const BookingRequestModal = ({ row, onCancel }) => {
  const bookingRequestModalClass = classNames("booking-request-modal-container")
  const dispatch = useDispatch()
  const [vehicleForOperator, setVehicleForOperator] = useState({})
  const [driverForOperator, setDriverForOperator] = useState({})
  const [reducedRate, setReducedRate] = useState(false)
  const [driversOffset, setDriversOffset] = useState(0)
  const [vehiclesOffset, setVehiclesOffset] = useState(0)
  const [searchValueDriver, setSearchValueDriver] = useState("")
  const [searchValueVehicle, setSearchValueVehicle] = useState("")
  const [vehicleDropdownOpen, setVehicleDropdownOpen] = useState(false)

  const [
    drivers,
    vehicles,
    userData,
    callsInProgress,
  ] = useSelector(({ driver, vehicle, user, api }) => [driver, vehicle, user, api.callsInProgress])
  useEffect(() => {
    dispatch(getCompanyDrivers({ company: userData.company_details.id }))
    dispatch(getCompanyVehicles({ company: userData.company_details.id }))
  }, [])

  useEffect(() => {
    if (!userData.company_details) {
      dispatch(getDriver({ company__is_landline: true, full_name: searchValueDriver }))
    } else {
      dispatch(
        getCompanyDrivers({ full_name: searchValueDriver, company: userData.company_details?.id }),
      )
    }
  }, [searchValueDriver])

  useEffect(() => {
    dispatch(
      getCompanyVehicles({
        company: userData.company_details.id,
        vehicle_id__icontains: searchValueVehicle,
      }),
    )
  }, [searchValueVehicle])

  const driversToShow = drivers[userData.company_details?.id]?.results
  const vehiclesToShow = vehicles[userData.company_details?.id]?.results

  const onRequest = () => {
    dispatch(requestTrip(row.id, driverForOperator.id, vehicleForOperator.id, reducedRate))
    onCancel()
  }

  const vehicleBusy = useMemo(() => {
    return apiUtils.areCallsInProgress([API.GET_COMPANY_VEHICLES], callsInProgress)
  }, [callsInProgress])

  const driverBusy = useMemo(() => {
    return apiUtils.areCallsInProgress([API.GET_COMPANY_DRIVERS], callsInProgress)
  }, [callsInProgress])

  const loadMoreVehicles = () => {
    if (!vehicleBusy) {
      const currentOffset = vehiclesOffset + PAGE_LIMIT
      dispatch(getCompanyVehicles({ company: userData.company_details.id, offset: currentOffset }))
      setVehiclesOffset(currentOffset)
    }
  }

  const loadMoreDrivers = () => {
    if (!driverBusy) {
      const currentOffset = driversOffset + PAGE_LIMIT
      dispatch(getCompanyDrivers({ company: userData.company_details.id, offset: currentOffset }))
      setDriversOffset(currentOffset)
    }
  }

  const isDropdownOpen = open => {
    setVehicleDropdownOpen(open)
  }

  return (
    <div className={bookingRequestModalClass} style={{ height: vehicleDropdownOpen ? 520 : 400 }}>
      <div>
        <p className="request-to-operate-text">Request to operate this booking</p>
        <p className="request-to-operate-input-label">Driver</p>

        <TableDropDown
          style={{ width: 344, height: 40 }}
          rowType={!row.company_details ? "landlineDriver" : "driver"}
          status={row.status}
          value={
            driverForOperator.id ? (
              <UserAvatarName
                fname={`${driverForOperator?.first_name} ${driverForOperator?.last_name}`}
                avatarURL={driverForOperator.avatar}
                avatarType={getUserAvatar(driverForOperator.avatar)}
                avatarWidth="24px"
                avatarHeight="24px"
                initialsFontSize="14px"
                nameFontSize="14px"
              />
            ) : (
              <AvatarName
                fname="Add"
                lname="Driver"
                avatarURL={undefined}
                avatarType="driverWithoutImage"
              />
            )
          }
          onToggle={() => {
            if (!userData.company_details) {
              dispatch(getDriver({ company__is_landline: true }))
            } else {
              dispatch(getCompanyDrivers({ company: userData.company_details?.id }))
            }
            setDriversOffset(0)
          }}
          content={driversToShow}
          fieldKey="id"
          onRowClick={driverID => {
            setDriverForOperator(driversToShow.find(driver => driver.id === driverID))
            setSearchValueDriver("")
          }}
          rowRender={rowData => {
            return (
              <UserAvatarName
                fname={rowData.first_name}
                lname={rowData.last_name}
                avatarURL={rowData.avatar}
                avatarType={getUserAvatar(rowData.avatar)}
                avatarWidth="24px"
                avatarHeight="24px"
                initialsFontSize="14px"
                nameFontSize="14px"
              />
            )
          }}
          showCustomFirstRow={driverForOperator?.id}
          firstRowRender={() => {
            return (
              <UserAvatarName
                fname="Unassigned"
                avatarURL={undefined}
                avatarType="unassignedAvatar"
                avatarWidth="24px"
                avatarHeight="24px"
                initialsFontSize="14px"
                nameFontSize="14px"
              />
            )
          }}
          onFirstRowClick={() => {
            setDriverForOperator({})
            setSearchValueDriver("")
          }}
          loadMore={loadMoreDrivers}
          hasMore={!!drivers[userData.company_details.id]?.next}
          withSearch
          offset={driversOffset}
          setOffset={setDriversOffset}
          searchCallback={value => {
            setSearchValueDriver(value)
          }}
          searchPlaceholder="Search driver"
        />

        <p className="request-to-operate-input-label vehicle-label">Vehicle</p>

        <TableDropDown
          style={{ width: 344, height: 40 }}
          isDropdownOpen={isDropdownOpen}
          rowType={!row.company_details ? "landlineDriver" : "driver"}
          status={row.status}
          value={
            vehicleForOperator.id ? (
              <UserAvatarName
                fname={vehicleForOperator?.vehicle_id}
                avatarURL={vehicleForOperator.vehicle_image}
                avatarType={getUserAvatar(vehicleForOperator.vehicle_image)}
                avatarWidth="24px"
                avatarHeight="24px"
                initialsFontSize="14px"
                nameFontSize="14px"
              />
            ) : (
              <div className="noVehicle">
                <SvgIcon margin="0 8 0 0" icon="unassigned-car-light-gray" width="24" height="24" />
                <p>Add Vehicle</p>
              </div>
            )
          }
          onToggle={() => {
            dispatch(getCompanyVehicles({ company: userData.company_details?.id }))
            setVehiclesOffset(0)
            setVehicleDropdownOpen(true)
          }}
          content={vehiclesToShow}
          fieldKey="id"
          onRowClick={vehicleID => {
            setVehicleForOperator(vehiclesToShow.find(vehicle => vehicle.id === vehicleID))
            setSearchValueVehicle("")
            setVehicleDropdownOpen(false)
          }}
          rowRender={rowData => {
            return (
              <UserAvatarName
                fname={rowData.vehicle_id}
                avatarURL={rowData.vehicle_image}
                avatarType={getUserAvatar(rowData.vehicle_image)}
                avatarWidth="24px"
                avatarHeight="24px"
                initialsFontSize="14px"
                nameFontSize="14px"
              />
            )
          }}
          showCustomFirstRow={vehicleForOperator.id}
          firstRowRender={() => {
            return (
              <UserAvatarName
                fname="Unassigned"
                avatarURL={undefined}
                avatarType="unassignedAvatar"
                avatarWidth="24px"
                avatarHeight="24px"
                initialsFontSize="14px"
                nameFontSize="14px"
              />
            )
          }}
          onFirstRowClick={() => {
            setVehicleForOperator({})
            setSearchValueVehicle("")
            setVehicleDropdownOpen(false)
          }}
          loadMore={loadMoreVehicles}
          hasMore={!!vehicles[userData.company_details.id]?.next}
          withSearch
          searchCallback={value => {
            setSearchValueVehicle(value)
            setVehiclesOffset(0)
          }}
          searchPlaceholder="Search vehicle"
        />

        <div
          className="reduce-rate-checkbox"
          role="button"
          onClick={() => {
            setReducedRate(!reducedRate)
          }}
        >
          <div className="reduce-rate-checkbox-button">
            <Checkbox checked={reducedRate} />
          </div>
          <p>Reduce rate</p>
        </div>
      </div>
      <div className="buttons-container">
        <Button
          buttonType="grayColor"
          margin="0px 12px 0px 0px"
          buttonSize="smallSize"
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button buttonType="landlineColor" buttonSize="smallSize" onClick={onRequest}>
          Request
        </Button>
      </div>
    </div>
  )
}

BookingRequestModal.propTypes = {
  row: PropTypes.instanceOf(Object),
  onCancel: PropTypes.func,
}

BookingRequestModal.defaultProps = {
  row: {},
  onCancel: () => {},
}

export default BookingRequestModal
