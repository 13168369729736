import "./DriverShiftTable.scss"

import moment from "moment"
import PropTypes from "prop-types"
import React from "react"
import { useSelector } from "react-redux"

import { DEFAULT_TIME, TWELVE_HOUR_WITHOUT_LEADING_ZERO } from "../../../constants/dateFormats"
import { SHIFT } from "../../../constants/shiftChoices"
import { COMPANY_DRIVER } from "../../../constants/userRoles"
import InfoPopup from "../../atoms/InfoPopup"
import SvgIcon from "../../atoms/SvgIcon"
import DriverShiftModal from "../DriverShiftModal"

function DriverShiftTable({ driverDetails }) {
  const shifts = driverDetails.driver_profile?.shifts
  const [userRole] = useSelector(({ user }) => [user.jwtData?.role])

  return (
    <div className="driverShiftTable">
      <div className="driverShiftTable__content">
        <div className="daysColumn">
          <p className="day">Monday</p>
          <p className="day">Tuesday</p>
          <p className="day">Wednesday</p>
          <p className="day">Thursday</p>
          <p className="day">Friday</p>
          <p className="day">Saturday</p>
          <p className="day">Sunday</p>
        </div>
        <div className="driverTable">
          <div className="driverTable__content">
            <div className="tableHead">
              <div className="tableData">
                <p>Start time</p>
              </div>
              <div className="tableData">
                <p>End time</p>
              </div>
              <div className="tableData">
                <p>Preferable shift</p>
              </div>
            </div>
            <div className="tableRow__wrapper">
              {shifts?.map(day => {
                return (
                  <div key={day.id} className="tableRow">
                    <div className="tableData">
                      {day.off_duty === true ? (
                        <p>----</p>
                      ) : (
                        <p>
                          {moment(day.start_time, [DEFAULT_TIME]).format(
                            TWELVE_HOUR_WITHOUT_LEADING_ZERO,
                          )}
                        </p>
                      )}
                    </div>
                    <div className="tableData">
                      {day.off_duty === true ? (
                        <p>----</p>
                      ) : (
                        <p>
                          {moment(day.end_time, [DEFAULT_TIME]).format(
                            TWELVE_HOUR_WITHOUT_LEADING_ZERO,
                          )}
                        </p>
                      )}
                    </div>
                    <div className="tableData">
                      <div className="dropDownBox">
                        {day.off_duty === true ? <p>----</p> : <p>{SHIFT[day.preferable_shift]}</p>}
                      </div>
                    </div>
                    {userRole !== COMPANY_DRIVER && (
                      <div className="editModal">
                        <DriverShiftModal day={day} driverId={driverDetails.id} />
                      </div>
                    )}

                    <div className="offDutyIcon">
                      <InfoPopup
                        popupText="Off duty"
                        position={{ right: "21px", top: "-5px" }}
                        textSpacing={{ width: 69, height: 24 }}
                      >
                        {day.off_duty && (
                          <SvgIcon
                            icon="offDuty-clock"
                            width="20"
                            height="19"
                            viewBox="0 0 20 19"
                          />
                        )}
                      </InfoPopup>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
DriverShiftTable.propTypes = {
  driverDetails: PropTypes.instanceOf(Object),
}

DriverShiftTable.defaultProps = {
  driverDetails: {},
}
export default DriverShiftTable
