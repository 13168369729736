import "./AdditionalEventInfo.scss"

import classNames from "classnames"
import moment from "moment"
import PropTypes from "prop-types"
import React, { useRef, useState } from "react"
import { useDispatch } from "react-redux"

import { TWELVE_HOUR_WITH_LEADING_ZERO } from "../../../constants/dateFormats"
import { DRIVER_STATUS } from "../../../constants/driverStatuses"
import { BLACK_CAR_TYPE, BUS_TYPE, EXPRESS_TYPE } from "../../../constants/transportationTypes"
import { setActiveNotification } from "../../../redux/notifications/notificationsActions"
import { getFirstLetter, getLocalDateTime } from "../../../utils/common"
import { redirectToPlanning } from "../../../utils/commonFunctionality"
import Button from "../Button"
import LegConnection from "../LegConnection"
import SvgIcon from "../SvgIcon"

const AdditionalEventInfo = ({
  showOnTop,
  event,
  isRide,
  onCancelClick,
  history,
  cancelDisabled,
}) => {
  const dispatch = useDispatch()
  const [descriptionPopupVisible, setDescriptionPopupVisible] = useState(false)
  const [showPopupOnRight, setShowPopupOnRight] = useState(false)
  const descriptionRef = useRef()

  const getIconName = () => {
    let iconName

    switch (event.related_object.service_type) {
      case EXPRESS_TYPE:
        iconName = "leg-connection-express"
        break
      case BUS_TYPE:
        iconName = "leg-connection-bus"
        break
      case BLACK_CAR_TYPE:
        iconName = "leg-connection-black-car"
        break
      default:
        iconName = "leg-connection-express"
        break
    }

    return iconName
  }

  const containerClass = classNames("additional-trip-info-container", {
    "show-on-top": showOnTop,
  })

  const descriptionPopupClass = classNames("description-popup", {
    "show-on-right": showPopupOnRight,
  })

  const multiLineOverflows = () => {
    const el = descriptionRef.current
    return el.scrollHeight > el.clientHeight
  }

  return (
    <div className={containerClass} role="button" onClick={e => e.stopPropagation()}>
      {isRide ? (
        <>
          <div
            className="title-wraper"
            role="button"
            onClick={() => {
              dispatch(
                setActiveNotification({
                  relatedObjectId: event.related_object.id,
                  showEditModal: true,
                }),
              )
              redirectToPlanning(history, {
                departure_time__gte: event.related_object.departure_time,
              })
            }}
          >
            {event.related_object.pnr}
          </div>
          <LegConnection
            iconName={getIconName()}
            arrivalData={event.related_object.arrive}
            departureData={event.related_object.depart}
          />
          <div className="date-time-container">
            <div className="left-container">
              <div className="date-time-label">Clock-in time</div>
              <div className="date-time-value">
                {moment(event.start_date_time).format(TWELVE_HOUR_WITH_LEADING_ZERO)}
              </div>
            </div>
            <div className="right-container">
              <div className="date-time-label">Clock-out time</div>
              <div className="date-time-value">
                {moment(event.end_date_time).format(TWELVE_HOUR_WITH_LEADING_ZERO)}
                {!moment(event.start_date_time).isSame(moment(event.end_date_time), "day") && (
                  <div className="additional-day-text">+1 day</div>
                )}
              </div>
            </div>
          </div>
          <div className="date-time-container">
            <div className="left-container">
              <div className="date-time-label">Departure time</div>
              <div className="date-time-value">
                {moment(event.related_object.departure_time).format(TWELVE_HOUR_WITH_LEADING_ZERO)}
              </div>
            </div>
            <div className="right-container">
              <div className="date-time-label">Arrival time</div>
              <div className="date-time-value">
                {moment(event.related_object.arrival_time).format(TWELVE_HOUR_WITH_LEADING_ZERO)}
                {!moment(event.related_object.departure_time).isSame(
                  moment(event.related_object.arrival_time),
                  "day",
                ) && <div className="additional-day-text">+1 day</div>}
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="non-drive-details">
          <SvgIcon icon={DRIVER_STATUS[event.event_type]} width="32" height="32" />
          <div className="title-wraper event-type">{DRIVER_STATUS[event.event_type]}</div>
          <div className="non-drive-details-label">Created by:</div>
          <div className="flex-row">
            {event.creator_details.avatar ? (
              <img height="24" width="24" src={event.creator_details.avatar} alt="avatar" />
            ) : (
              <div className="letter-avatar">{`${getFirstLetter(
                event.creator_details.first_name,
              )}${getFirstLetter(event.creator_details.last_name)}`}</div>
            )}
            <div className="creator-name">{`${event.creator_details.first_name} ${event.creator_details.last_name}`}</div>
          </div>
          <div className="flex-row">
            <SvgIcon icon="clock" width={14} height={14} fill="#B2B2B2" />
            <div className="creation-date-time">{getLocalDateTime(event.created, "hh:mm A")}</div>

            <SvgIcon icon="calendar" width={14} height={14} fill="#B2B2B2" />
            <div className="creation-date-time">{getLocalDateTime(event.created, "MMM DD")}</div>
          </div>
          {event.description && (
            <>
              <div className="non-drive-details-label">Description:</div>
              <div className="description-wrapper">
                <div
                  className="description-text"
                  onMouseEnter={() => {
                    if (multiLineOverflows()) {
                      setDescriptionPopupVisible(true)
                      setShowPopupOnRight(
                        window.innerWidth - descriptionRef.current.getBoundingClientRect().right >
                          window.innerWidth / 2,
                      )
                    }
                  }}
                  onMouseLeave={() => {
                    if (multiLineOverflows()) {
                      setDescriptionPopupVisible(false)
                    }
                  }}
                  ref={descriptionRef}
                >
                  {event.description}
                </div>
                {descriptionPopupVisible && (
                  <div className={descriptionPopupClass}>{event.description}</div>
                )}
              </div>
            </>
          )}
        </div>
      )}

      {!cancelDisabled && (
        <div className="cancel-button-wrapper">
          <Button
            onClick={() => onCancelClick(event)}
            buttonType="grayColor"
            buttonSize="smallSize confirmation"
            type="button"
          >
            Cancel
          </Button>
        </div>
      )}
    </div>
  )
}

AdditionalEventInfo.propTypes = {
  event: PropTypes.instanceOf(Object),
  showOnTop: PropTypes.bool,
  isRide: PropTypes.bool,
  onCancelClick: PropTypes.func,
  history: PropTypes.instanceOf(Object),
  cancelDisabled: PropTypes.bool,
}

AdditionalEventInfo.defaultProps = {
  event: {},
  isRide: false,
  showOnTop: false,
  onCancelClick: () => {},
  history: {},
  cancelDisabled: false,
}

export default AdditionalEventInfo
