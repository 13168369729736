import "./TableBody.scss"

import classNames from "classnames"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import InfiniteScroll from "react-infinite-scroller"

import { CANCELLED_STATUS } from "../../../constants/status"
import { EXPRESS_HOME_TYPE } from "../../../constants/transportationTypes"
import { isDoor2Door } from "../../../utils/common"
import Loading from "../../atoms/Loading"
import TableRow from "../../atoms/TableRow"

const TableBody = ({
  columns,
  data,
  onRowClick,
  loadMore,
  hasMore,
  useWindow,
  scrollRef,
  initialLoading,
  getCancelTripComponent,
  tableBodyType,
}) => {
  const tableBodyClass = classNames("table-body-container")
  // const getColumnByKey = column => {}
  const [newHeight, setNewHeight] = useState(0)

  const useResponsiveHeight =
    tableBodyType === "tripRequests" || tableBodyType === "tripSuggestions"

  const changeHeight = () => {
    // subtract bottom padding
    const calculatedHeight =
      window.innerHeight - scrollRef?.current?.getBoundingClientRect().top - 42
    setNewHeight(calculatedHeight)
  }

  useEffect(() => {
    if (useResponsiveHeight) {
      changeHeight()
    }
  }, [scrollRef?.current?.getBoundingClientRect()])

  useEffect(() => {
    if (useResponsiveHeight) {
      window.addEventListener("resize", changeHeight, false)
    }

    return () => window.removeEventListener("resize", changeHeight)
  }, [])

  return (
    // Dashboard page tables
    <div className={tableBodyClass} ref={scrollRef}>
      {!useWindow ? (
        <InfiniteScroll
          loadMore={loadMore}
          hasMore={hasMore}
          pageStart={0}
          useWindow={useWindow}
          threshold={100}
          loader={<Loading className="loading-planning" />}
        >
          <>
            {data.map(column => {
              return (
                <TableRow
                  id={column.id}
                  status={column.status}
                  onClick={() => onRowClick(column.id)}
                  companyOperator={column.operator}
                  tableBodyType={tableBodyType}
                  isExpressHome={
                    column.service_type === EXPRESS_HOME_TYPE && column.status !== CANCELLED_STATUS
                  }
                  isSelectDoor2Door={isDoor2Door(column) && column.status !== CANCELLED_STATUS}
                >
                  {columns.map(cell => {
                    return cell.component(column)
                  })}
                  {getCancelTripComponent(column)}
                </TableRow>
              )
            })}
          </>
        </InfiniteScroll>
      ) : (
        // planning page
        <div
          className={tableBodyClass}
          ref={scrollRef}
          style={{
            minHeight: useResponsiveHeight && window.innerHeight > 1500 && newHeight,
          }}
        >
          <InfiniteScroll
            loadMore={loadMore}
            hasMore={hasMore}
            pageStart={0}
            useWindow={useWindow}
            threshold={100}
            loader={!initialLoading && <Loading className="loading-scroll" />}
          >
            {initialLoading ? (
              <Loading className="loading-operators" />
            ) : (
              <>
                {data.map(column => {
                  return (
                    <TableRow
                      id={column.id}
                      status={column.status}
                      companyOperator={column.operator}
                      onClick={() => onRowClick(column.id)}
                      tableBodyType={tableBodyType}
                      isExpressHome={
                        column.service_type === EXPRESS_HOME_TYPE &&
                        column.status !== CANCELLED_STATUS
                      }
                      isSelectDoor2Door={isDoor2Door(column) && column.status !== CANCELLED_STATUS}
                    >
                      {columns.map(cell => {
                        return cell.component(column)
                      })}

                      {getCancelTripComponent(column)}
                    </TableRow>
                  )
                })}
              </>
            )}
          </InfiniteScroll>
        </div>
      )}
    </div>
  )
}

TableBody.propTypes = {
  columns: PropTypes.instanceOf(Array),
  data: PropTypes.instanceOf(Array),
  onRowClick: PropTypes.func,
  loadMore: PropTypes.func,
  hasMore: PropTypes.bool,
  useWindow: PropTypes.bool,
  scrollRef: PropTypes.instanceOf(Element),
  initialLoading: PropTypes.bool,
  getCancelTripComponent: PropTypes.func,
  tableBodyType: PropTypes.string,
}

TableBody.defaultProps = {
  columns: [],
  data: [],
  onRowClick: () => {},
  loadMore: () => {},
  hasMore: false,
  useWindow: false,
  scrollRef: null,
  initialLoading: false,
  getCancelTripComponent: () => {},
  tableBodyType: "",
}

export default TableBody
