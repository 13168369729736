import "./ActiveFilterList.scss"

import moment from "moment"
import PropTypes from "prop-types"
import React from "react"
import { useSelector } from "react-redux"

import {
  MONTH_DAY_YEAR,
  TWELVE_HOUR_WITH_LEADING_ZERO,
  TWENTY_FOUR_HOUR_WITH_LEADING_ZERO,
} from "../../../constants/dateFormats"
import { DRIVER_STATUS } from "../../../constants/driverStatuses"
import { TRIP_FILTERS } from "../../../constants/filterTypes"
import { PLANNING_STATUS } from "../../../constants/status"
import { TRANSPORTATION_TYPE } from "../../../constants/transportationTypes"
import ActiveFilter from "../ActiveFilter"

const ActiveFilterList = ({
  isFilterChecked,
  setIsFilterChecked,
  filterType,
  tripFilters,
  changeToCurrentDay,
}) => {
  const [filtersData] = useSelector(({ filter }) => [filter])

  const changeCheckedState = params => {
    setIsFilterChecked(prevState => ({ ...prevState, ...params }))
  }

  const getFiltersArr = () => {
    if (filterType === TRIP_FILTERS) {
      const parsedFilters = { ...tripFilters }
      delete parsedFilters.exclude_service_type
      delete parsedFilters.ordering
      delete parsedFilters.departure_time__gte
      return Object.entries(parsedFilters).map(([key, value]) => {
        let newKey = key
        let newValue = value

        switch (key) {
          case "pnr":
            newKey = "PNR"
            newValue = value?.toUpperCase()
            break
          case "driver":
            newKey = "Driver"
            newValue = filtersData.tripFiltersValues.driver
            break
          case "depart":
            newKey = "Departure airport"
            break
          case "arrive":
            newKey = "Arrival airport"
            break
          case "company":
            newKey = "Operator"
            newValue = filtersData.tripFiltersValues.company
            break
          case "status":
            newKey = "Status"
            newValue = PLANNING_STATUS[value]
            break
          case "created__gte":
            newKey = "Date of booking"
            newValue = moment(value).format(MONTH_DAY_YEAR)
            break
          case "vehicle__id__contains":
            newKey = "Vehicle #"
            break
          case "departure_time__time":
            newKey = "Departure time"
            newValue =
              value &&
              moment(value, [TWENTY_FOUR_HOUR_WITH_LEADING_ZERO]).format(
                TWELVE_HOUR_WITH_LEADING_ZERO,
              )
            break
          default:
            break
        }

        return {
          name: newKey,
          value: newValue,
          isChecked: isFilterChecked[key],
          onClose: () => {
            const newFilters = { ...tripFilters }
            delete newFilters.departure_time__gte
            if (key !== "departure_time__gte" && Object.keys(parsedFilters).length <= 1) {
              changeToCurrentDay()
              changeCheckedState({
                [key]: false,
                departure_time__gte: true,
              })
            } else {
              changeCheckedState({
                [key]: false,
              })
            }
          },
        }
      })
    }

    const { filters } = filtersData[filterType]

    return Object.entries(filters).map(([key, value]) => {
      let newKey = key
      let newValue = value
      let isChecked
      let onClose

      switch (key) {
        case "status":
          newKey = "Status"
          newValue = DRIVER_STATUS[value]
          isChecked = isFilterChecked.status
          onClose = () =>
            changeCheckedState({
              status: false,
            })
          break

        case "vehicle_type":
          newKey = "Vehicle type"
          newValue = TRANSPORTATION_TYPE[value]
          isChecked = isFilterChecked.vehicle_type
          onClose = () =>
            changeCheckedState({
              vehicle_type: false,
            })
          break

        default:
          break
      }

      return {
        name: newKey,
        value: newValue,
        isChecked,
        onClose,
      }
    })
  }

  return (
    <div className="active-filter-list-container">
      {getFiltersArr().map(filter => {
        if (filter.isChecked && filter.value) {
          return (
            <ActiveFilter
              filterKey={filter.name}
              filterValue={filter.value}
              onClose={filter.onClose}
            />
          )
        }
        return null
      })}
    </div>
  )
}

ActiveFilterList.propTypes = {
  isFilterChecked: PropTypes.instanceOf(Object).isRequired,
  setIsFilterChecked: PropTypes.func.isRequired,
  filterType: PropTypes.string.isRequired,
  tripFilters: PropTypes.instanceOf(Object),
  changeToCurrentDay: PropTypes.func.isRequired,
}

ActiveFilterList.defaultProps = {
  tripFilters: {},
}

export default ActiveFilterList
