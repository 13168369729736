export const MONDAY = 1
export const TUESDAY = 2
export const WEDNESDAY = 3
export const THURSDAY = 4
export const FRIDAY = 5
export const SATURDAY = 6
export const SUNDAY = 7

export const DAYS = {
  [MONDAY]: "Monday",
  [TUESDAY]: "Tuesday",
  [WEDNESDAY]: "Wednesday",
  [THURSDAY]: "Thursday",
  [FRIDAY]: "Friday",
  [SATURDAY]: "Saturday",
  [SUNDAY]: "Sunday",
}
