import "./ColorPicker.scss"

import PropTypes from "prop-types"
import React, { useRef, useState } from "react"
import { ChromePicker } from "react-color"

import { useOnClickOutside } from "../../../utils/hooks"

const ColorPicker = ({ color, setColor }) => {
  const [showPicker, setShowPicker] = useState(false)
  const ref = useRef()

  useOnClickOutside(ref, () => setShowPicker(false))

  return (
    <div className="color-picker-container">
      <div className="color-picker-label">Change color</div>
      <div
        ref={ref}
        className="color-picker-input"
        role="button"
        onClick={() => setShowPicker(true)}
      >
        {color}
        {color && <div className="color-preview" style={{ backgroundColor: color }} />}
        {showPicker && <ChromePicker color={color} onChange={value => setColor(value.hex)} />}
      </div>
    </div>
  )
}

ColorPicker.propTypes = {
  color: PropTypes.string.isRequired,
  setColor: PropTypes.func.isRequired,
}

ColorPicker.defaultProps = {}

export default ColorPicker
