import "./DropDownCalendar.scss"
import "react-calendar/dist/Calendar.css"

import classNames from "classnames"
import moment from "moment"
import PropTypes from "prop-types"
import React, { useRef, useState } from "react"

import { MONTH_DAY_YEAR } from "../../../constants/dateFormats"
import { useOnClickOutside } from "../../../utils/hooks"
import CalendarComponent from "../CalendarComponent"
import DateChange from "../DateChange"
import SvgIcon from "../SvgIcon"

const DropDownCalendar = ({
  label,
  onDateChange,
  value,
  error,
  textInputStyle,
  showDateChange,
  containerClassName,
  calendarClassName,
  disabled,
  showClearDate,
  onClearDateClick,
  placeholder,
  showWarning,
  alignRight,
  calendarView,
}) => {
  const dropDownCalendarClass = classNames(
    "drop-down-calendar-container",
    { error },
    containerClassName,
  )
  const calendarContainerClass = classNames("calendar-container", calendarClassName, { alignRight })
  const dateTextClass = classNames("calendar-date-text", textInputStyle, {
    disabled,
    warning: showWarning,
  })

  const ref = useRef()
  const [date, setSelectedDate] = useState(undefined)
  const [showCalendar, setShowCalendar] = useState(false)

  const onTextClick = () => {
    if (!disabled) {
      setShowCalendar(!showCalendar)
    }
  }

  useOnClickOutside(ref, () => setShowCalendar(false))

  const controlledDate = value || date

  const setDate = dateParam => {
    if (onDateChange) {
      onDateChange(dateParam)
    }
    setShowCalendar(false)
    setSelectedDate(dateParam)
  }

  const handleClearDate = () => {
    onClearDateClick()
    setSelectedDate(undefined)
    setShowCalendar(false)
  }

  return (
    <div className="calendar-wrapper">
      {label && <label className="date-text-label">{label}</label>}

      <div className={dropDownCalendarClass} ref={ref}>
        {showDateChange ? (
          <DateChange
            dateValue={controlledDate}
            onDateChange={onDateChange}
            onDateClick={() => {
              setShowCalendar(!showCalendar)
            }}
            setShowCalendar={setShowCalendar}
            isYearView={calendarView === "year"}
          />
        ) : (
          <div className={dateTextClass} role="button" onClick={onTextClick}>
            {controlledDate
              ? moment(controlledDate.toString()).format(MONTH_DAY_YEAR)
              : placeholder}
            {!disabled && (
              <div className="calendar-icon-wrapper">
                <SvgIcon
                  icon="calendar"
                  width={16}
                  height={16}
                  fill={textInputStyle === "filter-calendar-style" ? "#999999" : "#3c3835"}
                />
              </div>
            )}
          </div>
        )}

        {showCalendar && (
          <div className={calendarContainerClass}>
            <CalendarComponent
              view={calendarView}
              value={controlledDate || new Date()}
              onChange={dateV => setDate(dateV)}
            />
            {showClearDate && (
              <div className="clear-date-button" role="button" onClick={handleClearDate}>
                Clear date
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

DropDownCalendar.propTypes = {
  label: PropTypes.string,
  onDateChange: PropTypes.func.isRequired,
  value: PropTypes.shape({ value: PropTypes.string, format: PropTypes.string }),
  error: PropTypes.string,
  textInputStyle: PropTypes.string,
  showDateChange: PropTypes.bool,
  calendarClassName: PropTypes.string,
  containerClassName: PropTypes.string,
  disabled: PropTypes.bool,
  showClearDate: PropTypes.bool,
  onClearDateClick: PropTypes.func,
  placeholder: PropTypes.string,
  showWarning: PropTypes.bool,
  alignRight: PropTypes.bool,
  calendarView: PropTypes.string,
}

DropDownCalendar.defaultProps = {
  label: "",
  value: undefined,
  error: "",
  textInputStyle: "",
  showDateChange: false,
  calendarClassName: "",
  containerClassName: "",
  disabled: false,
  showClearDate: false,
  onClearDateClick: () => {},
  placeholder: "",
  showWarning: false,
  alignRight: false,
  calendarView: "",
}

export default DropDownCalendar
