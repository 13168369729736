import "./TableButtonDisabled.scss"

import propTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"

import { useOnClickOutside } from "../../../utils/hooks"
import RowItem from "../RowItem"

function TableButtonDisabled({ message, label }) {
  const [isRestrictionPopupOpen, setIsRestrictionPopupOpen] = useState(false)
  const [openOnTop, setOpenOnTop] = useState(false)
  const popupElement = useRef()
  const ref = useRef()

  useEffect(() => {
    if (window.innerHeight - ref.current.getBoundingClientRect().bottom < window.innerHeight / 5) {
      setOpenOnTop(true)
    }
  }, [])

  useOnClickOutside(popupElement, () => {
    setIsRestrictionPopupOpen(false)
  })

  return (
    <RowItem>
      <div
        ref={ref}
        role="button"
        className="btn"
        onClick={e => {
          e.stopPropagation()
          setIsRestrictionPopupOpen(true)
        }}
      >
        {isRestrictionPopupOpen && (
          <div className={`restriction-popup  ${openOnTop && "openOnTop"}`} ref={popupElement}>
            <p>{message}</p>
          </div>
        )}
        {label}
      </div>
    </RowItem>
  )
}
TableButtonDisabled.propTypes = {
  message: propTypes.string,
  label: propTypes.instanceOf(Element),
}
TableButtonDisabled.defaultProps = {
  message: "",
  label: null,
}
export default TableButtonDisabled
