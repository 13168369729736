import { toast } from "react-toastify"

import ACTIONS from "../../constants/ACTIONS"
import API from "../../constants/API"
import { landlineApi } from "../../utils/api/calls"
import { apiError, startApiCall } from "../api/apiActions"
import { getTrip } from "../trip/tripActions"

const saveNote = ({ noteType, tripID, content, recipients, selectAllChecked }) => {
  return async dispatch => {
    try {
      dispatch(startApiCall(API.SAVE_NOTE))
      const data = {
        description: content,
      }
      if (recipients && recipients.length) {
        data.recipients = recipients
      }
      if (selectAllChecked) {
        data.select_all = true
      }
      const tripDetails = await landlineApi.post(
        `v2/bossadmin/trips/${tripID}/post_${noteType}_note/`,
        data,
      )
      dispatch({ type: ACTIONS.SAVE_NOTE_SUCCESS, payload: tripDetails.data.notes })
      toast.success("Note Saved")
    } catch (error) {
      dispatch(apiError(API.SAVE_NOTE, error))
    }
  }
}

const deleteNote = (noteId, tripId) => {
  return async dispatch => {
    try {
      dispatch(startApiCall(API.DELETE_NOTE))
      await landlineApi.delete(`v2/bossadmin/notes/${noteId}/delete/`)
      dispatch({ type: ACTIONS.DELETE_NOTE_SUCCESS })

      await dispatch(getTrip({ tripID: tripId }))
      toast.success("Note Deleted")
    } catch (error) {
      dispatch(apiError(API.DELETE_NOTE, error))
    }
  }
}

const updateNote = (noteId, content) => {
  return async dispatch => {
    try {
      const data = {
        description: content,
      }
      dispatch(startApiCall(API.PATCH_NOTE))
      await landlineApi.patch(`v2/bossadmin/notes/${noteId}/`, data)
      dispatch({ type: ACTIONS.PATCH_NOTE_SUCCESS })
      toast.success("Note Saved")
    } catch (error) {
      dispatch(apiError(API.PATCH_NOTE, error))
    }
  }
}

export { saveNote, deleteNote, updateNote }
