import { Button } from "grommet"
import React from "react"
import { Row } from "react-table"
import { RoutesWithDelayResult } from "../../../../utils/api/getRoutesWithDelay"
import { Cell, CellProps } from "../../../tables/cells/Cell"

export interface DelayFormatCellProps extends CellProps {
  onClick?: (data: RoutesWithDelayResult) => void
  row?: Row<RoutesWithDelayResult>
  delayMatchesEntered?: boolean
  delayMismatchColor?: string
}

export function DelayFormatCell({
  value,
  delayMatchesEntered,
  delayMismatchColor = "red",
  onClick,
  row,
  ...rest
}: DelayFormatCellProps) {
  const color = value > 0 && !delayMatchesEntered ? delayMismatchColor : undefined

  return (
    <Cell
      {...rest}
      value={
        <Button
          plain
          label={value > 0 ? `+${value}m` : `---`}
          onClick={() => onClick(row.original)}
          color={color}
          style={{
            borderBottom: `1px solid ${color}`,
          }}
        ></Button>
      }
    />
  )
}
