import { landlineApi } from "./calls"

export async function deleteRouteDelay(id: number) {
  try {
    await landlineApi.delete(`v2/bossadmin/route_delays/`, {
      data: {
        id,
      },
    })
    return true
  } catch (e) {
    console.error("Failed to delete route delay", e)
    return false
  }
}
