import "./LegConnection.scss"

import PropTypes from "prop-types"
import React from "react"

import SvgIcon from "../SvgIcon"

const LegConnection = ({ iconName, arrivalData, departureData }) => {
  return (
    <div className="leg-connection-container">
      <div className="leg-connection">
        <div className="leg-connection-line" />
        <SvgIcon icon="leg-connection-dot" width={11} height={11} />
        <div className="leg-type-icon-wrapper">
          <SvgIcon icon={iconName} width={24} height={11} margin="0 0 5px 0" />
        </div>
        <SvgIcon icon="leg-connection-dot" width={11} height={11} />
      </div>
      <div className="leg-info-container">
        <div className="leg-info">{departureData.iata}</div>
        <div className="leg-info">{arrivalData.iata}</div>
      </div>
    </div>
  )
}

LegConnection.propTypes = {
  iconName: PropTypes.string.isRequired,
  arrivalData: PropTypes.instanceOf(Object).isRequired,
  departureData: PropTypes.instanceOf(Object).isRequired,
}

LegConnection.defaultProps = {}

export default LegConnection
