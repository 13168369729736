import "./ContactInfoTab.scss"

import PropTypes from "prop-types"
import React, { useEffect, useMemo, useState } from "react"
import InfiniteScroll from "react-infinite-scroller"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router"

import API from "../../../constants/API"
import { PAGE_LIMIT } from "../../../constants/offset"
import { getAgents } from "../../../redux/actions"
import { getCompanyRepresentatives } from "../../../redux/representative/representativeActions"
import apiUtils from "../../../utils/api/api-utils"
import { useMounted } from "../../../utils/hooks"
import CompanyCard from "../../atoms/CompanyCard"
import LandlineCard from "../../atoms/LandlineCard"
import Loading from "../../atoms/Loading"
import OperatorsEmptyState from "../../atoms/OperatorsEmptyState"
import TextInput from "../../atoms/TextInput"
import UserCard from "../../atoms/UserCard"

const ContactInfoTab = ({ selectedCompany, isLandline, companyLoading }) => {
  const dispatch = useDispatch()
  const isMounted = useMounted()
  const { companyId } = useParams()

  const [search, setSearch] = useState("")
  const [offset, setOffset] = useState(0)

  useEffect(() => {
    if (isLandline) {
      dispatch(getAgents())
    } else {
      dispatch(getCompanyRepresentatives({ company: companyId }))
    }
  }, [isLandline])

  const [
    callsInProgress,
    representatives,
    allAgents,
  ] = useSelector(({ api, representative, agents }) => [
    api.callsInProgress,
    representative.representatives,
    agents?.agents,
  ])

  const loading = useMemo(() => {
    return apiUtils.areCallsInProgress(
      [API.GET_COMPANY_REPRESENTATIVES, API.GET_AGENTS],
      callsInProgress,
    )
  }, [callsInProgress])

  const loadMore = () => {
    if (!loading) {
      const currentOffset = offset + PAGE_LIMIT
      if (isLandline) {
        dispatch(getAgents({ offset: currentOffset }))
      } else {
        dispatch(getCompanyRepresentatives({ company: selectedCompany.id, offset: currentOffset }))
      }
      setOffset(currentOffset)
    }
  }

  useEffect(() => {
    if (!isMounted) {
      return undefined
    }

    const delayedCall = setTimeout(() => {
      if (isLandline) {
        dispatch(getAgents({ full_name: search }))
      } else {
        dispatch(getCompanyRepresentatives({ company: selectedCompany.id, full_name: search }))
      }
      setOffset(0)
    }, 500)

    return () => clearTimeout(delayedCall)
  }, [search, isLandline])

  const usersList = isLandline ? allAgents || {} : representatives || {}

  return (
    <div>
      <>
        {isLandline ? (
          <LandlineCard company={selectedCompany} loading={companyLoading} />
        ) : (
          <CompanyCard isHorizontal company={selectedCompany} loading={companyLoading} />
        )}
        <div className="company-input-container">
          <TextInput
            onChange={e => setSearch(e.target.value)}
            value={search}
            inputSize="search-size"
            inputStyle="search-style"
            icon="search"
            iconStyle="search-icon"
            placeholder="Search by name"
          />
        </div>
        <div>
          {loading && !offset ? (
            <Loading className="loading-operators" />
          ) : (
            <InfiniteScroll
              loadMore={loadMore}
              hasMore={!!usersList.next}
              pageStart={0}
              loader={<Loading className="loading-operators" />}
              threshold={100}
              useWindow
            >
              <>
                {usersList.results?.length > 0 ? (
                  usersList.results.map(user => <UserCard user={user} />)
                ) : (
                  <OperatorsEmptyState searchedItems={isLandline ? "agents" : "representatives"} />
                )}
              </>
            </InfiniteScroll>
          )}
        </div>
      </>
    </div>
  )
}

ContactInfoTab.propTypes = {
  selectedCompany: PropTypes.instanceOf(Object),
  isLandline: PropTypes.bool,
  companyLoading: PropTypes.bool,
}

ContactInfoTab.defaultProps = {
  selectedCompany: {},
  isLandline: false,
  companyLoading: false,
}

export default ContactInfoTab
