import "./VidecomReservation.scss"

import classNames from "classnames"
import PropTypes from "prop-types"
import React, { useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import API from "../../../constants/API"
import { refreshVidecomTicket } from "../../../redux/actions"
import apiUtils from "../../../utils/api/api-utils"
import Button from "../Button"
import Loading from "../Loading"
import TextInput from "../TextInput"

const VidecomReservation = ({ requestCallback }) => {
  const dispatch = useDispatch()
  const [pnr, setPnr] = useState("")

  const [callsInProgress] = useSelector(({ api }) => [api.callsInProgress])

  const loading = useMemo(() => {
    return apiUtils.areCallsInProgress([API.REFRESH_VIDECOM_TICKET], callsInProgress)
  }, [callsInProgress])

  const handleRefreshVidecomTicket = () => {
    dispatch(
      refreshVidecomTicket(pnr, () => {
        requestCallback(pnr)
        setPnr("")
      }),
    )
  }

  const buttonTextStyle = classNames({ loading })

  return (
    <div className="videcom-reservation-container">
      <div className="videcom-input-container">
        <TextInput
          placeholder="Import from Videcom"
          onChange={e => setPnr(e.target.value.toUpperCase())}
          value={pnr}
          inputStyle="videcom-style"
        />
      </div>

      <Button
        buttonType="videcomColor"
        buttonSize="videcom-size"
        onClick={!loading && handleRefreshVidecomTicket}
        disabled={pnr.length < 6}
      >
        {loading && <Loading buttonLoading />}
        <div className={buttonTextStyle}>Import</div>
      </Button>
    </div>
  )
}

VidecomReservation.propTypes = {
  requestCallback: PropTypes.func.isRequired,
}

VidecomReservation.defaultProps = {}

export default VidecomReservation
