import axios from "axios"

import { INVALID_ACCESS_TOKEN } from "../../constants/errorMessages"
import { store } from "../../redux/store"
import { logoutUser, refreshAccessToken } from "../../redux/user/userActions"
import config from "../config"
import { showError } from "../error"

export const landlineApi = axios.create({
  baseURL: config.API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
})

landlineApi.interceptors.request.use(requestConfig => {
  const accessToken = localStorage.getItem("accessToken")
  if (accessToken) {
    requestConfig.headers.common.Authorization = `JWT ${accessToken}`
  }

  return requestConfig
})

let isRefreshing = false
let failedQueue = []

const processQueue = (error, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error)
    } else {
      prom.resolve(token)
    }
  })

  failedQueue = []
}

const handle401 = async error => {
  const originalRequest = error.config

  if (!error?.response?.data?.code) {
    showError(error)
  }

  if (error?.response?.data?.code && error?.response?.data?.code !== INVALID_ACCESS_TOKEN) {
    await store.dispatch(logoutUser(false, "Your session has expired."))
    return false
  }

  if (
    // eslint-disable-next-line
    !originalRequest._retry &&
    error?.response?.data?.code === INVALID_ACCESS_TOKEN
  ) {
    if (isRefreshing) {
      return new Promise((resolve, reject) => {
        failedQueue.push({ resolve, reject })
      })
        .then(token => {
          originalRequest.headers.Authorization = `JWT ${token}`
          return axios(originalRequest)
        })
        .catch(err => {
          return Promise.reject(err)
        })
    }

    // eslint-disable-next-line
    originalRequest._retry = true
    isRefreshing = true

    const successfulRefresh = await store.dispatch(refreshAccessToken())

    if (successfulRefresh) {
      const accessToken = localStorage.getItem("accessToken")

      originalRequest.headers.Authorization = `JWT ${accessToken}`

      processQueue(null, accessToken)
      isRefreshing = false

      return landlineApi(originalRequest)
    }

    isRefreshing = false
  }
  return false
}

// Response interceptor for API calls
landlineApi.interceptors.response.use(
  response => {
    return response
  },
  // eslint-disable-next-line
  async function(error) {
    if (error.response.status !== 401) {
      showError(error)
    }

    if (error.response.status === 401) {
      return handle401(error)
    }

    return Promise.reject(error)
  },
)

export default { landlineApi }
