import "./CompanyCard.scss"

import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"

import { changeColor } from "../../../utils/commonFunctionality"
import Loading from "../Loading"
import SvgIcon from "../SvgIcon"

const CompanyCard = ({ company, onClick, isHorizontal, loading }) => {
  const companyCardContainer = classNames("company-card-container", {
    "company-card-horizontal-container": isHorizontal,
  })
  const firstLetter = company?.name?.substring(0, 1).toUpperCase()

  return (
    <div role="button" className={companyCardContainer} onClick={() => onClick(company.id)}>
      {loading ? (
        <Loading className="loading-scroll" />
      ) : (
        <>
          {company.logo ? (
            <div className="avatar-company-image">
              <img src={company.logo} width={88} height={88} alt="Logo" />
            </div>
          ) : (
            <div className="avatar-image" style={{ backgroundColor: company.color }}>
              <div className="avatar-text" style={{ color: changeColor(company.color) }}>
                {firstLetter}
              </div>
            </div>
          )}

          <div className="company-info-wrapper">
            <div className="company-name">{company.name}</div>
            <div className="company-info">
              <div className="phone-info">
                <SvgIcon
                  icon="phone"
                  margin="0px 8px 0px 0px"
                  fill="#F5F5F5"
                  width={24}
                  height={24}
                />
                <span className="phone-text">Phone:</span>
                {company.phone_number}
              </div>
              <div className="email-info">
                <SvgIcon icon="e-mail" margin="0px 8px 0px 0px" width={24} height={24} />
                <span className="email-text">Email:</span>
                {company.email}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

CompanyCard.propTypes = {
  company: PropTypes.instanceOf(Object),
  onClick: PropTypes.func,
  isHorizontal: PropTypes.bool,
  loading: PropTypes.bool,
}

CompanyCard.defaultProps = {
  company: {},
  isHorizontal: false,
  onClick: () => {},
  loading: false,
}

export default CompanyCard
