import "./DriverEditModal.scss"

import classNames from "classnames"
import moment from "moment"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import { DATE_WITHOUT_TIME } from "../../../constants/dateFormats"
import { EMPLOYMENT_STATUS, FULL_TIME, PART_TIME } from "../../../constants/employmentStatus"
import { BLACK_CAR_TYPE, BUS_TYPE, EXPRESS_TYPE } from "../../../constants/transportationTypes"
import { updateDriverDetails, updateDriverVehicleLicence } from "../../../redux/actions"
import { getAllAirports } from "../../../redux/airport/airportActions"
import { useMounted } from "../../../utils/hooks"
import Button from "../../atoms/Button"
import Checkbox from "../../atoms/Checkbox"
import Dropdown from "../../atoms/Dropdown"
import DropDownCalendar from "../../atoms/DropDownCalendar"
import NewNote from "../../atoms/NewNote"
import SearchDropdown from "../../atoms/SearchDropdown"
import SvgIcon from "../../atoms/SvgIcon"
import TextInput from "../../atoms/TextInput"

const DriverEditModal = ({ driverDetails, setDriverEditModalOpen }) => {
  const driverEditModalClass = classNames("driver-edit-modal-container")
  const dispatch = useDispatch()
  const isMounted = useMounted()

  const [newDriverDetails, setNewDriverDetails] = useState(driverDetails)
  const [dateError, setDateError] = useState(false)

  const [airportSearch, setAirportSearch] = useState(
    `${driverDetails.driver_profile?.location_details?.AirportName} (${driverDetails.driver_profile?.location_details?.iata})`,
  )

  const [allAirports] = useSelector(({ airport }) => [airport.allAirports])

  const handleGetAirports = cityCode => {
    dispatch(getAllAirports({ search: cityCode }))
  }

  useEffect(() => {
    setDateError(
      moment(newDriverDetails.driver_profile?.start_date).isSameOrAfter(
        moment(newDriverDetails.driver_profile?.end_date),
      ),
    )
  }, [newDriverDetails.driver_profile?.start_date, newDriverDetails.driver_profile?.end_date])

  useEffect(() => {
    if (!isMounted) {
      return undefined
    }

    const delayedCall = setTimeout(() => {
      handleGetAirports(airportSearch)
    }, 500)

    return () => clearTimeout(delayedCall)
  }, [airportSearch])

  const formatAirportSearch = search => {
    const index = search.indexOf("(") - 1
    return index > 0 ? search.slice(0, index) : search
  }

  const changeNewDriverDetails = params => {
    setNewDriverDetails(prevState => ({ ...prevState, ...params }))
  }

  const changeEmploymentStatus = status =>
    setNewDriverDetails(prevState => ({
      ...prevState,
      driver_profile: {
        ...prevState.driver_profile,
        employment_status: status,
      },
    }))

  const changeDate = (date, field) => {
    setNewDriverDetails(prevState => ({
      ...prevState,
      driver_profile: {
        ...prevState.driver_profile,
        [field]: date ? moment(date).format(DATE_WITHOUT_TIME) : null,
      },
    }))
  }

  const changeNote = newNote => {
    setNewDriverDetails(prevState => ({
      ...prevState,
      driver_profile: {
        ...prevState.driver_profile,
        note: newNote,
      },
    }))
  }

  const getLicenceInfo = vehicleType => {
    return driverDetails.driver_profile?.vehicle_licences.find(
      license => license.vehicle_type === vehicleType,
    )
  }

  const checkForLicense = vehicleType => {
    const licence = driverDetails.driver_profile?.vehicle_licences.find(
      license => license.vehicle_type === vehicleType,
    )

    return licence.active
  }

  const aiportItems = allAirports?.flatMap(item => item.airports.map(airport => airport))

  const changeAirportName = location => {
    setNewDriverDetails(prevState => ({
      ...prevState,
      driver_profile: {
        ...prevState.driver_profile,
        location_details: {
          iata: location.CityCode,
          AirportName: location.AirportName,
        },
        location: location.CityCode,
      },
    }))
  }

  const statusItems = [
    {
      name: EMPLOYMENT_STATUS[FULL_TIME],
      value: FULL_TIME,
    },
    {
      name: EMPLOYMENT_STATUS[PART_TIME],
      value: PART_TIME,
    },
  ]

  return (
    <>
      <div role="button" onClick={() => setDriverEditModalOpen(false)}>
        <SvgIcon className="close-icon-style" icon="close" margin="0px 12px 0px 0px" />
      </div>
      <div className={driverEditModalClass}>
        <div className="personal-info-container">Personal Info</div>
        <div className="info-title-container">
          <TextInput
            value={newDriverDetails.first_name}
            inputStyle="trip-details-modal-style"
            fullWidth
            label="Name"
            onChange={e => changeNewDriverDetails({ first_name: e.target.value })}
          />
          <TextInput
            value={newDriverDetails.last_name}
            inputStyle="trip-details-modal-style"
            fullWidth
            label="Surname"
            onChange={e => changeNewDriverDetails({ last_name: e.target.value })}
          />
          <div className="location-style">Location</div>
          <SearchDropdown
            textInputStyle="location-search-style"
            noTopMargin
            value={airportSearch}
            setValue={setAirportSearch}
            items={aiportItems}
            showItemField="CityCode"
            showSecondItemField="AirportName"
            onFocus={() => handleGetAirports(formatAirportSearch(airportSearch))}
            onItemClick={item => {
              changeAirportName(item)
            }}
          />
          <TextInput
            value={newDriverDetails.phone_number}
            inputStyle="trip-details-modal-style"
            fullWidth
            label="Phone"
            onChange={e => changeNewDriverDetails({ phone_number: e.target.value })}
          />
          <TextInput
            value={newDriverDetails.email}
            inputStyle="trip-details-modal-style"
            fullWidth
            label="Email"
            onChange={e => changeNewDriverDetails({ email: e.target.value })}
          />
        </div>
        <div className="info-container">Status</div>
        <Dropdown
          value={
            statusItems.find(
              status => status.value === newDriverDetails.driver_profile.employment_status,
            )?.name
          }
          label="Employment status"
          showItemField="name"
          className="dropdown-status-style"
          margin="8px 0px 4px 0px"
          items={statusItems}
          onClick={item => changeEmploymentStatus(item.value)}
          paddingLeft
        />
        <div className="start-end-date-container">
          <DropDownCalendar
            value={
              newDriverDetails.driver_profile?.start_date &&
              moment(newDriverDetails.driver_profile?.start_date).toDate()
            }
            containerClassName="driver-modal-calendar-container"
            label="Start date"
            textInputStyle="driver-modal-style"
            placeholder="-"
            onDateChange={date => changeDate(date, "start_date")}
          />
          <DropDownCalendar
            value={
              newDriverDetails.driver_profile?.end_date &&
              moment(newDriverDetails.driver_profile?.end_date).toDate()
            }
            containerClassName="driver-modal-calendar-container end"
            label="End date"
            textInputStyle="driver-modal-style"
            placeholder="-"
            onDateChange={date => changeDate(date, "end_date")}
            alignRight
            showClearDate
            onClearDateClick={() => changeDate(null, "end_date")}
          />
        </div>
        <span className="error-date">{dateError && "Start date must be before end date."}</span>
        <div className="info-container">Vehicle type</div>
        <div className="choose-vehicle-container">Choose vehicle</div>
        <div className="vehicle-container">
          <div
            className="checkbox-wrapper"
            role="button"
            onClick={() => {
              const licenceInfo = getLicenceInfo(BLACK_CAR_TYPE)
              dispatch(updateDriverVehicleLicence(licenceInfo.id, !licenceInfo.active))
            }}
          >
            <Checkbox checked={checkForLicense(BLACK_CAR_TYPE)} />
            <div className="vehicle-title">SUV</div>
          </div>
          <div
            className="checkbox-wrapper"
            role="button"
            onClick={() => {
              const licenceInfo = getLicenceInfo(EXPRESS_TYPE)
              dispatch(updateDriverVehicleLicence(licenceInfo.id, !licenceInfo.active))
            }}
          >
            <Checkbox checked={checkForLicense(EXPRESS_TYPE)} />
            <div className="vehicle-title">Sprinter</div>
          </div>
          <div
            className="checkbox-wrapper"
            role="button"
            onClick={() => {
              const licenceInfo = getLicenceInfo(BUS_TYPE)
              dispatch(updateDriverVehicleLicence(licenceInfo.id, !licenceInfo.active))
            }}
          >
            <Checkbox checked={checkForLicense(BUS_TYPE)} />
            <div className="vehicle-title">Bus</div>
          </div>
        </div>
        <div className="info-container">Note</div>
        <div className="add-note-container">Add note</div>
        <NewNote
          value={newDriverDetails.driver_profile?.note}
          onChange={e => changeNote(e.target.value)}
          hideButtons
        />
        <div className="button-wrapper">
          <Button
            buttonType="landlineColor"
            onClick={() => {
              const clonedDetails = JSON.parse(JSON.stringify(newDriverDetails))
              delete clonedDetails.avatar

              dispatch(updateDriverDetails(newDriverDetails.id, clonedDetails))
            }}
            disabled={dateError}
          >
            Update
          </Button>
        </div>
      </div>
    </>
  )
}

DriverEditModal.propTypes = {
  driverDetails: PropTypes.instanceOf(Object).isRequired,
  setDriverEditModalOpen: PropTypes.func,
}

DriverEditModal.defaultProps = {
  setDriverEditModalOpen: () => {},
}

export default DriverEditModal
