import "./InfoPopup.scss"

import PropTypes from "prop-types"
import React, { useState } from "react"

function InfoPopup({ children, position, popupText, arrowDown, noArrow, disabled, textSpacing }) {
  const [open, setOpen] = useState(false)
  return (
    <div className="infoPopup">
      <div
        className="infoPopup__content"
        onMouseEnter={() => setOpen(true)}
        onMouseLeave={() => setOpen(false)}
      >
        {open && !disabled && (
          <div className="popup__wrapper" style={position}>
            <div className="popup--shape">
              <div className="popup__content" style={textSpacing}>
                {popupText}
              </div>
              {!noArrow && <div className={arrowDown ? "arrow-down" : "arrow-right"} />}
            </div>
          </div>
        )}
        {children}
      </div>
    </div>
  )
}

InfoPopup.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  position: PropTypes.instanceOf(Object),
  textSpacing: PropTypes.instanceOf(Object),
  popupText: PropTypes.string,
  arrowDown: PropTypes.bool,
  noArrow: PropTypes.bool,
  disabled: PropTypes.bool,
}

InfoPopup.defaultProps = {
  children: null,
  position: {},
  textSpacing: {},
  popupText: "",
  arrowDown: false,
  noArrow: false,
  disabled: false,
}
export default InfoPopup
