import "./DriverTransportationStatistic.scss"

import PropTypes from "prop-types"
import React, { useState } from "react"

import {
  EXPRESS_HOME_TYPE,
  EXPRESS_TYPE,
  TRANSPORTATION_TYPE_LIST,
  TRANSPORTATION_TYPE_OFFSET,
} from "../../../constants/transportationTypes"
import InfoPopup from "../../atoms/InfoPopup"
import Loading from "../../atoms/Loading"
import SvgIcon from "../../atoms/SvgIcon"

function DriverTransportationStatistic({ driverDetails, loading }) {
  const [direction, setDirection] = useState(true)
  const [count, setCount] = useState(1)

  const arrive = driverDetails?.driver_profile?.rides_per_airport.msp_arrive
  const depart = driverDetails?.driver_profile?.rides_per_airport.msp_depart

  const filterDate = () => {
    const displayData = direction ? depart : arrive
    const filteredData = displayData?.filter(item => {
      let serviceType = item.service_type
      if (serviceType === EXPRESS_HOME_TYPE) {
        serviceType = EXPRESS_TYPE
      }
      return serviceType === count + TRANSPORTATION_TYPE_OFFSET
    })
    return filteredData
  }
  return (
    <div className="driverTransportationStatistic">
      <div className="driverTransportationStatistic__content">
        <div className="transportationNavigation">
          <div
            className="arrowLeft"
            role="button"
            onClick={() =>
              count > 0 ? setCount(count - 1) : setCount(TRANSPORTATION_TYPE_LIST.length - 1)
            }
          >
            <SvgIcon width="8" height="12" icon="chevron-left" />
          </div>
          <p className="vehicleTypeName">{TRANSPORTATION_TYPE_LIST[count]}</p>
          <div
            className="arrowRight"
            role="button"
            onClick={() =>
              count < TRANSPORTATION_TYPE_LIST.length - 1 ? setCount(count + 1) : setCount(0)
            }
          >
            <SvgIcon width="8" height="12" className="arrowRight-icon" icon="chevron-right" />
          </div>
        </div>

        <div className="transportationDirection">
          <div className="destinationLeft">
            <SvgIcon width="85" height="11" icon="destination-left" />
          </div>
          <div
            role="button"
            className="transportation--icon--container"
            onClick={() => setDirection(!direction)}
          >
            <InfoPopup
              arrowDown
              notchDown
              popupText="Switch direction"
              position={{ top: "-30px", width: "150px", left: "-40px" }}
            >
              <div
                className={`transportation-icon ${direction ? null : "transportation-icon-rotate"}`}
              >
                <SvgIcon
                  width="29"
                  height="14"
                  icon={`vehicle-${TRANSPORTATION_TYPE_LIST[count]}`}
                />
              </div>
            </InfoPopup>
          </div>

          <div className="destinationRight">
            <SvgIcon width="85" height="11" icon="destination-left" />
          </div>
        </div>

        <div className="transportationStatsTable">
          <div className="transportationStatsTable__content">
            <div className="tableHeadRow">
              <p>From</p>
              <p>No. of rides</p>
              <p>To</p>
            </div>
            {loading ? (
              <Loading className="transportation-loading" />
            ) : (
              <>
                {filterDate() && filterDate().length > 0 ? (
                  <div className="tableBody">
                    {filterDate().map(item => {
                      return (
                        <div className="row" key={Math.random()}>
                          <p className="ridesDestination">{item.depart_id}</p>
                          <p className="ridesCount">{item.count}</p>
                          <p className="ridesDestination">{item.arrive_id}</p>
                        </div>
                      )
                    })}
                  </div>
                ) : (
                  <div className="transportation-empty-state">
                    <img
                      src="/media/empty-transportation-type.png"
                      alt="edfwef"
                      width="166px"
                      height="98px"
                    />
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
DriverTransportationStatistic.propTypes = {
  driverDetails: PropTypes.instanceOf(Object),
  loading: PropTypes.bool,
}

DriverTransportationStatistic.defaultProps = {
  driverDetails: {},
  loading: false,
}
export default DriverTransportationStatistic
