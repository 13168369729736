import "./DriverAvatar.scss"

import classNames from "classnames"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"

import { updateDriverDetails } from "../../../redux/actions"
import { getFirstLetter } from "../../../utils/common"
import DriverStatus from "../DriverStatus"
import SvgIcon from "../SvgIcon"

const DriverAvatar = ({ avatar, driverId, fname, lname, disabled }) => {
  const [uploadVisible, setUploadVisible] = useState(false)
  const [picture, setPicture] = useState(null)
  const dispatch = useDispatch()
  const imageContainerClass = classNames("image-container", { disabled })

  const getFormData = () => {
    const formData = new FormData()
    formData.append("avatar", picture)

    return formData
  }

  useEffect(() => {
    if (picture) {
      dispatch(updateDriverDetails(driverId, getFormData()))
    }
  }, [picture])

  const handleOnChange = e => {
    const file = e.target.files[0]
    if (file) {
      setPicture(file)
    }
  }

  return (
    <div className="driver-profile-image">
      <div
        className={imageContainerClass}
        onMouseEnter={() => !disabled && setUploadVisible(true)}
        onMouseLeave={() => !disabled && setUploadVisible(false)}
      >
        <input
          type="file"
          accept="image/png, image/jpeg"
          onChange={e => {
            handleOnChange(e)
          }}
          id="picture"
          className="input-file"
          disabled={disabled}
        />

        <label className="driver-avatar-file-input" htmlFor="picture" />
        {avatar ? (
          <img className="driver-image" src={avatar} alt="driver" width="128" height="128" />
        ) : (
          <div className="defaultDriverAvatar">
            <div>{`${getFirstLetter(fname)}${getFirstLetter(lname)}`}</div>
          </div>
        )}

        {uploadVisible && (
          <div className="upload-container">
            <SvgIcon fill="#fff" icon="camera-icon" width="24px" height="20px" />
            <div className="upload-text">Upload</div>
          </div>
        )}
      </div>
      <div className="driver-status-wrapper">
        <DriverStatus type={1} />
      </div>
    </div>
  )
}

DriverAvatar.propTypes = {
  avatar: PropTypes.string,
  driverId: PropTypes.number,
  fname: PropTypes.string,
  lname: PropTypes.string,
  disabled: PropTypes.bool,
}

DriverAvatar.defaultProps = {
  avatar: "",
  driverId: 0,
  fname: "",
  lname: "",
  disabled: false,
}

export default DriverAvatar
