import "./NotifyModal.scss"

import PropTypes from "prop-types"
import React, { useMemo, useRef, useState } from "react"
import { useSelector } from "react-redux"

import API from "../../../constants/API"
import apiUtils from "../../../utils/api/api-utils"
import Button from "../Button"
import Loading from "../Loading"
import NotifyModalItem from "../NotifyModalItem"
import SvgIcon from "../SvgIcon"

const NotifyModal = ({ itemList, onCloseModal, onRemovePnr, onConfirm, notifyType }) => {
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [confirmationItemList, setConfirmationItemList] = useState([])
  const scrollRef = useRef()

  const [callsInProgress, failedNotificationIds] = useSelector(({ api, driver }) => [
    api.callsInProgress,
    driver.failedNotificationIds,
  ])

  const loading = useMemo(() => {
    return apiUtils.areCallsInProgress([API.NOTIFY_CUSTOMERS, API.NOTIFY_DRIVERS], callsInProgress)
  }, [callsInProgress])

  const getSentenceEnding = () => {
    const oneItem = itemList.length === 1

    if (notifyType === "drivers") {
      if (oneItem) {
        return "this driver"
      }
      return "these drivers"
    }

    if (oneItem) {
      return "this customer"
    }

    return "these customers"
  }

  if (loading) {
    return <Loading className="loading-notify-modal" />
  }

  const listToShow = showConfirmation ? confirmationItemList : itemList

  return (
    <div className="notify-modal-container">
      <div className="close-modal-icon-wrapper" role="button" onClick={onCloseModal}>
        <SvgIcon icon="close" />
      </div>

      <div className="title-wrapper">
        {showConfirmation
          ? "Here is the status of each PNR that has been marked for notifying:"
          : `Are you sure you want to notify ${getSentenceEnding()}?`}
      </div>

      <div className="pnr-list-container" ref={scrollRef}>
        {listToShow.map(item => (
          <NotifyModalItem
            item={item}
            notifyType={notifyType}
            failedNotificationIds={failedNotificationIds}
            isConfirmation={showConfirmation}
            onRemovePnr={onRemovePnr}
            scrollRef={scrollRef}
          />
        ))}
      </div>

      <div className="buttons-wrapper">
        {!showConfirmation && (
          <Button
            buttonType="landlineColor"
            buttonSize="driverStatusModalSize confirmation"
            type="button"
            onClick={() =>
              onConfirm(() => {
                setConfirmationItemList([...itemList])
                setShowConfirmation(true)
              })
            }
          >
            Yes
          </Button>
        )}

        <Button
          buttonType="grayColor"
          buttonSize="driverStatusModalSize confirmation"
          type="button"
          margin="0px 0px 0px 12px"
          onClick={onCloseModal}
        >
          {showConfirmation ? "Close" : "No"}
        </Button>
      </div>
    </div>
  )
}

NotifyModal.propTypes = {
  itemList: PropTypes.instanceOf(Array),
  onCloseModal: PropTypes.func.isRequired,
  onRemovePnr: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  notifyType: PropTypes.string,
}

NotifyModal.defaultProps = {
  itemList: [],
  notifyType: "",
}

export default NotifyModal
