import "./Tab.scss"

import classNames from "classnames"
import PropTypes from "prop-types"
import React, { useCallback } from "react"

import SvgIcon from "../SvgIcon"

const Tab = ({ title, onTabClick, active, index, iconName, tabTitleStyle }) => {
  const tabContainer = classNames("tab-container", {
    activeTab: index === active,
    [tabTitleStyle]: tabTitleStyle,
  })

  const onTabClickHandler = useCallback(() => {
    onTabClick(index)
  }, [onTabClick, index])

  return (
    <div role="button" className={tabContainer} onClick={onTabClickHandler}>
      {iconName && (
        <SvgIcon
          className="tab-svg-icon"
          icon={iconName}
          width="16"
          height="16"
          margin="0 10px 0 0"
          fill={active === index ? "#3C3835" : "#999999"}
        />
      )}
      {title}
    </div>
  )
}

Tab.propTypes = {
  index: PropTypes.number.isRequired,
  active: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  onTabClick: PropTypes.func.isRequired,
  iconName: PropTypes.string,
  tabTitleStyle: PropTypes.string,
}

Tab.defaultProps = {
  iconName: "",
  tabTitleStyle: "",
}

export default Tab
