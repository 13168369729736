import React, { useEffect, useState } from "react"

export function useOnClickOutside(ref, handler) {
  useEffect(
    () => {
      const listener = event => {
        // Do nothing if clicking ref's element or descendent elements
        if (!ref.current || ref.current.contains(event.target)) {
          return
        }

        handler(event)
      }

      document.addEventListener("mousedown", listener)
      document.addEventListener("touchstart", listener)

      return () => {
        document.removeEventListener("mousedown", listener)
        document.removeEventListener("touchstart", listener)
      }
    },
    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
    [ref, handler],
  )
}

export function useMounted() {
  const [isMounted, setIsMounted] = useState(false)

  React.useEffect(() => {
    setIsMounted(true)
  }, [])
  return isMounted
}

export function useDebounce({
  value,
  miliseconds = 500,
  callback,
  noValueCallback,
  condition = true,
}) {
  const isMounted = useMounted()

  useEffect(() => {
    if (!isMounted || !condition) {
      return undefined
    }

    if (noValueCallback && !value) {
      noValueCallback({ depart: undefined })
    }

    const delayedCall = setTimeout(() => {
      callback()
    }, miliseconds)

    return () => clearTimeout(delayedCall)
  }, [value])
}

export default { useOnClickOutside, useMounted, useDebounce }
