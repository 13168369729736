import ACTIONS from "../../constants/ACTIONS"

const initialState = {}

export default function companyReducer(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.GET_COMPANY_REPRESENTATIVES_SUCCESS:
      if (action.offset) {
        return {
          ...state,
          representatives: {
            ...action.representatives,
            results: [...state.representatives.results, ...action.representatives.results],
          },
        }
      }
      return {
        ...state,
        representatives: action.representatives,
      }

    default:
      return { ...state }
  }
}
