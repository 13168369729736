import "./PieChart.scss"

import * as d3 from "d3"
import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"

const PieChart = ({ data, loading, isDonut }) => {
  const ref = useRef(null)
  const [shouldAnimate, setShouldAnimate] = useState(true)

  const drawChart = () => {
    const allZeroValues = !data.find(item => item.value > 0)
    const filteredData = data.filter(item => item.value > 0)
    const allValues = data.map(item => item.value)
    const indexOfGreatestValueItem = filteredData.findIndex(
      item => item.value === Math.max(...allValues),
    )
    const element = filteredData[indexOfGreatestValueItem]
    filteredData.splice(indexOfGreatestValueItem, 1)
    filteredData.splice(1, 0, element)

    const width = 138
    const height = 138

    const radius = Math.min(width, height) / 2

    d3.select(ref.current)
      .select("svg")
      .remove()

    const svg = d3
      .select(ref.current)
      .append("svg")
      .attr("width", 300)
      .attr("height", 160)
      .append("g")

    const pie = d3
      .pie()
      .sort(null)
      .value(d => (allZeroValues ? 1 : d.value))

    const arc = d3
      .arc()
      .innerRadius(isDonut ? radius * 0.7 : 0)
      .outerRadius(radius)

    const outerArc = d3
      .arc()
      .outerRadius(isDonut ? radius * 1.3 + 5 : radius + 5)
      .innerRadius(isDonut ? radius * 0.7 + 5 : radius + 5)

    svg.attr("transform", `translate(${width / 2 + 100},${height / 2 + 10})`)

    svg.append("g").classed("slices", true)

    const slices = svg
      .select(".slices")
      .selectAll("path")
      .data(pie(allZeroValues ? [{ value: 1 }, { value: 1 }, { value: 1 }] : filteredData))
      .enter()
      .append("path")
      .attr("d", arc)
      .attr("fill", (d, i) => (allZeroValues ? data[i].color : filteredData[i].color))
      .attr("stroke", "white")
      .attr("stroke-width", 3)

    slices
      .transition()
      .attrTween("d", d => {
        const i = d3.interpolate(d.startAngle + 0.1, d.endAngle)
        return t => {
          d.endAngle = i(t)
          return arc(d)
        }
      })
      .duration(shouldAnimate ? 800 : 0)

    svg.append("g").classed("labels", true)
    svg.append("g").classed("lines", true)

    const midAngle = d => {
      return d.startAngle + (d.endAngle - d.startAngle) / 2
    }

    const startingPointScale = isDonut ? 1.05 : 1.8

    const polyline = svg
      .select(".lines")
      .selectAll("polyline")
      .data(pie(allZeroValues ? data : filteredData))
      .enter()
      .append("polyline")
      .attr("points", d => {
        const pos = outerArc.centroid(d)
        pos[0] = radius * 1.2 * 0.95 * (midAngle(d) < Math.PI ? 1 : -1)
        return [
          [arc.centroid(d)[0] * startingPointScale, arc.centroid(d)[1] * startingPointScale],
          outerArc.centroid(d),
          pos,
        ]
      })
      .attr("fill", "none")
      .attr("stroke", "#3C3835")
      .attr("opacity", 0)

    polyline
      .transition()
      .attr("opacity", 1)
      .duration(shouldAnimate ? 800 : 0)

    const label = svg
      .select(".labels")
      .selectAll("text")
      .data(pie(allZeroValues ? data : filteredData))
      .enter()
      .append("text")
      .attr("dy", ".35em")
      .html(d => {
        return d.data.value
      })
      .attr("transform", d => {
        const pos = outerArc.centroid(d)
        pos[0] =
          radius * 1.2 * 0.95 * (midAngle(d) < Math.PI ? 1 : -1) + (midAngle(d) < Math.PI ? 5 : -5)
        return `translate(${pos})`
      })
      .style("text-anchor", d => {
        return midAngle(d) < Math.PI ? "start" : "end"
      })
      .attr("fill", "#3C3835")
      .attr("opacity", 0)

    label
      .transition()
      .attr("opacity", 1)
      .duration(shouldAnimate ? 800 : 0)

    setTimeout(() => {
      setShouldAnimate(false)
    }, 800)
  }

  useEffect(() => {
    if (!loading && typeof data[0].value !== "undefined") {
      drawChart()
    }
  }, [loading, data[0].value, data[1].value, data[2].value])

  return (
    <div className="pie-chart-tab-wrapper">
      <div className="pie-chart-label-wrapper">
        {data.map(item => (
          <div className="pie-chart-label-item">
            <div className="pie-chart-label-icon" style={{ backgroundColor: item.color }} />
            <div className="pie-chart-label-text">{item.label}</div>
          </div>
        ))}
      </div>
      <div ref={ref} />
    </div>
  )
}

PieChart.propTypes = {
  data: PropTypes.instanceOf(Array),
  loading: PropTypes.bool,
  isDonut: PropTypes.bool,
}

PieChart.defaultProps = {
  data: [],
  loading: false,
  isDonut: false,
}
export default PieChart
