import "./DriversLimitations.scss"

import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import { COMPANY_DRIVER } from "../../../constants/userRoles"
import { updateDriverDetails } from "../../../redux/actions"
import { useOnClickOutside } from "../../../utils/hooks"
import SvgIcon from "../../atoms/SvgIcon"

function DriversLimitations({ duration, driverDetails, isDrive }) {
  const dispatch = useDispatch()
  const ref = useRef()
  const [openModal, setOpenModal] = useState(false)

  const [inputValue, setInputValue] = useState()
  const [formWarning, openFormWarning] = useState(false)

  const [userRole] = useSelector(({ user }) => [user.jwtData?.role])

  useOnClickOutside(ref, () => {
    setOpenModal(false)
    openFormWarning(false)
  })
  const getData = () => {
    let data = {}
    if (isDrive) {
      data = {
        driver_profile: {
          drive_limit: +inputValue,
        },
      }
    } else {
      data = {
        driver_profile: {
          duty_limit: +inputValue,
        },
      }
    }
    return data
  }
  const onSubmit = () => {
    if (inputValue >= 0 && inputValue !== " ") {
      const data = getData()

      setOpenModal(false)
      openFormWarning(false)
      dispatch(updateDriverDetails(driverDetails.id, data))
    } else {
      openFormWarning(true)
    }
  }
  useEffect(() => {
    setInputValue(duration)
  }, [duration])

  return (
    <div className="driverLimitationsCard">
      <div className="driverLimitationsCard__content">
        <p className="limit--title">{isDrive ? "Drive limit" : "Duty limit"}</p>
        <div className="limitation--icon" style={{ paddingTop: `${isDrive ? "30px" : "20px"}` }}>
          {isDrive ? (
            <SvgIcon width="38px" height="22" icon="gauge" />
          ) : (
            <SvgIcon width="35px" height="35" icon="round-clock" />
          )}
        </div>
        <p className="limit--duration">{`${duration || "0"} hrs`}</p>
      </div>
      {userRole !== COMPANY_DRIVER && (
        <div
          role="button"
          className="editButton"
          onClick={() => {
            setOpenModal(true)
          }}
        >
          <SvgIcon icon="pen-edit-medium" fill="#F5F5F5" />
        </div>
      )}
      {openModal && (
        <div className="driverLimitationsModal">
          <div className="driverLimitationsModal__content">
            <div className="driverLimitationsModal__content--innerContent" ref={ref}>
              <div className="exitButton">
                <div
                  role="button"
                  onClick={() => {
                    setOpenModal(false)
                  }}
                >
                  <SvgIcon width="10" height="10" icon="close-x-dark" />
                </div>
              </div>
              <div className="modalTitle">
                <p>{`Edit ${isDrive ? "drive" : "duty"} limit`}</p>
              </div>

              <div className="editInputWrapper">
                <p className="label">{`${isDrive ? "Drive" : "Duty"} limit`}</p>

                <div className="editInputBox">
                  <input
                    type="number"
                    value={inputValue}
                    onChange={e => {
                      if (e.target.value <= 24 && e.target.value > -1) {
                        setInputValue(e.target.value)
                      }
                    }}
                  />

                  <div className="spinButtons">
                    <div
                      className="buttonHolder"
                      role="button"
                      onClick={() => {
                        if (inputValue < 24) {
                          setInputValue(+inputValue + 1)
                        }
                      }}
                    >
                      <SvgIcon width="8" height="5" icon="chevron-up" />
                    </div>
                    <div
                      className="buttonHolder"
                      role="button"
                      onClick={() => {
                        if (inputValue > 0) {
                          setInputValue(+inputValue - 1)
                        }
                      }}
                    >
                      <SvgIcon width="8" height="5" icon="chevron-down" />
                    </div>
                  </div>
                </div>

                <p className="timeFormat">HRS</p>
              </div>
              {formWarning && <p className="formWarning">Enter a valid number</p>}
              <div className="editModalActions">
                <button className="btn-cancel" onClick={() => setOpenModal(false)}>
                  Cancel
                </button>
                <button className="btn-confirm" onClick={() => onSubmit()}>
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
DriversLimitations.propTypes = {
  duration: PropTypes.number,
  driverDetails: PropTypes.instanceOf(Object),
  isDrive: PropTypes.bool,
}

DriversLimitations.defaultProps = {
  duration: 0,
  driverDetails: {},
  isDrive: false,
}
export default DriversLimitations
