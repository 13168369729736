import "./VehiclesPage.scss"

import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"

import RegularLayout from "../../layouts/RegularLayout"
import CardSection from "../../molecules/CardSection"

const VehiclesPage = ({ history }) => {
  const vehiclesPageClass = classNames("vehicles-page-container")
  return (
    <RegularLayout history={history}>
      <div className={vehiclesPageClass}>
        <CardSection title="about" name="mister" />
        <CardSection title="title" name="mister" />
        <CardSection title="details" name="mister" />
      </div>
    </RegularLayout>
  )
}

VehiclesPage.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
}

VehiclesPage.defaultProps = {}

export default VehiclesPage
