import "./CardSection.scss"

import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"

import ItemSection from "../../atoms/ItemSection"
import LabelType from "../../atoms/LabelType"

const CardSection = ({ title, name, img, alt }) => {
  const cardSectionClass = classNames("card-section-container")
  return (
    <div className={cardSectionClass}>
      <h4>{title}</h4>
      <div className="wrap">
        <img src={img} alt={alt} />
        <div className="firstWrap">
          <div className="secondWrap">
            <p>{name}</p>
            <LabelType title="test" active />
          </div>
          <div className="thirdWrap">
            <ItemSection />
            <ItemSection />
            <ItemSection />
            <ItemSection />
          </div>
        </div>
      </div>
    </div>
  )
}

CardSection.propTypes = {
  title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired,
  alt: PropTypes.string,
}

CardSection.defaultProps = {
  alt: "",
}

export default CardSection
