import React from "react"

import SvgIcon from "../components/atoms/SvgIcon"

export const getRLOCIcon = details => {
  const way = { 1: "depart", 2: "return" }

  if (details.is_round_trip) {
    return <SvgIcon icon={`${way[details.way]}-rloc-arrows`} width="16" height="16" />
  }
  return <SvgIcon icon="oneway-rloc-arrows" width="16" height="16" />
}

export default { getRLOCIcon }
