import ACTIONS from "../../constants/ACTIONS"

const initialState = {}

export default function vehicleReducer(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.GET_COMPANY_VEHICLES_SUCCESS:
      if (action.offset) {
        return {
          ...state,
          // companyID: list of company vehicles
          [action.companyId]: {
            ...action.vehicles,
            results: [...state[action.companyId].results, ...action.vehicles.results],
          },
        }
      }

      return {
        ...state,
        [action.companyId]: action.vehicles,
      }

    case ACTIONS.GET_ALL_VEHICLES_SUCCESS:
      return {
        ...state,
        allVehicles: action.vehicles.results,
      }

    case ACTIONS.GET_VEHICLE_SUCCESS: {
      if (action.offset) {
        return {
          ...state,
          selectedCompanyVehicles: {
            ...action.vehicle,
            results: [...state.selectedCompanyVehicles.results, ...action.vehicle.results],
          },
        }
      }
      return {
        ...state,
        selectedCompanyVehicles: action.vehicle,
      }
    }

    default:
      return { ...state }
  }
}
