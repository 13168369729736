import "./Button.scss"

import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"

const Button = ({
  onClick,
  type,
  children,
  fullWidth,
  disabled,
  margin,
  buttonType,
  buttonSize,
}) => {
  const buttonClass = classNames("button-container", {
    [buttonType]: buttonType,
    [buttonSize]: buttonSize,
    fullWidth,
    disabled,
  })

  return (
    <div className={buttonClass} style={{ margin }}>
      <button disabled={disabled} type={type} onClick={onClick}>
        {children}
      </button>
    </div>
  )
}

Button.propTypes = {
  type: PropTypes.oneOf(["button", "submit"]),
  onClick: PropTypes.func.isRequired,
  children: PropTypes.string.isRequired,
  buttonType: PropTypes.oneOf([
    "regularColor",
    "primaryColor",
    "destructiveColor",
    "outlineColor",
    "landlineColor",
    "landlineColorDisabled",
    "grayColor",
    "approveColor",
    "denyColor",
    "videcomColor",
  ]),
  buttonSize: PropTypes.oneOf([
    "smallSize",
    "regularSize",
    "largeSize, modalButtonSize",
    "driverStatusModalSize",
    "driverStatusModalSize confirmation",
  ]),
  fullWidth: PropTypes.bool,
  margin: PropTypes.string,
  disabled: PropTypes.bool,
}

Button.defaultProps = {
  type: "buton",
  buttonType: "primaryColor",
  buttonSize: "regularSize",
  fullWidth: false,
  margin: "",
  disabled: false,
}

export default Button
