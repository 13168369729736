import "./SettingsPage.scss"

import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useParams } from "react-router-dom"

import { COMPANY_DRIVER, LANDLINE_AGENT } from "../../../constants/userRoles"
import { getKeyByValue } from "../../../utils/common"
import CurrentDate from "../../atoms/CurrentDate"
import NotificationButton from "../../atoms/NotificationButton"
import RegularLayout from "../../layouts/RegularLayout"
import TabLayout from "../../molecules/TabLayout"
import ChangeAppSettingsTab from "../../organisms/ChangeAppSettingsTab"
import ChangePasswordTab from "../../organisms/ChangePasswordTab"
import PersonalInformationTab from "../../organisms/PersonalInformationTab"

const SettingsPage = ({ history }) => {
  const { tab } = useParams()

  const [tabIndex, setTabIndex] = useState(0)
  const personalInformationTab = 0
  const changePasswordTab = 1
  const changeAppSettingsTab = 2

  const [userData] = useSelector(({ user }) => [user.jwtData])

  const tabs = {
    [personalInformationTab]: "",
    [changePasswordTab]: "password",
    [changeAppSettingsTab]: "app-settings",
  }

  useEffect(() => {
    if (!tab) {
      setTabIndex(personalInformationTab)
    } else {
      setTabIndex(+getKeyByValue(tabs, tab))
    }
  }, [tab])

  return (
    <RegularLayout withTopMenu={false} history={history} backgroundColor="#f5f5f5">
      <div className="settings-container">
        <div className="title-text">Settings</div>
        <div className="right-side-content">
          {userData?.role !== COMPANY_DRIVER && (
            <div className="notification-button">
              <NotificationButton onClick={() => history.push("/")} />
            </div>
          )}
          <div className="current-date">
            <CurrentDate />
          </div>
        </div>
      </div>
      <TabLayout
        tabTitleStyle="title-style"
        tabIndex={tabIndex}
        setTabIndex={index => {
          history.replace(`/settings${index !== 0 ? `/${tabs[index]}` : ""}`)
        }}
        titles={[
          "Personal Information",
          "Change Password",
          userData?.role === LANDLINE_AGENT && "Change App Settings",
        ]}
        contents={[
          <PersonalInformationTab />,
          <ChangePasswordTab />,
          userData?.role === LANDLINE_AGENT && <ChangeAppSettingsTab />,
        ]}
        className="tab-layout-gray-bg"
        tabContainerClassname="settings-tab-container"
      />
    </RegularLayout>
  )
}

SettingsPage.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
}

SettingsPage.defaultProps = {}

export default SettingsPage
