import "./OperatorsPage.scss"

import classNames from "classnames"
import PropTypes from "prop-types"
import React, { useEffect, useMemo, useState } from "react"
import InfiniteScroll from "react-infinite-scroller"
import { useDispatch, useSelector } from "react-redux"

import API from "../../../constants/API"
import { PAGE_LIMIT } from "../../../constants/offset"
import { COMPANY_DRIVER } from "../../../constants/userRoles"
import { getCompanies } from "../../../redux/company/companyActions"
import apiUtils from "../../../utils/api/api-utils"
import { useMounted } from "../../../utils/hooks"
import CompanyCard from "../../atoms/CompanyCard"
import CurrentDate from "../../atoms/CurrentDate"
import Loading from "../../atoms/Loading"
import NotificationButton from "../../atoms/NotificationButton"
import OperatorsEmptyState from "../../atoms/OperatorsEmptyState"
import TextInput from "../../atoms/TextInput"
import RegularLayout from "../../layouts/RegularLayout"

const OperatorsPage = ({ history }) => {
  const dispatch = useDispatch()
  const OperatorsPageClass = classNames("operators-page-container")
  const isMounted = useMounted()
  const [offset, setOffset] = useState(0)
  const [companiesLoading, setCompaniesLoading] = useState(true)

  const [search, setSearch] = useState("")

  useEffect(() => {
    if (isMounted) {
      const delayedCall = setTimeout(() => {
        dispatch(getCompanies({ name: search, is_landline: false }))
        setOffset(0)
      }, 500)

      return () => clearTimeout(delayedCall)
    }
    return undefined
  }, [search])

  useEffect(() => {
    const loadCompanies = async () => {
      await dispatch(getCompanies({ is_landline: false }))
      setCompaniesLoading(false)
    }
    loadCompanies()
  }, [])

  const [allCompanies, callsInProgress, userRole] = useSelector(({ company, api, user }) => [
    company.allCompanies,
    api.callsInProgress,
    user.jwtData?.role,
  ])

  const loading = useMemo(() => {
    return apiUtils.areCallsInProgress([API.GET_COMPANIES], callsInProgress)
  }, [callsInProgress])

  const loadMore = () => {
    if (!loading) {
      const currentOffset = offset + PAGE_LIMIT
      dispatch(getCompanies({ offset: currentOffset, is_landline: false }))
      setOffset(currentOffset)
    }
  }

  const onCompanyCardClick = id => {
    history.push(`/operators/${id}`)
  }

  return (
    <RegularLayout withTopMenu={false} history={history} backgroundColor="#ffffff">
      <div className={OperatorsPageClass}>
        <div className="operators-container">
          <div className="operators-text">Operators</div>
          <div className="right-side-content">
            {userRole !== COMPANY_DRIVER && (
              <div className="notification-button">
                <NotificationButton onClick={() => history.push("/")} />
              </div>
            )}
            <div className="current-date">
              <CurrentDate />
            </div>
          </div>
        </div>
      </div>
      <div className="input-container">
        <TextInput
          onChange={e => {
            setSearch(e.target.value)
          }}
          value={search}
          inputSize="search-size"
          inputStyle="search-style"
          icon="search"
          iconStyle="search-icon"
          placeholder="Search by name"
        />
      </div>
      {companiesLoading ? (
        <Loading className="loading-operators" />
      ) : (
        <>
          <InfiniteScroll
            loadMore={loadMore}
            hasMore={!!allCompanies?.next}
            pageStart={0}
            loader={<Loading className="loading-operators" />}
            threshold={100}
            useWindow
          >
            {allCompanies?.results.length > 0 ? (
              <div className="all-companies-container">
                {allCompanies.results.map(company => (
                  <CompanyCard role="button" company={company} onClick={onCompanyCardClick} />
                ))}
              </div>
            ) : (
              <OperatorsEmptyState
                afterSearch={search}
                searchedItems="companies"
                searchedField="name"
                withStyle
              />
            )}
          </InfiniteScroll>
        </>
      )}
    </RegularLayout>
  )
}

OperatorsPage.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
}

OperatorsPage.defaultProps = {}

export default OperatorsPage
