import "./DriversTab.scss"

import PropTypes from "prop-types"
import React, { useEffect, useMemo, useState } from "react"
import InfiniteScroll from "react-infinite-scroller"
import { useDispatch, useSelector } from "react-redux"

import API from "../../../constants/API"
import { PAGE_LIMIT } from "../../../constants/offset"
import { getDriver } from "../../../redux/actions"
import apiUtils from "../../../utils/api/api-utils"
import { useMounted } from "../../../utils/hooks"
import Loading from "../../atoms/Loading"
import OperatorsEmptyState from "../../atoms/OperatorsEmptyState"
import TextInput from "../../atoms/TextInput"
import UserCard from "../../atoms/UserCard"

const DriversTab = ({ history, company, companyDrivers, isLandline }) => {
  const dispatch = useDispatch()
  const isMounted = useMounted()

  const [search, setSearch] = useState("")
  const [offset, setOffset] = useState(0)

  const [callsInProgress] = useSelector(({ api }) => [api.callsInProgress])

  const loading = useMemo(() => {
    return apiUtils.areCallsInProgress([API.GET_DRIVER], callsInProgress)
  }, [callsInProgress])

  useEffect(() => {
    if (isLandline) {
      dispatch(getDriver({ company__is_landline: true }))
    } else {
      dispatch(getDriver({ company: company.id }))
    }
  }, [isLandline, company.id])

  const loadMore = () => {
    if (!loading) {
      const currentOffset = offset + PAGE_LIMIT
      dispatch(getDriver({ company: company.id, offset: currentOffset }))
      setOffset(currentOffset)
    }
  }

  useEffect(() => {
    if (isMounted) {
      const delayedCall = setTimeout(() => {
        dispatch(getDriver({ company: company.id, full_name: search }))
        setOffset(0)
      }, 500)

      return () => clearTimeout(delayedCall)
    }
    return undefined
  }, [search])

  return (
    <div>
      <div className="driver-input-container">
        <TextInput
          onChange={e => setSearch(e.target.value)}
          value={search}
          inputSize="search-size"
          inputStyle="search-style"
          icon="search"
          iconStyle="search-icon"
          placeholder="Search by name"
        />
      </div>
      {loading && !offset ? (
        <Loading className="loading-operators" />
      ) : (
        <InfiniteScroll
          loadMore={loadMore}
          hasMore={!!companyDrivers.next}
          pageStart={0}
          loader={<Loading className="loading-operators" />}
          threshold={100}
          useWindow
        >
          <>
            {companyDrivers.results && companyDrivers.results.length > 0 ? (
              companyDrivers.results.map(driver => (
                <UserCard
                  user={driver}
                  showDetails
                  history={history}
                  isLandline={isLandline}
                  companyId={company.id}
                />
              ))
            ) : (
              <OperatorsEmptyState
                afterSearch={!!search}
                searchedItems="drivers"
                searchedField="name"
              />
            )}
          </>
        </InfiniteScroll>
      )}
    </div>
  )
}

DriversTab.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
  company: PropTypes.instanceOf(Object),
  companyDrivers: PropTypes.instanceOf(Array),
  isLandline: PropTypes.bool,
}

DriversTab.defaultProps = {
  company: {},
  companyDrivers: [],
  isLandline: false,
}

export default DriversTab
