import "./SideBar.scss"

import classNames from "classnames"
import PropTypes from "prop-types"
import React, { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import {
  COMPANY_DRIVER,
  COMPANY_REPRESENTATIVE,
  LANDLINE_AGENT,
  LANDLINE_USER,
} from "../../../constants/userRoles"
import { getUserProfile, setSidebarState } from "../../../redux/actions"
import { logoutUser } from "../../../redux/user/userActions"
import SvgIcon from "../../atoms/SvgIcon"
import UserProfileName from "../../molecules/UserProfileName"
import SidebarMenu from "../SidebarMenu"

const SideBar = ({ history }) => {
  const dispatch = useDispatch()

  const [currentUser] = useSelector(({ user }) => [user])
  const [appData] = useSelector(({ app }) => [app])
  const [openCollapse, setOpenCollapse] = useState(appData.isSidebarCollapsed)

  const toggleCollapse = () => {
    setOpenCollapse(!openCollapse)
    dispatch(setSidebarState(!openCollapse))
  }

  const logout = () => {
    dispatch(logoutUser(history))
  }

  const sideBarClass = classNames("side-bar-container", { isCollapsed: openCollapse })
  const logoutClass = classNames("logout", { isCollapsed: openCollapse })

  const userRole = useMemo(() => {
    if (currentUser?.jwtData?.role) {
      switch (currentUser.jwtData.role) {
        case LANDLINE_USER:
          return "Landline user"
        case LANDLINE_AGENT:
          return "Landline agent"
        case COMPANY_REPRESENTATIVE:
          return "Company representative"
        case COMPANY_DRIVER:
          return "Company driver"
        default:
          return "User"
      }
    }
    return "User"
  }, [currentUser])

  useEffect(() => {
    dispatch(getUserProfile())
  }, [])

  return (
    <div className={sideBarClass}>
      <div className="collapse-sidebar-container" onClick={toggleCollapse} role="button">
        {currentUser.jwtData?.role === COMPANY_REPRESENTATIVE ? (
          <div className="collapse-sidebar-content-representative">
            <span className="sidebar-label">{currentUser.company_details?.name}</span>
            <SvgIcon
              margin="0 4px 0 0 "
              icon={openCollapse ? "double-right-arrows" : "double-left-arrows"}
              width="16"
              height="16"
              fill="#ffffff"
            />
          </div>
        ) : (
          <div className="collapse-sidebar-content">
            <SvgIcon
              margin="0 4px 0 0 "
              icon={openCollapse ? "double-right-arrows" : "double-left-arrows"}
              width="16"
              height="16"
              fill="#ffffff"
            />
            <span className="sidebar-label">Collapse sidebar</span>
          </div>
        )}
      </div>
      <UserProfileName
        firstName={currentUser.first_name}
        lastName={currentUser.last_name}
        description={userRole}
        image={currentUser.avatar}
        openCollapse={openCollapse}
      />
      <SidebarMenu
        openCollapse={openCollapse}
        history={history}
        isDriver={currentUser.jwtData?.role === COMPANY_DRIVER}
      />
      <div role="button" onClick={logout} className={logoutClass}>
        <SvgIcon icon="logout" width="19" height="18" fill="#ffffff" />
        {!openCollapse && <span className="logout-title">Log out</span>}
      </div>
    </div>
  )
}

SideBar.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
}

SideBar.defaultProps = {}

export default SideBar
