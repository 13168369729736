import "./Popup.scss"

import classNames from "classnames"
import PropTypes from "prop-types"
import React, { useRef } from "react"

import { useOnClickOutside } from "../../../utils/hooks"
import Button from "../Button"

const Popup = ({
  children,
  style,
  className,
  onConfirm,
  onCancel,
  confirmButtonDisabled,
  confirmButtonText,
  shouldCloseOnOverlayClick,
  overlayRef,
}) => {
  const ref = useRef()
  const popupClass = classNames(["popup-container", className])

  useOnClickOutside(overlayRef || ref, shouldCloseOnOverlayClick ? onCancel : () => {})

  return (
    <div className={popupClass} style={style} ref={overlayRef}>
      {children}
      <div className="buttons-container">
        <Button
          buttonSize="smallSize"
          buttonType="landlineColor"
          onClick={onConfirm}
          disabled={confirmButtonDisabled}
        >
          {confirmButtonText}
        </Button>
        <Button
          margin="0px 0px 0px 12px"
          buttonSize="smallSize"
          buttonType="grayColor"
          onClick={onCancel}
        >
          Cancel
        </Button>
      </div>
    </div>
  )
}

Popup.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  style: PropTypes.instanceOf(Object),
  className: PropTypes.string,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  confirmButtonDisabled: PropTypes.bool,
  confirmButtonText: PropTypes.string,
  shouldCloseOnOverlayClick: PropTypes.bool,
  overlayRef: PropTypes.instanceOf(Object),
}

Popup.defaultProps = {
  children: null,
  style: {},
  className: "",
  onCancel: () => {},
  onConfirm: () => {},
  confirmButtonDisabled: false,
  confirmButtonText: "Confirm",
  shouldCloseOnOverlayClick: false,
  overlayRef: null,
}

export default Popup
