import { landlineApi } from "./calls"

export type LandlineRouteDelayCode = {
  id: number
  created: string
  modified: string
  code: string
  name: string
  description: string
  controllable: boolean
}

export async function getDelayCodes() {
  try {
    const { data: codes } = await landlineApi.get<Array<LandlineRouteDelayCode>>(
      `v2/bossadmin/delay_codes/all/`,
    )

    return codes
  } catch (e) {
    console.error("Failed to retrieve delay codes", e)
    return []
  }
}
