import React, { useEffect, useState } from "react"
import { Box, Tab, Tabs, Text } from "grommet"

interface AnimatedTabsProps {
  tabs: Array<{
    title: string
    children: React.ReactNode
  }>
  initialTab: number
  onActive?: (index: number) => void
}

export function AnimatedTabs({ tabs, initialTab = 0, onActive }: AnimatedTabsProps) {
  const [activeTab, setActiveTab] = useState(initialTab)

  return (
    <Tabs
      onActive={index => {
        setActiveTab(index)
        onActive(index)
      }}
      flex="grow"
      activeIndex={activeTab}
    >
      {tabs.map(({ title, children }, i) => {
        return (
          <Tab
            key={`${title}-${i}`}
            title={
              <Box pad={{ vertical: "5px" }}>
                <Text size="small">{title}</Text>
              </Box>
            }
            style={{
              display: "flex",
              flexGrow: i === activeTab ? 1 : 0,
              padding: 0,
              alignItems: "center",
              borderBottom: "2px solid #FAB500",
              borderBottomColor: i === activeTab ? "#FAB500" : "#D9D9D9",
              transition: "all .6s",
            }}
          >
            {children}
          </Tab>
        )
      })}
    </Tabs>
  )
}
