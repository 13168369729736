import "./PasswordResetPage.scss"

import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { toast } from "react-toastify"

import { passwordReset } from "../../../redux/user/userActions"
import SvgIcon from "../../atoms/SvgIcon"

function PasswordResetPage({ match, history }) {
  const [showNewPassword, setShowNewPassword] = useState(false)
  const [showReEnterPassword, setShowReEnterPassword] = useState(false)

  const [newPassword, setNewPassword] = useState("")
  const [reEnterPassword, setReEnterPassword] = useState("")
  const [passwordError, setPasswordError] = useState(false)
  const dispatch = useDispatch()
  const [formSending] = useSelector(({ forms }) => [forms.formSending])
  const [currentUser] = useSelector(({ user }) => [user])
  const { code } = match.params

  useEffect(() => {
    const tokenId = localStorage.getItem("accessToken")
    if (currentUser.jwtData || tokenId) {
      toast.warning("You must log out first")
      history.push("/")
    }
  }, [])
  const handlePasswordReset = () => {
    if (newPassword === reEnterPassword) {
      const data = {
        new_password: newPassword,
        repeated_password: reEnterPassword,
        code,
      }
      dispatch(passwordReset(data, history))
    } else {
      setPasswordError(true)
    }
  }

  return (
    <div className="passwordResetPage">
      <div className="passwordResetPage--backgroundSplit">
        <div className="whiteBox" />
        <div className="yellowBox" />
      </div>
      <div className="passwordResetPage__content">
        <div className="passwordResetPage__content--innerContent">
          <SvgIcon icon="landline-gray-logo" width="200" height="33" margin="0px 0px 16px 0px" />
          <h4 className="pageTitle">Reset password</h4>

          <div className="passwordResetPage--form">
            <div className="input__wrapper">
              <p className="label">New password</p>
              <div className="inputBox">
                <input
                  type={showNewPassword ? "text" : "password"}
                  onChange={e => setNewPassword(e.target.value)}
                />
                <div
                  className="eyeToggle"
                  onClick={() => setShowNewPassword(!showNewPassword)}
                  role="button"
                >
                  <SvgIcon
                    className="showPassword"
                    icon={showNewPassword ? "eye-show" : "eye-hide"}
                  />
                </div>
              </div>
            </div>
            <div className="input__wrapper">
              <p className="label">Re-Enter Password</p>
              <div className="inputBox">
                <input
                  type={showReEnterPassword ? "text" : "password"}
                  onChange={e => setReEnterPassword(e.target.value)}
                />
                <div
                  className="eyeToggle"
                  onClick={() => setShowReEnterPassword(!showReEnterPassword)}
                  role="button"
                >
                  <SvgIcon
                    className="showPassword"
                    icon={showReEnterPassword ? "eye-show" : "eye-hide"}
                  />
                </div>
              </div>
            </div>
            {passwordError && (
              <div className="passwordError">New password and re-entered password must match</div>
            )}
            <button className="submitButton" onClick={handlePasswordReset}>
              {formSending ? "Loading..." : "Submit"}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
PasswordResetPage.propTypes = {
  match: PropTypes.instanceOf(Object),
  history: PropTypes.instanceOf(Object),
}

PasswordResetPage.defaultProps = {
  match: {},
  history: {},
}
export default PasswordResetPage
