import queryString from "query-string"

import ACTIONS from "../../constants/ACTIONS"
import API from "../../constants/API"
import { landlineApi } from "../../utils/api/calls"
import { apiError, startApiCall } from "../api/apiActions"

const getQuote = queryParams => {
  return async dispatch => {
    try {
      dispatch({ type: ACTIONS.GET_QUOTE_START })
      const urlQuery = queryString.stringify(queryParams)
      dispatch(startApiCall(API.GET_QUOTE))
      const response = await landlineApi.get(`quoting/quote/?${urlQuery}`)

      dispatch({ type: ACTIONS.GET_QUOTE_SUCCESS, quote: response.data })
    } catch (error) {
      dispatch(apiError(API.GET_QUOTE, error))
    }
  }
}

// eslint-disable-next-line
export { getQuote }
