import "./FormatedTableCell.scss"

import classNames from "classnames"
import PropTypes from "prop-types"
import React, { useRef, useState } from "react"

import { PRE_TRIP_NOTIFICATION_FORMAT } from "../../../constants/dateFormats"
import {
  NOTIFICATION_QUEUED,
  NOTIFICATION_REQUEUED,
  NOTIFICATION_STRINGS,
} from "../../../constants/preTripNotifications"
import { PLANNING_STATUS } from "../../../constants/status"
import { getLocalDateTime } from "../../../utils/common"
import { isFirefox } from "../../../utils/commonFunctionality"
import MixedPnrPopup from "../../molecules/MixedPnrPopup"
import RowItem from "../RowItem"
import SvgIcon from "../SvgIcon"

const FormatedTableCell = ({
  label,
  status,
  className,
  icon,
  bordered,
  style,
  image,
  mixedPnrLegs,
  customerNotified,
  notificationStatus,
  notificationDatetime,
}) => {
  const [showMixedPnrPopup, setShowMixedPnrPopup] = useState(false)
  const [shouldShowOnTop, setShouldShowOnTop] = useState(false)
  const ref = useRef()
  const [notificationPopupVisible, setNotificationPopupVisible] = useState(false)

  const formatedTableCellClass = classNames(`formatedTableCell-container ${className}`, {
    [PLANNING_STATUS[status]]: status,
    bordered,
  })

  const statusTextClass = classNames("status-text", NOTIFICATION_STRINGS[notificationStatus])

  return (
    <RowItem className={formatedTableCellClass} style={style}>
      <div
        className="formatedTableCell-inner-container"
        role="button"
        onMouseEnter={() => {
          if (mixedPnrLegs.length > 0) {
            setShowMixedPnrPopup(true)
            setShouldShowOnTop(
              window.innerHeight - ref.current.getBoundingClientRect().bottom <
                window.innerHeight / 2,
            )
          }
        }}
        onMouseLeave={() => {
          if (mixedPnrLegs.length > 0) setShowMixedPnrPopup(false)
        }}
        ref={ref}
      >
        {image}
        {icon}
        <span>{label}</span>

        {mixedPnrLegs.length > 0 && showMixedPnrPopup && (
          <MixedPnrPopup legs={mixedPnrLegs} shouldShowOnTop={shouldShowOnTop} />
        )}

        {customerNotified && (
          <div
            className="email-notified-container"
            onMouseEnter={() => setNotificationPopupVisible(true)}
            onMouseLeave={() => setNotificationPopupVisible(false)}
          >
            <div className={isFirefox ? "mozilla-bg" : ""}>
              <SvgIcon
                icon={`email-notified-${
                  notificationStatus === NOTIFICATION_REQUEUED
                    ? NOTIFICATION_QUEUED
                    : notificationStatus
                }`}
                width="26"
                height="26"
              />
            </div>

            {notificationPopupVisible && (
              <div className="email-notification-popup">
                <span className={statusTextClass}>{NOTIFICATION_STRINGS[notificationStatus]}</span>
                {` ${getLocalDateTime(notificationDatetime, PRE_TRIP_NOTIFICATION_FORMAT)}`}
              </div>
            )}
          </div>
        )}
      </div>
    </RowItem>
  )
}

FormatedTableCell.propTypes = {
  label: PropTypes.string,
  status: PropTypes.number,
  className: PropTypes.string,
  bordered: PropTypes.bool,
  icon: PropTypes.instanceOf(Element),
  style: PropTypes.instanceOf(Object),
  image: PropTypes.instanceOf(Element),
  mixedPnrLegs: PropTypes.instanceOf(Array),
  customerNotified: PropTypes.bool,
  notificationStatus: PropTypes.number,
  notificationDatetime: PropTypes.string,
}

FormatedTableCell.defaultProps = {
  label: "",
  status: null,
  className: "",
  bordered: false,
  icon: null,
  style: {},
  image: null,
  mixedPnrLegs: [],
  customerNotified: false,
  notificationStatus: -1,
  notificationDatetime: "",
}

export default FormatedTableCell
