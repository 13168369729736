import { createBrowserHistory } from "history"
import { applyMiddleware, combineReducers, compose, createStore } from "redux"
import { createReduxHistoryContext } from "redux-first-history"
import immutableCheckMiddleWare from "redux-immutable-state-invariant"
import { createLogger } from "redux-logger"
import thunk from "redux-thunk"

import addressAutocomplete from "./addressAutocomplete/addressAutocompleteReducer"
import agents from "./agents/agentsReducer"
import airport from "./airport/airportReducer"
import api from "./api/apiReducer"
import app from "./app/appReducer"
import cities from "./cities/citiesReducer"
import company from "./company/companyReducer"
import driver from "./driver/driverReducer"
import filter from "./filter/filterReducer"
import forms from "./forms/formsReducer"
import notes from "./notes/notesReducer"
import notifications from "./notifications/notificationsReducer"
import quote from "./quoting/quotingReducer"
import representative from "./representative/representativeReducer"
import trip from "./trip/tripReducer"
import user from "./user/userReducer"
import vehicle from "./vehicle/vehicleReducer"
import manifest from "./manifest/manifestReducer"


// NOTE(sb): plop reduxGen import redux reducer

const { createReduxHistory, routerMiddleware } = createReduxHistoryContext({
  history: createBrowserHistory(),
})
const appReducers = combineReducers({
  user,
  api,
  forms,
  trip,
  driver,
  vehicle,
  notes,
  agents,
  company,
  notifications,
  app,
  airport,
  manifest,
  representative,
  cities,
  filter,
  quote,
  addressAutocomplete,
  // NOTE(sb): plop reduxGen implement redux reducer
})

const rootReducer = (state, action) => {
  return appReducers(state, action)
}

const middleware = [thunk, routerMiddleware]

const composeEnhancer =
  (process.env.NODE_ENV !== "production" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose

if (process.env.NODE_ENV === "development") {
  middleware.push(immutableCheckMiddleWare())
}

const loggerMiddleware = createLogger({
  predicate: () => process.env.NODE_ENV === "development",
  duration: true,
})

middleware.push(loggerMiddleware)

export const store = createStore(rootReducer, {}, composeEnhancer(applyMiddleware(...middleware)))
export const history = createReduxHistory(store)
store.subscribe(() => store.getState())
