import "./NavigationDetails.scss"

import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"

import SvgIcon from "../SvgIcon"

const NavigationDetails = ({ paths }) => {
  return (
    <div className="navigation-details-container">
      {paths.map((path, index) => {
        const isFirst = index === 0
        const isLast = index === paths.length - 1
        const pathStringClass = classNames("path-string", { isLast, clickable: path.onClick })

        return (
          <>
            {!isFirst && (
              <SvgIcon
                icon="right-arrow"
                width="24"
                height="24"
                fill={isLast ? "#3c3835" : "#999999"}
              />
            )}
            <div role="button" onClick={path.onClick} className={pathStringClass}>
              {path.name}
            </div>
          </>
        )
      })}
    </div>
  )
}

NavigationDetails.propTypes = {
  paths: PropTypes.instanceOf(Array),
}

NavigationDetails.defaultProps = {
  paths: [],
}

export default NavigationDetails
