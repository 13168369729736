export const FLIGHT_TYPE = 1
export const BUS_TYPE = 2
export const BLACK_CAR_TYPE = 3
export const EXPRESS_TYPE = 4
export const EXPRESS_HOME_TYPE = 5

export const TRANSPORTATION_TYPE = {
  [BUS_TYPE]: "Bus",
  [BLACK_CAR_TYPE]: "SUV",
  [EXPRESS_TYPE]: "Express",
}
export const TRANSPORTATION_TYPE_LIST = ["Bus", "SUV", "Express"]
export const TRANSPORTATION_TYPE_OFFSET = 2
