import "./NotificationItem.scss"

import classNames from "classnames"
import moment from "moment"
import PropTypes from "prop-types"
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router"

import { TWELVE_HOUR_WITH_LEADING_ZERO } from "../../../constants/dateFormats"
import { NOTIFICATION_CODES } from "../../../constants/notificationCodes"
import { ROUTES } from "../../../constants/routes"
import {
  getNewTripRequests,
  getSuggestedTrips,
  getTrip,
  getTripRequests,
  getTripSuggestions,
  pushTripToTop,
} from "../../../redux/actions"
import {
  removeSingleNotification,
  userOpenNotification,
} from "../../../redux/notifications/notificationsActions"
import { isFirefox, redirectToPlanning } from "../../../utils/commonFunctionality"
import SvgIcon from "../SvgIcon"

const NotificationItem = ({
  notificationItem,
  index,
  changeExcludeId,
  resetTripRequestsTab,
  resetTripSuggestionsTab,
  tripRequestsScrollRef,
  newRequestsScrollRef,
  pendingSuggestionsScrollRef,
  tripSuggestionsScrollRef,
  tripRequestsShouldReset,
  tripSuggestionsShouldReset,
}) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [trips] = useSelector(({ trip }) => [trip])

  const highlightDashboardNotifications = (table, getTrips, scrollRef, tableShouldReset) => {
    const id = notificationItem?.related_object?.id
    const tableTrips = trips[table].results

    const tripIndex = tableTrips.findIndex(trip => trip.id === id)

    if (tripIndex === 0) {
      return
    }

    if (tripIndex === -1 || tableShouldReset) {
      getTrips(id)

      changeExcludeId({ [table]: id })
      return
    }

    if (scrollRef.current) {
      scrollRef.current.scrollTo(0, 0)
    }
    dispatch(pushTripToTop(tripIndex, table))
  }

  const handleRedirectToPlanning = dateTime => {
    redirectToPlanning(history, { departure_time__gte: dateTime })
  }

  const getRelatedObjectId = () => {
    if (
      notificationItem.notification_type === NOTIFICATION_CODES.NEW_INTERNAL_NOTE ||
      notificationItem.notification_type === NOTIFICATION_CODES.NEW_PUBLIC_NOTE
    ) {
      if (notificationItem.related_object.trip_details?.note_leg?.id) {
        return notificationItem.related_object.trip_details.note_leg.id
      }

      return notificationItem.related_object.trip_details.id
    }

    return notificationItem.related_object.id
  }

  const allNotifications = {
    [NOTIFICATION_CODES.MISSING_INFO]: {
      iconId: "missing-info",
      label: "Missing info",
      description: "Add necessary information for this booking",
      route: ROUTES.DASHBOARD_PAGE, // open in right sidebar
      tableToHighlight: "planning",
      callback: () => {
        dispatch(
          getTrip({
            tripID: getRelatedObjectId(),
            externalTripDetailsFetch: true,
            callback: () => {
              handleRedirectToPlanning(notificationItem?.related_object?.departure_time)
            },
          }),
        )
      },
      showEditModal: true,
    },

    [NOTIFICATION_CODES.NEW_TRIP_REQUEST]: {
      iconId: "new-trip-request",
      label: "New trip request",
      description: `${notificationItem?.related_object?.company?.name} operator made new trip request`,
      route: ROUTES.DASHBOARD_PAGE, // mark trip request
      tableToHighlight: "newTripRequests",
      callback: () =>
        highlightDashboardNotifications(
          "NewTrips",
          id => {
            dispatch(getNewTripRequests(null, id, id))
          },
          newRequestsScrollRef,
        ),
    },
    [NOTIFICATION_CODES.NEW_TRIP_SUGGESTION]: {
      iconId: "new-trip-suggestion",
      label: "New trip suggestion",
      description: "You have new trip suggestion",
      route: ROUTES.DASHBOARD_PAGE, // mark in trip suggestions tab
      tableToHighlight: "newTripSuggestions",
      callback: () =>
        highlightDashboardNotifications(
          "PendingTripSuggestions",
          id => {
            dispatch(getTripSuggestions(null, id, id))
          },
          pendingSuggestionsScrollRef,
        ),
    },
    [NOTIFICATION_CODES.APPROVED_TRIP_REQUEST]: {
      iconId: "approved-trip-request",
      label: "Approved trip request",
      description: "Your trip request was approved",
      route: ROUTES.DASHBOARD_PAGE, // mark trip approved
      tableToHighlight: "tripRequests",
      callback: () =>
        highlightDashboardNotifications(
          "TripRequests",
          id => {
            resetTripRequestsTab()
            dispatch(getTripRequests(null, null, id, id))
          },
          tripRequestsScrollRef,
          tripRequestsShouldReset,
        ),
    },
    [NOTIFICATION_CODES.DENIED_TRIP]: {
      iconId: "denied-trip",
      label: "Denied trip request",
      description: "Your trip request was denied",
      route: ROUTES.DASHBOARD_PAGE, // mark trip request
      tableToHighlight: "tripRequests",
      callback: () =>
        highlightDashboardNotifications(
          "TripRequests",
          id => {
            resetTripRequestsTab()
            dispatch(getTripRequests(null, null, id, id))
          },
          tripRequestsScrollRef,
          tripRequestsShouldReset,
        ),
    },
    [NOTIFICATION_CODES.ACCEPTED_TRIP_SUGGESTION]: {
      iconId: "accepted-trip-suggestion",
      label: "Accepted trip suggestion",
      description: `${notificationItem?.related_object?.company_details?.name} operator accepted trip suggestion`,
      route: ROUTES.DASHBOARD_PAGE, // mark in trip suggestions tab
      tableToHighlight: "tripSuggestions",
      callback: () =>
        highlightDashboardNotifications(
          "TripSuggestions",
          id => {
            resetTripSuggestionsTab()
            dispatch(getSuggestedTrips(null, null, id, id))
          },
          tripSuggestionsScrollRef,
          tripSuggestionsShouldReset,
        ),
    },
    [NOTIFICATION_CODES.DENIED_TRIP_SUGGESTION]: {
      iconId: "denied-trip",
      label: "Denied trip suggestion",
      description: "Your trip suggestion was denied",
      route: ROUTES.DASHBOARD_PAGE, // mark in trip suggestions tab
      tableToHighlight: "tripSuggestions",
      callback: () =>
        highlightDashboardNotifications(
          "TripSuggestions",
          id => {
            resetTripSuggestionsTab()
            dispatch(getSuggestedTrips(null, null, id, id))
          },
          tripSuggestionsScrollRef,
          tripSuggestionsShouldReset,
        ),
    },
    [NOTIFICATION_CODES.NEW_PUBLIC_NOTE]: {
      iconId: "new-public-note",
      label: "New public note",
      description: `${notificationItem?.related_object?.user_details?.first_name} ${notificationItem?.related_object?.user_details?.last_name} left note for you`,
      route: ROUTES.DASHBOARD_PAGE, // open in right sidebar
      tableToHighlight: "planning",
      callback: () => {
        dispatch(
          getTrip({
            tripID: getRelatedObjectId(),
            externalTripDetailsFetch: true,
            callback: () => {
              handleRedirectToPlanning(
                notificationItem?.related_object?.trip_details?.departure_time,
              )
            },
          }),
        )
      },
      showEditModal: true,
      showPublicNote: true,
      noteId: notificationItem?.related_object?.id,
    },
    [NOTIFICATION_CODES.NEW_INTERNAL_NOTE]: {
      iconId: "new-internal-note",
      label: "New internal note",
      description: `${notificationItem?.related_object?.user_details?.first_name} ${notificationItem?.related_object?.user_details?.last_name} left note for you`,
      route: ROUTES.DASHBOARD_PAGE, // open in right sidebar
      tableToHighlight: "planning",
      callback: () => {
        dispatch(
          getTrip({
            tripID: getRelatedObjectId(),
            externalTripDetailsFetch: true,
            callback: () => {
              handleRedirectToPlanning(
                notificationItem?.related_object?.trip_details?.departure_time ||
                  notificationItem?.related_object?.trip_details?.note_leg?.departure_time,
              )
            },
          }),
        )
      },
      showEditModal: true,
      showInternalNote: true,
      noteId: notificationItem?.related_object?.id,
    },
    [NOTIFICATION_CODES.CANCEL_TRIP]: {
      iconId: "cancelled-trip",
      label: "Cancelled trip",
      description: `Trip ${notificationItem?.related_object?.pnr} has been cancelled`,
      route: ROUTES.DASHBOARD_PAGE, // mark row
      tableToHighlight: "planning",
      callback: () => {
        dispatch(
          getTrip({
            tripID: getRelatedObjectId(),
            externalTripDetailsFetch: true,
            callback: () => {
              handleRedirectToPlanning(notificationItem?.related_object?.departure_time)
            },
          }),
        )
      },
      showEditModal: true,
    },
    [NOTIFICATION_CODES.ASSIGNED_TRIP]: {
      iconId: "assigned-trip",
      label: "New assigned trip",
      description: `Trip ${notificationItem?.related_object?.pnr} has been assigned`,
      route: ROUTES.DASHBOARD_PAGE, // mark row
      tableToHighlight: "planning",
      callback: () => {
        dispatch(
          getTrip({
            tripID: getRelatedObjectId(),
            externalTripDetailsFetch: true,
            callback: () => {
              handleRedirectToPlanning(notificationItem?.related_object?.departure_time)
            },
          }),
        )
      },
      showEditModal: true,
    },
    [NOTIFICATION_CODES.UNASSIGNED_TRIP]: {
      iconId: "unassigned-trip",
      label: "Unassigned trip",
      description: `Trip ${notificationItem?.related_object?.pnr} has been unassigned`,
      route: ROUTES.DASHBOARD_PAGE, // mark row
      tableToHighlight: "planning",
      callback: () => {
        dispatch(
          getTrip({
            tripID: getRelatedObjectId(),
            externalTripDetailsFetch: true,
            callback: () => {
              handleRedirectToPlanning(notificationItem?.related_object?.departure_time)
            },
          }),
        )
      },
      showEditModal: true,
    },
    [NOTIFICATION_CODES.INVALID_PICK_UP_ADDRESS]: {
      iconId: "invalid-address-icon",
      label: "Invalid pick-up address",
      description: `The trip has not been ${
        notificationItem.data.POST ? "imported" : "updated"
      } to Samsara`,
      route: ROUTES.DASHBOARD_PAGE, // mark row
      tableToHighlight: "planning",
      callback: () => {
        dispatch(
          getTrip({
            tripID: getRelatedObjectId(),
            externalTripDetailsFetch: true,
            callback: () => {
              handleRedirectToPlanning(notificationItem?.related_object?.departure_time)
            },
          }),
        )
      },
      showEditModal: true,
      showPickUp: true,
    },
    [NOTIFICATION_CODES.INVALID_DROP_OFF_ADDRESS]: {
      iconId: "invalid-address-icon",
      label: "Invalid drop-off address",
      description: `The trip has not been ${
        notificationItem.data.POST ? "imported" : "updated"
      } to Samsara`,
      route: ROUTES.DASHBOARD_PAGE, // mark row
      tableToHighlight: "planning",
      callback: () => {
        dispatch(
          getTrip({
            tripID: getRelatedObjectId(),
            externalTripDetailsFetch: true,
            callback: () => {
              handleRedirectToPlanning(notificationItem?.related_object?.departure_time)
            },
          }),
        )
      },
      showEditModal: true,
      showDropOff: true,
    },
    [NOTIFICATION_CODES.D2D_ADDRESS_UPDATED]: {
      iconId: "d2d-address-updated",
      label: "D2D Address updated",
      description: `Address for trip ${notificationItem?.related_object?.pnr} has been updated`,
      route: ROUTES.DASHBOARD_PAGE, // mark row
      tableToHighlight: "planning",
      callback: () => {
        dispatch(
          getTrip({
            tripID: getRelatedObjectId(),
            externalTripDetailsFetch: true,
            callback: () => {
              handleRedirectToPlanning(notificationItem?.related_object?.departure_time)
            },
          }),
        )
      },
      showEditModal: true,
    },
  }

  const {
    iconId,
    label,
    description,
    route,
    callback,
    showEditModal,
    showInternalNote,
    showPublicNote,
    noteId,
    tableToHighlight,
    showPickUp,
    showDropOff,
  } = allNotifications[notificationItem.notification_type]
  const notificationItemClass = classNames("notification-item", { unread: notificationItem.unread })

  const oneDayInMiliseconds = 86400000
  const oneHourInMiliseconds = 3600000
  const oneMinInMiliseconds = 60000
  const creationTime = moment.utc(notificationItem.created)
  const currentTime = moment.utc(new Date())
  const duration = currentTime.diff(creationTime)
  // initial formatting, notification creation time
  let created = moment(notificationItem.created).format("MMM DD")
  // if less than 1 day old
  if (duration <= oneDayInMiliseconds) {
    created = `${Math.trunc(moment.duration(duration).asHours())}h ago`
  }
  // if less than 1 hour old
  if (duration <= oneHourInMiliseconds) {
    created = `${moment(duration).format("mm")}min ago`
  }
  // if less than 1 minute old
  if (duration <= oneMinInMiliseconds) {
    created = "Now"
  }

  const dispatchNotification = () => {
    if (!notificationItem.unread) {
      return
    }
    history.push(route)

    if (callback) {
      callback()
    }

    dispatch(
      userOpenNotification({
        notificationType: notificationItem.notification_type,
        relatedObjectId: getRelatedObjectId(),
        index,
        notificationId: notificationItem.id,
        showEditModal,
        showInternalNote,
        showPublicNote,
        noteId,
        tableToHighlight,
        showPickUp,
        showDropOff,
      }),
    )
  }

  const getNotificationField = (notification, field) => {
    if (notification?.related_object?.[field]) {
      return notification.related_object[field]
    }

    if (notification?.related_object?.trip_details?.[field]) {
      return notification.related_object.trip_details[field]
    }

    if (notification?.related_object?.trip_details?.note_leg?.[field]) {
      return notification?.related_object?.trip_details?.note_leg[field]
    }

    return undefined
  }

  return (
    <div role="button" onClick={dispatchNotification}>
      <div className={notificationItemClass}>
        <div className="dot-wrapper">
          {notificationItem?.unread && <SvgIcon width={6} height={6} icon="small-dot" />}
        </div>
        <div className={isFirefox ? "mozilla-bg" : ""}>
          <SvgIcon width={46} height={46} fill="none" icon={iconId} />
        </div>

        <div
          className="close-icon-wrapper"
          role="button"
          onClick={e => {
            e.stopPropagation()
            dispatch(removeSingleNotification(notificationItem.id))
          }}
        >
          <SvgIcon icon="close" width="20" height="20" />
        </div>
        <div className="info">
          <h5 className="label">{label}</h5>
          <div className="description">{description}</div>
          <div>
            <span className="pnr">PNR: {getNotificationField(notificationItem, "pnr")}</span>
            <SvgIcon className="clock" width={14} height={14} icon="clock" fill="#2278B5" />
            <span className="time">
              {moment(getNotificationField(notificationItem, "departure_time")).format(
                TWELVE_HOUR_WITH_LEADING_ZERO,
              )}
            </span>
            <SvgIcon className="clock" width={14} height={14} icon="calendar" fill="#2278B5" />
            <span className="pickup-date">
              {moment(getNotificationField(notificationItem, "departure_time")).format("MMM DD")}
            </span>
          </div>
          <div className="created-date">Created: {created} </div>
        </div>
      </div>
    </div>
  )
}

NotificationItem.propTypes = {
  notificationItem: PropTypes.instanceOf(Object).isRequired,
  index: PropTypes.number,
  changeExcludeId: PropTypes.func,
  resetTripRequestsTab: PropTypes.func,
  resetTripSuggestionsTab: PropTypes.func,
  tripRequestsScrollRef: PropTypes.instanceOf(Element),
  newRequestsScrollRef: PropTypes.instanceOf(Element),
  pendingSuggestionsScrollRef: PropTypes.instanceOf(Element),
  tripSuggestionsScrollRef: PropTypes.instanceOf(Element),
  tripRequestsShouldReset: PropTypes.bool,
  tripSuggestionsShouldReset: PropTypes.bool,
}

NotificationItem.defaultProps = {
  index: 0,
  changeExcludeId: () => {},
  resetTripRequestsTab: () => {},
  resetTripSuggestionsTab: () => {},
  tripRequestsScrollRef: null,
  newRequestsScrollRef: null,
  pendingSuggestionsScrollRef: null,
  tripSuggestionsScrollRef: null,
  tripRequestsShouldReset: false,
  tripSuggestionsShouldReset: false,
}
export default NotificationItem
