import ACTIONS from "../../constants/ACTIONS"

const initialState = {}

export default function driverReducer(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.GET_ALL_AIPORTS_SUCCESS:
      return {
        ...state,
        allAirports: action.airports,
      }

    default:
      return { ...state }
  }
}
