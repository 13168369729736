import "./LabelType.scss"

import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"

const LabelType = ({ title, active }) => {
  const labelTypeClass = classNames("label-type-container", { activeClass: active })
  return <div className={labelTypeClass}>{title}</div>
}

LabelType.propTypes = {
  title: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
}

LabelType.defaultProps = {}

export default LabelType
