import "./PersonalInformationTab.scss"

import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import { getUserDetails, updateUserDetails } from "../../../redux/actions"
import { getFirstLetter } from "../../../utils/common"
import Button from "../../atoms/Button"
import Dropdown from "../../atoms/Dropdown"
import SvgIcon from "../../atoms/SvgIcon"
import TextInput from "../../atoms/TextInput"
import Modal from "../Modal"

const PersonalInformationTab = () => {
  const dispatch = useDispatch()
  const [userData] = useSelector(({ user }) => [user])
  const { userDetails, jwtData } = userData
  const [changePhotoModalOpen, setChangePhotoModalOpen] = useState(false)
  const [removePhotoModalOpen, setRemovePhotoModalOpen] = useState(false)
  const [picture, setPicture] = useState(null)

  const handlePictureChange = e => {
    const file = e.target.files[0]
    if (file) {
      setPicture(file)
    }
  }

  useEffect(() => {
    if (userData.jwtData && userData.id) {
      dispatch(getUserDetails(jwtData?.role, userData.id))
    }
  }, [jwtData, userData.id])

  const [newUserDetails, setNewUserDetails] = useState(userDetails)
  const changeUserDetails = params => {
    setNewUserDetails(prevState => ({ ...prevState, ...params }))
  }

  const getFormData = () => {
    const formData = new FormData()
    formData.append("avatar", picture)

    return formData
  }

  useEffect(() => {
    setNewUserDetails(userDetails)
  }, [userDetails])

  const genderItems = [
    { value: 1, name: "Male" },
    { value: 2, name: "Female" },
    { value: 3, name: "Non-binary" },
  ]

  const fileInputRef = useRef(null)
  const imagePreview = useRef(null)

  function previewPicture() {
    const file = fileInputRef.current.files[0]
    const reader = new FileReader()
    const preview = imagePreview?.current

    reader.addEventListener(
      "load",
      () => {
        // convert image file to base64 string
        preview.src = reader.result
      },
      false,
    )

    if (file) {
      reader.readAsDataURL(file)
    }
  }

  useEffect(() => {
    if (picture) {
      previewPicture()
    }
  }, [picture])

  return (
    <div className="personal-information-container">
      <div className="image-upload-container">
        {userDetails.avatar ? (
          <img
            className="driver-image"
            src={userDetails.avatar}
            alt="driver"
            width="128"
            height="128"
          />
        ) : (
          <div className="defaultDriverAvatar">
            <div>{`${getFirstLetter(userDetails.first_name)}${getFirstLetter(
              userDetails.last_name,
            )}`}</div>
          </div>
        )}

        <div className="image-upload-text-container">
          <div className="upload-photo-text">Upload a new photo</div>
          <div className="upload-additional-info">Allowed JPG or PNG. Max size of 800kB</div>
          <div className="image-buttons-container">
            <Button
              buttonType="landlineColor"
              buttonSize="settings-size"
              margin="0 16px 0 0"
              onClick={() => setChangePhotoModalOpen(true)}
            >
              Update
            </Button>
            <Button
              buttonType="grayColor"
              buttonSize="settings-size"
              onClick={() => setRemovePhotoModalOpen(true)}
            >
              Remove
            </Button>
          </div>
        </div>
      </div>
      <div className="input-row">
        <TextInput
          inputStyle="settings-style no-margin"
          value={newUserDetails.first_name}
          onChange={e => changeUserDetails({ first_name: e.target.value })}
          label="First name"
          fullWidth
        />

        <TextInput
          inputStyle="settings-style"
          value={newUserDetails.last_name}
          onChange={e => changeUserDetails({ last_name: e.target.value })}
          label="Last name"
          fullWidth
        />

        <div className="dropdown-wrapper">
          <Dropdown
            value={genderItems.find(item => item.value === +newUserDetails.gender)?.name}
            label="Gender"
            showItemField="name"
            className="settings-style"
            margin="8px 0px 4px 0px"
            items={genderItems}
            onClick={item => {
              changeUserDetails({ gender: item.value })
            }}
            paddingLeft
          />
        </div>
      </div>

      <div className="input-row">
        <TextInput
          inputStyle="settings-style no-margin"
          value={newUserDetails.phone_number}
          onChange={e => changeUserDetails({ phone_number: e.target.value })}
          label="Phone"
          fullWidth
        />

        <TextInput
          inputStyle="settings-style disabled"
          value={newUserDetails.email}
          disabled
          label="Email"
          fullWidth
        />
        <div className="dropdown-wrapper" />
      </div>

      <div className="buttons-wrapper">
        <Button
          buttonType="grayColor"
          buttonSize="settings-size"
          margin="0 16px 0 0"
          onClick={() => setNewUserDetails(userDetails)}
        >
          Cancel
        </Button>
        <Button
          buttonType="landlineColor"
          buttonSize="settings-size"
          onClick={() => {
            const data = newUserDetails
            delete data.avatar
            dispatch(updateUserDetails(jwtData?.role, userData.id, data))
          }}
        >
          Save changes
        </Button>
      </div>

      <Modal
        shouldCloseOnOverlayClick
        onRequestClose={() => {
          setChangePhotoModalOpen(false)
          setPicture(null)
        }}
        render={() => (
          <div>
            <div className="photo-modal">
              <div
                role="button"
                onClick={() => {
                  setPicture(null)
                  setChangePhotoModalOpen(false)
                }}
              >
                <SvgIcon icon="x" className="close-button-container" />
              </div>
              {picture ? (
                <img
                  className="driver-image"
                  src={newUserDetails.avatar}
                  alt="driver"
                  width="128"
                  height="128"
                  ref={imagePreview}
                />
              ) : (
                <>
                  {userDetails.avatar ? (
                    <img
                      className="driver-image"
                      src={userDetails.avatar}
                      alt="driver"
                      width="128"
                      height="128"
                    />
                  ) : (
                    <div className="defaultDriverAvatar">
                      <div>{`${getFirstLetter(userDetails.first_name)}${getFirstLetter(
                        userDetails.last_name,
                      )}`}</div>
                    </div>
                  )}
                </>
              )}

              <div className="change-profile-photo-text">Change profile photo</div>

              <input
                ref={fileInputRef}
                type="file"
                accept="image/png, image/jpeg"
                onChange={e => {
                  handlePictureChange(e)
                }}
                id="picture"
                className="input-file"
              />
              <label className="upload-user-image" htmlFor="picture">
                Upload image
              </label>

              <div className="modal-buttons-wrapper">
                <Button
                  buttonType="grayColor"
                  buttonSize="settings-size"
                  margin="0 16px 0 0"
                  onClick={() => {
                    setPicture(null)
                    setChangePhotoModalOpen(false)
                  }}
                >
                  Cancel
                </Button>
                <Button
                  buttonType="landlineColor"
                  buttonSize="settings-size"
                  onClick={() => {
                    dispatch(updateUserDetails(jwtData?.role, userData.id, getFormData()))
                    setPicture(null)
                    setChangePhotoModalOpen(false)
                  }}
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
        )}
        isOpen={changePhotoModalOpen}
        style={{
          overlay: {
            position: "fixed",
            display: "flex",
            backgroundColor: "rgba(0, 0, 0, 0.2)",
            zIndex: 99999,
            overflow: "none",
          },
          content: {
            width: 392,
            height: 412,
            display: "flex",
            overflow: "none",
            boxShadow: "0px 0px 16px 4px rgba(66, 64, 62, 0.12)",
            borderRadius: "4px",
            border: "none",
            alignItems: "center",
            padding: 0,
          },
        }}
      />

      <Modal
        shouldCloseOnOverlayClick
        onRequestClose={() => {
          setRemovePhotoModalOpen(false)
        }}
        render={() => (
          <div className="photo-modal remove">
            <div
              role="button"
              onClick={() => {
                setPicture(null)
                setRemovePhotoModalOpen(false)
              }}
            >
              <SvgIcon icon="x" className="close-button-container" />
            </div>
            <SvgIcon icon="exclamation-mark" width="48" height="48" />

            <div className="remove-photo-text">Are you sure you want to remove profile photo?</div>
            <div className="modal-buttons-wrapper">
              <Button
                buttonType="grayColor"
                buttonSize="settings-size"
                margin="0 16px 0 0"
                onClick={() => {
                  setRemovePhotoModalOpen(false)
                }}
              >
                Cancel
              </Button>
              <Button
                buttonType="landlineColor"
                buttonSize="settings-size"
                onClick={() => {
                  dispatch(updateUserDetails(jwtData?.role, userData.id, { avatar: null }))
                  setRemovePhotoModalOpen(false)
                }}
              >
                Remove
              </Button>
            </div>
          </div>
        )}
        isOpen={removePhotoModalOpen}
        style={{
          overlay: {
            position: "fixed",
            display: "flex",
            backgroundColor: "rgba(0, 0, 0, 0.2)",
            zIndex: 99999,
            overflow: "none",
          },
          content: {
            width: 392,
            height: 314,
            display: "flex",
            overflow: "none",
            boxShadow: "0px 0px 16px 4px rgba(66, 64, 62, 0.12)",
            borderRadius: "4px",
            border: "none",
            alignItems: "center",
            padding: 0,
          },
        }}
      />
    </div>
  )
}

PersonalInformationTab.propTypes = {}

PersonalInformationTab.defaultProps = {}

export default PersonalInformationTab
