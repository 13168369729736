import { landlineApi } from "./calls"

export type LandlineAirport = {
  iata: string
  time_zone: string
}

export async function getAirports() {
  try {
    const { data } = await landlineApi.get<Array<LandlineAirport>>(`v2/bossadmin/airports/`)

    return data
  } catch (e) {
    console.error("Failed to retrieve airports", e)
    return []
  }
}
