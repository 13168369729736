import PropTypes from "prop-types"
import React, { useCallback, useContext, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Redirect, Route, RouteProps } from "react-router"
import { toast } from "react-toastify"

import { COMPANY_DRIVER } from "../../../constants/userRoles"
import { decodeJWT } from "../../../redux/actions"
import { getPermissions } from "../../../utils/api/getPermissions"
import { PermissionContext } from "../../contexts/PermissionContext"

interface AuthenticatedRouteProps extends RouteProps {
  requiresPermission?: string
}

type ReduxState = {
  user: {
    jwtData?: {
      role: number
    }
  }
}

const AuthenticatedRoute = ({
  exact,
  path,
  component,
  requiresPermission,
}: AuthenticatedRouteProps) => {
  const dispatch = useDispatch()

  const [userRole] = useSelector<ReduxState, Array<number>>(({ user }) => [user.jwtData?.role])

  const checkToken = () => {
    const accessToken = localStorage.getItem("accessToken")
    if (!accessToken && JSON.parse(sessionStorage.getItem("showLoginMessage")) && path !== "/") {
      toast.warning("You must login.", { toastId: "login-msg" })
      return false
    }

    sessionStorage.setItem("showLoginMessage", "true")
    return accessToken
  }

  const { permissions, setPermissions } = useContext(PermissionContext)

  const handleFetchPermissions = useCallback(async () => {
    const permissionMap = await getPermissions()
    setPermissions(permissionMap)
  }, [setPermissions])

  useEffect(() => {
    dispatch(decodeJWT())
    handleFetchPermissions()
  }, [])

  // Not authenticated
  if (!checkToken()) {
    return <Redirect to="/login" />
  }

  // Waiting for permissions to resolve
  if (requiresPermission && !permissions) {
    return null
  }

  // Doesnt have permission to route
  if (requiresPermission && !permissions[requiresPermission]) {
    return <Redirect to="/" />
  }

  // Legacy user role permission test
  if (userRole === COMPANY_DRIVER && path === "/") {
    return <Redirect to="/planning" />
  }

  // Validated auth/auth
  return <Route exact={exact} path={path} component={component} />
}

AuthenticatedRoute.propTypes = {
  exact: PropTypes.bool.isRequired,
  path: PropTypes.string.isRequired,
  component: PropTypes.node.isRequired,
}

export default AuthenticatedRoute
