import "./ListView.scss"

import classNames from "classnames"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import InfiniteScroll from "react-infinite-scroller"
import { useDispatch } from "react-redux"

import { getCompanies } from "../../../redux/actions"
import { useMounted } from "../../../utils/hooks"
import Checkbox from "../../atoms/Checkbox"
import Loading from "../../atoms/Loading"
import TextInput from "../../atoms/TextInput"

const ListView = ({
  content,
  rowRender,
  type,
  mark,
  setMark,
  markField,
  unassignedColumn,
  newTripColumn,
  checkedState,
  onChecked,
  disableRowField,
  disabledCheckBoxField,
  unassignedChecked,
  newBookingChecked,
  onUnassignedClick,
  onNewBookingClick,
  hasUnassignedField,
  hasNewBookingField,
  checkIfSuggested,
  checkIfAssigned,
  loadMore,
  hasMore,
  loading,
  setOffset,
  hideSearch,
  resetCheckedState,
}) => {
  const listViewClass = classNames("list-view-container")
  const [stateMark, setStateMark] = useState(mark)
  const dispatch = useDispatch()

  const controlledMark = mark || stateMark

  const toggleMark = isMark => {
    if (setMark) {
      setMark(isMark)
    }
    setStateMark(isMark)
  }

  const [search, setSearch] = useState("")
  const isMounted = useMounted()

  useEffect(() => {
    if (!isMounted) {
      return undefined
    }

    const delayedCall = setTimeout(() => {
      dispatch(getCompanies({ name: search }))
      resetCheckedState()
      setOffset(0)
    }, 500)

    return () => clearTimeout(delayedCall)
  }, [search])

  const firstRowColumn = classNames("row-column", { mark: controlledMark === 0 })
  return (
    <>
      {!hideSearch && (
        <div className="search-company-container">
          <TextInput
            onChange={e => setSearch(e.target.value)}
            value={search}
            inputSize="search-size"
            inputStyle="search-style"
            icon="search"
            iconStyle="search-icon"
            placeholder="Search company"
          />
        </div>
      )}

      <div className={listViewClass}>
        {loading ? (
          <Loading className="loading-list-view" />
        ) : (
          <InfiniteScroll
            loadMore={loadMore}
            hasMore={hasMore}
            pageStart={0}
            initialLoad={false}
            loader={content.length > 0 && <Loading className="loading-list-view" />}
            threshold={100}
            useWindow={false}
          >
            {hasNewBookingField && (
              <div className={firstRowColumn} role="button" onClick={onNewBookingClick}>
                {newTripColumn(toggleMark)}

                <Checkbox checked={newBookingChecked} />
              </div>
            )}
            {hasUnassignedField && (
              <div className={firstRowColumn} role="button" onClick={onUnassignedClick}>
                {unassignedColumn(toggleMark)}

                <Checkbox checked={unassignedChecked} />
              </div>
            )}
            {content.map(column => {
              // markField is name of key in content which to compare with selected key (mark)
              const listRowColumn = classNames("row-column", {
                mark: column[markField] === controlledMark,
                disabled: column[disableRowField],
              })

              const isSuggested = checkIfSuggested(column.id)
              const isAssigned = checkIfAssigned(column.id)

              return (
                <div
                  className={listRowColumn}
                  role="button"
                  onClick={
                    isSuggested || isAssigned
                      ? () => {}
                      : () => onChecked({ [column.id]: !checkedState[column.id] })
                  }
                >
                  {rowRender(column, toggleMark)}
                  {isSuggested && <div className="suggested-text">(Already suggested)</div>}
                  {isAssigned && <div className="assigned-text">(Currently Assigned)</div>}

                  {type === "checkbox" && (
                    <>
                      <Checkbox
                        disabled={column[disabledCheckBoxField]}
                        checked={checkedState[column.id]}
                      />
                    </>
                  )}
                </div>
              )
            })}
          </InfiniteScroll>
        )}
      </div>
    </>
  )
}

ListView.propTypes = {
  content: PropTypes.instanceOf(Array),
  unassignedColumn: PropTypes.func,
  newTripColumn: PropTypes.func,
  rowRender: PropTypes.func,
  type: PropTypes.string,
  mark: PropTypes.number,
  setMark: PropTypes.func,
  markField: PropTypes.string,
  onChecked: PropTypes.func,
  disableRowField: PropTypes.string,
  disabledCheckBoxField: PropTypes.string,
  unassignedChecked: PropTypes.bool,
  newBookingChecked: PropTypes.bool,
  onUnassignedClick: PropTypes.func,
  onNewBookingClick: PropTypes.func,
  checkedState: PropTypes.instanceOf(Object),
  hasUnassignedField: PropTypes.bool,
  hasNewBookingField: PropTypes.bool,
  checkIfSuggested: PropTypes.func,
  checkIfAssigned: PropTypes.func,
  loadMore: PropTypes.func,
  hasMore: PropTypes.bool,
  loading: PropTypes.bool,
  setOffset: PropTypes.func,
  hideSearch: PropTypes.bool,
  resetCheckedState: PropTypes.func,
}

ListView.defaultProps = {
  content: [],
  unassignedColumn: () => null,
  newTripColumn: () => null,
  rowRender: () => null,
  type: "",
  mark: 0,
  setMark: () => {},
  markField: "",
  onChecked: () => {},
  disableRowField: "",
  disabledCheckBoxField: "",
  unassignedChecked: false,
  newBookingChecked: false,
  onUnassignedClick: () => {},
  onNewBookingClick: () => {},
  checkedState: {},
  hasUnassignedField: false,
  hasNewBookingField: false,
  checkIfSuggested: () => {},
  checkIfAssigned: () => {},
  loadMore: () => {},
  hasMore: false,
  loading: false,
  setOffset: () => {},
  hideSearch: false,
  resetCheckedState: () => {},
}

export default ListView
