import queryString from "query-string"
import { toast } from "react-toastify"

import ACTIONS from "../../constants/ACTIONS"
import API from "../../constants/API"
import {
  TRIP_REQUEST_PENDING_STATUS,
  TRIP_REQUEST_STATUS,
  TRIP_SUGGESTION_PENDING_STATUS,
  TRIP_SUGGESTION_STATUS,
} from "../../constants/status"
import { TRIP_REQUESTS_TABLE } from "../../constants/tables"
import { landlineApi } from "../../utils/api/calls"
import { apiError, startApiCall } from "../api/apiActions"
import { getUnreadNotifications } from "../notifications/notificationsActions"
import { store } from "../store"

const setInitialTableLoading = (table, initialLoading) => {
  return async dispatch => {
    dispatch({ type: ACTIONS.SET_INITIAL_TABLE_LOADING, table, initialLoading })
  }
}

const setTimestamp = timestamp => {
  return async dispatch => {
    dispatch({ type: ACTIONS.SET_TIMESTAMP, timestamp })
  }
}

const getTrips = (queryParams, callback) => {
  return async dispatch => {
    try {
      const timestamp = Date.now()
      dispatch(setTimestamp(timestamp))
      const urlQuery = queryString.stringify(queryParams)
      dispatch(startApiCall(API.GET_TRIPS))
      const trips = await landlineApi.get(`v2/bossadmin/trips/?${urlQuery}`)
      dispatch({
        type: ACTIONS.GET_TRIPS_SUCCESS,
        trips: trips.data,
        timestamp,
      })
      if (callback) {
        callback()
      }
    } catch (error) {
      dispatch(apiError(API.GET_TRIPS, error))
    }
  }
}

const getNextTripsPage = queryParams => {
  return async dispatch => {
    try {
      dispatch(startApiCall(API.GET_NEXT_TRIPS_PAGE))
      const urlQuery = queryString.stringify(queryParams)

      const trips = await landlineApi.get(`v2/bossadmin/trips/?${urlQuery}`)
      dispatch({
        type: ACTIONS.GET_NEXT_TRIPS_PAGE_SUCCESS,
        trips: trips.data,
      })
    } catch (error) {
      dispatch(apiError(API.GET_NEXT_TRIPS_PAGE, error))
    }
  }
}

const getTrip = ({ tripID, externalTripDetailsFetch = false, callback }) => {
  return async dispatch => {
    try {
      dispatch(startApiCall(API.GET_TRIP))
      const trip = await landlineApi.get(`v2/bossadmin/trips/${tripID}/`)

      dispatch({ type: ACTIONS.GET_TRIP_SUCCESS, tripDetails: trip.data, externalTripDetailsFetch })

      if (callback) {
        callback()
      }
      return trip
    } catch (error) {
      dispatch(apiError(API.GET_TRIP, error))
      return false
    }
  }
}

const resetTripExternalFetch = () => {
  return async dispatch => {
    dispatch({ type: ACTIONS.RESET_EXTERNAL_FETCH_SUCCESS })
  }
}

const getTripRequests = (status = "", offset, idToExclude, idToHighlight, ordering) => {
  return async dispatch => {
    try {
      dispatch(startApiCall(API.GET_TRIP_REQUESTS))

      if (idToHighlight) {
        await dispatch(setInitialTableLoading("TripRequests", true))
      }

      const queryParams = {}
      if (offset) {
        queryParams.offset = offset
      }
      if (status) {
        queryParams.status = status
      }
      if (idToExclude) {
        queryParams.exclude_id = idToExclude
      }

      if (ordering) {
        queryParams.ordering = ordering
      }

      const urlQuery = queryString.stringify(queryParams)

      const tripRequests = await landlineApi.get(`v2/bossadmin/trip_requests/?${urlQuery}`)

      let highlightedTrip

      if (idToHighlight) {
        highlightedTrip = await landlineApi.get(`v2/bossadmin/trip_requests/${idToHighlight}/`)
      }

      let results = []

      if (highlightedTrip) {
        results = [highlightedTrip.data, ...tripRequests.data.results]
      } else {
        results = [...tripRequests.data.results]
      }

      dispatch({
        type: ACTIONS.GET_TRIP_REQUESTS_SUCCESS,
        data: { ...tripRequests.data, results },
        fieldToUpdate: [`${TRIP_REQUEST_STATUS[status] || ""}TripRequests`],
        status,
        offset,
      })

      if (idToHighlight) {
        await dispatch(setInitialTableLoading("TripRequests", false))
      }
    } catch (error) {
      dispatch(apiError(API.GET_TRIP_REQUESTS, error))
      if (idToHighlight) {
        await dispatch(setInitialTableLoading("TripRequests", false))
      }
    }
  }
}

const getNewTripRequests = (offset, idToExclude, idToHighlight) => {
  return async dispatch => {
    try {
      dispatch(startApiCall(API.GET_NEW_TRIP_REQUESTS))
      if (idToHighlight) {
        await dispatch(setInitialTableLoading("NewTrips", true))
      }
      const queryParams = { status: TRIP_REQUEST_PENDING_STATUS }
      if (offset) {
        queryParams.offset = offset
      }
      if (idToExclude) {
        queryParams.exclude_id = idToExclude
      }
      const urlQuery = queryString.stringify(queryParams)

      const tripRequests = await landlineApi.get(`v2/bossadmin/trip_requests/?${urlQuery}`)

      let highlightedTrip

      if (idToHighlight) {
        highlightedTrip = await landlineApi.get(`v2/bossadmin/trip_requests/${idToHighlight}/`)
      }

      let results = []

      if (highlightedTrip) {
        results = [highlightedTrip.data, ...tripRequests.data.results]
      } else {
        results = [...tripRequests.data.results]
      }

      dispatch({
        type: ACTIONS.GET_NEW_TRIP_REQUESTS_SUCCESS,
        data: { ...tripRequests.data, results, initialLoading: false },
        offset,
      })

      if (idToHighlight) {
        await dispatch(setInitialTableLoading("NewTrips", false))
      }
    } catch (error) {
      dispatch(apiError(API.GET_NEW_TRIP_REQUESTS, error))
      if (idToHighlight) {
        await dispatch(setInitialTableLoading("NewTrips", false))
      }
    }
  }
}

const updateRow = tripID => {
  return async dispatch => {
    try {
      dispatch(startApiCall(ACTIONS.UPDATE_TRIP_ROW))
      const allTrips = store.getState().trip.results
      const response = await landlineApi.get(`v2/bossadmin/trips/${tripID}/row_data_format/`)
      const responseTrips = response.data
      const newTrips = allTrips.map(tripRow => {
        const foundTrip = responseTrips.find(trip => trip.id === tripRow.id)

        if (foundTrip) {
          return foundTrip
        }

        return tripRow
      })
      await dispatch({ type: ACTIONS.UPDATE_TRIP_ROW, payload: { newTrips } })
      dispatch({ type: ACTIONS.UPDATE_TRIP_ROW_SUCCESS })
    } catch (error) {
      dispatch(apiError(ACTIONS.UPDATE_TRIP_ROW, error))
    }
  }
}

const patchTrip = (tripID, id) => {
  return async dispatch => {
    try {
      dispatch(startApiCall(API.PATCH_TRIP))

      await landlineApi.patch(`v2/bossadmin/trips/${tripID}/`, id)

      await dispatch(updateRow(tripID))

      dispatch({ type: ACTIONS.PATCH_TRIP_SUCCESS })
      toast.success("Trip updated successfully.")
      return false
    } catch (error) {
      dispatch(apiError(API.PATCH_TRIP, error))
      return true
    }
  }
}

const approveTrip = (tripID, companyID) => {
  return async dispatch => {
    try {
      dispatch(startApiCall(API.APPROVE_TRIP))
      await landlineApi.post(`v2/bossadmin/trips/${tripID}/approve_trip/`, {
        company: companyID,
      })

      await dispatch(updateRow(tripID))

      dispatch({ type: ACTIONS.APPROVE_TRIP_SUCCESS })
      toast.success("Trip updated successfully.")
    } catch (error) {
      dispatch(apiError(API.APPROVE_TRIP, error))
    }
  }
}

const unassignTrip = tripID => {
  return async dispatch => {
    try {
      dispatch(startApiCall(API.UNASSIGN_TRIP))

      await landlineApi.post(`v2/bossadmin/trips/${tripID}/unassign_trip/`)

      await dispatch(updateRow(tripID))
      toast.success("Trip updated successfully.")
      dispatch({ type: ACTIONS.UNASSIGN_TRIP_SUCCESS })
    } catch (error) {
      dispatch(apiError(API.UNASSIGN_TRIP, error))
    }
  }
}

const unassignMultipleTrips = (trips, getTripsFilters) => {
  return async dispatch => {
    try {
      dispatch(startApiCall(API.UNASSIGN_MULTIPLE_TRIPS))

      const data = {
        trips,
      }

      await landlineApi.post("v2/bossadmin/trips/make_available_for_company_representatives/", data)
      await dispatch(updateRow(trips[0]))

      await dispatch(getTrips(getTripsFilters))

      toast.success("Trips updated successfully.")
      dispatch({ type: ACTIONS.UNASSIGN_MULTIPLE_TRIPS_SUCCESS })
    } catch (error) {
      dispatch(apiError(API.UNASSIGN_MULTIPLE_TRIPS, error))
    }
  }
}

const changeToNewTrip = tripID => {
  return async dispatch => {
    try {
      dispatch(startApiCall(API.CHANGE_TO_NEW_TRIP))
      await landlineApi.post(`v2/bossadmin/trips/${tripID}/new_trip/`)

      await dispatch(updateRow(tripID))
      toast.success("Trip updated successfully.")
      dispatch({ type: ACTIONS.CHANGE_TO_NEW_TRIP_SUCCESS })
    } catch (error) {
      dispatch(apiError(API.CHANGE_TO_NEW_TRIP, error))
    }
  }
}

const suggestTrip = (tripID, companyIds) => {
  return async dispatch => {
    try {
      dispatch(startApiCall(API.SUGGEST_TRIP))
      const suggestTo = companyIds.map(company => {
        return {
          company,
          trip: tripID,
        }
      })
      await landlineApi.post(`v2/bossadmin/trip_suggestions/`, suggestTo)

      dispatch({ type: ACTIONS.SUGGEST_TRIP_SUCCESS })
      await dispatch(updateRow(tripID))
      toast.success("Successfully suggested.")
      dispatch({ type: ACTIONS.SUGGEST_TRIP_SUCCESS })
    } catch (error) {
      dispatch(apiError(API.SUGGEST_TRIP, error))
    }
  }
}

const sortTripTable = (queryParams, changeTable) => {
  return async dispatch => {
    try {
      dispatch(startApiCall(API.GET_TRIP_REQUESTS))
      let response
      if (changeTable === TRIP_REQUESTS_TABLE) {
        response = await landlineApi.get(`v2/bossadmin/trip_requests/?ordering=${queryParams}`)
      } else {
        response = await landlineApi.get(`v2/bossadmin/trip_suggestions/?ordering=${queryParams}`)
      }

      dispatch({
        type: ACTIONS.GET_SUGGESTED_TRIPS_SUCCESS,
        data: response.data,
        fieldToUpdate: changeTable,
      })
    } catch (error) {
      dispatch(apiError(API.GET_TRIP_REQUESTS, error))
    }
  }
}

const getSuggestedTrips = (status = "", offset, idToExclude, idToHighlight) => {
  return async dispatch => {
    try {
      dispatch(startApiCall(API.GET_SUGGESTED_TRIPS))

      if (idToHighlight) {
        await dispatch(setInitialTableLoading("TripSuggestions", true))
      }

      const queryParams = {}
      if (offset) {
        queryParams.offset = offset
      }
      if (status) {
        queryParams.status = status
      }
      if (idToExclude) {
        queryParams.exclude_id = idToExclude
      }
      const urlQuery = queryString.stringify(queryParams)

      const tripSuggestions = await landlineApi.get(`v2/bossadmin/trip_suggestions/?${urlQuery}`)

      let highlightedTrip

      if (idToHighlight) {
        highlightedTrip = await landlineApi.get(`v2/bossadmin/trip_suggestions/${idToHighlight}/`)
      }

      let results = []

      if (highlightedTrip) {
        results = [highlightedTrip.data, ...tripSuggestions.data.results]
      } else {
        results = [...tripSuggestions.data.results]
      }

      dispatch({
        type: ACTIONS.GET_SUGGESTED_TRIPS_SUCCESS,
        data: { ...tripSuggestions.data, results },
        fieldToUpdate: [`${TRIP_SUGGESTION_STATUS[status] || ""}TripSuggestions`],
        status,
        offset,
      })

      if (idToHighlight) {
        await dispatch(setInitialTableLoading("TripSuggestions", false))
      }
    } catch (error) {
      dispatch(apiError(API.GET_SUGGESTED_TRIPS, error))
      if (idToHighlight) {
        await dispatch(setInitialTableLoading("TripSuggestions", false))
      }
    }
  }
}

const requestTrip = (tripID, driverID, vehicleID, reducedRate) => {
  return async dispatch => {
    try {
      dispatch(startApiCall(API.REQUEST_TRIP))
      const requestBody = {
        trip: tripID,
        driver: driverID,
        vehicle: vehicleID,
        reduced_rate: reducedRate,
      }
      await landlineApi.post(`v2/bossadmin/trip_requests/`, requestBody)

      await dispatch(updateRow(tripID))
      toast.success("Trip successfully requested.")
      dispatch({ type: ACTIONS.REQUEST_TRIP_SUCCESS })
    } catch (error) {
      dispatch(apiError(API.REQUEST_TRIP, error))
    }
  }
}

const getTripSuggestions = (offset, idToExclude, idToHighlight) => {
  return async dispatch => {
    try {
      dispatch(startApiCall(API.GET_PENDING_TRIP_SUGGESTIONS))

      if (idToHighlight) {
        await dispatch(setInitialTableLoading("PendingTripSuggestions", true))
      }
      const queryParams = { status: TRIP_SUGGESTION_PENDING_STATUS }
      if (offset) {
        queryParams.offset = offset
      }
      if (idToExclude) {
        queryParams.exclude_id = idToExclude
      }
      const urlQuery = queryString.stringify(queryParams)

      const response = await landlineApi.get(`v2/bossadmin/trip_suggestions/?${urlQuery}`)

      let highlightedTrip

      if (idToHighlight) {
        highlightedTrip = await landlineApi.get(`v2/bossadmin/trip_suggestions/${idToHighlight}/`)
      }

      let results = []

      if (highlightedTrip) {
        results = [highlightedTrip.data, ...response.data.results]
      } else {
        results = [...response.data.results]
      }

      dispatch({
        type: ACTIONS.GET_PENDING_TRIP_SUGGESTIONS_SUCCESS,
        data: { ...response.data, results, initialLoading: false },
        offset,
      })
      if (idToHighlight) {
        await dispatch(setInitialTableLoading("PendingTripSuggestions", false))
      }
    } catch (error) {
      dispatch(apiError(API.GET_PENDING_TRIP_SUGGESTIONS, error))
      if (idToHighlight) {
        await dispatch(setInitialTableLoading("NewTrips", false))
      }
    }
  }
}

const acceptTripSuggestion = tripSuggestion => {
  return async dispatch => {
    try {
      dispatch(startApiCall(API.ACCEPT_TRIP_SUGGESTION))
      await landlineApi.post(
        `v2/bossadmin/trip_suggestions/${tripSuggestion}/accept-trip-suggestion/`,
      )
      await dispatch(getTripSuggestions())
      await dispatch(getUnreadNotifications())
      toast.success("Trip successfully accepted.")
      dispatch({ type: ACTIONS.ACCEPT_TRIP_SUGGESTION_SUCCESS })
    } catch (error) {
      dispatch(apiError(API.ACCEPT_TRIP_SUGGESTION, error))
    }
  }
}
// get suggestions with status 1

const denyTripSuggestion = tripSuggestion => {
  return async dispatch => {
    try {
      dispatch(startApiCall(API.DENY_TRIP_SUGGESTION))
      await landlineApi.post(
        `v2/bossadmin/trip_suggestions/${tripSuggestion}/deny-trip-suggestion/`,
      )
      await dispatch(getTripSuggestions())
      await dispatch(getUnreadNotifications())
      toast.success("Trip successfully denied.")
      dispatch({ type: ACTIONS.DENY_TRIP_SUGGESTION_SUCCESS })
    } catch (error) {
      dispatch(apiError(API.DENY_TRIP_SUGGESTION, error))
    }
  }
}

const approveTripRequest = tripRequestID => {
  return async dispatch => {
    try {
      dispatch(startApiCall(API.APPROVE_TRIP_REQUEST))
      await landlineApi.post(`v2/bossadmin/trip_requests/${tripRequestID}/approve-trip-request/`)

      await dispatch(getTripRequests(TRIP_REQUEST_PENDING_STATUS))
      await dispatch(getNewTripRequests())
      await dispatch(getUnreadNotifications())
      toast.success("Trip successfully approved.")
      dispatch({ type: ACTIONS.APPROVE_TRIP_REQUEST_SUCCESS })
    } catch (error) {
      dispatch(apiError(API.APPROVE_TRIP_REQUEST, error))
    }
  }
}

const denyTripRequest = tripRequestID => {
  return async dispatch => {
    try {
      dispatch(startApiCall(API.DENY_TRIP_REQUEST))
      await landlineApi.post(`v2/bossadmin/trip_requests/${tripRequestID}/deny-trip-request/`)

      await dispatch(getTripRequests(TRIP_REQUEST_PENDING_STATUS))
      await dispatch(getNewTripRequests())
      await dispatch(getUnreadNotifications())
      toast.success("Trip successfully denied.")
      dispatch({ type: ACTIONS.DENY_TRIP_REQUEST_SUCCESS })
    } catch (error) {
      dispatch(apiError(API.DENY_TRIP_REQUEST, error))
    }
  }
}

const updateTrip = (tripID, tripData, updateOtherSegment, errorCallback) => {
  return async dispatch => {
    try {
      dispatch(startApiCall(API.UPDATE_TRIP))
      await landlineApi.put(`v2/bossadmin/trips/${tripID}/`, tripData)

      if (tripData.departure_time !== tripData.departure_time_write) {
        dispatch(updateRow(tripID))
      }

      if (updateOtherSegment) {
        dispatch(updateRow(tripData.other_segment.id))
      }

      dispatch({ type: ACTIONS.UPDATE_TRIP_SUCCESS })
      toast.success("Trip updated successfuly")
    } catch (error) {
      errorCallback(
        error.response.data?.pick_up_details?.pick_up_address,
        error.response.data?.pick_up_details?.drop_off_address,
        error.response.data?.holder_passenger,
      )
      dispatch(apiError(API.UPDATE_TRIP, error))
    }
  }
}

const cancelTrip = tripID => {
  return async dispatch => {
    try {
      dispatch(startApiCall(API.CANCEL_TRIP))
      await landlineApi.post(`v2/bossadmin/trips/${tripID}/cancel_trip/`)
      dispatch({ type: ACTIONS.CANCEL_TRIP_SUCCESS })
      await dispatch(updateRow(tripID))

      toast.success("Trip successfuly cancelled.")
    } catch (error) {
      dispatch(apiError(API.CANCEL_TRIP, error))
    }
  }
}

const getLastDayInfo = () => {
  return async dispatch => {
    try {
      dispatch(startApiCall(API.GET_LAST_DAY_INFO))
      const response = await landlineApi.get("v2/bossadmin/trips/dashboard/")

      dispatch({ type: ACTIONS.GET_LAST_DAY_INFO_SUCCESS, lastDayInfo: response.data })
    } catch (error) {
      dispatch(apiError(API.GET_LAST_DAY_INFO, error))
    }
  }
}

const cancelTripRequest = (trip, callback) => {
  return async dispatch => {
    try {
      dispatch(startApiCall(API.CANCEL_TRIP_REQUEST))
      await landlineApi.post(`v2/bossadmin/trip_requests/${trip.id}/cancel-trip-request/`, trip)
      dispatch({ type: ACTIONS.CANCEL_TRIP_REQUEST_SUCCESS })
      callback()
      toast.success("Trip request successfuly cancelled.")
    } catch (error) {
      dispatch(apiError(API.CANCEL_TRIP_REQUEST, error))
    }
  }
}

const cancelTripSuggestion = (trip, callback) => {
  return async dispatch => {
    try {
      dispatch(startApiCall(API.CANCEL_TRIP_SUGGESTION))
      await landlineApi.post(
        `v2/bossadmin/trip_suggestions/${trip.id}/cancel-trip-suggestion/`,
        trip,
      )
      dispatch({ type: ACTIONS.CANCEL_TRIP_SUGGESTION_SUCCESS })
      callback()
      toast.success("Trip suggestion successfuly cancelled.")
    } catch (error) {
      dispatch(apiError(API.CANCEL_TRIP_SUGGESTION, error))
    }
  }
}

const pushTripToTop = (index, table) => {
  return async dispatch => {
    const trips = store.getState().trip
    const tableTrips = JSON.parse(JSON.stringify(trips[table].results))

    tableTrips.unshift(tableTrips.splice(index, 1)[0])

    dispatch({ type: ACTIONS.PUSH_TRIP_TO_TOP_SUCESS, data: tableTrips, table })
  }
}

const setRowToHighlight = rowID => {
  return async dispatch => {
    dispatch({ type: ACTIONS.SET_ROW_TO_HIGHLIGHT, id: rowID })
  }
}

const refreshVidecomTicket = (pnr, callback, isSync = false) => {
  return async dispatch => {
    try {
      dispatch(startApiCall(API.REFRESH_VIDECOM_TICKET))
      await landlineApi.post(`v2/booking/refresh_videcom_ticket/`, { pnr: pnr.toUpperCase() })
      dispatch({ type: ACTIONS.REFRESH_VIDECOM_TICKET_SUCCESS })
      toast.success(
        isSync
          ? "Synchronizing has been completed successfully"
          : "Booking has been successfully imported/synchronized",
      )
      callback()
    } catch (error) {
      dispatch(apiError(API.REFRESH_VIDECOM_TICKET, error))
    }
  }
}

export {
  updateRow,
  getTrips,
  getTrip,
  patchTrip,
  approveTrip,
  unassignTrip,
  suggestTrip,
  requestTrip,
  getTripRequests,
  approveTripRequest,
  denyTripRequest,
  updateTrip,
  getSuggestedTrips,
  cancelTrip,
  getLastDayInfo,
  acceptTripSuggestion,
  denyTripSuggestion,
  getTripSuggestions,
  getNextTripsPage,
  getNewTripRequests,
  cancelTripRequest,
  cancelTripSuggestion,
  changeToNewTrip,
  unassignMultipleTrips,
  pushTripToTop,
  setRowToHighlight,
  resetTripExternalFetch,
  sortTripTable,
  refreshVidecomTicket,
}
