import "./DriversPage.scss"

import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"

import RegularLayout from "../../layouts/RegularLayout"
import DriverCard from "../../molecules/DriverCard"

const DriversPage = ({ history }) => {
  const driversPageClass = classNames("drivers-page-container")
  return (
    <RegularLayout history={history}>
      <div className={driversPageClass}>
        <DriverCard name="Mark Thomson" />
        <DriverCard name="Mark Thomson" />
        <DriverCard name="Mark Thomson" />
        <DriverCard name="Mark Thomson" />
        <DriverCard name="Mark Thomson" />
        <DriverCard name="Mark Thomson" />
        <DriverCard name="Mark Thomson" />
        <DriverCard name="Mark Thomson" />
        <DriverCard name="Mark Thomson" />
        <DriverCard name="Mark Thomson" />
        <DriverCard name="Mark Thomson" />
        <DriverCard name="Mark Thomson" />
      </div>
    </RegularLayout>
  )
}

DriversPage.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
}

DriversPage.defaultProps = {}

export default DriversPage
