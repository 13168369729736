import React, { useEffect, useMemo, useState } from "react"
import { Box, Menu, Text } from "grommet"
import { DateTime } from "luxon"
import SvgIcon from "../../../atoms/SvgIcon"

interface TZSelectorCellProps {
  onChange: (useLocalTZ: boolean) => void
}

const localTime = DateTime.now()

export function TZSelectorCell({ onChange }: TZSelectorCellProps) {
  const localTZ = localTime.toFormat("ZZZZ") // PDT
  const settableLocalTZ = localTime.zoneName // America/Los_Angeles
  const [tz, setTZ] = useState<string>()

  useEffect(() => {
    onChange(!!tz)
  }, [tz])

  return (
    <Box direction="row">
      <Menu
        items={[
          { label: "Origin TZ", onClick: () => setTZ(null) },
          { label: `Local TZ (${localTZ})`, onClick: () => setTZ(settableLocalTZ) },
        ]}
      >
        <Box direction="row" align="center" gap="xxsmall">
          <Text
            size="small"
            style={{
              verticalAlign: "top",
            }}
          >
            TZ
          </Text>
          <SvgIcon width={8} height={5} icon="chevron-down" />
        </Box>
      </Menu>
    </Box>
  )
}
