import moment from "moment"

import { MINNEAPOLIS, MINNEAPOLIS_MSX } from "../constants/iataCodes"
import { NEW_STATUS } from "../constants/status"
import { BLACK_CAR_TYPE } from "../constants/transportationTypes"

export const getCompanyIcon = (companyDetails, logo, status) => {
  if (status === NEW_STATUS) {
    return "newBooking"
  }
  if (!companyDetails) {
    return "unassignedCompany"
  }
  if (!logo) {
    return "companyWithColor"
  }
  return "withImage"
}

export const getUserAvatar = avatar => {
  if (!avatar) {
    return "lettersWithColor"
  }
  return "withAvatar"
}

export const getFirstLetter = word => {
  if (word) {
    return word[0]
  }
  return ""
}

export const changeState = (setter, newValue) => {
  setter(prevState => ({ ...prevState, ...newValue }))
}

export const isEmptyObject = obj => {
  if (obj) return Object.keys(obj).length === 0 && obj.constructor === Object
  return undefined
}

export const firstCharToUpperCase = string => string.charAt(0).toUpperCase() + string.slice(1)

export const getKeyByValue = (object, value) => {
  return Object.keys(object).find(key => object[key] === value)
}

export const isDoor2Door = column => {
  return (
    column.service_type === BLACK_CAR_TYPE &&
    ((column.depart.iata === MINNEAPOLIS && column.arrive.iata === MINNEAPOLIS_MSX) ||
      (column.depart.iata === MINNEAPOLIS_MSX && column.arrive.iata === MINNEAPOLIS))
  )
}

export const getFontColor = bgColor => {
  const color = bgColor.charAt(0) === "#" ? bgColor.substring(1, 7) : bgColor
  const r = parseInt(color.substring(0, 2), 16) // hexToR
  const g = parseInt(color.substring(2, 4), 16) // hexToG
  const b = parseInt(color.substring(4, 6), 16) // hexToB
  const uicolors = [r / 255, g / 255, b / 255]
  const c = uicolors.map(col => {
    if (col <= 0.03928) {
      return col / 12.92
    }
    // eslint-disable-next-line no-restricted-properties
    return Math.pow((col + 0.055) / 1.055, 2.4)
  })
  const L = 0.2126 * c[0] + 0.7152 * c[1] + 0.0722 * c[2]
  return L > 0.179 ? "#3c3835" : "#FFFFFF"
}

export const getLocalDateTime = (date, format) => {
  const utcDate = moment.utc(date).toDate()
  return moment(utcDate)
    .local()
    .format(format)
}

export default {
  getUserAvatar,
  getCompanyIcon,
  changeState,
  isEmptyObject,
  firstCharToUpperCase,
  getFirstLetter,
  getKeyByValue,
  getLocalDateTime,
}
