import "./GoogleMap.scss"

import classNames from "classnames"
import GoogleMapReact from "google-map-react"
import PropTypes from "prop-types"
import React, { useCallback } from "react"

import { MSX_LAT_LONG } from "../../../constants/locations"
import config from "../../../utils/config"

const Point = ({ label }) => <div className="google-map-point">{label}</div>

Point.propTypes = {
  label: PropTypes.string,
}

Point.defaultProps = {
  label: "*",
}

const GoogleMap = ({ defaultCenter, defaultZoom, points }) => {
  const googleMapClass = classNames("google-map-container")

  const validPoints = useCallback(() => {
    // Adding a quick validity check here because google map component will fail hard
    return points.filter(point => point && point.lat && point.lng)
  }, [points])

  return (
    <div className={googleMapClass}>
      <GoogleMapReact
        defaultCenter={defaultCenter}
        defaultZoom={defaultZoom}
        bootstrapURLKeys={{ key: config.GOOGLE_MAPS_API_KEY }}
      >
        {validPoints().map(point => {
          return <Point text="point" lat={point.lat} lng={point.lng} label={point.label} />
        })}
      </GoogleMapReact>
    </div>
  )
}

GoogleMap.propTypes = {
  defaultCenter: PropTypes.shape({
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
  }),
  defaultZoom: PropTypes.number,
  points: PropTypes.arrayOf(
    PropTypes.shape({
      lat: PropTypes.number.isRequired,
      lng: PropTypes.number.isRequired,
      label: PropTypes.string,
    }),
  ),
}

GoogleMap.defaultProps = {
  defaultCenter: MSX_LAT_LONG,
  defaultZoom: 10,
  points: [],
}

export default GoogleMap
