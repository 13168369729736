import ACTIONS from "../../constants/ACTIONS"

const initalState = {}

export default function userReducer(state = initalState, action) {
  switch (action.type) {
    case ACTIONS.GET_AGENTS_SUCCESS:
      if (action.offset) {
        return {
          ...state,
          agents: {
            ...action.agents,
            results: [...state.agents.results, ...action.agents.results],
          },
        }
      }
      return {
        ...state,
        agents: action.agents,
      }

    default:
      return state
  }
}
