import "./ActiveFilter.scss"

import PropTypes from "prop-types"
import React from "react"

import SvgIcon from "../SvgIcon"

const ActiveFilter = ({ filterKey, filterValue, onClose }) => {
  return (
    <div className="active-filter-container">
      <div className="active-filter-text">
        {filterKey}: {filterValue}
      </div>

      <div className="close-icon-wrapper" role="button" onClick={onClose}>
        <SvgIcon icon="close" width="14" height="14" margin="0 0 2 0" />
      </div>
    </div>
  )
}

ActiveFilter.propTypes = {
  filterKey: PropTypes.string.isRequired,
  filterValue: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
}

ActiveFilter.defaultProps = {}

export default ActiveFilter
