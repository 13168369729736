import "./TabLayout.scss"

import classNames from "classnames"
import PropTypes from "prop-types"
import React, { useCallback, useState } from "react"
import { useSelector } from "react-redux"

import { COMPANY_DRIVER } from "../../../constants/userRoles"
import CurrentDate from "../../atoms/CurrentDate"
import NotificationButton from "../../atoms/NotificationButton"
import Tab from "../../atoms/Tab"

const TabLayout = ({
  tabIndex,
  setTabIndex,
  titles,
  contents,
  iconNames,
  className,
  headingPadding,
  tabTitleStyle,
  isPageLayout,
  history,
  tabContainerClassname,
}) => {
  const tabLayoutClass = classNames(["tab-layout-container", className])
  const tabContainerClass = classNames(["tabs-container", { isPageLayout }, tabContainerClassname])
  const tabsNavigationClass = classNames(["tabs-navigation", { isPageLayout }])
  const tabContentClass = classNames(["tab-content", { isPageLayout }])

  const [activeIndex, setActiveIndex] = useState(0)

  const [userRole] = useSelector(({ user }) => [user.jwtData?.role])

  const controlledIndex = tabIndex || activeIndex

  const onTabClick = useCallback(
    index => {
      if (setTabIndex) {
        setTabIndex(index)
        return
      }
      setActiveIndex(index)
    },
    [setActiveIndex, setTabIndex],
  )
  return (
    <div className={tabLayoutClass}>
      <div className={tabContainerClass} style={{ padding: headingPadding }}>
        <div className={tabsNavigationClass}>
          {titles.map((title, index) => {
            return (
              <Tab
                title={title}
                key={title}
                index={index}
                onTabClick={onTabClick}
                active={controlledIndex}
                iconName={iconNames[index]}
                tabTitleStyle={tabTitleStyle}
              />
            )
          })}
        </div>
        {isPageLayout && (
          <div className="right-side-layout-container ">
            {userRole !== COMPANY_DRIVER && (
              <div className="notification-button">
                <NotificationButton onClick={() => history.push("/")} />
              </div>
            )}
            <div className="current-date">
              <CurrentDate />
            </div>
          </div>
        )}
      </div>
      <div className={tabContentClass} key={controlledIndex}>
        <div className="SectionWrapper">{contents[controlledIndex]}</div>
      </div>
    </div>
  )
}

TabLayout.propTypes = {
  titles: PropTypes.instanceOf(Array).isRequired,
  contents: PropTypes.instanceOf(Array).isRequired,
  className: PropTypes.string,
  headingPadding: PropTypes.string,
  tabIndex: PropTypes.number,
  setTabIndex: PropTypes.func,
  iconNames: PropTypes.instanceOf(Array),
  tabTitleStyle: PropTypes.string,
  isPageLayout: PropTypes.bool,
  history: PropTypes.instanceOf(Object),
  tabContainerClassname: PropTypes.string,
}

TabLayout.defaultProps = {
  className: "",
  headingPadding: "",
  tabIndex: 0,
  setTabIndex: () => {},
  iconNames: [],
  tabTitleStyle: "",
  isPageLayout: false,
  history: {},
  tabContainerClassname: "",
}

export default TabLayout
