import queryString from "query-string"

import ACTIONS from "../../constants/ACTIONS"
import API from "../../constants/API"
import { landlineApi } from "../../utils/api/calls"
import { apiError, startApiCall } from "../api/apiActions"

const getCompanies = queryParams => {
  return async dispatch => {
    try {
      const urlQuery = queryString.stringify(queryParams)
      dispatch(startApiCall(API.GET_COMPANIES))
      const companies = await landlineApi.get(`v2/bossadmin/companies/?${urlQuery}`)

      dispatch({
        type: ACTIONS.GET_COMPANIES_SUCCESS,
        companies: companies.data,
        offset: queryParams?.offset,
      })
    } catch (error) {
      dispatch(apiError(API.GET_COMPANIES, error))
    }
  }
}

// returns companies in drop down but exclude the selected one
const getUnselectedCompanies = queryParams => {
  return async dispatch => {
    try {
      if (queryParams) {
        dispatch(startApiCall(API.GET_COMPANIES))
        const companies = await landlineApi.get(`v2/bossadmin/companies/?exclude_id=${queryParams}`)
        dispatch({ type: ACTIONS.GET_COMPANIES_SUCCESS, companies: companies.data })
      }
    } catch (error) {
      dispatch(apiError(API.GET_COMPANIES, error))
    }
  }
}

const getCompany = id => {
  return async dispatch => {
    try {
      dispatch(startApiCall(API.GET_COMPANY))
      const company = await landlineApi.get(`v2/bossadmin/companies/${id}/`)

      dispatch({ type: ACTIONS.GET_COMPANY_SUCCESS, company: company.data })
    } catch (error) {
      dispatch(apiError(API.GET_COMPANY, error))
    }
  }
}

const updateSelectedCompanies = companies => {
  return async dispatch => {
    dispatch({ type: ACTIONS.UPDATE_COMPANIES, companies })
  }
}

const getLandlineInfo = () => {
  return async dispatch => {
    try {
      dispatch(startApiCall(API.GET_LANDLINE_INFO))

      const landline = await landlineApi.get(`v2/bossadmin/companies/?is_landline=true`)

      dispatch({ type: ACTIONS.GET_LANDLINE_INFO_SUCCESS, landline: landline.data.results[0] })
    } catch (error) {
      dispatch(apiError(API.GET_LANDLINE_INFO, error))
    }
  }
}

// eslint-disable-next-line
export {
  getCompanies,
  getCompany,
  updateSelectedCompanies,
  getLandlineInfo,
  getUnselectedCompanies,
}
