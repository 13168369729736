import "./DriverInfoPage.scss"

import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { useLocation, useParams } from "react-router-dom"

import { getKeyByValue } from "../../../utils/common"
import RegularLayout from "../../layouts/RegularLayout"
import TabLayout from "../../molecules/TabLayout"
import AvailabilityCalendar from "../../organisms/AvailabilityCalendar"
import DriverInfo from "../../organisms/DriverInfo"

const DriverInfoPage = ({ history }) => {
  const location = useLocation()
  const { companyId, driverId, tab } = useParams()
  const isLandline = location.pathname.includes("/landline")

  const [tabIndex, setTabIndex] = useState(0)

  const driverInfoTabIndex = 0
  const availabilityTabIndex = 1

  const tabs = {
    [driverInfoTabIndex]: "",
    [availabilityTabIndex]: "availability",
  }

  useEffect(() => {
    if (!tab) {
      setTabIndex(driverInfoTabIndex)
    } else {
      setTabIndex(+getKeyByValue(tabs, tab))
    }
  }, [tab])

  return (
    <RegularLayout
      withTopMenu={false}
      history={history}
      backgroundColor={tabIndex === 0 ? "#F5F5F5" : "#FFFFFF"}
    >
      <TabLayout
        history={history}
        tabTitleStyle="title-style"
        tabIndex={tabIndex}
        setTabIndex={index => {
          history.replace(
            `${isLandline ? "/landline" : `/operators/${companyId}`}/drivers/${driverId}${
              index !== 0 ? `/${tabs[index]}` : ""
            }`,
          )
        }}
        titles={["Driver info", "Availability"]}
        contents={[
          <DriverInfo driverID={driverId} />,
          <AvailabilityCalendar history={history} driverID={driverId} isLandline={isLandline} />,
        ]}
        isPageLayout
        className={tabIndex === 1 && "tab-layout-white-bg"}
      />
    </RegularLayout>
  )
}

DriverInfoPage.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
}

DriverInfoPage.defaultProps = {}

export default DriverInfoPage
