export default {
  // actions
  REFRESH_ACCESS_TOKEN_SUCCESS: "REFRESH_ACCESS_TOKEN_SUCCESS",
  DECODE_JWT_SUCCESS: "DECODE_JWT_SUCCESS",

  // user
  LOGIN_USER_SUCCESS: "LOGIN_USER_SUCCESS",
  GET_USER_PROFILE_SUCCESS: "GET_USER_PROFILE_SUCCESS",
  LOGOUT_USER: "LOGOUT_USER",
  GET_USER_DETAILS_SUCCESS: "GET_USER_DETAILS_SUCCESS",
  UPDATE_USER_DETAILS_SUCCESS: "UPDATE_USER_DETAILS_SUCCESS",
  CHANGE_USER_PASSWORD_SUCCESS: "CHANGE_USER_PASSWORD_SUCCESS",

  // api actions
  START_API_CALL: "START_API_CALL",
  API_ERROR: "API_ERROR",
  CLEAR_API_ERROR: "CLEAR_API_ERROR",

  // form actions
  UPDATE_FORM_FIELD: "UPDATE_FORM_FIELD",
  UPDATE_FORM_FIELDS: "UPDATE_FORM_FIELDS",
  INIT_FORM: "INIT_FORM",
  CLEAR_FORM: "CLEAR_FORM",
  SET_FORM_ERRORS: "SET_FORM_ERRORS",
  CLEAR_FORM_ERRORS: "CLEAR_FORM_ERRORS",
  SET_ACTIVE_FIELD: "SET_ACTIVE_FIELD",
  SET_FORM_SENT: "SET_FORM_SENT",
  FORM_SENT_RESET: "FORM_SENT_RESET",
  SET_FORM_RESPONSE: "SET_FORM_RESPONSE",

  // trip actions
  GET_TRIP_REQUESTS_SUCCESS: "GET_TRIP_REQUESTS_SUCCESS",
  GET_TRIPS_SUCCESS: "GET_TRIPS_SUCCESS",
  GET_TRIP_SUCCESS: "GET_TRIP_SUCCESS",
  PATCH_TRIP_SUCCESS: "PATCH_TRIP_SUCCESS",
  APPROVE_TRIP_SUCCESS: "APPROVE_TRIP_SUCCESS",
  UNASSIGN_TRIP_SUCCESS: "UNASSIGN_TRIP_SUCCESS",
  UNASSIGN_MULTIPLE_TRIPS_SUCCESS: "UNASSIGN_MULTIPLE_TRIPS_SUCCESS",
  CHANGE_TO_NEW_TRIP_SUCCESS: "CHANGE_TO_NEW_TRIP_SUCCESS",
  GET_SUGGESTED_TRIPS_SUCCESS: "GET_SUGGESTED_TRIPS_SUCCESS",
  SUGGEST_TRIP_SUCCESS: "SUGGEST_TRIP_SUCCESS",
  REQUEST_TRIP_SUCCESS: "REQUEST_TRIP_SUCCESS",
  APPROVE_TRIP_REQUEST_SUCCESS: "APPROVE_TRIP_REQUEST_SUCCESS",
  DENY_TRIP_REQUEST_SUCCESS: "DENY_TRIP_REQUEST_SUCCESS",
  UPDATE_TRIP_SUCCESS: "UPDATE_TRIP_SUCCESS",
  CANCEL_TRIP_SUCCESS: "CANCEL_TRIP_SUCCESS",
  GET_LAST_DAY_INFO_SUCCESS: "GET_LAST_DAY_INFO_SUCCESS",
  GET_NEXT_TRIPS_PAGE_SUCCESS: "GET_NEXT_TRIPS_PAGE_SUCCESS",
  GET_NEW_TRIP_REQUESTS_SUCCESS: "GET_NEW_TRIP_REQUESTS_SUCCESS",
  PUSH_TRIP_TO_TOP_SUCESS: "PUSH_TRIP_TO_TOP_SUCESS",
  SET_ROW_TO_HIGHLIGHT: "SET_ROW_TO_HIGHLIGHT",
  RESET_EXTERNAL_FETCH_SUCCESS: "RESET_EXTERNAL_FETCH_SUCCESS",

  ACCEPT_TRIP_SUGGESTION_SUCCESS: "ACCEPT_TRIP_SUGGESTION_SUCCESS",
  DENY_TRIP_SUGGESTION: "DENY_TRIP_SUGGESTION",
  DENY_TRIP_SUGGESTION_SUCCESS: "DENY_TRIP_SUGGESTION_SUCCESS",
  ACCEPT_TRIP_SUGGESTION: "ACCEPT_TRIP_SUGGESTION",
  GET_PENDING_TRIP_SUGGESTIONS_SUCCESS: "GET_PENDING_TRIP_SUGGESTIONS_SUCCESS",
  ACCEPT_PENDING_TRIP_SUGGESTION: "ACCEPT_PENDING_TRIP_SUGGESTION",
  DENY_PENDING_TRIP_SUGGESTION: "DENY_PENDING_TRIP_SUGGESTION",

  UPDATE_TRIP_ROW: "UPDATE_TRIP_ROW",
  UPDATE_TRIP_ROW_SUCCESS: "UPDATE_TRIP_ROW_SUCCESS",
  CANCEL_TRIP_REQUEST_SUCCESS: "CANCEL_TRIP_REQUEST_SUCCESS",
  CANCEL_TRIP_SUGGESTION_SUCCESS: "CANCEL_TRIP_SUGGESTION_SUCCESS",
  SET_TIMESTAMP: "SET_TIMESTAMP",

  SET_INITIAL_TABLE_LOADING: "SET_INITIAL_TABLE_LOADING",
  REFRESH_VIDECOM_TICKET_SUCCESS: "REFRESH_VIDECOM_TICKET_SUCCESS",

  // driver actions
  GET_COMPANY_DRIVERS_SUCCESS: "GET_COMPANY_DRIVERS_SUCCESS",
  GET_ALL_DRIVERS_SUCCESS: "GET_ALL_DRIVERS_SUCCESS",
  GET_DRIVER_SUCCESS: "GET_DRIVER_SUCCESS",
  NOTIFY_DRIVERS_SUCCESS: "NOTIFY_DRIVERS_SUCCESS",
  ASSIGN_LANDLINE_DRIVER_SUCCESS: "ASSIGN_LANDLINE_DRIVER_SUCCESS",
  GET_DRIVER_DETAILS_SUCCESS: "GET_DRIVER_DETAILS_SUCCESS",
  UPDATE_DRIVER_DETAILS_SUCCESS: "UPDATE_DRIVER_DETAILS_SUCCESS",
  UPDATE_DRIVER_VEHICLE_LICENCE_SUCCESS: "UPDATE_DRIVER_VEHICLE_LICENCE_SUCCESS",
  CLEAR_DRIVER_DETAILS: "CLEAR_DRIVER_DETAILS",
  GET_DRIVER_EVENTS_SUCCESS: "GET_DRIVER_EVENTS_SUCCESS",
  CHECK_DRIVER_EVENT_SUCCESS: "CHECK_DRIVER_EVENT_SUCCESS",
  RESET_DRIVER_EVENTS_SUCCESS: "RESET_DRIVER_EVENTS_SUCCESS",
  GET_TRIP_DRIVERS_SUCCESS: "GET_TRIP_DRIVERS_SUCCESS",
  NOTIFY_CUSTOMERS_SUCCESS: "NOTIFY_CUSTOMERS_SUCCESS",

  // vehicle actions
  GET_COMPANY_VEHICLES_SUCCESS: "GET_COMPANY_VEHICLES_SUCCESS",
  GET_ALL_VEHICLES_SUCCESS: "GET_ALL_VEHICLES_SUCCESS",
  GET_VEHICLE_SUCCESS: "GET_VEHICLE_SUCCESS",

  // company actions
  GET_COMPANIES_SUCCESS: "GET_COMPANIES_SUCCESS",
  GET_COMPANY_SUCCESS: "GET_COMPANY_SUCCESS",
  UPDATE_COMPANIES: "UPDATE_COMPANIES",
  GET_LANDLINE_INFO_SUCCESS: "GET_LANDLINE_INFO_SUCCESS",

  // notifications actions
  GET_UNREAD_NOTIFICATIONS_SUCCESS: "GET_UNREAD_NOTIFICATIONS_SUCCESS",
  GET_UNREAD_NOTIFICATIONS_FAIL: "GET_UNREAD_NOTIFICATIONS_FAIL",
  SET_ACTIVE_NOTIFICATION_SUCCESS: "SET_ACTIVE_NOTIFICATION_SUCCESS",
  MARK_NOTIFICATION_AS_READ: "MARK_NOTIFICATION_AS_READ",
  HIGHLIGIHT_NOTIFICATIONS_SUCCESS: " HIGHLIGIHT_NOTIFICATIONS_SUCCESS",
  RESET_HIGHLIGHT_NOTIFICATIONS_SUCCESS: "RESET_HIGHLIGHT_NOTIFICATIONS_SUCCESS",
  MARK_ALL_NOTIFICATIONS_AS_READ_SUCCESS: "MARK_ALL_NOTIFICATIONS_AS_READ_SUCCESS",
  GET_MORE_UNREAD_NOTIFICATIONS_SUCCESS: "GET_MORE_UNREAD_NOTIFICATIONS_SUCCESS",

  // notes actions
  SAVE_NOTE_SUCCESS: "SAVE_NOTE_SUCCESS",
  SAVE_NOTE_FAIL: "SAVE_NOTE_FAIL",
  DELETE_NOTE_SUCCESS: "DELETE_NOTE_SUCCESS",
  DELETE_NOTE_FAIL: "DELETE_NOTE_FAIL",
  PATCH_NOTE_FAIL: "PATCH_NOTE_FAIL",
  PATCH_NOTE_SUCCESS: "PATCH_NOTE_SUCCESS",

  // agents actions
  GET_AGENTS_SUCCESS: "GET_AGENTS_SUCCESS",
  GET_AGENTS_FAIL: "GET_AGENTS_FAIL",

  // app actions
  IS_SIDEBAR_COLLAPSED: "IS_SIDEBAR_COLLAPSED",

  // airport actions
  GET_ALL_AIPORTS_SUCCESS: "GET_ALL_AIRPORTS_SUCCESS",

  // quote actions
  GET_QUOTE_START: "GET_QUOTE_START",
  GET_QUOTE_SUCCESS: "GET_QUOTE_SUCCESS",
  GET_ADDRESS_AUTOCOMPLETE_SUCCESS: "GET_ADDRESS_AUTOCOMPLETE_SUCCESS",
  GET_ADDRESS_AUTOCOMPLETE_CLEAR_PREDICTIONS: "GET_ADDRESS_AUTOCOMPLETE_CLEAR_PREDICTIONS",

  // representative actions
  GET_COMPANY_REPRESENTATIVES_SUCCESS: "GET_COMPANY_REPRESENTATIVES_SUCCESS",

  // cities actions
  GET_CITIES_SUCCESS: "GET_CITIES_SUCCESS",

  // filter actions
  UPDATE_FILTERS_SUCCESS: "UPDATE_FILTERS_SUCCESS",
  UPDATE_FILTERS_FAIL: "UPDATE_FILTERS_FAIL",
  SET_INITIAL_FILTERS_SUCCESS: "SET_INITIAL_FILTERS_SUCCESS",
  SET_INITIAL_FILTERS_FAIL: "SET_INITIAL_FILTERS_FAIL",
  RESET_FILTERS_SUCCESS: "RESET_FILTERS_SUCCESS",
  GET_TRIP_FILTER_VALUE_SUCCESS: "GET_TRIP_FILTER_VALUE_SUCCESS",

  // manifest actions
  GET_MANIFEST_SUCCESS: "GET_MANIFEST_SUCCESS"
}
