import { Box, Button, Select, TextInput } from "grommet"
import React from "react"
import { Controller, UseFormReturn } from "react-hook-form"
import { Column, Row } from "react-table"
import { LandlineRouteDelayCode } from "../../../../utils/api/getDelayCodes"
import { LandlineRouteDelay } from "../../../../utils/api/getRoutesWithDelay"
import SvgIcon from "../../../atoms/SvgIcon"
import { Cell } from "../../../tables/cells/Cell"
import { EditableCell } from "../../../tables/cells/EditableCell"

export function getDelayCodeEntryTableColumns(
  form: UseFormReturn<LandlineRouteDelay>,
  delayCodeOptions: Array<LandlineRouteDelayCode>,
  onEditRow: (index: number) => void,
  onSubmit: (update: LandlineRouteDelay) => void,
  onDeleteRow: (row: LandlineRouteDelay) => void,
): Array<Column<LandlineRouteDelay>> {
  return [
    {
      accessor: "code",
      Header: "Delay Code",
      style: {
        color: "#000",
      },
      Cell: ({ value, row }) => (
        <EditableCell
          row={row}
          form={form}
          DisplayCell={() => (
            <Cell
              boxProps={{ style: { color: "#999" } }}
              value={value?.code ? `${value.code} - ${value.name}` : ""}
            />
          )}
          EditCell={() => (
            <Cell
              boxProps={{
                style: {
                  left: -10,
                  position: "relative",
                },
              }}
              value={
                <Controller
                  control={form.control}
                  name="code"
                  render={({ field }) => (
                    <Select
                      {...field}
                      onChange={e => {
                        field.onChange(e.option)
                      }}
                      options={delayCodeOptions}
                      labelKey={({ code, name }) => `${code} - ${name}`}
                      style={{
                        left: -11,
                      }}
                    />
                  )}
                />
              }
            />
          )}
        />
      ),
    },
    {
      accessor: "delay_amount",
      Header: "Delay Min",
      style: {
        color: "#000",
      },
      Cell: ({ value, row }) => (
        <EditableCell
          row={row}
          form={form}
          DisplayCell={() => (
            <Cell boxProps={{ style: { color: "#999" } }} value={value > 0 ? value : ""} />
          )}
          EditCell={() => (
            <Cell
              boxProps={{
                style: {
                  left: -11,
                  position: "relative",
                },
              }}
              value={
                <Controller
                  control={form.control}
                  name="delay_amount"
                  render={({ field }) => (
                    <TextInput
                      {...field}
                      onChange={e => field.onChange(e.target.valueAsNumber)}
                      type="number"
                      onFocus={e => e.target.select()}
                    />
                  )}
                />
              }
            />
          )}
        />
      ),
    },
    {
      accessor: "note",
      Header: "Note",
      style: {
        color: "#000",
      },
      Cell: ({ value, row }) => (
        <EditableCell
          row={row}
          form={form}
          DisplayCell={() => <Cell boxProps={{ style: { color: "#999" } }} value={value} />}
          EditCell={() => (
            <Cell
              boxProps={{
                style: {
                  left: -11,
                  position: "relative",
                },
              }}
              value={
                <Controller
                  control={form.control}
                  name="note"
                  render={({ field }) => <TextInput {...field} />}
                />
              }
            />
          )}
        />
      ),
    },
    {
      id: "edit-controller",
      Cell: ({ row }: { row: Row<LandlineRouteDelay> }) => (
        <EditableCell
          row={row}
          form={form}
          DisplayCell={() => (
            <Cell
              boxProps={{
                alignSelf: "end",
              }}
              value={
                <Button
                  a11yTitle="Edit Row"
                  plain
                  style={{
                    borderRadius: 4,
                    border: "1px solid #000",
                    width: 34,
                    height: 34,
                    textAlign: "center",
                  }}
                  icon={<SvgIcon icon="edit-fillable" fill="#3C3835" />}
                  onClick={() => onEditRow(row.index)}
                />
              }
            />
          )}
          EditCell={() => (
            <Cell
              boxProps={{
                alignSelf: "end",
              }}
              value={
                <Box direction="row" gap="xsmall">
                  <Button
                    primary
                    a11yTitle="Persist changes"
                    icon={
                      <SvgIcon
                        style={{ transform: "translateY(1px)" }}
                        icon="check"
                        fill="#3C3835"
                      />
                    }
                    style={{
                      borderRadius: 4,
                      padding: 0,
                      width: 34,
                      height: 34,
                      textAlign: "center",
                    }}
                    onClick={() => {
                      onEditRow(-1)
                      form.handleSubmit(update => {
                        onSubmit({
                          ...row.original,
                          ...update,
                        })
                      })()
                    }}
                  />
                  {typeof row.original.id === "undefined" ? null : (
                    <Button
                      primary
                      a11yTitle="Delete row"
                      icon={
                        <SvgIcon
                          style={{ transform: "scale(1.5) translate(5px, 5px)" }}
                          icon="close-x-dark"
                          fill="#3C3835"
                        />
                      }
                      style={{
                        borderRadius: 4,
                        padding: 0,
                        width: 34,
                        height: 34,
                        textAlign: "center",
                        background: "#DBD8D8",
                      }}
                      onClick={() => {
                        onEditRow(-1)
                        onDeleteRow(row.original)
                      }}
                    />
                  )}
                </Box>
              }
            />
          )}
        />
      ),
    },
  ]
}
