import "./ItemSection.scss"

import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"

const ItemSection = ({ name, value }) => {
  const itemSectionClass = classNames("item-section-container")
  return (
    <div className={itemSectionClass}>
      <h5>{name}</h5>
      {value}
    </div>
  )
}

ItemSection.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
}

ItemSection.defaultProps = {
  name: "item",
  value: "aboutItem",
}

export default ItemSection
