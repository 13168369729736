import "./NewNote.scss"

import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"

const NewNote = ({ value, onChange, onCancelClick, onSaveClick, hideButtons, placeholder }) => {
  const noteEnabledClass = classNames({ saveEnabled: value.trim().length })

  return (
    <div className="add-new-body">
      <textarea
        className="add-new-body_content"
        onChange={onChange}
        value={value}
        placeholder={placeholder}
        rows="2"
      />
      {!hideButtons && (
        <div className="add-new-body_actions">
          <span role="button" className={noteEnabledClass} onClick={onCancelClick}>
            Cancel
          </span>
          <span role="button" className={noteEnabledClass} onClick={onSaveClick}>
            Save
          </span>
        </div>
      )}
    </div>
  )
}

NewNote.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  onCancelClick: PropTypes.func,
  onSaveClick: PropTypes.func,
  hideButtons: PropTypes.bool,
  placeholder: PropTypes.string,
}

NewNote.defaultProps = {
  value: "",
  onChange: () => {},
  onCancelClick: () => {},
  onSaveClick: () => {},
  hideButtons: false,
  placeholder: "Enter note here",
}

export default NewNote
