import "./DriverStatusModal.scss"

import moment from "moment"
import PropTypes from "prop-types"
import React, { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import API from "../../../constants/API"
import {
  DEFAULT_DATETIME,
  END_OF_DAY_DATETIME,
  MIDNIGHT_DATETIME,
  TWELVE_HOUR_WITHOUT_LEADING_ZERO,
  TWENTY_FOUR_HOUR_WITH_LEADING_ZERO,
} from "../../../constants/dateFormats"
import {
  DRIVER_STATUS,
  DRIVER_STATUS_COLORS,
  DROPDOWN_DRIVER_STATUSES,
} from "../../../constants/driverStatuses"
import { checkDriverEvent, createDriverEvent } from "../../../redux/actions"
import apiUtils from "../../../utils/api/api-utils"
import { formatEventText } from "../../../utils/eventUtils"
import Button from "../Button"
import CalendarComponent from "../CalendarComponent"
import Dropdown from "../Dropdown"
import Loading from "../Loading"
import NewNote from "../NewNote"
import TimeInputPicker from "../TimeInputPicker"

const DriverStatusModal = ({ closeModal, driverID, callback, activeStartDate }) => {
  const dispatch = useDispatch()
  const [eventDetails, setEventDetails] = useState({
    user: driverID,
    description: "",
    start_date_time_write: moment().format(MIDNIGHT_DATETIME),
    end_date_time_write: moment().format(END_OF_DAY_DATETIME),
  })
  const [affectedEvents, setAffectedEvents] = useState([])
  const [showSecondModal, setShowSecondModal] = useState(false)
  const [eventDetailsError, setEventDetailsError] = useState({})
  const [dateChosen, setDateChosen] = useState(false)
  const [startTime, setStartTime] = useState("12:00 AM")
  const [endTime, setEndTime] = useState("11:59 PM")

  const changeEventDetailsError = params => {
    setEventDetailsError(prevState => ({ ...prevState, ...params }))
  }

  const [callsInProgress] = useSelector(({ api }) => [api.callsInProgress])

  const loading = useMemo(() => {
    return apiUtils.areCallsInProgress([API.CHECK_DRIVER_EVENT], callsInProgress)
  }, [callsInProgress])

  const validateEventDetails = () => {
    let invalidInput
    if (!eventDetails.event_type) {
      changeEventDetailsError({ status: "Status is required." })
      invalidInput = true
    }

    if (!dateChosen) {
      changeEventDetailsError({ date: "Date is required." })
      invalidInput = true
    }

    if (!startTime || !endTime) {
      changeEventDetailsError({ time: "Start time and end time are required." })
    }

    if (
      moment(eventDetails.start_date_time_write).isSameOrAfter(
        moment(eventDetails.end_date_time_write),
      )
    ) {
      changeEventDetailsError({ time: "Start date/time must be before end date/time." })
      invalidInput = true
    }

    return !invalidInput
  }

  const onEventTimeChange = (time, timeToChange, field) => {
    const t = moment(time, TWENTY_FOUR_HOUR_WITH_LEADING_ZERO)
    const d = moment(timeToChange)

    d.set({
      hour: t.hour(),
      minute: t.minute(),
      second: t.second(),
    })

    setEventDetails(prevState => ({
      ...prevState,
      [field]: d.format(DEFAULT_DATETIME),
    }))
  }

  const onEventDateChange = (date, dateToChange, field) => {
    const newDate = moment(date)
    const oldDate = moment(dateToChange)
    setDateChosen(true)

    if (dateToChange) {
      newDate.set({
        hour: oldDate.hour(),
        minute: oldDate.minute(),
        second: oldDate.second(),
      })
    }

    setEventDetails(prevState => ({
      ...prevState,
      [field]: newDate.format(DEFAULT_DATETIME),
    }))
  }

  useEffect(() => {
    const newStartTime = moment(startTime, [TWELVE_HOUR_WITHOUT_LEADING_ZERO]).format(
      TWENTY_FOUR_HOUR_WITH_LEADING_ZERO,
    )

    onEventTimeChange(newStartTime, eventDetails.start_date_time_write, "start_date_time_write")
  }, [startTime])

  useEffect(() => {
    const newEndTime = moment(endTime, [TWELVE_HOUR_WITHOUT_LEADING_ZERO]).format(
      TWENTY_FOUR_HOUR_WITH_LEADING_ZERO,
    )

    onEventTimeChange(newEndTime, eventDetails.end_date_time_write, "end_date_time_write")
  }, [endTime])

  const handleConfirmClick = async () => {
    setEventDetailsError({})

    if (validateEventDetails()) {
      const nextStep = await dispatch(checkDriverEvent(eventDetails, callback))
      if (nextStep.shouldModalClose) {
        closeModal()
      } else {
        setAffectedEvents(nextStep.affectedEvents)
        setShowSecondModal(true)
      }
    }
  }

  const changeDescription = description => {
    setEventDetails(prevState => ({
      ...prevState,
      description,
    }))
  }

  return showSecondModal ? (
    <div className="status-confirmation-modal">
      <div className="affected-event-modal-text">
        Are you sure you want to change the status? The new status will affect previously scheduled
        events:
      </div>
      <div className="affected-events-container">
        {affectedEvents.map(event => (
          <div className="affected-event">
            <div
              className="event-dot"
              style={{ backgroundColor: DRIVER_STATUS_COLORS[event.event_type] }}
            />
            {formatEventText(event, true)}
          </div>
        ))}
      </div>
      <div className="driver-status-buttons-container confirmation">
        <div>
          <Button
            onClick={() => {
              dispatch(createDriverEvent(eventDetails, callback))
              closeModal()
            }}
            buttonType="landlineColor"
            buttonSize="driverStatusModalSize confirmation"
            type="button"
          >
            Yes
          </Button>
        </div>
        <div>
          <Button
            buttonType="grayColor"
            buttonSize="driverStatusModalSize confirmation"
            type="button"
            onClick={closeModal}
            margin="0px 0px 0px 12px"
          >
            No
          </Button>
        </div>
      </div>
    </div>
  ) : (
    <div className="status-modal">
      {loading ? (
        <Loading className="loading-modal" />
      ) : (
        <>
          <div className="event-details-error-wrapper">
            <div className="driver-status-label">Set status</div>
            {eventDetailsError.status && (
              <div className="event-details-error">{eventDetailsError.status}</div>
            )}
          </div>

          <Dropdown
            placeholder="Choose status"
            className="driver-status-dropdown"
            onClick={item => {
              setEventDetails(prevState => ({
                ...prevState,
                event_type: item.value,
              }))
            }}
            value={DRIVER_STATUS[eventDetails.event_type]}
            items={DROPDOWN_DRIVER_STATUSES}
            showItemField="name"
            label=""
          />

          <div className="start-end-time-container">
            <div>
              <div className="event-details-error-wrapper">
                <div className="driver-status-label">Start time</div>
                {eventDetailsError.time && (
                  <div className="event-details-error">{eventDetailsError.time}</div>
                )}
              </div>

              <TimeInputPicker
                className="driverStatusStyle"
                value={startTime}
                onChange={value => setStartTime(value)}
              />
            </div>

            <div>
              <div className="driver-status-label">End time</div>

              <TimeInputPicker
                className="driverStatusStyle"
                value={endTime}
                onChange={value => setEndTime(value)}
              />
            </div>
          </div>

          <div className="driver-status-calendar-container">
            <div className="event-details-error-wrapper">
              <div className="driver-status-label">Choose date</div>

              {eventDetailsError.date && (
                <div className="event-details-error">{eventDetailsError.date}</div>
              )}
            </div>

            <CalendarComponent
              value={[
                moment(eventDetails.start_date_time_write).toDate(),
                moment(eventDetails.end_date_time_write).toDate(),
              ]}
              onChange={dateV => {
                onEventDateChange(
                  dateV[0],
                  eventDetails.start_date_time_write,
                  "start_date_time_write",
                )
                onEventDateChange(dateV[1], eventDetails.end_date_time_write, "end_date_time_write")
              }}
              selectRange
              className="driver-status-modal"
              onClickDay={dateV => {
                onEventDateChange(
                  dateV,
                  eventDetails.start_date_time_write,
                  "start_date_time_write",
                )
                onEventDateChange(dateV, eventDetails.end_date_time_write, "end_date_time_write")
              }}
              activeStartDate={activeStartDate}
            />
          </div>

          <div className="driver-status-label">Add description</div>

          <NewNote
            placeholder="Enter description here"
            value={eventDetails.description}
            onChange={e => changeDescription(e.target.value)}
            hideButtons
          />

          <div className="driver-status-buttons-container">
            <div>
              <Button
                onClick={closeModal}
                buttonType="grayColor"
                buttonSize="driverStatusModalSize"
                type="button"
                margin="0px 0px 0px 0px"
              >
                Cancel
              </Button>
            </div>
            <div>
              <Button
                buttonType="landlineColor"
                buttonSize="driverStatusModalSize"
                type="button"
                margin="0px 0px 0px 12px"
                onClick={handleConfirmClick}
              >
                Confirm
              </Button>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

DriverStatusModal.propTypes = {
  closeModal: PropTypes.func,
  driverID: PropTypes.string.isRequired,
  callback: PropTypes.func.isRequired,
  activeStartDate: PropTypes.string,
}

DriverStatusModal.defaultProps = {
  closeModal: () => {},
  activeStartDate: "",
}

export default DriverStatusModal
