import "./TimeInputPicker.scss"

import classNames from "classnames"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"

const TimeInputPicker = ({
  onChange,
  value,
  className,
  title,
  disabled,
  showEmptyField,
  showWarning,
}) => {
  const timePickerClass = classNames("timePicker-container")
  const timePickerInputClass = classNames("timePickerInput-container", className, {
    warning: showWarning,
  })
  const [state, setState] = useState({
    abbreviation: "AM",
    hours: "",
    minutes: "",
  })

  useEffect(() => {
    if (value) {
      const splitedValue = value.split(":")
      const newHours = splitedValue[0]
      const splitedMinutes = splitedValue[1].split(" ")
      const newMinutes = splitedMinutes[0]
      const newAbbreviation = splitedMinutes[1]
      setState({
        hours: newHours,
        minutes: newMinutes,
        abbreviation: newAbbreviation,
      })
    }
  }, [])

  useEffect(() => {
    if (!value) {
      setState({
        hours: "",
        minutes: "",
        abbreviation: "AM",
      })
    }
  }, [value])

  const changeTime = (hours = "", minutes = "", abbreviation) => {
    let newHours = hours[0] === "0" ? hours.substr(1) : hours
    if (!state.hours || !newHours || newHours.match(/^(10|11|12|[1-9])$/)) {
      newHours = newHours.length === 1 ? `0${newHours}` : newHours
    } else {
      newHours = state.hours
    }

    let newMinutes = minutes[0] === "0" ? minutes.substr(-2) : minutes
    if (!state.minutes || !newMinutes || newMinutes.match(/^[0-5][0-9]?$/)) {
      newMinutes = newMinutes.length === 1 ? `0${newMinutes}` : newMinutes
    } else {
      newMinutes = state.minutes
    }

    if (newHours && newMinutes) {
      onChange(`${newHours}:${newMinutes} ${abbreviation}`)
    } else {
      onChange(undefined)
    }
    setState({ hours: newHours, minutes: newMinutes, abbreviation })
  }

  const changeHours = e => {
    const hours = e.target.value

    changeTime(hours, state.minutes, state.abbreviation)
  }

  const changeMinutes = e => {
    let minutes = e.target.value

    if (state.minutes === "00" && e.target.value === "0") {
      minutes = ""
    }

    changeTime(state.hours, minutes, state.abbreviation)
  }

  const selectAbbreviation = e => {
    const abbreviation = e.target.value

    changeTime(state.hours, state.minutes, abbreviation)
  }

  const hourValue = state.hours && state.hours.length === 1 ? `0${state.hours}` : state.hours
  const minutesValue =
    state.minutes && state.minutes.length === 1 ? `0${state.minutes}` : state.minutes

  return (
    <div className={timePickerClass}>
      {title && <span className="label">{title}</span>}

      <div className={timePickerInputClass}>
        {!showEmptyField && (
          <>
            <div className="timePickerInputs-wrapper">
              <div className="timePickerInput-hours">
                <input
                  type="number"
                  placeholder="--"
                  value={hourValue}
                  onChange={changeHours}
                  disabled={disabled}
                />
              </div>
              :
              <div className="timePickerInput-minutes">
                <input
                  type="number"
                  placeholder="--"
                  value={minutesValue}
                  onChange={changeMinutes}
                  disabled={disabled}
                />
              </div>
            </div>
            <div className="timePickerInput-abbreviation">
              <select
                onChange={selectAbbreviation}
                value={state.abbreviation}
                disabled={disabled}
                style={{ WebkitAppearance: "button", width: 53, cursor: "pointer" }}
              >
                <option value="AM">AM</option>
                <option value="PM">PM</option>
              </select>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

TimeInputPicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  title: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  showEmptyField: PropTypes.bool,
  showWarning: PropTypes.bool,
}

TimeInputPicker.defaultProps = {
  value: "",
  title: "",
  className: "",
  disabled: false,
  showEmptyField: false,
  showWarning: false,
}

export default TimeInputPicker
