import ACTIONS from "../../constants/ACTIONS"
import API from "../../constants/API"
import { NOTIFICATIONS_LOADING_LIMIT } from "../../constants/offset"
import { landlineApi } from "../../utils/api/calls"
import { apiError, startApiCall } from "../api/apiActions"

const getUnreadNotifications = offset => {
  return async dispatch => {
    try {
      dispatch(startApiCall(API.GET_UNREAD_NOTIFICATIONS))
      const notifications = await landlineApi.get(
        `v2/bossadmin/notifications/?ordering=-created&unread=true&offset=${offset ||
          0}&limit=${NOTIFICATIONS_LOADING_LIMIT}`,
      )

      if (offset) {
        dispatch({
          type: ACTIONS.GET_MORE_UNREAD_NOTIFICATIONS_SUCCESS,
          payload: notifications.data,
        })
      } else {
        dispatch({ type: ACTIONS.GET_UNREAD_NOTIFICATIONS_SUCCESS, payload: notifications.data })
      }
    } catch (error) {
      dispatch(apiError(API.GET_UNREAD_NOTIFICATIONS, error))
    }
  }
}

const setActiveNotification = notification => {
  return async dispatch => {
    dispatch({ type: ACTIONS.SET_ACTIVE_NOTIFICATION_SUCCESS, payload: notification })
  }
}

const userOpenNotification = activeNotification => {
  return async dispatch => {
    dispatch(setActiveNotification(activeNotification))
    dispatch({ type: ACTIONS.MARK_NOTIFICATION_AS_READ, payload: activeNotification.index })
    try {
      dispatch(startApiCall(API.MARK_NOTIFICATION_AS_READ))
      await landlineApi.post(
        `/v2/bossadmin/notifications/${activeNotification.notificationId}/read/`,
      )
    } catch (error) {
      dispatch(apiError(API.MARK_NOTIFICATION_AS_READ, error))
    }
  }
}

const highlightNotifications = () => {
  return async dispatch => {
    dispatch({ type: ACTIONS.HIGHLIGIHT_NOTIFICATIONS_SUCCESS })
  }
}

const resetHighlightNotifications = () => {
  return async dispatch => {
    dispatch({ type: ACTIONS.RESET_HIGHLIGHT_NOTIFICATIONS_SUCCESS })
  }
}

const markAllNotificationsAsRead = () => {
  return async dispatch => {
    try {
      await landlineApi.post(`/v2/bossadmin/notifications/read_all/`)
      dispatch({
        type: ACTIONS.MARK_ALL_NOTIFICATIONS_AS_READ_SUCCESS,
      })
    } catch (error) {
      dispatch(apiError(API.MARK_ALL_NOTIFICATION_AS_READ, error))
    }
  }
}

const removeSingleNotification = notificationId => {
  return async dispatch => {
    try {
      dispatch(startApiCall(API.REMOVE_SINGLE_NOTIFICATION))
      await landlineApi.post(`/v2/bossadmin/notifications/${notificationId}/read/`)
      await dispatch(getUnreadNotifications())
    } catch (error) {
      dispatch(apiError(API.REMOVE_SINGLE_NOTIFICATION, error))
    }
  }
}

// eslint-disable-next-line
export {
  getUnreadNotifications,
  userOpenNotification,
  highlightNotifications,
  resetHighlightNotifications,
  setActiveNotification,
  markAllNotificationsAsRead,
  removeSingleNotification,
}
