import "./Page404.scss"

// import PropTypes from "prop-types"
import React from "react"

const Page404 = () => {
  return (
    <div className="page-404-container">
      <img src="/media/page-404.png" alt="This page is not found!" width="780px" height="580px" />
    </div>
  )
}

Page404.propTypes = {}

Page404.defaultProps = {}

export default Page404
