/* eslint-disable */
import "./index.scss"

import React from "react"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import { store } from "./redux/store"

import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Grommet } from "grommet"
import { landlineTheme } from "./theme/landline"
import { PermissionContextProvider } from "./components/contexts/PermissionContext"
import App from "./App"

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Grommet theme={landlineTheme}>
        <PermissionContextProvider>
          <App />
        </PermissionContextProvider>
      </Grommet>
    </React.StrictMode>
  </Provider>,
  document.getElementById("root"),
)
