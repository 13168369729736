import "./TableHead.scss"

import PropTypes from "prop-types"
import React, { useState } from "react"

import Checkbox from "../Checkbox"
import THeadItem from "../THeadItem"

const TableHead = ({ columns, showCheckbox, onCheckboxClick, checked, checkboxDisabled }) => {
  const [sortBy, setSortBy] = useState("")
  const [sortDirection, setSortDirection] = useState("None")

  return (
    <div className="TableHead-container">
      {showCheckbox && (
        <div
          className="table-head-checkbox"
          role="button"
          onClick={!checkboxDisabled && onCheckboxClick}
        >
          <Checkbox checked={checked} disabled={checkboxDisabled} />
        </div>
      )}

      <div className="tableHeadRow">
        {columns.map(th => (
          <THeadItem
            onClick={th.onClick}
            sortDirection={sortDirection}
            setSortDirection={setSortDirection}
            sortBy={sortBy}
            setSortBy={setSortBy}
            title={th.name}
            style={{ width: th.width }}
            key={th.id}
          />
        ))}
      </div>
    </div>
  )
}

TableHead.propTypes = {
  columns: PropTypes.instanceOf(Array),
  showCheckbox: PropTypes.bool,
  checked: PropTypes.bool,
  onCheckboxClick: PropTypes.func,
  checkboxDisabled: PropTypes.bool,
}

TableHead.defaultProps = {
  columns: [],
  showCheckbox: false,
  checked: false,
  onCheckboxClick: () => {},
  checkboxDisabled: false,
}
export default TableHead
