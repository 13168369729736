export default {
  black: "#000",
  white: "#fff",
  placeholder: "#CDCCC8",
  darkLandline: "#3C3835",
  lightBlack: "#28221E",
  mediumGray: "#F5F5F5",
  darkGray: "#696D6F",
  lightGray: "#E5E5E5",
  primaryLandline: "#FAB500",
  loyaltyLandline: "#FF9800",
  red: "#FF4040",
  lightBeige: "#ffe6a6",
  darkGrayBorder: "#bebebe",
  mediumBlack: "#2C2C2C",
  lightBlackBorder: "#3f3835",
  lightGreen: "#b0eadb",
  green: "#00c08b",
  lightBlue: "#bbebff",
  lightPurple: "#C7BEFF",
  borderGrayPicker: "#C4C4C4",
  linkUnderline: "blue",
}
