import "./DateFilter.scss"

import classNames from "classnames"
import moment from "moment"
import PropTypes from "prop-types"
import React from "react"

import SvgIcon from "../SvgIcon"

const DateFilter = ({ tripFilters, onDateClose, onTodayClick, onAllTimeClick }) => {
  const isCurrentDate =
    tripFilters.departure_time__gte &&
    moment(tripFilters.departure_time__gte).isSame(new Date(), "day")
  const todayClass = classNames("date-filter-container", { active: isCurrentDate })
  const dateClass = classNames("date-filter-container", { active: !isCurrentDate })
  const showDate = tripFilters.departure_time__gte && !isCurrentDate

  return (
    <div className="date-filter-wrapper">
      <div
        className={dateClass}
        role="button"
        onClick={() => {
          if (!showDate) {
            onAllTimeClick()
          } else {
            onDateClose()
          }
        }}
      >
        <div className="date-filter-text">
          {showDate ? moment(tripFilters.departure_time__gte).format("MMM DD YYYY") : "All time"}
        </div>

        {showDate && (
          <div className="close-icon-wrapper" role="button" onClick={onDateClose}>
            <SvgIcon icon="close" width="14" height="14" margin="0 0 2 0" />
          </div>
        )}
      </div>

      <div className={todayClass} role="button" onClick={onTodayClick}>
        <div className="date-filter-text">Today</div>
      </div>
    </div>
  )
}

DateFilter.propTypes = {
  tripFilters: PropTypes.instanceOf(Object),
  onDateClose: PropTypes.func.isRequired,
  onTodayClick: PropTypes.func.isRequired,
  onAllTimeClick: PropTypes.func.isRequired,
}

DateFilter.defaultProps = {
  tripFilters: {},
}

export default DateFilter
