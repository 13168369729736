import "./SidebarMenuItem.scss"

import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"
import { Link } from "react-router-dom"

import { ROUTES } from "../../../constants/routes"
import SvgIcon from "../SvgIcon"

const SidebarMenuItem = ({ title, icon, url, pathname, openCollapse, iconProps }) => {
  const isActive = title === "Dashboard" ? pathname === "/" : pathname.includes(url)
  const activeClass = classNames("sidebar-menu-item body1-semibold", {
    "sidebar-menu-item-active": isActive,
    openCollapse,
    "is-settings": icon === "settings",
  })

  const getURL = () => {
    if (url === ROUTES.PLANNING_PAGE && isActive) {
      return pathname
    }

    return url
  }

  return (
    <Link to={getURL()} className={activeClass} replace={isActive}>
      <SvgIcon icon={icon} margin="0" {...iconProps} />
      {!openCollapse && <span className="menu-item-title">{title}</span>}
    </Link>
  )
}

SidebarMenuItem.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  pathname: PropTypes.bool.isRequired,
  url: PropTypes.string.isRequired,
  openCollapse: PropTypes.bool,
}

SidebarMenuItem.defaultProps = { openCollapse: false }

export default SidebarMenuItem
