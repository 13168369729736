import "./TripRequestsDropdown.scss"

import classNames from "classnames"
import PropTypes from "prop-types"
import React, { useRef, useState } from "react"

import { useOnClickOutside } from "../../../utils/hooks"
import Checkbox from "../Checkbox"
import SvgIcon from "../SvgIcon"

const TripRequestsDropdown = ({ items, label }) => {
  const [isOpenState, setIsOpenState] = useState(false)
  const ref = useRef()
  const dropdownClass = classNames("requests-dropdown-container")

  const changeStatus = () => {
    setIsOpenState(!isOpenState)
  }
  useOnClickOutside(ref, () => setIsOpenState(false))
  return (
    <div className={dropdownClass}>
      <div role="button" tabIndex={0} onClick={changeStatus} className="dropdown-button">
        <div className="label-text">{label}</div>
        <div className="arrow-wrapper">
          <SvgIcon icon="medium-size-down-arrow" width="16" height="16" fill="#3C3835" />
        </div>
      </div>

      {isOpenState && (
        <div className="activeList" ref={ref}>
          {items.map(item => {
            return (
              <div
                onClick={() => {
                  item.onClick()
                }}
                role="button"
                className="item"
                tabIndex={0}
              >
                <div className="name-container">
                  <div className="icon-wrapper">
                    <SvgIcon fill="#e35c55" icon={`${item.name}-icon`} width="16" height="16" />
                  </div>
                  {item.name}
                </div>
                <Checkbox checked={item.checked} />
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}

TripRequestsDropdown.propTypes = {
  items: PropTypes.instanceOf(Array).isRequired,
  label: PropTypes.string,
}

TripRequestsDropdown.defaultProps = {
  label: " choose",
}

export default TripRequestsDropdown
