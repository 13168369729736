import "./DriverEvent.scss"

import moment from "moment"
import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import { useDispatch } from "react-redux"

import { DRIVER_RIDE, DRIVER_STATUS_COLORS } from "../../../constants/driverStatuses"
import { cancelDriverEvent } from "../../../redux/actions"
import { formatEventText, formatEventTextTimeFirst } from "../../../utils/eventUtils"
import { useOnClickOutside } from "../../../utils/hooks"
import Modal from "../../organisms/Modal"
import AdditionalEventInfo from "../AdditionalEventInfo"
import Button from "../Button"

const DriverEvent = ({ event, cancelCallback, history, cancelDisabled }) => {
  const selectedEvent = localStorage.getItem("selectedEvent")

  const dispatch = useDispatch()
  const [additionalInfoVisible, setAdditionalInfoVisible] = useState(event.id === +selectedEvent)
  const [cancelModalOpen, setCancelModalOpen] = useState(false)
  const [shouldShowOnTop, setShouldShowOnTop] = useState(false)
  const [popupVisible, setPopupVisible] = useState(false)
  const ref = useRef()
  const textRef = useRef()
  const [textRefWidth, setTextRefWidth] = useState(0)

  useOnClickOutside(ref, () => {
    if (!cancelModalOpen) {
      setAdditionalInfoVisible(false)
      localStorage.removeItem("selectedEvent")
    }
  })

  useEffect(() => {
    setShouldShowOnTop(
      window.innerHeight - ref.current.getBoundingClientRect().bottom < window.innerHeight / 2,
    )
    localStorage.removeItem("selectedEvent")
  }, [])

  useEffect(() => {
    if (textRef.current) {
      setTextRefWidth(textRef.current.clientWidth)
    }
  }, [textRef.current && textRef.current.clientWidth])

  return (
    <div className="driver-event-container">
      <div
        className="driver-event-dot"
        style={{ backgroundColor: DRIVER_STATUS_COLORS[event.event_type] }}
      />
      <div
        className="driver-event-text-wrapper"
        role="button"
        onClick={() => {
          setAdditionalInfoVisible(!additionalInfoVisible)
          setShouldShowOnTop(
            window.innerHeight - ref.current.getBoundingClientRect().bottom <
              window.innerHeight / 2,
          )
        }}
        ref={ref}
      >
        <div
          onMouseEnter={() => setPopupVisible(true)}
          onMouseLeave={() => setPopupVisible(false)}
          className="driver-event-text"
          ref={textRef}
        >
          {formatEventTextTimeFirst(event)}
        </div>

        {popupVisible && !moment(event.start_date_time).isSame(moment(event.end_date_time), "day") && (
          <div style={{ left: `${textRefWidth + 8}px` }} className="additional-day-popup">
            +1 day
          </div>
        )}

        {additionalInfoVisible && (
          <AdditionalEventInfo
            event={event}
            showOnTop={shouldShowOnTop}
            isRide={event.event_type === DRIVER_RIDE}
            onCancelClick={() => {
              setCancelModalOpen(true)
            }}
            history={history}
            cancelDisabled={cancelDisabled}
          />
        )}
      </div>

      <Modal
        render={() => (
          <div role="button" onClick={e => e.stopPropagation()} className="cancel-event-container">
            <div>Are you sure you want to cancel {formatEventText(event)}?</div>
            <div className="buttons-container">
              <Button
                onClick={() => {
                  dispatch(cancelDriverEvent(event.id, cancelCallback))
                  setCancelModalOpen(false)
                }}
                buttonType="landlineColor"
                buttonSize="driverStatusModalSize confirmation"
                type="button"
              >
                Yes
              </Button>

              <Button
                buttonType="grayColor"
                buttonSize="driverStatusModalSize confirmation"
                type="button"
                onClick={() => setCancelModalOpen(false)}
                margin="0px 0px 0px 12px"
              >
                No
              </Button>
            </div>
          </div>
        )}
        shouldCloseOnOverlayClick
        isOpen={cancelModalOpen}
        onRequestClose={() => setCancelModalOpen(false)}
        style={{
          overlay: {
            position: "fixed",
            display: "flex",
            backgroundColor: "rgba(0, 0, 0, 0.2)",
            zIndex: 99999,
            overflow: "none",
          },
          content: {
            backgroundColor: "#fafafa",
            width: 288,
            display: "flex",
            overflow: "none",
            boxShadow: "0px 0px 16px 4px rgba(66, 64, 62, 0.12)",
            borderRadius: "4px",
            border: "none",
            padding: "30px 24px 36px 24px",
          },
        }}
      />
    </div>
  )
}

DriverEvent.propTypes = {
  event: PropTypes.instanceOf(Object),
  cancelCallback: PropTypes.func,
  history: PropTypes.instanceOf(Object),
  cancelDisabled: PropTypes.bool,
}

DriverEvent.defaultProps = {
  event: {},
  cancelCallback: () => {},
  history: {},
  cancelDisabled: false,
}

export default DriverEvent
