import "./TripRequestTable.scss"

import classNames from "classnames"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"

import { NOTIFICATION_CODES } from "../../../constants/notificationCodes"
import TableHead from "../../atoms/TableHead"
import TripRequestsDropDown from "../../atoms/TripRequestsDropdown"
import TabLayout from "../../molecules/TabLayout"
import Table from "../Table"
import TableBody from "../TableBody"

const TripRequestTable = ({
  tripRequests,
  columns,
  tripRequestsTabs,
  tripSuggestionsColumns,
  tripSuggestions,
  tripSuggestionsTabs,
  loadMoreTripRequests,
  loadMoreTripSuggestions,
  hasMoreRequests,
  hasMoreSuggestions,
  tripRequestsScrollRef,
  tripSuggestionsScrollRef,
  initialRequestsLoading,
  initialSuggestionsLoading,
  getRequestCancelComponent,
  getSuggestionCancelComponent,
}) => {
  const tripRequestTableClass = classNames("trip-request-table-container body2")
  const [tabIndex, setTabIndex] = useState(0)
  const [activeNotification] = useSelector(({ notifications }) => [
    notifications.activeNotification,
  ])

  useEffect(() => {
    if (
      activeNotification.notificationType === NOTIFICATION_CODES.ACCEPTED_TRIP_SUGGESTION ||
      activeNotification.notificationType === NOTIFICATION_CODES.DENIED_TRIP_SUGGESTION
    ) {
      setTabIndex(1)
    } else if (
      activeNotification.notificationType === NOTIFICATION_CODES.APPROVED_TRIP_REQUEST ||
      activeNotification.notificationType === NOTIFICATION_CODES.DENIED_TRIP
    ) {
      setTabIndex(0)
    }
  }, [activeNotification])

  return (
    <div className={tripRequestTableClass}>
      <Table id="TripRequestsTable" title="trip summary">
        <TabLayout
          iconNames={["edit-note", "light-bulb"]}
          tabIndex={tabIndex}
          setTabIndex={setTabIndex}
          className="trip-requests-tab-container"
          titles={["Trip requests", "Trip suggestions"]}
          headingPadding="30px 24px 0px 24px"
          contents={[
            <div className="tripRequests__wrapper">
              <TripRequestsDropDown label="Most recent" items={tripRequestsTabs} />
              <TableHead columns={columns} />

              <TableBody
                initialLoading={initialRequestsLoading}
                columns={columns}
                data={tripRequests}
                loadMore={loadMoreTripRequests}
                hasMore={hasMoreRequests}
                scrollRef={tripRequestsScrollRef}
                getCancelTripComponent={getRequestCancelComponent}
                tableBodyType="tripRequests"
              />
            </div>,
            <div className="tripSuggestions__wrapper">
              <TripRequestsDropDown label="Most recent" items={tripSuggestionsTabs} />
              <TableHead columns={tripSuggestionsColumns} />
              <TableBody
                initialLoading={initialSuggestionsLoading}
                columns={tripSuggestionsColumns}
                data={tripSuggestions}
                loadMore={loadMoreTripSuggestions}
                hasMore={hasMoreSuggestions}
                scrollRef={tripSuggestionsScrollRef}
                getCancelTripComponent={getSuggestionCancelComponent}
                tableBodyType="tripSuggestions"
              />
            </div>,
          ]}
        />
      </Table>
    </div>
  )
}

TripRequestTable.propTypes = {
  tripRequests: PropTypes.instanceOf(Array),
  columns: PropTypes.instanceOf(Array),
  tripRequestsTabs: PropTypes.instanceOf(Array),
  tripSuggestionsColumns: PropTypes.instanceOf(Array),
  tripSuggestions: PropTypes.instanceOf(Array),
  tripSuggestionsTabs: PropTypes.instanceOf(Array),
  loadMoreTripRequests: PropTypes.func,
  loadMoreTripSuggestions: PropTypes.func,
  hasMoreRequests: PropTypes.bool,
  hasMoreSuggestions: PropTypes.bool,
  tripRequestsScrollRef: PropTypes.instanceOf(Element),
  tripSuggestionsScrollRef: PropTypes.instanceOf(Element),
  initialRequestsLoading: PropTypes.bool,
  initialSuggestionsLoading: PropTypes.bool,
  getRequestCancelComponent: PropTypes.func,
  getSuggestionCancelComponent: PropTypes.func,
}

TripRequestTable.defaultProps = {
  tripRequests: [],
  columns: [],
  tripRequestsTabs: [],
  tripSuggestionsColumns: [],
  tripSuggestions: [],
  tripSuggestionsTabs: [],
  loadMoreTripRequests: () => {},
  loadMoreTripSuggestions: () => {},
  hasMoreRequests: false,
  hasMoreSuggestions: false,
  tripRequestsScrollRef: null,
  tripSuggestionsScrollRef: null,
  initialRequestsLoading: false,
  initialSuggestionsLoading: false,
  getRequestCancelComponent: () => {},
  getSuggestionCancelComponent: () => {},
}

export default TripRequestTable
