import "./TablePopup.scss"

import classNames from "classnames"
import PropTypes from "prop-types"
import React, { useState } from "react"

import RowItem from "../RowItem"

function TablePopup({ children, popupLabel, icon }) {
  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const popupClass = classNames("popup", { withIcon: icon })
  return (
    <RowItem>
      <div
        className="tablePopup-wrapper"
        onMouseLeave={() => setIsPopupOpen(false)}
        onMouseEnter={() => setIsPopupOpen(true)}
      >
        <div className="tablePopup-content">{children}</div>
        {isPopupOpen ? (
          <div className={popupClass}>
            {icon}
            <p>{popupLabel}</p>
          </div>
        ) : null}
      </div>
    </RowItem>
  )
}
TablePopup.propTypes = {
  popupLabel: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  icon: PropTypes.instanceOf(Element),
}

TablePopup.defaultProps = {
  popupLabel: "",
  children: null,
  icon: null,
}
export default TablePopup
