import "./NotificationButton.scss"

import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import {
  getUnreadNotifications,
  highlightNotifications,
} from "../../../redux/notifications/notificationsActions"
import SvgIcon from "../SvgIcon"

const NotificationButton = ({ onClick, shouldGetNotifications }) => {
  const [notificationsData] = useSelector(({ notifications }) => [notifications])

  const dispatch = useDispatch()

  const unreadNotificationsCount = notificationsData.unread?.count

  useEffect(() => {
    if (shouldGetNotifications || typeof unreadNotificationsCount === "undefined") {
      dispatch(getUnreadNotifications())
    }
  }, [dispatch])

  const getUnreadCount = () => {
    return unreadNotificationsCount > 9 ? "9+" : unreadNotificationsCount
  }

  return (
    <div
      role="button"
      onClick={() => {
        onClick()
        if (!notificationsData.highlightNotifications) {
          dispatch(highlightNotifications())
        }
      }}
      className="notificationsContainer"
    >
      <div
        className="notificationsCount"
        style={{
          lineHeight: "12px",
        }}
      >
        {getUnreadCount()}
      </div>
      <SvgIcon width={22} height={26} icon="notification-bell" />
    </div>
  )
}

NotificationButton.propTypes = {
  onClick: PropTypes.func,
  shouldGetNotifications: PropTypes.bool,
}

NotificationButton.defaultProps = {
  onClick: () => {},
  shouldGetNotifications: false,
}

export default NotificationButton
