import * as React from "react"
import { Box, Grid, Pagination, PaginationProps, Text } from "grommet"
import { Column, useTable, useSortBy, SortingRule } from "react-table"
import { PaginationEnvelope } from "../../utils/api/types"
import SvgIcon from "../atoms/SvgIcon"

export interface DataTableProps<TData extends object = {}> {
  columns: Array<Column<TData>>
  data: Array<TData> | PaginationEnvelope<TData>
  // ...
  showPagination?: boolean
  paginationProps?: PaginationProps
  rowHeight?: number
  sortable?: boolean
  initialSortBy?: SortingRule<TData>
  onSortColumn?: (
    column: Column<TData>,
    desc: boolean,
    setSortBy: (rule: SortingRule<TData>[]) => void,
  ) => void
}

export function DataTable<TData extends object>({
  columns,
  data,
  showPagination,
  paginationProps,
  rowHeight = 65,
  sortable = false,
  initialSortBy,
  onSortColumn,
}: DataTableProps<TData>) {
  const isPaginated = !Array.isArray(data)

  const { headerGroups, rows, prepareRow, setSortBy } = useTable<TData>(
    {
      columns,
      data: (isPaginated ? data?.results : data) ?? [],
      manualSortBy: true,
      initialState: {
        sortBy: [initialSortBy],
      },
    },
    sortable ? useSortBy : undefined,
  )

  const maximumColumns = headerGroups.reduce((acc, g) => Math.max(acc, g.headers.length), 0)

  return (
    <Box>
      <Box>
        <Grid
          style={{
            gridTemplateColumns: `repeat(${maximumColumns}, auto)`,
          }}
          rows="56px"
        >
          {headerGroups.map(headerGroup => {
            const tableHeaderGroupProps = headerGroup.getHeaderGroupProps()
            return (
              <React.Fragment key={tableHeaderGroupProps.key}>
                {headerGroup.headers.map(column => {
                  const sortColumn = () => onSortColumn(column, column.isSortedDesc, setSortBy)
                  const tableColumnProps = column.getHeaderProps(column.getSortByToggleProps?.())

                  return (
                    <Box
                      key={tableColumnProps.key}
                      style={{
                        color: "#999",
                        ...column.style,
                      }}
                      pad={{
                        horizontal: "10px",
                        vertical: "8px",
                      }}
                      direction={column.isSorted ? "row" : undefined}
                      align={column.isSorted ? "center" : undefined}
                      justify={column.isSorted ? "between" : undefined}
                      gap={column.isSorted ? "xsmall" : undefined}
                      onClick={column.defaultCanSort ? sortColumn : undefined}
                    >
                      {typeof column.Header === "string" ? (
                        <Text
                          size="small"
                          style={{ textTransform: "uppercase", whiteSpace: "nowrap" }}
                        >
                          {column.render("Header")}
                        </Text>
                      ) : (
                        column.render("Header")
                      )}
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <SvgIcon width={8} height={5} icon="chevron-down" />
                        ) : (
                          <SvgIcon width={8} height={5} icon="chevron-up" />
                        )
                      ) : null}
                    </Box>
                  )
                })}
              </React.Fragment>
            )
          })}
          {rows.map((row, i) => {
            const isLastRow = i === rows.length - 1
            prepareRow(row)
            const tableRowProps = row.getRowProps()

            return (
              <React.Fragment key={tableRowProps.key}>
                {row.cells.map((cell, i) => {
                  const tableCellProps = cell.getCellProps()
                  return (
                    <Box
                      key={`${tableRowProps.key}-${tableCellProps.key}`}
                      pad={{
                        horizontal: "10px",
                        top: "8px",
                        bottom: isLastRow ? "8px" : "",
                      }}
                      justify="center"
                      style={{
                        height: rowHeight,
                        ...cell.column.containerStyle,
                      }}
                    >
                      {cell.render("Cell", cell.column.cellProps)}
                    </Box>
                  )
                })}
              </React.Fragment>
            )
          })}
        </Grid>
      </Box>
      {showPagination && isPaginated ? (
        <Pagination {...paginationProps} numberItems={data.count} />
      ) : null}
    </Box>
  )
}
