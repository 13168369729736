import "./SavedNote.scss"

import moment from "moment"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import { deleteNote, updateNote } from "../../../redux/actions"
import { getUserAvatar } from "../../../utils/common"
import SvgIcon from "../../atoms/SvgIcon"
import UserAvatarName from "../../atoms/UserAvatarName"

const SavedNote = ({
  avatar,
  firstName,
  lastName,
  created,
  description,
  noteId,
  tripId,
  userId,
  isSystemNote,
}) => {
  const dispatch = useDispatch()
  const currentUserId = useSelector(state => state.user.id)

  const editPermissionChecker = () => {
    if (userId === currentUserId) {
      return true
    }
    return false
  }

  const removeNote = () => {
    dispatch(deleteNote(noteId, tripId))
  }

  const [savedNoteValue, setSavedNoteValue] = useState(description)
  const [isNoteDisabled, setIsNoteDisabled] = useState(true)

  const saveNote = () => {
    dispatch(updateNote(noteId, savedNoteValue))
    setIsNoteDisabled(true)
  }

  const onChangeHandler = evt => {
    setSavedNoteValue(evt.target.value)
  }

  return (
    <div className="saved-note">
      <div className="saved-note_header">
        <div className="saved-note_header-info">
          {!isSystemNote ? (
            <>
              <UserAvatarName
                fname={firstName}
                lname={lastName}
                avatarURL={avatar}
                avatarType={getUserAvatar(avatar)}
                avatarWidth="24px"
                avatarHeight="24px"
                initialsFontSize="12px"
                nameFontSize="12px"
              />
              <span className="saved-note_header-info-middleDot">·</span>
              <span className="saved-note_time">
                {moment
                  .utc(created)
                  .local()
                  .format("MMM DD, hh:mm A")}
              </span>
            </>
          ) : (
            <span>System note</span>
          )}
        </div>

        {editPermissionChecker() ? (
          <span
            role="button"
            onClick={() => setIsNoteDisabled(!isNoteDisabled)}
            className="edit-note"
          >
            <SvgIcon icon="note-edit" fill="#3c3835" width="16" height="16" />
          </span>
        ) : null}
      </div>
      {isSystemNote ? (
        description
      ) : (
        <textarea
          className="saved-note-content"
          disabled={isNoteDisabled}
          onChange={onChangeHandler}
          value={savedNoteValue}
          placeholder="Enter note here"
          rows="2"
        />
      )}

      {editPermissionChecker() ? (
        <div className="saved-note_actions">
          <span>
            <SvgIcon icon="trash-can" fill="#E35C55" width="20" height="20" />
            <span role="button" onClick={removeNote} className="delete-label">
              Delete
            </span>
          </span>
          <span>
            <span role="button" onClick={saveNote} className="send-label">
              Save
            </span>
          </span>
        </div>
      ) : null}
    </div>
  )
}

SavedNote.propTypes = {
  avatar: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  created: PropTypes.string,
  description: PropTypes.string,
  noteId: PropTypes.number,
  tripId: PropTypes.number,
  userId: PropTypes.number,
  isSystemNote: PropTypes.bool,
}

SavedNote.defaultProps = {
  avatar: "",
  firstName: "",
  lastName: "",
  created: "",
  description: "",
  noteId: null,
  tripId: null,
  userId: null,
  isSystemNote: false,
}

export default SavedNote
