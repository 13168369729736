import React from "react"
import { DataTable, DataTableProps } from "./DataTable"

type EditingContextState = {
  editingRow: number
}

export const EditingContext = React.createContext<EditingContextState>({
  editingRow: -1,
})

interface EditableDataTableProps<TData extends object = {}> extends DataTableProps<TData> {
  editingRow: number
}

export function EditableDataTable<TData extends object = {}>({
  editingRow,
  ...props
}: EditableDataTableProps<TData>) {
  return (
    <EditingContext.Provider
      value={{
        editingRow,
      }}
    >
      <DataTable {...props} />
    </EditingContext.Provider>
  )
}
