import "./RowPreview.scss"

import classNames from "classnames"
import moment from "moment"
import PropTypes from "prop-types"
import React from "react"

import { getFontColor } from "../../../utils/common"
import SvgIcon from "../SvgIcon"

const RowPreview = ({ isSelect, color }) => {
  const textClass = classNames("row-text", {
    whiteFont: getFontColor(color) === "#FFFFFF",
  })

  return (
    <div className="row-preview-container" style={{ backgroundColor: color }}>
      <div className="status-wrapper">
        <SvgIcon icon="Approved-icon" width="12" height="12" margin="0 4px 0 0" />
        <span>Approved</span>
      </div>

      <div className="item-wrapper">
        <SvgIcon
          icon="return-rloc-arrows"
          fill={getFontColor(color)}
          width="12"
          height="12"
          margin="0 4px 0 0"
        />
        <span className={textClass}>AABGXH</span>
      </div>

      <div className="item-wrapper">
        <SvgIcon
          icon="calendar"
          fill={getFontColor(color)}
          width="12"
          height="12"
          margin="0 4px 0 0"
        />
        <span className={textClass}>{moment().format("MMM DD YYYY")}</span>
      </div>

      <div className="item-wrapper">
        <SvgIcon
          icon="clock"
          fill={getFontColor(color)}
          width="12"
          height="12"
          margin="0 4px 0 0"
        />
        <span className={textClass}>08:05 AM</span>
      </div>

      <span className={textClass}>{isSelect ? "MSX" : "STC"}</span>
      <span className={textClass}>MSP</span>

      <div className="dropdown-wrapper" style={{ width: 96 }}>
        <SvgIcon
          icon={isSelect ? "select-preview" : "express-preview"}
          width="14"
          height="14"
          margin="0 6px 0 0"
        />

        <span>{isSelect ? "Landline" : "Express"}</span>
      </div>

      <div className="dropdown-wrapper" style={{ width: 114 }}>
        <SvgIcon icon="unassigned-driver-preview" width="15" height="14" margin="0 6px 0 0" />
        <span>Add driver</span>
        <SvgIcon
          icon="arrow-down-preview"
          width="4"
          height="2"
          fill="#3c3835"
          margin="0 0 0 35px"
        />
      </div>

      <div className="dropdown-wrapper" style={{ width: 58 }}>
        <span>4445667</span>
        <SvgIcon icon="arrow-down-preview" width="4" height="2" fill="#3c3835" margin="0 0 0 6px" />
      </div>

      <SvgIcon icon="close-preview" margin="15px 0 0 0" fill={getFontColor(color)} />
    </div>
  )
}

RowPreview.propTypes = {
  color: PropTypes.string.isRequired,
  isSelect: PropTypes.bool,
}

RowPreview.defaultProps = {
  isSelect: false,
}

export default RowPreview
