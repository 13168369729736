import "./AddVehicleModal.scss"

import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"

import { VEHICLE_TYPE } from "../../../constants/vehicles"
import { addVehicle, updateVehicle } from "../../../redux/actions"
import { isEmptyObject } from "../../../utils/common"
import Button from "../../atoms/Button"
import Dropdown from "../../atoms/Dropdown"
import SvgIcon from "../../atoms/SvgIcon"
import TextInput from "../../atoms/TextInput"
import UploadImage from "../../atoms/UploadImage"
import Modal from "../Modal"

const AddVehicleModal = ({
  companyId,
  isModalOpen,
  setIsModalOpen,
  isLandlineAgent,
  editVehicleData,
  onClose,
}) => {
  const dispatch = useDispatch()

  const vehicleTypesArray = Object.entries(VEHICLE_TYPE).map(([key, value]) => {
    return {
      value: key,
      name: value,
    }
  })

  const [addVehicleData, setAddVehicleData] = useState({ company: companyId })
  const [picture, setPicture] = useState(null)
  const [chosenVehicleType, setChosenVehicleType] = useState(undefined)

  const changeAddVehicleData = params =>
    setAddVehicleData(prevState => ({ ...prevState, ...params }))

  const generateArrayOfYears = () => {
    const max = new Date().getFullYear()
    const min = 1900
    const years = []

    for (let i = max; i >= min; i--) {
      years.push({ year: i })
    }
    return years
  }

  const getFormData = () => {
    const formData = new FormData()
    if (isLandlineAgent) {
      formData.append("company", addVehicleData.company)
    }
    formData.append("vehicle_model", addVehicleData.vehicle_model)
    formData.append("vehicle_id", addVehicleData.vehicle_id)
    formData.append("production_year", addVehicleData.production_year)
    formData.append("vehicle_type", addVehicleData.vehicle_type)
    formData.append("registration", addVehicleData.registration)
    if (addVehicleData.vehicle_image && typeof addVehicleData.vehicle_image !== "string") {
      formData.append("vehicle_image", addVehicleData.vehicle_image)
    }

    return formData
  }

  const getEditVehicleData = () => {
    if (addVehicleData.vehicle_image && typeof addVehicleData.vehicle_image !== "string") {
      return getFormData()
    }

    const newVehicleData = {
      company: addVehicleData.company,
      vehicle_model: addVehicleData.vehicle_model,
      vehicle_id: addVehicleData.vehicle_id,
      production_year: addVehicleData.production_year,
      vehicle_type: addVehicleData.vehicle_type,
      registration: addVehicleData.registration,
    }

    if (!addVehicleData.vehicle_image) {
      newVehicleData.vehicle_image = null
    }

    return newVehicleData
  }

  useEffect(() => {
    changeAddVehicleData({ vehicle_image: picture })
  }, [picture])

  useEffect(() => {
    if (!isEmptyObject(editVehicleData)) {
      setAddVehicleData({
        ...editVehicleData,
      })
      setChosenVehicleType(
        vehicleTypesArray.find(vehicle => +vehicle.value === editVehicleData.vehicle_type),
      )
    }
  }, [editVehicleData])

  const resetModalData = () => {
    setPicture(null)
    setAddVehicleData({ company: companyId })
    setChosenVehicleType(undefined)
  }

  return (
    <div>
      <Modal
        isOpen={isModalOpen}
        shouldCloseOnOverlayClick
        onRequestClose={() => {
          setIsModalOpen(false)
          resetModalData()
          onClose()
        }}
        render={() => (
          <div className="add-vehicle-modal">
            <div className="title-wrapper">
              <div className="title">
                {!isEmptyObject(editVehicleData) ? "Edit vehicle" : "Add vehicle"}
              </div>
              <div
                role="button"
                onClick={() => {
                  setIsModalOpen(false)
                  resetModalData()
                  onClose()
                }}
                className="close-button-wrapper "
              >
                <SvgIcon icon="close" width={14} height={14} />
              </div>
            </div>
            <UploadImage
              onChange={file => {
                setPicture(file)
              }}
              value={addVehicleData.vehicle_image}
              onRemoveImage={() => setPicture(undefined)}
            />
            <div className="field-label">Model</div>
            <div className="text-input-wrapper">
              <TextInput
                value={addVehicleData.vehicle_model}
                onChange={e => {
                  changeAddVehicleData({ vehicle_model: e.target.value })
                }}
                fullWidth
                inputStyle="add-vehicle-style"
                placeholder="Enter vehicle model"
              />
            </div>

            <div className="input-pair">
              <div className="first-input-field">
                <div className="field-label">ID</div>
                <TextInput
                  value={addVehicleData.vehicle_id}
                  onChange={e => changeAddVehicleData({ vehicle_id: e.target.value })}
                  inputStyle="add-vehicle-style"
                  fullWidth
                  placeholder="Enter ID"
                />
              </div>

              <div className="second-input-field">
                <div className="field-label">Year</div>
                <Dropdown
                  scrollable
                  value={addVehicleData.production_year}
                  onClick={item => changeAddVehicleData({ production_year: item.year })}
                  placeholder="Choose a year"
                  label=""
                  items={generateArrayOfYears()}
                  showItemField="year"
                />
              </div>
            </div>

            <div className="input-pair">
              <div className="first-input-field">
                <div className="field-label">Type</div>
                <Dropdown
                  scrollable
                  label=""
                  placeholder="Choose a vehicle type"
                  items={vehicleTypesArray}
                  showItemField="name"
                  value={chosenVehicleType?.name}
                  onClick={item => {
                    setChosenVehicleType(item)
                    changeAddVehicleData({ vehicle_type: +item.value })
                  }}
                />
              </div>

              <div className="second-input-field">
                <div className="field-label">Registration</div>
                <TextInput
                  value={addVehicleData.registration}
                  onChange={e => changeAddVehicleData({ registration: e.target.value })}
                  inputStyle="add-vehicle-style"
                  fullWidth
                  placeholder="Enter registration"
                />
              </div>
            </div>

            <div className="buttons-wrapper">
              <Button
                margin="0 12px 0 0"
                buttonType="secondaryColor"
                onClick={() => {
                  resetModalData()
                  onClose()
                  setIsModalOpen(false)
                }}
              >
                Cancel
              </Button>
              <Button
                buttonType="landlineColor"
                onClick={() => {
                  setIsModalOpen(false)
                  if (!isEmptyObject(editVehicleData)) {
                    dispatch(updateVehicle(editVehicleData.id, getEditVehicleData(), companyId))
                  } else {
                    dispatch(addVehicle(getFormData(), companyId))
                  }
                  resetModalData()
                  onClose()
                }}
              >
                {!isEmptyObject(editVehicleData) ? "Edit" : "Add"}
              </Button>
            </div>
          </div>
        )}
        style={{
          overlay: {
            position: "fixed",
            display: "flex",
            backgroundColor: "rgba(0, 0, 0, 0.2)",
            zIndex: 99999,
            overflow: "none",
          },
          content: {
            width: 468,
            height: 592,
            display: "flex",
            overflow: "none",
            boxShadow: "0px 0px 16px 4px rgba(66, 64, 62, 0.12)",
            borderRadius: "4px",
            border: "none",
          },
        }}
      />
    </div>
  )
}

AddVehicleModal.propTypes = {
  companyId: PropTypes.string.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  setIsModalOpen: PropTypes.bool.isRequired,
  isLandlineAgent: PropTypes.bool,
  editVehicleData: PropTypes.instanceOf(Object),
  onClose: PropTypes.func.isRequired,
}

AddVehicleModal.defaultProps = {
  isLandlineAgent: false,
  editVehicleData: {},
}

export default AddVehicleModal
