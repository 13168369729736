import "./TableDropDown.scss"

import classNames from "classnames"
import PropTypes from "prop-types"
import React, { useEffect, useMemo, useRef, useState } from "react"
import InfiniteScroll from "react-infinite-scroller"
import { useSelector } from "react-redux"

import API from "../../../constants/API"
import { PRE_TRIP_NOTIFICATION_FORMAT } from "../../../constants/dateFormats"
import {
  NOTIFICATION_QUEUED,
  NOTIFICATION_REQUEUED,
  NOTIFICATION_STRINGS,
} from "../../../constants/preTripNotifications"
import { PLANNING_STATUS } from "../../../constants/status"
import {
  DRIVER_ERRORS,
  OUTSIDE_OF_THE_SHIFT,
  OVER_DRIVE_LIMIT,
  OVER_DUTY_LIMIT,
  OVER_ROLLING_DUTY_LIMIT,
  OVER_WAIT_LIMIT,
  OVERLAPPING_RIDE,
} from "../../../constants/tripDriverErrors"
import apiUtils from "../../../utils/api/api-utils"
import { getLocalDateTime } from "../../../utils/common"
import { isFirefox } from "../../../utils/commonFunctionality"
import { useMounted, useOnClickOutside } from "../../../utils/hooks"
import Loading from "../../atoms/Loading"
import RowItem from "../../atoms/RowItem"
import SvgIcon from "../../atoms/SvgIcon"
import TextInput from "../../atoms/TextInput"

const TableDropDown = ({
  status,
  value,
  style,
  content,
  disabled,
  onToggle,
  toggle,
  open,
  rowRender,
  onRowClick,
  fieldKey,
  customDropDownContent,
  display,
  onOutsideClickClose,
  rowType,
  popupText,
  popupLetterLimit,
  shouldShowOnTop,
  setShouldShowOnTop,
  showCustomFirstRow,
  firstRowRender,
  onFirstRowClick,
  loadMore,
  hasMore,
  offset,
  setOffset,
  searchCallback,
  searchPlaceholder,
  isDropdownOpen,
  setDriverErrors,
  assignDriverModalVisible,
  setShowAssignDriverModal,
  setAssignDriverCallback,
  customNamePopup,
  namePopupRowID,
  rowID,
  driverNotified,
  notificationStatus,
  notificationDatetime,
}) => {
  const ref = useRef()
  const isMounted = useMounted()

  const tableDropDownClass = classNames("table-drop-down-inner-div", {
    [PLANNING_STATUS[status]]: status,
    disabled,
  })

  const dropDownContentClass = classNames("dropdown-content", {
    "show-on-top": shouldShowOnTop,
    isVehicle: rowType === "vehicle#",
  })

  const statusTextClass = classNames("status-text", NOTIFICATION_STRINGS[notificationStatus])

  const [driverPopupVisible, setDriverPopupVisible] = useState(false)
  const [avatarPopupVisible, setAvatarPopupVisible] = useState(false)
  const [driverErrorToShow, setDriverErrorToShow] = useState({})

  const [wrapperPosition, setWrapperPosition] = useState(null)
  const [avatarPosition, setAvatarPosition] = useState(null)

  const [showContent, setShowContent] = useState(false)
  const [search, setSearch] = useState("")
  const [searchResetting, setSearchResetting] = useState(false)
  const [notificationPopupVisible, setNotificationPopupVisible] = useState(false)

  const [dropDownValue, setDropDownValue] = useState(value)
  const [popupOpen, setPopupOpen] = useState(false)
  let actionToWatch
  let emptyState

  switch (rowType) {
    case "operator":
      actionToWatch = API.GET_COMPANIES
      emptyState = "There are no operators!"
      break
    case "driver":
      actionToWatch = API.GET_TRIP_DRIVERS
      emptyState = "There are no drivers!"
      break
    case "vehicle#":
      actionToWatch = API.GET_COMPANY_VEHICLES
      emptyState = "There are no vehicles!"
      break

    default:
      break
  }

  const getIcon = error => {
    const iconName = `driver-error-${error[0]}`
    let iconWidth
    let iconHeight

    switch (error[0]) {
      case OVERLAPPING_RIDE:
        iconWidth = 40
        iconHeight = 23
        break
      case OUTSIDE_OF_THE_SHIFT:
        iconWidth = 34
        iconHeight = 32
        break
      case OVER_DRIVE_LIMIT:
        iconWidth = 30
        iconHeight = 17
        break
      case OVER_DUTY_LIMIT:
        iconWidth = 30
        iconHeight = 30
        break
      case OVER_ROLLING_DUTY_LIMIT:
        iconWidth = 37
        iconHeight = 32
        break
      case OVER_WAIT_LIMIT:
        iconWidth = 21
        iconHeight = 30
        break

      default:
        break
    }

    return <SvgIcon icon={iconName} width={iconWidth} height={iconHeight} margin="0 0 14px 0" />
  }

  useEffect(() => {
    if (!isMounted || searchResetting) {
      return undefined
    }

    const delayedCall = setTimeout(() => {
      searchCallback(search)

      setOffset(0)
    }, 500)

    return () => clearTimeout(delayedCall)
  }, [search])

  const [callsInProgress] = useSelector(({ api }) => [api.callsInProgress])
  useEffect(() => {
    setDropDownValue(value)
  }, [value])

  const loading = useMemo(() => {
    return apiUtils.areCallsInProgress([actionToWatch], callsInProgress)
  }, [callsInProgress])

  const controlledDropDownContent = open || showContent

  const toggleMenu = isOpen => {
    isDropdownOpen(isOpen)
    if (toggle) {
      toggle(isOpen)
    } else {
      setShowContent(isOpen)
    }
  }

  useOnClickOutside(ref, () => {
    if (!assignDriverModalVisible && onOutsideClickClose) {
      toggleMenu(false)
    }
  })

  const onDropDownClick = e => {
    e.stopPropagation()
    if (disabled) return
    // call action only if component is toggle opening
    if (!controlledDropDownContent) onToggle()
    setSearchResetting(true)
    setSearch("")
    if (setShouldShowOnTop) {
      setShouldShowOnTop(
        window.innerHeight - ref.current.getBoundingClientRect().bottom < window.innerHeight / 2,
      )
    }
    toggleMenu(!controlledDropDownContent)
  }

  const onContentRowClick = async row => {
    if (rowType === "driver") {
      const response = await onRowClick(row.driver[fieldKey])
      if (!response) {
        setDropDownValue(rowRender(row.driver))
      }
    } else {
      const response = await onRowClick(row[fieldKey])
      if (!response) {
        setDropDownValue(rowRender(row))
      }
    }

    toggleMenu(!controlledDropDownContent)
  }
  if (display) {
    return (
      <RowItem>
        <div
          className="drop-down-only-display-container"
          onMouseEnter={() => setPopupOpen(true)}
          onMouseLeave={() => setPopupOpen(false)}
        >
          {dropDownValue}
          {popupOpen && popupText.length > popupLetterLimit && (
            <p className="planing-popup-element">{popupText}</p>
          )}
        </div>
      </RowItem>
    )
  }

  return (
    <RowItem>
      <div className="drop-down-table-container" ref={ref}>
        {driverNotified && (
          <div
            className="email-notified-container"
            onMouseEnter={() => setNotificationPopupVisible(true)}
            onMouseLeave={() => setNotificationPopupVisible(false)}
          >
            <div className={isFirefox ? "mozilla-bg" : ""}>
              <SvgIcon
                icon={`email-notified-${
                  notificationStatus === NOTIFICATION_REQUEUED
                    ? NOTIFICATION_QUEUED
                    : notificationStatus
                }`}
                width="26"
                height="26"
              />
            </div>
            {notificationPopupVisible && (
              <div className="email-notification-popup">
                <span className={statusTextClass}>{NOTIFICATION_STRINGS[notificationStatus]}</span>
                {` ${getLocalDateTime(notificationDatetime, PRE_TRIP_NOTIFICATION_FORMAT)}`}
              </div>
            )}
          </div>
        )}

        <div
          role="button"
          className={tableDropDownClass}
          style={style}
          onClick={onDropDownClick}
          onMouseEnter={() => setPopupOpen(true)}
          onMouseLeave={() => setPopupOpen(false)}
        >
          {dropDownValue}
          <SvgIcon
            className="dropdown-icon"
            fill="#3C3835"
            icon={
              controlledDropDownContent ? "table-dropdown-down-arrow" : "table-dropdown-up-arrow"
            }
            width="14"
            height="14"
          />
        </div>
        {(customNamePopup ? namePopupRowID === rowID : popupOpen) &&
          popupText.length > popupLetterLimit && (
            <p className="planing-popup-element">{popupText}</p>
          )}

        {driverPopupVisible && (
          <div
            style={{
              left: wrapperPosition.left - 260,
              top: wrapperPosition.top - 40,
            }}
            className="driver-error-popup"
          >
            {getIcon(driverErrorToShow)}
            <div className="driver-error-title">{DRIVER_ERRORS[driverErrorToShow[0]]}</div>
            <div className="driver-error-text ">{driverErrorToShow[1]}</div>
          </div>
        )}

        {avatarPopupVisible && (
          <div
            style={{
              left: avatarPosition.left - 50,
              top: avatarPosition.top - 40,
            }}
            className="driver-avatar-popup"
          >{`${"Driver's schedule"}`}</div>
        )}

        {showContent &&
          (customDropDownContent ? (
            customDropDownContent(toggleMenu, ref)
          ) : (
            <div className={dropDownContentClass} role="button" onClick={e => e.stopPropagation()}>
              <div className="search-driver-and-vehicle-container">
                <TextInput
                  onChange={e => {
                    if (searchResetting) {
                      setSearchResetting(false)
                    }
                    setSearch(e.target.value)
                  }}
                  value={search}
                  inputSize="search-driver-and-vehicle-size"
                  inputStyle="search-style"
                  icon="search"
                  iconStyle="search-icon"
                  placeholder={searchPlaceholder}
                />
              </div>
              <tbody className="infinite-scroll-table-dropdown">
                {loading && !offset ? (
                  <Loading className="loading" />
                ) : (
                  <InfiniteScroll
                    loadMore={() => loadMore(search)}
                    hasMore={hasMore}
                    pageStart={0}
                    initialLoad={false}
                    loader={<Loading className="loading" />}
                    threshold={100}
                    useWindow={false}
                  >
                    {content.length > 0 ? (
                      <>
                        {showCustomFirstRow && (
                          <div
                            role="button"
                            className="dropdown-row"
                            onClick={() => {
                              onFirstRowClick()
                              toggleMenu(!controlledDropDownContent)
                            }}
                          >
                            {firstRowRender()}
                          </div>
                        )}

                        {content.map(row => {
                          const dropdownRowClass = classNames("dropdown-row", {
                            "dead-leg": row.is_valid && row.is_valid.new_ride_deadleg,
                          })

                          return (
                            <div
                              role="button"
                              className={dropdownRowClass}
                              onClick={() => {
                                if (rowType === "driver" && row.is_valid.errors.length > 0) {
                                  setDriverErrors(row.is_valid.errors)
                                  setShowAssignDriverModal(true)
                                  setAssignDriverCallback(() => () => {
                                    onContentRowClick(row)
                                  })
                                } else {
                                  onContentRowClick(row)
                                }
                              }}
                            >
                              {rowRender(
                                row,
                                setDriverPopupVisible,
                                setWrapperPosition,
                                setDriverErrorToShow,
                                setAvatarPopupVisible,
                                setAvatarPosition,
                              )}
                            </div>
                          )
                        })}
                      </>
                    ) : (
                      <div className="empty-state-text">{emptyState}</div>
                    )}
                  </InfiniteScroll>
                )}
              </tbody>
            </div>
          ))}
      </div>
    </RowItem>
  )
}

TableDropDown.propTypes = {
  status: PropTypes.number,
  popupLetterLimit: PropTypes.number,
  value: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  style: PropTypes.instanceOf(Object),
  content: PropTypes.instanceOf(Array),
  disabled: PropTypes.bool,
  onToggle: PropTypes.func,
  toggle: PropTypes.func,
  open: PropTypes.bool,
  rowRender: PropTypes.func,
  onRowClick: PropTypes.func,
  fieldKey: PropTypes.string,
  customDropDownContent: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  display: PropTypes.bool,
  onOutsideClickClose: PropTypes.bool,
  rowType: PropTypes.string,
  popupText: PropTypes.string,
  shouldShowOnTop: PropTypes.bool,
  setShouldShowOnTop: PropTypes.func,
  showCustomFirstRow: PropTypes.bool,
  firstRowRender: PropTypes.func,
  onFirstRowClick: PropTypes.func,
  loadMore: PropTypes.func,
  hasMore: PropTypes.bool,
  offset: PropTypes.number,
  setOffset: PropTypes.func,
  searchCallback: PropTypes.func,
  isDropdownOpen: PropTypes.func,
  searchPlaceholder: PropTypes.string,
  setDriverErrors: PropTypes.func,
  setShowAssignDriverModal: PropTypes.func,
  setAssignDriverCallback: PropTypes.func,
  assignDriverModalVisible: PropTypes.bool,
  customNamePopup: PropTypes.bool,
  namePopupRowID: PropTypes.number,
  rowID: PropTypes.number,
  driverNotified: PropTypes.bool,
  notificationStatus: PropTypes.number,
  notificationDatetime: PropTypes.string,
}

TableDropDown.defaultProps = {
  popupLetterLimit: null,
  status: null,
  value: null,
  style: {},
  content: [],
  disabled: false,
  onToggle: () => {},
  toggle: undefined,
  open: false,
  rowRender: () => null,
  onRowClick: () => {},
  fieldKey: "",
  customDropDownContent: null,
  display: false,
  onOutsideClickClose: true,
  rowType: "",
  popupText: "",
  shouldShowOnTop: false,
  setShouldShowOnTop: () => {},
  showCustomFirstRow: false,
  firstRowRender: () => null,
  onFirstRowClick: () => {},
  loadMore: () => {},
  hasMore: false,
  offset: 0,
  setOffset: () => {},
  searchCallback: () => {},
  searchPlaceholder: "Search",
  isDropdownOpen: () => {},
  setDriverErrors: () => {},
  setShowAssignDriverModal: () => {},
  setAssignDriverCallback: () => {},
  assignDriverModalVisible: false,
  customNamePopup: false,
  namePopupRowID: -1,
  rowID: -1,
  driverNotified: false,
  notificationStatus: -1,
  notificationDatetime: "",
}

export default TableDropDown
