import "./StatusTableCellWithPopup.scss"

import PropTypes from "prop-types"
import React, { useState } from "react"

import SvgIcon from "../SvgIcon"

function StatusTableCellWithPopup({ reduceRate }) {
  const [popupOpen, setPopupOpen] = useState(false)

  return (
    <div className="new-requests-rate-status">
      {reduceRate && (
        <div
          className="new-requests-rate-status-content"
          onMouseEnter={() => setPopupOpen(true)}
          onMouseLeave={() => setPopupOpen(false)}
        >
          <SvgIcon className="new-requests-rate-status-icon" icon="reduced-rate" />
          {popupOpen && (
            <div className="rate-status-popup-content">
              <p>Reduced rate</p>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

StatusTableCellWithPopup.propTypes = {
  reduceRate: PropTypes.bool,
}

StatusTableCellWithPopup.defaultProps = {
  reduceRate: false,
}

export default StatusTableCellWithPopup
