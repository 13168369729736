export const LANDLINE_USER = 0
export const LANDLINE_AGENT = 1
export const COMPANY_REPRESENTATIVE = 2
export const COMPANY_DRIVER = 4

export const userRolesChoice = {
  [LANDLINE_USER]: "Landline user",
  [LANDLINE_AGENT]: "Landline agent",
  [COMPANY_REPRESENTATIVE]: "Company representative",
  [COMPANY_DRIVER]: "Company driver",
}
