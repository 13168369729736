import * as React from "react"
import RegularLayout from "."
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom"

import { COMPANY_DRIVER } from "../../../constants/userRoles"
import CurrentDate from "../../atoms/CurrentDate"
import NotificationButton from "../../atoms/NotificationButton"

interface RegularLayoutWithHeaderProps {
  children: React.ReactNode
  headerTitle: string
  wrapperClass?: string
}

type UserState = {
  user: { jwtData?: { role: number } }
}

export function RegularLayoutWithHeader({
  children,
  headerTitle,
  wrapperClass,
}: RegularLayoutWithHeaderProps) {
  const history = useHistory()
  const [userRole] = useSelector<UserState, Array<number>>(({ user }) => [user.jwtData?.role])

  return (
    <RegularLayout withTopMenu={false} history={history} backgroundColor="#ffffff">
      <div className={`regular-layout-with-header ${wrapperClass}`}>
        <div className="header-container">
          <div className="header-text">{headerTitle}</div>
          <div className="right-side-content">
            {userRole !== COMPANY_DRIVER && (
              <div className="notification-button">
                <NotificationButton onClick={() => history.push("/")} />
              </div>
            )}
            <div className="current-date">
              <CurrentDate />
            </div>
          </div>
        </div>
        {children}
      </div>
    </RegularLayout>
  )
}
