export const DAY = 1
export const NIGHT = 2
export const MORNING = 3
export const AFTERNOON = 4
export const EVENING = 5
export const OPTIONAL = 6

export const SHIFT = {
  [DAY]: "Day",
  [NIGHT]: "Night",
  [MORNING]: "Morning",
  [AFTERNOON]: "Afternoon",
  [EVENING]: "Evening",
  [OPTIONAL]: "Optional",
}
export const SHIFT_LIST = [DAY, NIGHT, MORNING, AFTERNOON, EVENING, OPTIONAL]
