import "./AvatarName.scss"

import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"

import { getFirstLetter } from "../../../utils/common"
import { changeColor } from "../../../utils/commonFunctionality"
import SvgIcon from "../SvgIcon"

const AvatarName = ({
  avatarURL,
  avatarWidth,
  avatarHeight,
  fname,
  lname,
  className,
  style,
  onClick,
  avatarType,
  companyColor,
  id,
  description,
}) => {
  const avatarNameClass = classNames(["avatar-name-container", className])

  const avatar = {
    companyWithColor: (
      <div
        className="companyColorWithLetters"
        style={{
          backgroundColor: companyColor,
          color: changeColor(companyColor),
        }}
      >
        <div>{`${getFirstLetter(fname)}${getFirstLetter(lname)}`}</div>
      </div>
    ),
    unassignedCompany: <SvgIcon icon="unassigned-car" width="24" height="24" />,
    newBooking: <SvgIcon icon="new-booking-car" width="24" height="24" />,
    driverWithoutImage: <SvgIcon icon="empty-profile" width="24" height="24" />,
    withImage: <img src={avatarURL} width={avatarWidth} height={avatarHeight} alt="Avatar" />,
    express: <SvgIcon icon="express-column-image" width="24" height="24" />,
  }[avatarType]

  return (
    <div role="button" className={avatarNameClass} style={style} onClick={() => onClick(id)}>
      {avatar}
      <div className="avatar-text-container">
        <span>
          {fname} {lname}
        </span>
        {description && <span>{description}</span>}
      </div>
    </div>
  )
}

AvatarName.propTypes = {
  avatarURL: PropTypes.string,
  fname: PropTypes.string,
  lname: PropTypes.string,
  avatarWidth: PropTypes.string,
  avatarHeight: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.instanceOf(Object),
  onClick: PropTypes.func,
  avatarType: PropTypes.oneOf([
    "unassignedCompany",
    "companyWithColor",
    "withImage",
    "driverWithoutImage",
  ]),
  companyColor: PropTypes.string,
  id: PropTypes.number,
  description: PropTypes.string,
}

AvatarName.defaultProps = {
  avatarURL: "/media/empty_profile.png",
  fname: "",
  lname: "",
  avatarWidth: "24",
  avatarHeight: "24",
  className: "",
  style: {},
  onClick: () => {},
  avatarType: "",
  companyColor: "",
  id: 0,
  description: "",
}

export default AvatarName
