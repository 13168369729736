import "./SecurityPage.scss"

import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"

import RegularLayout from "../../layouts/RegularLayout"
import Table from "../../organisms/Table"

const SecurityPage = ({ history }) => {
  const securityPageClass = classNames("security-page-container")
  return (
    <RegularLayout history={history}>
      <div className={securityPageClass}>
        <Table
          columns={["LL#", "Driver", "Vehicle", "Date", "Origin", "Depart", "Arrival", "Manifest"]}
          data={[
            ["1", "maja", "car", "17/02/20,20", "blabla", "00:15", "02:15", "blabla"],
            ["2", "stefan", "car", "17/02/20,20", "blabla", "00:15", "02:15", "blabla"],
            ["3", "marko", "car", "17/02/20,20", "blabla", "00:15", "02:15", "blabla"],
          ]}
        />
      </div>
    </RegularLayout>
  )
}

SecurityPage.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
}

SecurityPage.defaultProps = {}

export default SecurityPage
