import queryString from "query-string"

import ACTIONS from "../../constants/ACTIONS"
import API from "../../constants/API"
import { landlineApi } from "../../utils/api/calls"
import { apiError, startApiCall } from "../api/apiActions"

const getCompanyRepresentatives = queryParams => {
  return async dispatch => {
    try {
      const urlQuery = queryString.stringify(queryParams)
      dispatch(startApiCall(API.GET_COMPANY_REPRESENTATIVES))
      const representatives = await landlineApi.get(
        `v2/bossadmin/users/company_representatives/?${urlQuery}`,
      )

      dispatch({
        type: ACTIONS.GET_COMPANY_REPRESENTATIVES_SUCCESS,
        representatives: representatives.data,
        offset: queryParams?.offset,
      })
    } catch (error) {
      dispatch(apiError(API.GET_COMPANY_REPRESENTATIVES, error))
    }
  }
}

// eslint-disable-next-line
export { getCompanyRepresentatives }
