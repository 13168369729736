import "./RegularLayout.scss"

import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"
import { useSelector } from "react-redux"

import SideBar from "../../organisms/SideBar"
import TopMenu from "../../organisms/TopMenu"

const RegularLayout = ({ children, history, withTopMenu, backgroundColor }) => {
  const regularLayoutContent = classNames("regular-layout-content", { withTopMenu })

  const [appData] = useSelector(({ app }) => [app])
  const sideBarClass = classNames("regular-layout-container", {
    isCollapsed: appData.isSidebarCollapsed,
  })

  return (
    <div className={sideBarClass} style={{ backgroundColor }}>
      {withTopMenu && <TopMenu history={history} />}
      <SideBar history={history} />
      <div className={regularLayoutContent}>{children}</div>
    </div>
  )
}

RegularLayout.propTypes = {
  children: PropTypes.element.isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
  withTopMenu: PropTypes.bool,
  backgroundColor: PropTypes.string,
}

RegularLayout.defaultProps = {
  withTopMenu: true,
  backgroundColor: "",
}

export default RegularLayout
