import "./EmptyState.scss"

import React from "react"

import SvgIcon from "../../atoms/SvgIcon"

const EmptyState = () => {
  return (
    <div className="empty-state">
      <div className="content">
        <img width="292" src="/media/new-requests-empty-state.png" alt="No new requests" />
        <div className="empty-state-label">
          Everything&lsquo;s clear{" "}
          <span>
            <SvgIcon icon="green-checkmark" fill="#5CBB69" width="20" height="20" />
          </span>
        </div>
      </div>
    </div>
  )
}

export default EmptyState
