import "./App.css"
import "./globals.scss"

import React, { useMemo } from "react"
import { useSelector } from "react-redux"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"

import LoadingAnimation from "./components/atoms/LoadingAnimation"
import ControlPage from "./components/pages/ControlPage"
import D2DQuotingPage from "./components/pages/D2DQuotingPage"
import DashboardPage from "./components/pages/DashboardPage"
import DriversPage from "./components/pages/DriversPage"
import DriversProfilePage from "./components/pages/DriversProfilePage"
import LoginPage from "./components/pages/LoginPage"
import OperatorsPage from "./components/pages/OperatorsPage"
import OperatorsProfilePage from "./components/pages/OperatorsProfilePage"
import Page404 from "./components/pages/Page404"
import PasswordRecovery from "./components/pages/PasswordRecovery"
import PasswordResetPage from "./components/pages/PasswordResetPage"
import PlanningPage from "./components/pages/PlanningPage"
import SecurityPage from "./components/pages/SecurityPage"
import SettingsPage from "./components/pages/SettingsPage"
import VehiclesPage from "./components/pages/VehiclesPage"
import ManifestPage from "./components/pages/ManifestPage"
import AuthenticatedRoute from "./components/routers/AuthenticatedRoute"
import GuestUserRoute from "./components/routers/GuestUserRoute"
import ACTIONS from "./constants/ACTIONS"
import API from "./constants/API"
import apiUtils from "./utils/api/api-utils"
import { RoutesPage } from "./components/pages/RoutesPage"

function App() {
  const [callsInProgress] = useSelector(({ api }) => [api.callsInProgress])

  const loading = useMemo(() => {
    return apiUtils.areCallsInProgress(
      [ACTIONS.UPDATE_TRIP_ROW, ACTIONS.UPDATE_TRIP_ROW_SUCCESS, API.SUGGEST_TRIP],
      callsInProgress,
    )
  }, [callsInProgress])

  return (
    <Router>
      {loading && <LoadingAnimation />}
      <Switch>
        <GuestUserRoute exact path="/login" component={LoginPage} />
        <AuthenticatedRoute exact path={["/planning/:tripFilters?"]} component={PlanningPage} />
        <AuthenticatedRoute exact path="/" component={DashboardPage} />
        <AuthenticatedRoute exact path="/operators" component={OperatorsPage} />
        <AuthenticatedRoute exact path="/quoting" component={D2DQuotingPage} />
        <AuthenticatedRoute
          exact
          path="/operators/:companyId/:tab?"
          component={OperatorsProfilePage}
        />
        <AuthenticatedRoute
          exact
          path="/operators/:companyId/drivers/:driverId/:tab?/:calendarDate?"
          component={DriversProfilePage}
        />
        <AuthenticatedRoute exact path="/landline/:tab?" component={OperatorsProfilePage} />
        <AuthenticatedRoute
          exact
          path="/landline/drivers/:driverId/:tab?/:calendarDate?"
          component={DriversProfilePage}
        />
        <AuthenticatedRoute exact path="/settings/:tab?" component={SettingsPage} />
        <AuthenticatedRoute
          exact
          path="/routes/:routeFilters?"
          component={RoutesPage}
          requiresPermission="view_routedelay"
        />
        <AuthenticatedRoute exact path="/manifest" component={ManifestPage} />

        {/* TODO determine if these pages are used for anything */}
        <Route exact path="/control" component={ControlPage} />
        <Route exact path="/security" component={SecurityPage} />
        <Route exact path="/drivers" component={DriversPage} />
        <Route exact path="/vehicles" component={VehiclesPage} />
        <Route exact path="/passwordRecovery" component={PasswordRecovery} />
        <Route exact path="/passwordReset/:code" component={PasswordResetPage} />

        <Route component={Page404} />
      </Switch>
    </Router>
  )
}

export default App
