import "./CurrentDate.scss"

import moment from "moment-timezone"
import React, { useEffect, useState } from "react"

const getDate = () => {
  return moment()
    .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
    .format("hh:mmA (zz) dddd, DD MMM yyyy")
}
const CurrentDate = () => {
  const [currentDate, setCurrentDate] = useState(getDate())
  useEffect(() => {
    setInterval(() => {
      setCurrentDate(getDate())
    }, 5000)
  })
  return <div className="date-text">{currentDate}</div>
}

CurrentDate.propTypes = {}

CurrentDate.defaultProps = {}

export default CurrentDate
