import "./DriverCard.scss"

import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"

import AvatarImage from "../../atoms/AvatarImage"
import ItemForDriverCard from "../../atoms/ItemForDriverCard"
import SvgIcon from "../../atoms/SvgIcon"

const DriverCard = ({ name, footerName, link }) => {
  const driverCardClass = classNames("driver-card-container")
  return (
    <div className={driverCardClass}>
      <span className="link">{link}</span>
      <AvatarImage isActive />
      <span className="threeDot">
        <SvgIcon icon="threeDot" />
      </span>

      <h4>{name}</h4>
      <SvgIcon icon="phone" />
      <SvgIcon icon="message" />
      <ItemForDriverCard city="DULUTH - DLH" tripNumber="LB 051" />
      <h5 className="footer">{footerName}</h5>
    </div>
  )
}

DriverCard.propTypes = {
  name: PropTypes.string.isRequired,
  footerName: PropTypes.string,
  link: PropTypes.string,
}

DriverCard.defaultProps = {
  footerName: "CTA",
  link: "Go to profile",
}

export default DriverCard
