import ACTIONS from "../../constants/ACTIONS"

const initialState = {
  driverDetails: {},
  driverEvents: [],
}

export default function driverReducer(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.GET_COMPANY_DRIVERS_SUCCESS:
      if (action.offset) {
        return {
          ...state,
          // companyID: list of company vehicles
          [action.companyId]: {
            ...action.drivers,
            results: [...state[action.companyId].results, ...action.drivers.results],
          },
        }
      }

      return {
        ...state,
        [action.companyId]: action.drivers,
      }

    case ACTIONS.GET_ALL_DRIVERS_SUCCESS:
      if (action.offset) {
        return {
          ...state,
          allDrivers: {
            ...action.drivers,
            results: [...state.allDrivers.results, ...action.drivers.results],
          },
        }
      }
      return {
        ...state,
        allDrivers: action.drivers,
      }

    case ACTIONS.GET_DRIVER_SUCCESS:
      if (action.offset) {
        return {
          ...state,
          selectedCompanyDrivers: {
            ...action.drivers,
            results: [...state.selectedCompanyDrivers.results, ...action.drivers.results],
          },
        }
      }
      return {
        ...state,
        selectedCompanyDrivers: action.drivers,
      }

    case ACTIONS.UPDATE_DRIVER_DETAILS_SUCCESS:
    case ACTIONS.GET_DRIVER_DETAILS_SUCCESS:
      return {
        ...state,
        driverDetails: action.driverDetails,
      }

    case ACTIONS.UPDATE_DRIVER_VEHICLE_LICENCE_SUCCESS:
      return {
        ...state,
        driverDetails: {
          ...state.driverDetails,
          driver_profile: {
            ...state.driverDetails.driver_profile,
            vehicle_licences: action.newLicences,
          },
        },
      }

    case ACTIONS.CLEAR_DRIVER_DETAILS:
      return {
        ...state,
        driverDetails: initialState.driverDetails,
      }

    case ACTIONS.GET_DRIVER_EVENTS_SUCCESS:
      return {
        ...state,
        driverEvents: action.driverEvents,
      }

    case ACTIONS.RESET_DRIVER_EVENTS_SUCCESS: {
      return {
        ...state,
        driverEvents: initialState.driverEvents,
      }
    }

    case ACTIONS.GET_TRIP_DRIVERS_SUCCESS:
      return {
        ...state,
        tripDrivers: action.tripDrivers,
      }

    case ACTIONS.NOTIFY_CUSTOMERS_SUCCESS:
      return {
        ...state,
        failedNotificationIds: action.data.mails_not_sent,
      }

    default:
      return { ...state }
  }
}
