import { PropTypes } from "prop-types"
import React, { useCallback, useEffect, useState } from "react"
import { useDispatch } from "react-redux"

import {
  DRIVER_RIDE,
  DRIVER_STATUS,
  DROPDOWN_DRIVER_STATUSES,
} from "../../../constants/driverStatuses"
import { CALENDAR_FILTERS } from "../../../constants/filterTypes"
import { TRANSPORTATION_TYPE } from "../../../constants/transportationTypes"
import { updateFilters } from "../../../redux/actions"
import Dropdown from "../../atoms/Dropdown"
import FiltersDropdown from "../../atoms/FiltersDropdown"

const CalendarFilters = ({ isFilterChecked, setIsFilterChecked }) => {
  const dispatch = useDispatch()

  const [isFilterOpen, setIsFilterOpen] = useState({ status: false, vehicle_type: false })
  const [filterValues, setFilterValues] = useState({ status: null, vehicle_type: null })

  const changeIsFilterOpen = params => {
    setIsFilterOpen(prevState => ({ ...prevState, ...params }))
  }

  const changeIsFilterChecked = params => {
    setIsFilterChecked(prevState => ({ ...prevState, ...params }))
  }

  const changeFilterValues = params => {
    setFilterValues(prevState => ({ ...prevState, ...params }))
  }

  const driverStatusData = [
    ...DROPDOWN_DRIVER_STATUSES,
    { value: DRIVER_RIDE, name: DRIVER_STATUS[DRIVER_RIDE] },
  ]

  const vehicleTypeData = Object.entries(TRANSPORTATION_TYPE).map(([key, value]) => ({
    value: key,
    name: value,
  }))

  const clearAllFilters = () => {
    setIsFilterChecked({ status: false, vehicle_type: false })
    setFilterValues({ status: null, vehicle_type: null })
  }

  const filters = [
    {
      name: "Status",
      key: "status",
      value: filterValues.status,
      content: (
        <Dropdown
          placeholder="Choose status"
          className="filter-dropdown"
          onClick={item => changeFilterValues({ status: item.value })}
          value={driverStatusData.find(item => item.value === filterValues.status)?.name}
          items={driverStatusData}
          showItemField="name"
          label=""
          scrollable
        />
      ),
      isOpen: isFilterOpen.status,
      isChecked: isFilterChecked.status,
      setIsOpen: () => changeIsFilterOpen({ status: !isFilterOpen.status }),
      setIsChecked: () => changeIsFilterChecked({ status: !isFilterChecked.status }),
      isNextFilterOpen: isFilterOpen.vehicle_type,
    },
    {
      name: "Vehicle type",
      key: "vehicle_type",
      value: filterValues.vehicle_type,
      content: (
        <Dropdown
          placeholder="Select vehicle"
          className="filter-dropdown"
          onClick={item => changeFilterValues({ vehicle_type: item.value })}
          value={vehicleTypeData.find(item => item.value === filterValues.vehicle_type)?.name}
          items={vehicleTypeData}
          showItemField="name"
          label=""
        />
      ),
      isOpen: isFilterOpen.vehicle_type,
      isChecked: isFilterChecked.vehicle_type,
      setIsOpen: () => changeIsFilterOpen({ vehicle_type: !isFilterOpen.vehicle_type }),
      setIsChecked: () => changeIsFilterChecked({ vehicle_type: !isFilterChecked.vehicle_type }),
      isNextFilterOpen: false,
    },
  ]

  const getQueryFilters = () => {
    const filtersObj = {}

    filters.forEach(filter => {
      if (filter.isChecked && filter.value) {
        filtersObj[filter.key] = filter.value
      } else {
        filtersObj[filter.key] = undefined
      }
    })

    return filtersObj
  }

  const handleUpdateFilters = useCallback(() => {
    const queryFilters = getQueryFilters()

    dispatch(updateFilters(CALENDAR_FILTERS, queryFilters))
  }, [getQueryFilters, dispatch, updateFilters])

  useEffect(() => {
    handleUpdateFilters()
  }, [
    isFilterChecked.status && filterValues.status,
    isFilterChecked.vehicle_type && filterValues.vehicle_type,
  ])

  return <FiltersDropdown filterItems={filters} clearAllFilters={clearAllFilters} calendarFilters />
}

CalendarFilters.propTypes = {
  isFilterChecked: PropTypes.instanceOf(Object).isRequired,
  setIsFilterChecked: PropTypes.func.isRequired,
}

CalendarFilters.defaultProps = {}

export default CalendarFilters
