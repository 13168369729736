import "./OperatorsEmptyState.scss"

import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"

const OperatorsEmptyState = ({ searchedItems, afterSearch, searchedField, withStyle }) => {
  const emptyStateClass = classNames({ "operators-empty-state-text": withStyle })

  return (
    <div className={emptyStateClass}>{`${
      afterSearch
        ? `No ${searchedItems} with the given ${searchedField} were found.`
        : `There are no ${searchedItems} added, please contact Landline admin.`
    }`}</div>
  )
}

OperatorsEmptyState.propTypes = {
  searchedItems: PropTypes.string.isRequired,
  afterSearch: PropTypes.bool,
  searchedField: PropTypes.string,
  withStyle: PropTypes.bool,
}

OperatorsEmptyState.defaultProps = {
  afterSearch: false,
  searchedField: "",
  withStyle: false,
}

export default OperatorsEmptyState
