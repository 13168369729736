import "./PendingRequestsTable.scss"

import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"

import EmptyState from "../../molecules/EmptyState"
import Table from "../Table"
import TableBody from "../TableBody"

const PendingRequestsTable = ({
  requestsData,
  columns,
  loadMore,
  hasMore,
  initialLoading,
  pendingSuggestionsScrollRef,
}) => {
  const newRequestsTableClass = classNames("new-pending-requests-table-container")
  return (
    <div className={newRequestsTableClass}>
      {(initialLoading || requestsData.length > 0) && (
        <Table id="newRequestsTable" title="Pending Trip Suggestions">
          <TableBody
            columns={columns}
            data={requestsData}
            loadMore={loadMore}
            hasMore={hasMore}
            initialLoading={initialLoading}
            scrollRef={pendingSuggestionsScrollRef}
            tableBodyType="newTripSuggestions"
          />
        </Table>
      )}
      {!requestsData.length && !initialLoading && <EmptyState />}
    </div>
  )
}

PendingRequestsTable.propTypes = {
  requestsData: PropTypes.instanceOf(Array),
  columns: PropTypes.instanceOf(Array),
  loadMore: PropTypes.func,
  hasMore: PropTypes.bool,
  initialLoading: PropTypes.bool,
  pendingSuggestionsScrollRef: PropTypes.instanceOf(Element),
}

PendingRequestsTable.defaultProps = {
  requestsData: [],
  columns: [],
  loadMore: () => {},
  hasMore: false,
  initialLoading: false,
  pendingSuggestionsScrollRef: false,
}

export default PendingRequestsTable
