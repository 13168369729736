import "./LoadingAnimation.scss"

import React from "react"

function LoadingAnimation() {
  return (
    <div className="loadingAnimation">
      <div className="loadingAnimation--content">
        <img src="/media/car.png" alt="" />
        <img src="/media/tire-animation.gif" alt="" />
        <img src="/media/shadow.png" alt="" />
      </div>
    </div>
  )
}

export default LoadingAnimation
