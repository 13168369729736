import { Box, Text } from "grommet"
import React from "react"
import SvgIcon from "../atoms/SvgIcon"

interface EmptyStateProps {
  title: string
  subTitle?: string
}

export function EmptyState({ title, subTitle }: EmptyStateProps) {
  return (
    <Box align="center">
      <img width="702" src="/media/results-empty-state.png" alt="No results" />
      <Box direction="row" align="center">
        <Text size="large">{title}</Text>
        <SvgIcon icon="Cancelled-icon" fill="#5CBB69" width={20} height={20} />
        {subTitle ? <Text>{subTitle}</Text> : null}
      </Box>
    </Box>
  )
}
