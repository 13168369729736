import "./RowItem.scss"

import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"

const RowItem = ({ className, style, children, ref }) => {
  const rowItemClass = classNames(`row-item-container ${className}`)
  return (
    <div className={rowItemClass} style={style} ref={ref}>
      {children}
    </div>
  )
}

RowItem.propTypes = {
  className: PropTypes.string,
  style: PropTypes.instanceOf(Object),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  ref: PropTypes.instanceOf(Object),
}

RowItem.defaultProps = {
  className: "",
  style: {},
  children: null,
  ref: {},
}

export default RowItem
