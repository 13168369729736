import "./Table.scss"

import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"

const Table = ({ children, id, title, tabs, className }) => {
  const containerClass = classNames(["table-container", className])

  return (
    <>
      {title && (
        <label className="table-title-wrapper" htmlFor={id}>
          {title}
        </label>
      )}
      {tabs}
      <div id={id} className={containerClass}>
        {children}
      </div>
    </>
  )
}

Table.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  id: PropTypes.string,
  tabs: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  title: PropTypes.string,
  className: PropTypes.string,
}

Table.defaultProps = {
  children: null,
  id: "",
  tabs: null,
  title: "",
  className: "",
}

export default Table
