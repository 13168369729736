import queryString from "query-string"
import { toast } from "react-toastify"

import ACTIONS from "../../constants/ACTIONS"
import API from "../../constants/API"
import { VEHICLE_AVAILABLE } from "../../constants/vehicles"
import { landlineApi } from "../../utils/api/calls"
import { apiError, startApiCall } from "../api/apiActions"

const getCompanyVehicles = queryParams => {
  return async dispatch => {
    try {
      const urlQuery = queryString.stringify({ ...queryParams, status: VEHICLE_AVAILABLE })

      dispatch(startApiCall(API.GET_COMPANY_VEHICLES))
      const vehicles = await landlineApi.get(`v2/bossadmin/vehicles/?${urlQuery}`)

      dispatch({
        type: ACTIONS.GET_COMPANY_VEHICLES_SUCCESS,
        vehicles: vehicles.data,
        companyId: queryParams?.company,
        offset: queryParams?.offset,
      })
    } catch (error) {
      dispatch(apiError(API.GET_COMPANY_VEHICLES, error))
    }
  }
}
const getAllVehicles = queryParams => {
  return async dispatch => {
    try {
      const urlQuery = queryString.stringify(queryParams)

      dispatch(startApiCall(API.GET_ALL_VEHICLES))
      const vehicles = await landlineApi.get(`v2/bossadmin/vehicles/?${urlQuery}`)

      dispatch({ type: ACTIONS.GET_ALL_VEHICLES_SUCCESS, vehicles: vehicles.data })
    } catch (error) {
      dispatch(apiError(API.GET_ALL_VEHICLES, error))
    }
  }
}

const getVehicle = queryParams => {
  return async dispatch => {
    try {
      const urlQuery = queryString.stringify(queryParams)
      dispatch(startApiCall(API.GET_VEHICLE))
      const vehicles = await landlineApi.get(`v2/bossadmin/vehicles/?${urlQuery}`)

      dispatch({
        type: ACTIONS.GET_VEHICLE_SUCCESS,
        vehicle: vehicles.data,
        offset: queryParams?.offset,
      })
    } catch (error) {
      dispatch(apiError(API.GET_VEHICLE, error))
    }
  }
}

const addVehicle = (data, companyId) => {
  return async dispatch => {
    try {
      dispatch(startApiCall(API.ADD_VEHICLE))
      await landlineApi.post(`v2/bossadmin/vehicles/`, data)

      dispatch(getVehicle({ company: companyId }))
      toast.success("Vehicle added successfuly.")
    } catch (error) {
      dispatch(apiError(API.ADD_VEHICLE, error))
    }
  }
}

const patchVehicle = (vehicleId, data, companyId) => {
  return async dispatch => {
    try {
      dispatch(startApiCall(API.PATCH_VEHICLE))

      await landlineApi.patch(`v2/bossadmin/vehicles/${vehicleId}/`, data)

      if (companyId) {
        dispatch(getVehicle({ company: companyId }))
      }

      toast.success(`Vehicle updated successfuly.`)
    } catch (error) {
      dispatch(apiError(API.PATCH_VEHICLE, error))
    }
  }
}

const updateVehicle = (vehicleId, data, companyId) => {
  return async dispatch => {
    try {
      dispatch(startApiCall(API.UPDATE_VEHICLE))

      await landlineApi.put(`v2/bossadmin/vehicles/${vehicleId}/`, data)

      if (companyId) {
        dispatch(getVehicle({ company: companyId }))
      }

      toast.success(`Vehicle updated successfuly.`)
    } catch (error) {
      dispatch(apiError(API.UPDATE_VEHICLE, error))
    }
  }
}

const setVehicleStatus = (ids, status, companyId) => {
  return async dispatch => {
    try {
      dispatch(startApiCall(API.SET_VEHICLE_STATUS))

      await landlineApi.post(`v2/bossadmin/vehicles/set_status/`, { ids, status })
      dispatch(getVehicle({ company: companyId }))

      toast.success(`Vehicle status updated successfuly.`)
    } catch (error) {
      dispatch(apiError(API.SET_VEHICLE_STATUS))
    }
  }
}

// eslint-disable-next-line
export {
  getCompanyVehicles,
  getAllVehicles,
  getVehicle,
  addVehicle,
  patchVehicle,
  updateVehicle,
  setVehicleStatus,
}
