export const ROUTES = {
  PLANNING_PAGE: "/planning",
  DASHBOARD_PAGE: "/",
  OPERATORS_PAGE: "/operators",
  PASSWORD_RECOVERY_PAGE: "/passwordRecovery",
  PASSWORD_RESET_PAGE: "/passwordReset",
}
export const EXTERNAL_ROUTES = {
  PASSWORD_RECOVERY: "/password/recovery",
}
