import ACTIONS from "../../constants/ACTIONS"

/**
 * TODO make it a function add JSDoc & desc for return
 */
const initialState = {
  formData: {},
  formErrors: {},
  formResponse: false,
  formSending: false,
}

export default function formsReducer(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.UPDATE_FORM_FIELD:
      return {
        ...state,
        formData: {
          ...state.formData,
          [action.field]: action.value,
        },
      }

    case ACTIONS.UPDATE_FORM_FIELDS:
      return {
        ...state,
        formData: {
          ...state.formData,
          ...action.fields,
        },
      }

    case ACTIONS.INIT_FORM:
      return {
        ...state,
        formData: action.formData,
      }

    case ACTIONS.CLEAR_FORM:
      return {
        ...state,
        formData: {},
      }

    case ACTIONS.SET_FORM_ERRORS:
      return {
        ...state,
        formErrors: {
          ...state.formErrors,
          ...action.formErrors,
        },
      }

    case ACTIONS.CLEAR_FORM_ERRORS:
      return {
        ...state,
        formErrors: {},
      }
    case ACTIONS.SET_FORM_SENT:
      return {
        ...state,
        formSending: true,
      }
    case ACTIONS.FORM_SENT_RESET:
      return {
        ...state,
        formSending: false,
      }
    case ACTIONS.SET_FORM_RESPONSE:
      return {
        ...state,
        formResponse: true,
      }

    default:
      return { ...state }
  }
}
