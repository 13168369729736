import "./TripDetailsModal.scss"

import classNames from "classnames"
import moment from "moment"
import PropTypes from "prop-types"
import React, { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router"

import API from "../../../constants/API"
import {
  DEFAULT_DATETIME,
  TWELVE_HOUR_WITHOUT_LEADING_ZERO,
  TWENTY_FOUR_HOUR_WITH_LEADING_ZERO,
} from "../../../constants/dateFormats"
import {
  ARRIVAL_AFTER_DEPART,
  DEPART_BEFORE_ARRIVAL,
  DROP_OFF_AFTER_PICK_UP,
  DROP_OFF_NEEDS_UPDATE,
  PICK_UP_BEFORE_DROP_OFF,
  PICK_UP_NEEDS_UPDATE,
} from "../../../constants/formErrors"
import { INTERNAL_NOTE, PUBLIC_NOTE } from "../../../constants/notes"
import { SUNCOUNTRY } from "../../../constants/operators"
import { ROUTES } from "../../../constants/routes"
import { CANCELLED_STATUS, NEW_STATUS } from "../../../constants/status"
import { COMPANY_REPRESENTATIVE, LANDLINE_AGENT } from "../../../constants/userRoles"
import {
  getTrip,
  refreshVidecomTicket,
  resetTripExternalFetch,
  updateRow,
  updateTrip,
} from "../../../redux/actions"
import { getCities } from "../../../redux/cities/citiesActions"
import apiUtils from "../../../utils/api/api-utils"
import { changeState, isEmptyObject } from "../../../utils/common"
import { redirectToPlanning } from "../../../utils/commonFunctionality"
import { getRLOCIcon } from "../../../utils/utilFunctions"
import Button from "../../atoms/Button"
import Collapsible from "../../atoms/Collapsible"
import DropDownCalendar from "../../atoms/DropDownCalendar"
import Loading from "../../atoms/Loading"
import SvgIcon from "../../atoms/SvgIcon"
import TextInput from "../../atoms/TextInput"
import TimeInputPicker from "../../atoms/TimeInputPicker"
import BookingNote from "../BookingNote"
import Modal from "../Modal"

const TripDetailsModal = ({ tripID, setShowEditTripModal, setTripDetailID, setIdToHighlight }) => {
  const tripDetailsModalClass = classNames("trip-details-modal-container")

  const dispatch = useDispatch()
  const history = useHistory()

  const [userRole, activeNotification, trips] = useSelector(({ user, notifications, trip }) => [
    user.jwtData?.role,
    notifications.activeNotification,
    trip,
  ])

  const [loading, setLoading] = useState(true)
  const [pickupCollapse, setPickupCollapse] = useState(activeNotification?.showPickUp)
  const [dropOffCollapse, setDropOffCollapse] = useState(activeNotification?.showDropOff)
  const [flightInfoCollapse, setFlightInfoCollapse] = useState(false)
  const [passengerDetailsCollapse, setPassengerDetailsCollapse] = useState(false)
  const [internalNotesCollapse, setInternalNotesCollapse] = useState(
    activeNotification?.showInternalNote,
  )
  const [singleNoteToShow, setSingleNoteToShow] = useState(activeNotification?.noteId)
  const [publicNotesCollapse, setPublicNotesCollapse] = useState(activeNotification?.showPublicNote)
  const [tripDetails, setTripDetails] = useState({})
  const [showPickUpWarning, setShowPickUpWarning] = useState(false)
  const [showDropOffWarning, setShowDropOffWarning] = useState(false)
  const [showDepartureWarning, setShowDepartureWarning] = useState(false)
  const [showArrivalWarning, setShowArrivalWarning] = useState(false)
  const [showTooltip, setShowTooltip] = useState(false)
  const [errorMessages, setErrorMessages] = useState({
    pickUpTime: "",
    dropOffTime: "",
    departureTime: "",
    arrivalTime: "",
  })
  const [showAddressConfirmationModal, setShowAddressConfirmationModal] = useState(false)
  const [pickUpErrors, setPickUpErrors] = useState({})
  const [dropOffErrors, setDropOffErrors] = useState({})
  const [passengerErrors, setPassengerErrors] = useState({})

  const getErrorMessage = (errors, field) => {
    return errors && errors[field] && errors[field][0]
  }

  const changeErrorMessages = params => {
    setErrorMessages(prevState => ({ ...prevState, ...params }))
  }

  const pickUpDateInputPairClass = classNames("date-input-pair", { warning: showPickUpWarning })
  const dropOffDateInputPairClass = classNames("date-input-pair", { warning: showDropOffWarning })

  const [cities, setCities] = useState({})
  const [initialAddress, setInitialAddress] = useState({})

  useEffect(() => {
    if (trips.externalTripDetailsFetch) {
      changeState(setTripDetails, trips.tripDetails)
      setInitialAddress({
        drop_off_address: { ...trips.tripDetails.pick_up_details?.drop_off_address },
        pick_up_address: { ...trips.tripDetails.pick_up_details?.pick_up_address },
      })
      setLoading(false)
    } else {
      const getTripDetails = async () => {
        setLoading(true)
        const trip = await dispatch(getTrip({ tripID }))
        if (!trip.data) {
          history.push(ROUTES.DASHBOARD_PAGE)
        } else {
          changeState(setTripDetails, trip.data)
          const citiesData = await dispatch(
            getCities(trip.data?.depart.iata, trip.data?.arrive.iata),
          )
          changeState(setCities, citiesData)

          setInitialAddress({
            drop_off_address: { ...trip.data.pick_up_details?.drop_off_address },
            pick_up_address: { ...trip.data.pick_up_details?.pick_up_address },
          })
          setLoading(false)
        }
      }
      getTripDetails()
    }

    return () => dispatch(resetTripExternalFetch())
  }, [tripID])

  const toggleSegmentEnabled =
    tripDetails.other_segment &&
    (userRole === LANDLINE_AGENT ||
      tripDetails.other_segment.status !== NEW_STATUS ||
      tripDetails.other_segment.status !== CANCELLED_STATUS)

  const handleToggleSegment = async () => {
    if (toggleSegmentEnabled) {
      dispatch(resetTripExternalFetch())
      redirectToPlanning(
        history,
        {
          departure_time__gte: tripDetails.other_segment?.departure_time,
        },
        true,
      )
      setTripDetailID(tripDetails.other_segment?.id)
      setIdToHighlight(tripDetails.other_segment?.id)
      setShowTooltip(false)
    }
  }

  const tripWayIconClass = classNames("trip-way-icon", { "toggle-enabled": toggleSegmentEnabled })

  const isDepartureAirport = cities.departureCities && cities.departureCities[0].is_airport
  const isArrivalAirport = cities.departureCities && cities.arrivalCities[0].is_airport

  const onPickupCollapse = open => {
    setPickupCollapse(open)
  }

  useEffect(() => {
    if (tripDetails.departure_time) {
      setTripDetails(prevState => ({
        ...prevState,
        departure_time_write: tripDetails.departure_time,
        arrival_time_write: tripDetails.arrival_time,
      }))
    }
  }, [tripDetails.departure_time])

  useEffect(() => {
    if (
      tripDetails.departure_time_write !== tripDetails.departure_time &&
      tripDetails.arrival_time_write === tripDetails.arrival_time
    ) {
      setDropOffCollapse(true)
      setShowDropOffWarning(true)
      changeErrorMessages({ dropOffTime: DROP_OFF_NEEDS_UPDATE })
    }

    if (
      tripDetails.arrival_time_write !== tripDetails.arrival_time &&
      tripDetails.departure_time_write === tripDetails.departure_time
    ) {
      setPickupCollapse(true)
      setShowPickUpWarning(true)
      changeErrorMessages({ pickUpTime: PICK_UP_NEEDS_UPDATE })
    }
  }, [tripDetails.departure_time_write, tripDetails.arrival_time_write])

  const onPickupAddressChange = (e, field) => {
    const newValue = e.target.value
    setTripDetails(prevState => ({
      ...prevState,
      pick_up_details: {
        ...prevState.pick_up_details,
        pick_up_address: {
          ...prevState.pick_up_details.pick_up_address,
          [field]: newValue,
        },
      },
    }))
  }

  const onDropoffAddressChange = (e, field) => {
    const newValue = e.target.value
    setTripDetails(prevState => ({
      ...prevState,
      pick_up_details: {
        ...prevState.pick_up_details,
        drop_off_address: {
          ...prevState.pick_up_details.drop_off_address,
          [field]: newValue,
        },
      },
    }))
  }

  const onPickUpDetailsChange = (value, field) => {
    setTripDetails(prevState => ({
      ...prevState,
      pick_up_details: {
        ...prevState.pick_up_details,
        [field]: value,
      },
    }))
  }
  const onPickupTimeChange = (time, timeToChange, field) => {
    const t = moment(time, TWENTY_FOUR_HOUR_WITH_LEADING_ZERO)
    const d = moment(timeToChange)
    d.set({
      hour: t.hour(),
      minute: t.minute(),
      second: t.second(),
    })

    if (field === "departure_time_write" || field === "arrival_time_write") {
      setTripDetails(prevState => ({
        ...prevState,
        [field]: d.format(DEFAULT_DATETIME),
      }))
    } else {
      onPickUpDetailsChange(d.format(DEFAULT_DATETIME), field)
    }
  }

  const onPickupDateChange = (date, dateToChange, field) => {
    const newDate = moment(date)
    const oldDate = moment(dateToChange)

    if (dateToChange) {
      newDate.set({
        hour: oldDate.hour(),
        minute: oldDate.minute(),
        second: oldDate.second(),
      })
    }

    if (field === "departure_time_write" || field === "arrival_time_write") {
      setTripDetails(prevState => ({
        ...prevState,
        [field]: newDate.format(DEFAULT_DATETIME),
      }))
    } else {
      onPickUpDetailsChange(newDate.format(DEFAULT_DATETIME), field)
    }
  }

  const onHolderPassengerChange = (field, value) => {
    setTripDetails(prevState => ({
      ...prevState,
      holder_passenger: {
        ...prevState.holder_passenger,
        [field]: value,
      },
    }))
  }

  const isPickupAfterDropOff = () =>
    moment(tripDetails.departure_time_write).isAfter(moment(tripDetails.arrival_time_write))

  const isDepartureAfterArrival = () =>
    tripDetails.pick_up_details.departure_datetime &&
    tripDetails.pick_up_details.arrival_datetime &&
    moment(tripDetails.pick_up_details.departure_datetime).isAfter(
      moment(tripDetails.pick_up_details.arrival_datetime),
    )

  const onUpdate = (updateOtherSegment = false) => {
    setPickUpErrors({})
    setDropOffErrors({})
    let errorsFound = false
    if (isPickupAfterDropOff()) {
      errorsFound = true
      setShowPickUpWarning(true)
      setShowDropOffWarning(true)
      setPickupCollapse(true)
      setDropOffCollapse(true)
      changeErrorMessages({
        pickUpTime: PICK_UP_BEFORE_DROP_OFF,
        dropOffTime: DROP_OFF_AFTER_PICK_UP,
      })
    }
    if (isDepartureAfterArrival()) {
      errorsFound = true
      setShowDepartureWarning(true)
      setShowArrivalWarning(true)
      setFlightInfoCollapse(true)
      changeErrorMessages({
        departureTime: DEPART_BEFORE_ARRIVAL,
        arrivalTime: ARRIVAL_AFTER_DEPART,
      })
    }
    if (!errorsFound) {
      dispatch(
        updateTrip(
          tripID,
          { ...tripDetails, auto_complete_pick_up_details: updateOtherSegment },
          updateOtherSegment,
          (errorPickUp, errorDropOff, errorPassenger) => {
            if (errorPickUp) {
              setPickUpErrors(errorPickUp)
              setPickupCollapse(true)
            }
            if (errorDropOff) {
              setDropOffErrors(errorDropOff)
              setDropOffCollapse(true)
            }
            if (errorPassenger) {
              setPassengerErrors(errorPassenger)
              setPassengerDetailsCollapse(true)
            }
          },
        ),
      )
    }
  }

  const handleRefreshVidecomTicket = () => {
    dispatch(
      refreshVidecomTicket(
        tripDetails.pnr,
        () => {
          setShowEditTripModal(false)
          dispatch(updateRow(tripDetails.id))
          if (tripDetails.other_segment?.id) {
            dispatch(updateRow(tripDetails.other_segment.id))
          }
        },
        true,
      ),
    )
  }

  const [callsInProgress] = useSelector(({ api }) => [api.callsInProgress])

  const videcomReservationLoading = useMemo(() => {
    return apiUtils.areCallsInProgress([API.REFRESH_VIDECOM_TICKET], callsInProgress)
  }, [callsInProgress])

  const buttonTextStyle = classNames({ loading: videcomReservationLoading })

  if (loading) {
    return <Loading />
  }

  if (isEmptyObject(tripDetails) && !loading) {
    return <div className="trip-not-found">Trip not found</div>
  }

  const permissionChecker = () => {
    return userRole !== LANDLINE_AGENT
  }

  const clearPickUpErrors = () => {
    if (showPickUpWarning) {
      if (errorMessages.pickUpTime === PICK_UP_BEFORE_DROP_OFF) {
        setShowDropOffWarning(false)
      }
      setShowPickUpWarning(false)
    }
  }

  const clearDropOffErrors = () => {
    if (showDropOffWarning) {
      if (errorMessages.dropOffTime === DROP_OFF_AFTER_PICK_UP) {
        setShowPickUpWarning(false)
      }
      setShowDropOffWarning(false)
    }
  }

  const clearFlightErrors = () => {
    if (showDepartureWarning && showArrivalWarning) {
      setShowArrivalWarning(false)
      setShowDepartureWarning(false)
    }
  }

  const addressChanged = () => {
    let currentAddress

    if (isDepartureAirport) {
      currentAddress = { ...tripDetails.pick_up_details?.drop_off_address }
      return JSON.stringify(currentAddress) !== JSON.stringify(initialAddress.drop_off_address)
    }

    currentAddress = { ...tripDetails.pick_up_details?.pick_up_address }
    return JSON.stringify(currentAddress) !== JSON.stringify(initialAddress.pick_up_address)
  }

  const getLocationDetails = (isAirport, onChange, directionDetails, errors) => {
    if (isAirport) {
      return (
        <>
          <TextInput
            inputStyle="trip-details-modal-style"
            label="Airport"
            fullWidth
            value={directionDetails.city}
            onChange={e => onChange(e, "city")}
            disabled={permissionChecker()}
            showWarning={getErrorMessage(errors, "city")}
          />
          <TextInput
            inputStyle="trip-details-modal-style"
            label="Airport Terminal"
            fullWidth
            value={directionDetails.first_address_line}
            onChange={e => onChange(e, "first_address_line")}
            disabled={permissionChecker()}
            showWarning={getErrorMessage(errors, "first_address_line")}
          />
        </>
      )
    }
    return (
      <>
        <TextInput
          inputStyle="trip-details-modal-style"
          label="Address Line 1"
          fullWidth
          value={directionDetails.first_address_line}
          onChange={e => onChange(e, "first_address_line")}
          disabled={permissionChecker()}
          showWarning={getErrorMessage(errors, "first_address_line")}
        />
        <div className="text-input-pair">
          <TextInput
            inputStyle="trip-details-modal-style"
            label="City"
            fullWidth
            value={directionDetails.city}
            onChange={e => onChange(e, "city")}
            disabled={permissionChecker()}
            showWarning={getErrorMessage(errors, "city")}
          />
          <TextInput
            inputStyle="trip-details-modal-style"
            label="ZIP code"
            fullWidth
            value={directionDetails.zip_code}
            onChange={e => onChange(e, "zip_code")}
            disabled={permissionChecker()}
            showWarning={getErrorMessage(errors, "zip_code")}
          />
        </div>
      </>
    )
  }

  return (
    <div className={tripDetailsModalClass}>
      <div
        role="button"
        className="trip-details-modal-container__exitBtn"
        onClick={() => setShowEditTripModal(false)}
      >
        <SvgIcon icon="close" width="16" height="16" />
      </div>
      <div className="pnr-container">
        <div
          className={tripWayIconClass}
          role="button"
          onClick={handleToggleSegment}
          onMouseEnter={() => setShowTooltip(true)}
          onMouseLeave={() => setShowTooltip(false)}
        >
          {toggleSegmentEnabled && showTooltip && (
            <div className="switch-leg-tooltip">Switch leg</div>
          )}
          {getRLOCIcon(tripDetails)}
        </div>
        <h4>{tripDetails.pnr}</h4>
        <div>
          <p className="trip-way-text">{tripDetails.is_round_trip ? `Round trip` : `One way`}</p>
          <div className="date-of-booking-text">
            Date of booking: {moment(tripDetails.date_of_booking).format("MMMM D, YYYY")}
          </div>
        </div>
      </div>
      <Collapsible isOpen={pickupCollapse} onOpen={onPickupCollapse} title="Pick up">
        {getLocationDetails(
          isDepartureAirport,
          onPickupAddressChange,
          tripDetails.pick_up_details.pick_up_address,
          pickUpErrors,
        )}
        <TextInput
          inputStyle="trip-details-modal-style"
          label="State"
          fullWidth
          value={tripDetails.pick_up_details.pick_up_address.state}
          onChange={e => onPickupAddressChange(e, "state")}
          disabled={permissionChecker()}
          showWarning={getErrorMessage(pickUpErrors, "state")}
        />
        <div className={pickUpDateInputPairClass}>
          <DropDownCalendar
            disabled={userRole !== LANDLINE_AGENT || tripDetails.operator !== SUNCOUNTRY}
            containerClassName="trip-details-calendar-container"
            label="Pick up date"
            value={moment(tripDetails.departure_time_write || tripDetails.departure_time).toDate()}
            onDateChange={date => {
              onPickupDateChange(
                moment(date).format(DEFAULT_DATETIME),
                tripDetails.departure_time_write || tripDetails.departure_time,
                "departure_time_write",
              )

              clearPickUpErrors()
            }}
            fullWidth
            showWarning={showPickUpWarning}
          />
          <TimeInputPicker
            title="Pick up time"
            disabled={userRole !== LANDLINE_AGENT || tripDetails.operator !== SUNCOUNTRY}
            value={moment(tripDetails.departure_time_write || tripDetails.departure_time).format(
              TWELVE_HOUR_WITHOUT_LEADING_ZERO,
            )}
            onChange={time => {
              const newTime = moment(time, [TWELVE_HOUR_WITHOUT_LEADING_ZERO]).format(
                TWENTY_FOUR_HOUR_WITH_LEADING_ZERO,
              )

              onPickupTimeChange(
                newTime,
                tripDetails.departure_time_write || tripDetails.departure_time,
                "departure_time_write",
              )

              clearPickUpErrors()
            }}
            showWarning={showPickUpWarning}
          />
          <span className="date-warning">{showPickUpWarning && errorMessages.pickUpTime}</span>
        </div>
      </Collapsible>

      <Collapsible
        isOpen={dropOffCollapse}
        onOpen={open => setDropOffCollapse(open)}
        title="Drop off"
      >
        {getLocationDetails(
          isArrivalAirport,
          onDropoffAddressChange,
          tripDetails.pick_up_details.drop_off_address,
          dropOffErrors,
        )}
        <TextInput
          inputStyle="trip-details-modal-style"
          label="State"
          fullWidth
          value={tripDetails.pick_up_details.drop_off_address.state}
          onChange={e => onDropoffAddressChange(e, "state")}
          disabled={permissionChecker()}
          showWarning={getErrorMessage(dropOffErrors, "state")}
        />
        <div className={dropOffDateInputPairClass}>
          <DropDownCalendar
            disabled={userRole !== LANDLINE_AGENT || tripDetails.operator !== SUNCOUNTRY}
            containerClassName="trip-details-calendar-container"
            label="Drop off date"
            value={moment(tripDetails.arrival_time_write || tripDetails.arrival_time).toDate()}
            onDateChange={date => {
              onPickupDateChange(
                moment(date).format(DEFAULT_DATETIME),
                tripDetails.arrival_time_write || tripDetails.arrival_time,
                "arrival_time_write",
              )

              clearDropOffErrors()
            }}
            fullWidth
            showWarning={showDropOffWarning}
          />
          <TimeInputPicker
            title="Drop off time"
            disabled={userRole !== LANDLINE_AGENT || tripDetails.operator !== SUNCOUNTRY}
            value={moment(tripDetails.arrival_time_write || tripDetails.arrival_time).format(
              TWELVE_HOUR_WITHOUT_LEADING_ZERO,
            )}
            onChange={time => {
              const newTime = moment(time, [TWELVE_HOUR_WITHOUT_LEADING_ZERO]).format(
                TWENTY_FOUR_HOUR_WITH_LEADING_ZERO,
              )

              onPickupTimeChange(
                newTime,
                tripDetails.arrival_time_write || tripDetails.arrival_time,
                "arrival_time_write",
              )

              clearDropOffErrors()
            }}
            showWarning={showDropOffWarning}
          />
          <span className="date-warning">{showDropOffWarning && errorMessages.dropOffTime}</span>
        </div>
      </Collapsible>

      <Collapsible
        isOpen={flightInfoCollapse}
        onOpen={open => setFlightInfoCollapse(open)}
        title="Flight information"
      >
        <div className="text-input-pair reversed">
          <TextInput
            inputStyle="trip-details-modal-style"
            label="Flight number"
            fullWidth
            value={tripDetails.pick_up_details.flight_number}
            onChange={e => onPickUpDetailsChange(e.target.value, "flight_number")}
            disabled={permissionChecker()}
          />
          <TextInput
            inputStyle="trip-details-modal-style"
            label="Airline"
            fullWidth
            value={tripDetails.pick_up_details.airline}
            onChange={e => onPickUpDetailsChange(e.target.value, "airline")}
            disabled={permissionChecker()}
          />
        </div>
        <TextInput
          inputStyle="trip-details-modal-style"
          label="Origin"
          fullWidth
          value={tripDetails.pick_up_details.origin}
          onChange={e => onPickUpDetailsChange(e.target.value, "origin")}
          disabled={permissionChecker()}
        />
        <TextInput
          inputStyle="trip-details-modal-style"
          label="Destination"
          fullWidth
          value={tripDetails.pick_up_details.destination}
          onChange={e => onPickUpDetailsChange(e.target.value, "destination")}
          disabled={permissionChecker()}
        />

        <div className="date-input-pair">
          <DropDownCalendar
            showClearDate
            onClearDateClick={() => onPickUpDetailsChange(null, "departure_datetime")}
            disabled={permissionChecker()}
            containerClassName="trip-details-calendar-container"
            label="Departure date"
            placeholder={userRole === LANDLINE_AGENT && "Choose Date"}
            value={
              tripDetails.pick_up_details.departure_datetime
                ? moment(tripDetails.pick_up_details.departure_datetime).toDate()
                : undefined
            }
            onDateChange={date => {
              onPickupDateChange(
                moment(date).format(DEFAULT_DATETIME),
                tripDetails.pick_up_details.departure_datetime,
                "departure_datetime",
              )
              clearFlightErrors()
            }}
            showWarning={showDepartureWarning}
          />
          <TimeInputPicker
            title="Departure time"
            disabled={permissionChecker() || !tripDetails.pick_up_details.departure_datetime}
            value={
              tripDetails.pick_up_details.departure_datetime
                ? moment(tripDetails.pick_up_details.departure_datetime).format(
                    TWELVE_HOUR_WITHOUT_LEADING_ZERO,
                  )
                : undefined
            }
            onChange={time => {
              const newTime = moment(time, [TWELVE_HOUR_WITHOUT_LEADING_ZERO]).format(
                TWENTY_FOUR_HOUR_WITH_LEADING_ZERO,
              )

              onPickupTimeChange(
                newTime,
                tripDetails.pick_up_details.departure_datetime,
                "departure_datetime",
              )
              clearFlightErrors()
            }}
            showEmptyField={
              userRole === COMPANY_REPRESENTATIVE && !tripDetails.pick_up_details.departure_datetime
            }
            showWarning={showDepartureWarning}
          />
          <span className="date-warning flight">
            {showDepartureWarning && errorMessages.departureTime}
          </span>
        </div>

        <div className="date-input-pair">
          <DropDownCalendar
            showClearDate
            onClearDateClick={() => onPickUpDetailsChange(null, "arrival_datetime")}
            disabled={permissionChecker()}
            containerClassName="trip-details-calendar-container"
            label="Arrival date"
            placeholder={userRole === LANDLINE_AGENT && "Choose Date"}
            value={
              tripDetails.pick_up_details.arrival_datetime
                ? moment(tripDetails.pick_up_details.arrival_datetime).toDate()
                : undefined
            }
            onDateChange={date => {
              onPickupDateChange(
                moment(date).format(DEFAULT_DATETIME),
                tripDetails.pick_up_details.arrival_datetime,
                "arrival_datetime",
              )
              clearFlightErrors()
            }}
            showWarning={showArrivalWarning}
          />
          <TimeInputPicker
            title="Arrival time"
            disabled={permissionChecker() || !tripDetails.pick_up_details.arrival_datetime}
            value={
              tripDetails.pick_up_details.arrival_datetime
                ? moment(tripDetails.pick_up_details.arrival_datetime).format(
                    TWELVE_HOUR_WITHOUT_LEADING_ZERO,
                  )
                : undefined
            }
            onChange={time => {
              const newTime = moment(time, [TWELVE_HOUR_WITHOUT_LEADING_ZERO]).format(
                TWENTY_FOUR_HOUR_WITH_LEADING_ZERO,
              )

              onPickupTimeChange(
                newTime,
                tripDetails.pick_up_details.arrival_datetime,
                "arrival_datetime",
              )
              clearFlightErrors()
            }}
            showEmptyField={
              userRole === COMPANY_REPRESENTATIVE && !tripDetails.pick_up_details.departure_datetime
            }
            showWarning={showArrivalWarning}
          />
          <span className="date-warning flight">
            {showArrivalWarning && errorMessages.arrivalTime}
          </span>
        </div>
      </Collapsible>

      <Collapsible
        isOpen={passengerDetailsCollapse}
        onOpen={open => setPassengerDetailsCollapse(open)}
        title="Passenger Details"
      >
        <div className="number-of-passengers-container">
          <span>Number of passengers</span>
          <TextInput
            inputStyle="trip-details-modal-style"
            disabled
            value={
              tripDetails.number_of_passengers < 10
                ? `0${tripDetails.number_of_passengers}`
                : tripDetails.number_of_passengers
            }
          />
        </div>
        <TextInput
          inputStyle="trip-details-modal-style"
          label="Holder passenger first name"
          value={tripDetails.holder_passenger.first_name}
          fullWidth
          onChange={e => onHolderPassengerChange("first_name", e.target.value)}
          disabled={permissionChecker()}
          showWarning={getErrorMessage(passengerErrors, "first_name")}
        />
        <TextInput
          inputStyle="trip-details-modal-style"
          label="Holder passenger last name"
          value={tripDetails.holder_passenger.last_name}
          fullWidth
          onChange={e => onHolderPassengerChange("last_name", e.target.value)}
          disabled={permissionChecker()}
          showWarning={getErrorMessage(passengerErrors, "last_name")}
        />
        <TextInput
          inputStyle="trip-details-modal-style"
          label="Phone number"
          fullWidth
          value={tripDetails.holder_passenger.phone_number}
          onChange={e => onHolderPassengerChange("phone_number", e.target.value)}
          disabled={permissionChecker()}
          showWarning={getErrorMessage(passengerErrors, "phone_number")}
        />
        <TextInput
          inputStyle="trip-details-modal-style"
          onChange={e => onHolderPassengerChange("email", e.target.value)}
          label="Email address"
          fullWidth
          value={tripDetails.holder_passenger.email}
          disabled={permissionChecker()}
          showWarning={getErrorMessage(passengerErrors, "email")}
        />
      </Collapsible>
      {permissionChecker() ? null : (
        <Collapsible
          isOpen={internalNotesCollapse}
          onOpen={open => setInternalNotesCollapse(open)}
          title="Internal Notes"
        >
          <BookingNote
            noteType={INTERNAL_NOTE}
            tripID={tripID}
            showSingleNote={activeNotification?.showInternalNote && singleNoteToShow}
            setShowSingleNote={activeNotification?.showInternalNote && setSingleNoteToShow}
          />
        </Collapsible>
      )}

      <Collapsible
        isOpen={publicNotesCollapse}
        onOpen={open => setPublicNotesCollapse(open)}
        title="Public Notes"
      >
        <BookingNote
          noteType={PUBLIC_NOTE}
          tripID={tripID}
          disabled={permissionChecker()}
          showSingleNote={activeNotification?.showPublicNote && singleNoteToShow}
          setShowSingleNote={activeNotification?.showPublicNote && setSingleNoteToShow}
        />
      </Collapsible>
      {userRole === LANDLINE_AGENT && (
        <div className="buttons-container">
          {tripDetails.operator === "VIDECOM" && (
            <Button
              buttonType="videcomColor"
              buttonSize="videcom-size"
              onClick={!videcomReservationLoading && handleRefreshVidecomTicket}
              margin="0px 20px 0px 0px"
            >
              {videcomReservationLoading && <Loading buttonLoading />}
              <div className={buttonTextStyle}>Sync with Videcom</div>
            </Button>
          )}

          <Button
            buttonType="landlineColor"
            buttonSize="videcom-size update"
            onClick={
              tripDetails.is_round_trip && addressChanged()
                ? () => setShowAddressConfirmationModal(true)
                : () => onUpdate(false)
            }
          >
            Update
          </Button>
        </div>
      )}

      <Modal
        onClick={e => e.stopPropagation()}
        render={() => (
          <div className="address-confirmation-modal-container">
            <div
              className="address-confirmation-close-button"
              role="button"
              onClick={() => setShowAddressConfirmationModal(false)}
            >
              <SvgIcon icon="close" width="16" height="16" />
            </div>

            <div>
              {isDepartureAirport
                ? "Do you want to apply drop-off change to the pick-up details for the second leg?"
                : "Do you want to apply pick-up change to the drop-off details for the second-leg?"}
            </div>

            <div className="address-confirmation-button-container">
              <Button
                buttonType="landlineColor"
                buttonSize="driverStatusModalSize confirmation"
                type="button"
                onClick={() => {
                  onUpdate(true)
                  setShowAddressConfirmationModal(false)
                }}
              >
                Yes
              </Button>
              <Button
                buttonType="grayColor"
                buttonSize="driverStatusModalSize confirmation"
                type="button"
                margin="0px 0px 0px 12px"
                onClick={() => {
                  onUpdate(false)
                  setShowAddressConfirmationModal(false)
                }}
              >
                No
              </Button>
            </div>
          </div>
        )}
        shouldCloseOnOverlayClick
        isOpen={showAddressConfirmationModal}
        onRequestClose={() => setShowAddressConfirmationModal(false)}
        style={{
          overlay: {
            position: "fixed",
            display: "flex",
            backgroundColor: "rgba(0, 0, 0, 0.2)",
            zIndex: 99999,
            overflow: "none",
          },
          content: {
            width: 318,
            display: "flex",
            overflow: "none",
            boxShadow: "0px 0px 16px 4px rgba(66, 64, 62, 0.12)",
            borderRadius: "4px",
            border: "none",
            alignItems: "center",
            height: "auto",
            padding: 0,
          },
        }}
      />
    </div>
  )
}

TripDetailsModal.propTypes = {
  tripID: PropTypes.number,
  setShowEditTripModal: PropTypes.func,
  setTripDetailID: PropTypes.func,
  setIdToHighlight: PropTypes.func,
}

TripDetailsModal.defaultProps = {
  tripID: undefined,
  setShowEditTripModal: () => {},
  setTripDetailID: () => {},
  setIdToHighlight: () => {},
}

export default TripDetailsModal
