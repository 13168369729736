import "../OperatorsPage/OperatorsPage.scss"
import "./ManifestPage.scss"

import classNames from "classnames"
import PropTypes from "prop-types"
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import RegularLayout from "../../layouts/RegularLayout"
import { COMPANY_DRIVER } from "../../../constants/userRoles"
import NotificationButton from "../../atoms/NotificationButton"
import CurrentDate from "../../atoms/CurrentDate"
import TextInput from "../../atoms/TextInput"
import Button from "../../atoms/Button"
import { getManifest } from "../../../redux/manifest/manifestActions"
import ManifestTable from "../../molecules/ManifestTable"


const ManifestPage = ({ history }) => {

    const dispatch = useDispatch()
    const ManifestPageClass = classNames("manifest-page-container")
    const OperatorsPageClass = classNames("operators-page-container")

    const [manifest, userRole] = useSelector(({ manifest, user }) => [
        manifest.manifest,
        user.jwtData?.role
    ])

    const [flightNumber, setFlightNumber] = useState("L4")
    const [flightDate, setFlightDate] = useState()
    
    const updateManifest = async () => {
        await dispatch(getManifest(flightNumber, flightDate))
    }

    return (
        <RegularLayout history={history} withTopMenu={false} backgroundColor="white">
            <div className={OperatorsPageClass}>
                <div className="operators-container">
                <div className="operators-text">Manifest</div>
                <div className="right-side-content">
                    {userRole !== COMPANY_DRIVER && (
                    <div className="notification-button">
                        <NotificationButton onClick={() => history.push("/")} />
                    </div>
                    )}
                    <div className="current-date">
                    <CurrentDate />
                    </div>
                </div>
                </div>
            </div>
            <div className="manifest-search-container">
                <TextInput
                    onChange={e => {
                        setFlightNumber(e.target.value)
                    }}
                    value={flightNumber}
                    inputSize="search-size"
                    inputStyle="search-style"
                    icon="search"
                    iconStyle="search-icon"
                    placeholder="Search by Flight Number"
                />
                <TextInput
                    onChange={e => {
                        setFlightDate(e.target.value)
                    }}
                    value={flightDate}
                    inputType="date"
                    inputSize="search-size"
                    inputStyle="search-style"
                    icon="search"
                    iconStyle="search-icon"
                    placeholder="Select a Date"
                />
                <Button
                    type="primary"
                    buttonType="videcomColor"
                    buttonSize="newBookingSize"
                    onClick={() => {updateManifest()}}
                    disabled={flightNumber.length < 6}
                  >Search</Button>
            </div>
            {manifest ?
            <div className="manifest-container">
                <div className="flight-data-container">
                    <div>Flight Number: {manifest.flight.flight_number}</div>
                    <div>Date: {manifest.flight.date}</div>
                </div>
                {manifest.data.map(leg => (
                    < ManifestTable manifest={leg} />
                ))}
            </div>
            : ""}

        </RegularLayout>
    )
}

ManifestPage.propTypes = {
    history: PropTypes.instanceOf(Object).isRequired,
  }
  
ManifestPage.defaultProps = {}

export default ManifestPage