import queryString from "query-string"

import ACTIONS from "../../constants/ACTIONS"
import API from "../../constants/API"
import { landlineApi } from "../../utils/api/calls"
import { apiError, startApiCall } from "../api/apiActions"

const getAgents = queryParams => {
  return async dispatch => {
    try {
      const urlQuery = queryString.stringify(queryParams)

      dispatch(startApiCall(API.GET_AGENTS))
      const response = await landlineApi.get(`/v2/bossadmin/users/landline_agents/?${urlQuery}`)
      dispatch({
        type: ACTIONS.GET_AGENTS_SUCCESS,
        agents: response.data,
        offset: queryParams?.offset,
      })
    } catch (error) {
      dispatch(apiError(ACTIONS.GET_AGENTS_FAIL, error))
    }
  }
}

// eslint-disable-next-line import/prefer-default-export
export { getAgents }
