import "./SidebarMenu.scss"

import classNames from "classnames"
import PropTypes from "prop-types"
import React, { useContext } from "react"

import SidebarMenuItem from "../../atoms/SidebarMenuItem"
import { PermissionContext } from "../../contexts/PermissionContext"

const SidebarMenu = ({
  history: {
    location: { pathname },
  },
  openCollapse,
  isDriver,
}) => {
  const sidebarMenuClass = classNames("sidebar-menu-container")
  const { hasPermission } = useContext(PermissionContext)

  return (
    <div className={sidebarMenuClass}>
      {!isDriver && (
        <SidebarMenuItem
          openCollapse={openCollapse}
          url="/"
          pathname={pathname}
          title="Dashboard"
          icon="dashboard"
        />
      )}

      <SidebarMenuItem
        openCollapse={openCollapse}
        url="/planning"
        pathname={pathname}
        title="Planning"
        icon="planning"
      />
      <SidebarMenuItem
        openCollapse={openCollapse}
        url="/manifest"
        pathname={pathname}
        title="Manifest"
        icon="control"
      />
      <SidebarMenuItem
        openCollapse={openCollapse}
        url="/operators"
        pathname={pathname}
        title="Operators"
        icon="vehicle"
      />
      <SidebarMenuItem
        openCollapse={openCollapse}
        url="/landline"
        pathname={pathname}
        title="Landline"
        icon="greek-palace"
      />
      <SidebarMenuItem
        openCollapse={openCollapse}
        url="/quoting"
        pathname={pathname}
        title="D2D Quote Tool"
        icon="vehicle"
      />
      {hasPermission("view_routedelay") ? (
        <SidebarMenuItem
          openCollapse={openCollapse}
          url="/routes"
          pathname={pathname}
          title="Route Delays"
          icon="map"
          iconProps={{
            width: 24,
            height: 24,
            style: {
              transform: "scale(.75)",
            },
          }}
        />
      ) : null}
      <SidebarMenuItem
        openCollapse={openCollapse}
        url="/settings"
        pathname={pathname}
        title="Settings"
        icon="settings"
      />
    </div>
  )
}

SidebarMenu.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
  openCollapse: PropTypes.bool,
  isDriver: PropTypes.bool,
}

SidebarMenu.defaultProps = { openCollapse: false, isDriver: false }

export default SidebarMenu
