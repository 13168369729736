import "./TextInput.scss"

import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"
import { useDispatch, useSelector } from "react-redux"

import { updateFormField } from "../../../redux/actions"
import SvgIcon from "../SvgIcon"

const TextInput = ({
  label,
  value,
  field,
  onChange,
  mandatory,
  error,
  placeholder,
  disabled,
  inputType,
  inputStyle,
  inputSize,
  fullWidth,
  margin,
  onFocus,
  icon,
  iconStyle,
  inputRef,
  showWarning,
}) => {
  const dispatch = useDispatch()

  const onInputChange = e => {
    const newValue = e.target.value
    if (onChange) {
      onChange(e, field)
    } else {
      dispatch(updateFormField(field, newValue))
    }
  }

  const [inputValue, inputError] = useSelector(({ forms }) => [
    forms.formData[field],
    forms.formErrors[field],
  ])
  const getValue = () => {
    let tempValue = inputValue
    if (value) {
      tempValue = value
    }
    return tempValue ? tempValue.toString() : ""
  }

  const inputWrapper = classNames("input-wrapper", {
    "input-error": error || inputError || showWarning,
    fullWidth,
    [inputStyle]: inputStyle,
    [inputSize]: inputSize,
  })

  return (
    <div className={inputWrapper} style={{ margin }}>
      <div className="messages-container">
        {label && (
          <label className="label" htmlFor="title">
            {mandatory && <span className="mandatory-star">*&nbsp;</span>}
            {label}
          </label>
        )}
        {error && inputError && (
          <label className="label" htmlFor="title">
            {error || inputError}
          </label>
        )}
      </div>

      <div className="input-icon-wrapper">
        <input
          ref={inputRef}
          className="InputText"
          htmlFor="value"
          onFocus={onFocus}
          type={inputType}
          value={getValue()}
          onChange={onInputChange}
          placeholder={placeholder}
          disabled={disabled}
          required={mandatory}
        />
        {icon && (
          <SvgIcon className={iconStyle} icon={icon} width={15} height={15} fill="#999999" />
        )}
      </div>

      {showWarning && <div className="input-warning">{showWarning}</div>}
    </div>
  )
}

TextInput.propTypes = {
  value: PropTypes.string.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  mandatory: PropTypes.bool,
  error: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  margin: PropTypes.string,
  inputType: PropTypes.string,
  inputStyle: PropTypes.string,
  inputSize: PropTypes.string,
  fullWidth: PropTypes.bool,
  field: PropTypes.string,
  onFocus: PropTypes.func,
  icon: PropTypes.string,
  iconStyle: PropTypes.string,
  inputRef: PropTypes.instanceOf(Object),
  showWarning: PropTypes.string,
}

TextInput.defaultProps = {
  label: "",
  mandatory: false,
  error: "",
  disabled: false,
  placeholder: "",
  margin: "",
  inputType: "text",
  inputStyle: "regular-style",
  inputSize: "regular-size",
  fullWidth: false,
  field: "",
  onFocus: () => {},
  icon: "",
  iconStyle: "text-input-icon",
  inputRef: null,
  showWarning: "",
}

export default TextInput
