import "./AvailabilityCalendar.scss"
import "react-calendar/dist/Calendar.css"
import "../../atoms/DropDownCalendar/DropDownCalendar.scss"

import classNames from "classnames"
import moment from "moment"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"

import { END_OF_DAY_DATETIME, MIDNIGHT_DATETIME } from "../../../constants/dateFormats"
import { FULL_DAY_OFF_STATUSES } from "../../../constants/driverStatuses"
import { CALENDAR_FILTERS } from "../../../constants/filterTypes"
import { COMPANY_DRIVER } from "../../../constants/userRoles"
import { clearDriverDetails, getDriverDetails, getDriverEvents } from "../../../redux/actions"
import { getUserAvatar } from "../../../utils/common"
import ActiveFilterList from "../../atoms/ActiveFilterList"
import AdditionalDriverEvents from "../../atoms/AdditionalDriverEvents"
import Button from "../../atoms/Button"
import DriverEvent from "../../atoms/DriverEvent"
import DriverStatusModal from "../../atoms/DriverStatusModal"
import DropDownCalendar from "../../atoms/DropDownCalendar"
import UserAvatarName from "../../atoms/UserAvatarName"
import CalendarFilters from "../CalendarFilters"
import Modal from "../Modal"

const AvailabilityCalendar = ({ driverID, history, isLandline }) => {
  const dispatch = useDispatch()
  const { companyId, driverId, calendarDate } = useParams()

  useEffect(() => {
    dispatch(getDriverDetails(driverID))
    return () => dispatch(clearDriverDetails())
  }, [])

  const [isFilterChecked, setIsFilterChecked] = useState({ status: false, vehicle_type: false })

  const [eventsLoading, setEventsLoading] = useState(true)
  const [showStatusModal, setShowStatusModal] = useState(false)
  const [chosenMonth, setChosenMonth] = useState(
    moment(calendarDate)
      .startOf("month")
      .toDate(),
  )
  const weekDays = [...moment.weekdays().slice(1), moment.weekdays()[0]]

  const start = moment(chosenMonth)
    .startOf("month")
    .startOf("isoWeek")

  const end = moment(chosenMonth)
    .endOf("month")
    .endOf("isoWeek")

  const handleGetEvents = async () => {
    await dispatch(
      getDriverEvents({
        user: driverID,
        start_date_time__gte: moment(start).format(MIDNIGHT_DATETIME),
        start_date_time__lt: moment(end).format(END_OF_DAY_DATETIME),
      }),
    )
    setEventsLoading(false)
  }

  useEffect(() => {
    handleGetEvents()
  }, [chosenMonth])

  useEffect(() => {
    history.replace(
      `${
        isLandline ? "/landline" : `/operators/${companyId}`
      }/drivers/${driverId}/availability/${moment(chosenMonth).format("YYYY-MM")}`,
    )
  }, [chosenMonth])

  const [
    driverEvents,
    filterData,
    driverDetails,
    userRole,
  ] = useSelector(({ driver, filter, user }) => [
    driver.driverEvents,
    filter,
    driver.driverDetails,
    user.jwtData?.role,
  ])

  const { filters } = filterData.calendarFilters

  const getDaysBetweenDates = (startDate, endDate) => {
    const now = startDate.clone()
    const dates = []

    while (now.isSameOrBefore(endDate)) {
      dates.push(moment(now))
      now.add(1, "days")
    }
    return dates
  }

  const currentMonthDates = getDaysBetweenDates(start, end)

  const getAllDatesOfWeekDay = dayOfWeek => {
    return currentMonthDates.filter(day => day.day() === dayOfWeek)
  }
  return (
    <div className="availability-container">
      <div className="availability-upper-row-container">
        <div className="upper-row-left-side">
          <DropDownCalendar
            onDateChange={date => {
              setChosenMonth(date)
              setEventsLoading(true)
            }}
            value={chosenMonth}
            showDateChange
            calendarClassName="planning-page-calendar"
            calendarView="year"
          />
          {(driverDetails.avatar || driverDetails.first_name) && (
            <div className="driver-name-wrapper">
              <UserAvatarName
                avatarURL={driverDetails.avatar}
                avatarType={getUserAvatar(driverDetails.avatar)}
                fname={driverDetails.first_name}
                lname={driverDetails.last_name}
                initialsFontSize="16px"
                nameFontSize="16px"
                avatarHeight="32px"
                avatarWidth="32px"
              />
            </div>
          )}
        </div>
        <div className="upper-row-right-side">
          {userRole !== COMPANY_DRIVER && (
            <Button
              buttonType="landlineColor"
              buttonSize="statusSize"
              onClick={() => setShowStatusModal(true)}
            >
              Set status
            </Button>
          )}

          <CalendarFilters
            isFilterChecked={isFilterChecked}
            setIsFilterChecked={setIsFilterChecked}
          />
        </div>
      </div>

      <ActiveFilterList
        isFilterChecked={isFilterChecked}
        setIsFilterChecked={setIsFilterChecked}
        filterType={CALENDAR_FILTERS}
      />

      <div className="week-days">
        {weekDays.map((weekDay, weekDayIndex) => {
          const allWeekDays = getAllDatesOfWeekDay(weekDayIndex + 1 !== 7 ? weekDayIndex + 1 : 0)

          return (
            <div className="week-day-column">
              <div className="week-day-label">{weekDay}</div>

              {allWeekDays.map((day, dayIndex) => {
                const lastInRow = weekDayIndex === weekDays.length - 1
                const lastInColumn = dayIndex === allWeekDays.length - 1
                const isToday = day.isSame(new Date(), "day")
                const dayEvents = eventsLoading
                  ? []
                  : driverEvents.filter(
                      driverEvent =>
                        moment(driverEvent.start_date_time).isSame(day, "day") ||
                        (FULL_DAY_OFF_STATUSES.includes(driverEvent.event_type) &&
                          moment(day).isBetween(
                            moment(driverEvent.start_date_time),
                            moment(driverEvent.end_date_time),
                          )),
                    )
                const isDayOff =
                  dayEvents[0] && FULL_DAY_OFF_STATUSES.includes(dayEvents[0].event_type)

                const moreThanFourEvents = dayEvents.length > 4

                const firstFourEvents = []
                const restOfTheEvents = []

                if (moreThanFourEvents) {
                  firstFourEvents.push(...dayEvents.slice(0, 4))
                  restOfTheEvents.push(...dayEvents.slice(4))
                }

                const chosenArray = moreThanFourEvents ? firstFourEvents : dayEvents

                const filterMark =
                  (filters.status &&
                    dayEvents.find(event => event.event_type === filters.status)) ||
                  (filters.vehicle_type &&
                    dayEvents.find(
                      event => event.related_object?.service_type === +filters.vehicle_type,
                    ))

                const weekDayItemClass = classNames("week-day-item", {
                  lastInRow,
                  lastInColumn,
                  isToday,
                  filterMark,
                })

                const weekDayLabelClass = classNames("week-day-item-label", { isDayOff })

                return (
                  <div className={weekDayItemClass}>
                    <div className={weekDayLabelClass}>
                      {day.format("D") === "1" ? day.format("D. MMM") : day.format("D")}
                    </div>
                    <div className="events-wrapper">
                      {chosenArray.map(dayEvent => (
                        <DriverEvent
                          history={history}
                          event={dayEvent}
                          cancelDisabled={userRole === COMPANY_DRIVER}
                          cancelCallback={() => {
                            dispatch(
                              getDriverEvents({
                                user: driverID,
                                start_date_time__gte: moment(start).format(MIDNIGHT_DATETIME),
                                start_date_time__lt: moment(end).format(END_OF_DAY_DATETIME),
                              }),
                            )
                          }}
                        />
                      ))}
                      {moreThanFourEvents && (
                        <AdditionalDriverEvents history={history} events={restOfTheEvents} />
                      )}
                    </div>
                  </div>
                )
              })}
            </div>
          )
        })}
      </div>

      <Modal
        render={() => (
          <DriverStatusModal
            activeStartDate={chosenMonth}
            closeModal={() => setShowStatusModal(false)}
            driverID={driverID}
            callback={() => {
              dispatch(
                getDriverEvents({
                  user: driverID,
                  start_date_time__gte: moment(start).format(MIDNIGHT_DATETIME),
                  start_date_time__lt: moment(end).format(END_OF_DAY_DATETIME),
                }),
              )
            }}
          />
        )}
        shouldCloseOnOverlayClick
        isOpen={showStatusModal}
        onRequestClose={() => setShowStatusModal(false)}
        style={{
          overlay: {
            position: "fixed",
            display: "flex",
            backgroundColor: "rgba(0, 0, 0, 0.2)",
            zIndex: 99999,
            overflow: "none",
          },
          content: {
            backgroundColor: "#fafafa",
            width: "auto",
            display: "flex",
            overflow: "none",
            padding: 0,
            boxShadow: "0px 0px 16px 4px rgba(66, 64, 62, 0.12)",
            borderRadius: "4px",
            border: "none",
            alignItems: "center",
          },
        }}
      />
    </div>
  )
}

AvailabilityCalendar.propTypes = {
  driverID: PropTypes.string.isRequired,
  history: PropTypes.instanceOf(Object),
  isLandline: PropTypes.bool,
}

AvailabilityCalendar.defaultProps = {
  history: {},
  isLandline: false,
}

export default AvailabilityCalendar
