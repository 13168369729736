import "./TableRow.scss"

import classNames from "classnames"
import PropTypes from "prop-types"
import React, { useEffect, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"

import { CANCELLED_STATUS, PLANNING_STATUS } from "../../../constants/status"
import { LANDLINE_AGENT } from "../../../constants/userRoles"
import { setRowToHighlight } from "../../../redux/actions"
import { setActiveNotification } from "../../../redux/notifications/notificationsActions"
import { getFontColor } from "../../../utils/common"

const TableRow = ({
  status,
  children,
  onClick,
  id,
  companyOperator,
  tableBodyType,
  isExpressHome,
  isSelectDoor2Door,
}) => {
  const [
    notificationsData,
    rowToHighlight,
    userData,
  ] = useSelector(({ notifications, trip, user }) => [notifications, trip.rowToHighlight, user])
  const ref = useRef(null)
  const dispatch = useDispatch()
  const [appSettings] = useSelector(({ user }) => [user.app_settings])

  const getColor = () => {
    if (isExpressHome && status !== CANCELLED_STATUS) {
      return (
        appSettings?.express_home_service_color &&
        getFontColor(appSettings?.express_home_service_color) === "#FFFFFF"
      )
    }

    if (isSelectDoor2Door && status !== CANCELLED_STATUS) {
      return (
        appSettings?.select_door_to_door_color &&
        getFontColor(appSettings?.select_door_to_door_color) === "#FFFFFF"
      )
    }

    return null
  }

  const tableRowClass = classNames("table-row-container", companyOperator, {
    whiteColor: getColor(),
    isDoor2Door: isSelectDoor2Door,
    [PLANNING_STATUS[status]]: status,
    activeNotification:
      (notificationsData.activeNotification.relatedObjectId === id || rowToHighlight === id) &&
      tableBodyType === "planning",
    isExpress: isExpressHome,
  })

  const getRowStyle = () => {
    const rowStyle = {}
    if (userData?.jwtData?.role === LANDLINE_AGENT) {
      if (isExpressHome) {
        rowStyle.backgroundColor = userData.app_settings?.express_home_service_color
      }

      if (isSelectDoor2Door) {
        rowStyle.backgroundColor = userData.app_settings?.select_door_to_door_color
      }
    }

    return rowStyle
  }

  useEffect(() => {
    if (!ref.current || rowToHighlight !== id) {
      return undefined
    }

    ref.current.classList.remove("activeNotification")

    setTimeout(() => {
      dispatch(setRowToHighlight(undefined))
      ref.current.classList.add("activeNotification")
    }, 100)

    const removeClass = setTimeout(() => {
      ref.current.classList.remove("activeNotification")
    }, 2000)

    return () => clearTimeout(removeClass)
  }, [rowToHighlight, id])

  useEffect(() => {
    if (
      notificationsData.activeNotification.relatedObjectId !== id ||
      !ref.current ||
      tableBodyType !== notificationsData.activeNotification.tableToHighlight ||
      tableBodyType === "planning"
    ) {
      return undefined
    }

    ref.current.classList.remove("activeNotification")

    setTimeout(() => {
      ref.current.classList.add("activeNotification")
      dispatch(setActiveNotification({}))
    }, 100)

    const removeClass = setTimeout(() => {
      ref.current.classList.remove("activeNotification")
    }, 2000)

    return () => clearTimeout(removeClass)
  }, [notificationsData.activeNotification.relatedObjectId])
  return (
    <div role="button" className={tableRowClass} onClick={onClick} ref={ref} style={getRowStyle()}>
      {children}
    </div>
  )
}

TableRow.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  status: PropTypes.number,
  onClick: PropTypes.func,
  id: PropTypes.number.isRequired,
  companyOperator: PropTypes.string,
  tableBodyType: PropTypes.string,
  isExpressHome: PropTypes.bool,
  isSelectDoor2Door: PropTypes.bool,
}

TableRow.defaultProps = {
  status: null,
  children: null,
  onClick: () => {},
  companyOperator: null,
  tableBodyType: "",
  isExpressHome: false,
  isSelectDoor2Door: false,
}

export default TableRow
