import "./DriverShiftModal.scss"

import moment from "moment"
import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import { useDispatch } from "react-redux"
import { toast } from "react-toastify"

import { DEFAULT_TIME, TWELVE_HOUR_WITHOUT_LEADING_ZERO } from "../../../constants/dateFormats"
import { DAYS } from "../../../constants/days"
import { INVALID_INPUT } from "../../../constants/formErrors"
import { SHIFT, SHIFT_LIST } from "../../../constants/shiftChoices"
import { setShift } from "../../../redux/actions"
import { useOnClickOutside } from "../../../utils/hooks"
import Checkbox from "../../atoms/Checkbox"
import SvgIcon from "../../atoms/SvgIcon"
import TimeInputPicker from "../../atoms/TimeInputPicker"

function DriverShiftModal({ day, driverId }) {
  const dispatch = useDispatch()
  const [openModal, setOpenModal] = useState(false)
  const [offDutyChecked, setOffDutyChecked] = useState(day.off_duty)
  const [state, setState] = useState(day)

  const ref = useRef()
  useOnClickOutside(ref, () => setOpenModal(false))

  const onSubmit = () => {
    if (state.end_time && state.start_time === "Invalid date") {
      return toast.error(INVALID_INPUT)
    }
    if (offDutyChecked === day.off_duty && offDutyChecked) {
      return toast.warning("Driver is off duty")
    }
    setOpenModal(false)
    return dispatch(setShift(driverId, state))
  }

  useEffect(() => {
    if (openModal) {
      setOffDutyChecked(day.off_duty)
    }
  }, [openModal])

  return (
    <div className="driverShiftModal">
      <div className="iconToDisplay" role="button" onClick={() => setOpenModal(true)}>
        <SvgIcon icon="pen-edit-medium" fill="#F5F5F5" />
      </div>
      <div>
        {openModal && (
          <div className="shiftModal">
            <div className="shiftModal__content" ref={ref}>
              <div className="shiftModal-inner_content">
                <div className="modalTitle">
                  <p>{DAYS[day.day]}</p>
                </div>
                <div className={`timeEditSection ${offDutyChecked && "disabled"}`}>
                  <div className="startTime">
                    <p className="label">Start time</p>

                    <TimeInputPicker
                      disabled={offDutyChecked}
                      className={`shiftTimeModal ${offDutyChecked && "disabled"}`}
                      value={
                        offDutyChecked
                          ? ""
                          : moment(day.start_time, [DEFAULT_TIME]).format(
                              TWELVE_HOUR_WITHOUT_LEADING_ZERO,
                            )
                      }
                      onChange={value => {
                        setState({
                          ...state,
                          start_time: moment(value, [TWELVE_HOUR_WITHOUT_LEADING_ZERO]).format(
                            DEFAULT_TIME,
                          ),
                        })
                      }}
                    />
                  </div>
                  <div className="endTime">
                    <p className="label">End time</p>

                    <TimeInputPicker
                      className="shiftTimeModal"
                      disabled={offDutyChecked}
                      value={
                        offDutyChecked
                          ? ""
                          : moment(day.end_time, [DEFAULT_TIME]).format(
                              TWELVE_HOUR_WITHOUT_LEADING_ZERO,
                            )
                      }
                      onChange={value => {
                        setState({
                          ...state,
                          end_time: moment(value, [TWELVE_HOUR_WITHOUT_LEADING_ZERO]).format(
                            DEFAULT_TIME,
                          ),
                        })
                      }}
                    />
                  </div>
                </div>
                <div className="preferableShiftEditSection">
                  <p className="label">Preferable shift</p>
                  <div className={`selectContainer ${offDutyChecked && "disabled"} `}>
                    <select
                      disabled={offDutyChecked}
                      name="Select"
                      id="selectBox"
                      onChange={e =>
                        setState({
                          ...state,
                          preferable_shift: e.target.value,
                        })
                      }
                    >
                      {offDutyChecked && <option value="default">Choose shift</option>}

                      {SHIFT_LIST.map(item => {
                        return (
                          <option
                            selected={item === day.preferable_shift && !offDutyChecked}
                            key={item}
                            value={item}
                          >
                            {SHIFT[item]}
                          </option>
                        )
                      })}
                    </select>
                  </div>
                </div>
                <div className="offDutyInput">
                  <div
                    role="button"
                    className="checkbox-wrapper"
                    onClick={() => {
                      setOffDutyChecked(!offDutyChecked)
                      setState({
                        ...state,
                        off_duty: !offDutyChecked,
                      })
                    }}
                  >
                    <Checkbox checked={offDutyChecked} />
                  </div>

                  <p className="label">Off duty</p>
                </div>
                <div className="driverShiftModal--actions">
                  <div className="driverShiftModal--actions__content">
                    <button className="btn-cancel" onClick={() => setOpenModal(false)}>
                      Cancel
                    </button>
                    <button className="btn-confirm" onClick={() => onSubmit()}>
                      Confirm
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
DriverShiftModal.propTypes = {
  day: PropTypes.instanceOf(Object),
  driverId: PropTypes.number,
}

DriverShiftModal.defaultProps = {
  day: {},
  driverId: "",
}
export default DriverShiftModal
