import "./Dropdown.scss"

import classNames from "classnames"
import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import InfiniteScroll from "react-infinite-scroller"

import { useMounted, useOnClickOutside } from "../../../utils/hooks"
import Loading from "../Loading"
import SvgIcon from "../SvgIcon"
import TextInput from "../TextInput"

const Dropdown = ({
  onClick,
  value,
  items,
  label,
  error,
  mandatory,
  showItemField,
  showSecondItemField,
  margin,
  placeholder,
  className,
  scrollable,
  showIcon,
  avatarRender,
  emptyState,
  loadMore,
  hasMore,
  withSearch,
  onSearchChange,
  firstRowRender,
}) => {
  const [isOpenState, setIsOpenState] = useState(false)
  const ref = useRef()
  const isMounted = useMounted()

  useOnClickOutside(ref, () => setIsOpenState(false))

  const dropdownClass = classNames("dropdown-container body2", className, { withSearch })
  const activeListClass = classNames("activeList", { activeListScrollable: scrollable })

  const [state, setState] = useState()

  useEffect(() => {
    if (isMounted && withSearch) {
      const delayedCall = setTimeout(() => {
        onSearchChange(state)
      }, 500)

      return () => clearTimeout(delayedCall)
    }
    return undefined
  }, [state])

  useEffect(() => {
    if (!value) {
      setState()
    }
  }, [value])

  const controlledValue = value || state

  const changeStatus = () => {
    setIsOpenState(!isOpenState)
  }
  const returnOnChange = valueItem => {
    if (onClick) {
      onClick(valueItem)
    }

    setState(
      showSecondItemField
        ? `${valueItem[showItemField]} ${valueItem[showSecondItemField]}`
        : valueItem[showItemField],
    )
  }

  return (
    <div className="dropdown" ref={ref}>
      <label htmlFor="title">
        {label}
        {mandatory && <span className="mandatory-star">*</span>}
      </label>
      <div
        role="button"
        tabIndex={0}
        onClick={changeStatus}
        className={dropdownClass}
        style={{ margin }}
      >
        {withSearch ? (
          <TextInput
            placeholder={placeholder}
            inputSize="trip-request-size"
            inputStyle="search-style"
            icon="search"
            iconStyle="search-icon"
            onChange={e => setState(e.target.value)}
            value={state}
          />
        ) : (
          <>{controlledValue || <span className="placeholder">{placeholder}</span>}</>
        )}
        <div className="arrow-wrapper">
          <SvgIcon icon="medium-size-down-arrow" width="16" height="16" fill="#999999" />
        </div>
        {isOpenState && (
          <div className={activeListClass}>
            <InfiniteScroll
              loadMore={loadMore}
              hasMore={hasMore}
              pageStart={0}
              initialLoad={false}
              loader={<Loading className="loading-dropdown" />}
              threshold={100}
              useWindow={false}
            >
              <>
                {firstRowRender && (
                  <div
                    onClick={() => {
                      returnOnChange({})
                    }}
                    role="button"
                    className="item unassignedProfile"
                    tabIndex={0}
                  >
                    <SvgIcon icon="empty-profile" width="25" height="25" />
                    <p>Unassigned</p>
                  </div>
                )}
                {items.length > 0 ? (
                  items.map(item => {
                    return (
                      <div
                        onClick={() => {
                          returnOnChange(item)
                        }}
                        role="button"
                        className="item"
                        tabIndex={0}
                      >
                        {showIcon && avatarRender(item)}
                        {showSecondItemField
                          ? `${item[showItemField]} ${item[showSecondItemField]}`
                          : item[showItemField]}
                      </div>
                    )
                  })
                ) : (
                  <div className="empty-state-text">{emptyState}</div>
                )}
              </>
            </InfiniteScroll>
          </div>
        )}
        {error}
      </div>
    </div>
  )
}

Dropdown.propTypes = {
  onClick: PropTypes.func.isRequired,
  value: PropTypes.string,
  items: PropTypes.instanceOf(Array).isRequired,
  label: PropTypes.string,
  error: PropTypes.string.isRequired,
  mandatory: PropTypes.bool,
  showItemField: PropTypes.string,
  margin: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  showSecondItemField: PropTypes.string,
  scrollable: PropTypes.bool,
  showIcon: PropTypes.bool,
  avatarRender: PropTypes.func,
  emptyState: PropTypes.string,
  loadMore: PropTypes.func,
  hasMore: PropTypes.bool,
  withSearch: PropTypes.bool,
  onSearchChange: PropTypes.func,
  firstRowRender: PropTypes.bool,
}

Dropdown.defaultProps = {
  label: " choose",
  value: "",
  mandatory: false,
  showItemField: "",
  margin: "",
  placeholder: "",
  className: "",
  showSecondItemField: "",
  scrollable: false,
  showIcon: false,
  avatarRender: () => {},
  emptyState: "",
  loadMore: () => {},
  hasMore: false,
  withSearch: false,
  onSearchChange: () => {},
  firstRowRender: false,
}

export default Dropdown
