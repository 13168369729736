import "./Collapsible.scss"

import classNames from "classnames"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { Collapse } from "react-collapse"

import SvgIcon from "../SvgIcon"

const Collapsible = ({ onOpen, isOpen, title, icon, children }) => {
  const collapsibleClass = classNames("collapsible-container")

  const [openCollapse, setOpenCollapse] = useState(false)

  const controlledOpen = isOpen || openCollapse

  const onCollapseOpen = open => {
    if (onOpen) {
      onOpen(open)
      return
    }
    setOpenCollapse(open)
  }

  const onToggleContainerClick = () => {
    onCollapseOpen(!controlledOpen)
  }

  const toggleIcons = {
    default: {
      false: <SvgIcon icon="keyboard-arrow-down" width="16" height="16" />,
      true: <SvgIcon icon="keyboard-arrow-up" width="16" height="16" />,
    },
  }

  return (
    <div className={collapsibleClass}>
      <div role="button" className="toggle-container" onClick={onToggleContainerClick}>
        <div className="title body1-semibold">{title}</div>
        <div className="icon-wrapper">{toggleIcons[icon][controlledOpen]}</div>
      </div>
      <Collapse isOpened={controlledOpen}>
        <div>{children}</div>
      </Collapse>
    </div>
  )
}

Collapsible.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  isOpen: PropTypes.bool,
  onOpen: PropTypes.func,
  title: PropTypes.string,
}

Collapsible.defaultProps = {
  icon: "default",
  children: () => null,
  isOpen: false,
  onOpen: undefined,
  title: "",
}

export default Collapsible
