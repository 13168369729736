import "./DriverInfo.scss"

import moment from "moment"
import PropTypes from "prop-types"
import React, { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import API from "../../../constants/API"
import { EMPLOYMENT_STATUS } from "../../../constants/employmentStatus"
import { BLACK_CAR_TYPE, BUS_TYPE, EXPRESS_TYPE } from "../../../constants/transportationTypes"
import { COMPANY_DRIVER } from "../../../constants/userRoles"
import { clearDriverDetails, getDriverDetails } from "../../../redux/actions"
import apiUtils from "../../../utils/api/api-utils"
import BarChart from "../../atoms/BarChart"
import DriverAvatar from "../../atoms/DriverAvatar"
import Loading from "../../atoms/Loading"
import PieChart from "../../atoms/PieChart"
import ProgressBar from "../../atoms/ProgressBar"
import SvgIcon from "../../atoms/SvgIcon"
import TabLayout from "../../molecules/TabLayout"
import DriverEditModal from "../DriverEditModal"
import DriverShiftTable from "../DriverShiftTable"
import DriversLimitations from "../DriversLimitations"
import DriverTransportationStatistic from "../DriverTransportationStatistic"
import Modal from "../Modal"

const DriverInfo = ({ driverID }) => {
  const dispatch = useDispatch()
  const [notePopUpVisible, setNotePopUpVisible] = useState(false)
  const [editPopUpVisible, setEditPopUpVisible] = useState(false)
  const [driverEditModalOpen, setDriverEditModalOpen] = useState(false)

  useEffect(() => {
    dispatch(getDriverDetails(driverID))

    return () => dispatch(clearDriverDetails())
  }, [])

  const [barChartTabIndex, setBarChartTabIndex] = useState(1)
  const [pieChartTabIndex, setPieChartTabIndex] = useState(0)

  const [driverDetails, callsInProgress, userRole] = useSelector(({ driver, api, user }) => [
    driver.driverDetails,
    api.callsInProgress,
    user.jwtData?.role,
  ])

  const driverLoading = useMemo(() => {
    return apiUtils.areCallsInProgress([API.GET_DRIVER_DETAILS], callsInProgress)
  }, [callsInProgress])

  const checkForLicense = vehicleType => {
    return driverDetails.driver_profile?.vehicle_licences.find(
      license => license.vehicle_type === vehicleType && license.active,
    )
  }

  const transferredPassengersData = [
    {
      value: driverDetails.driver_profile?.transferred_passengers_per_service_type.select,
      label: "SUV",
      color: "#69BDEC",
    },
    {
      value: driverDetails.driver_profile?.transferred_passengers_per_service_type.express,
      label: "EXPRESS",
      color: "#FAB500",
    },
    {
      value: driverDetails.driver_profile?.transferred_passengers_per_service_type.bus,
      label: "BUS",
      color: "#67D19E",
    },
  ]

  const getHoursFromSeconds = seconds => {
    if (!seconds) {
      return seconds
    }

    return +(seconds / 60 / 60).toFixed(2)
  }

  const hoursPerTransportationTypeData = [
    {
      label: "SUV",
      value: getHoursFromSeconds(driverDetails.driver_profile?.seconds_per_service_type.select),
      color: "#69BDEC",
    },
    {
      label: "EXPRESS",
      value: getHoursFromSeconds(driverDetails.driver_profile?.seconds_per_service_type.express),
      color: "#FAB500",
    },
    {
      label: "BUS",
      value: getHoursFromSeconds(driverDetails.driver_profile?.seconds_per_service_type.bus),
      color: "#67D19E",
    },
  ]

  const ridesPerTransportationTypeData = [
    {
      label: "SUV",
      value: driverDetails.driver_profile?.rides_per_service_type.select,
      color: "#69BDEC",
    },
    {
      label: "EXPRESS",
      value: driverDetails.driver_profile?.rides_per_service_type.express,
      color: "#FAB500",
    },
    {
      label: "BUS",
      value: driverDetails.driver_profile?.rides_per_service_type.bus,
      color: "#67D19E",
    },
  ]

  return (
    <div>
      <div className="driver-info-container">
        {driverLoading ? (
          <Loading className="driver-loading" />
        ) : (
          <>
            <DriverAvatar
              avatar={driverDetails?.avatar}
              driverId={driverDetails.id}
              fname={driverDetails.first_name}
              lname={driverDetails.last_name}
              disabled={userRole === COMPANY_DRIVER}
            />
            {userRole !== COMPANY_DRIVER && (
              <div
                className="edit-container"
                role="button"
                onClick={() => setDriverEditModalOpen(true)}
                onMouseEnter={() => setEditPopUpVisible(true)}
                onMouseLeave={() => setEditPopUpVisible(false)}
              >
                {editPopUpVisible && <div className="edit-popup">Edit</div>}
                <SvgIcon icon="edit-pen-icon" width="32" height="32" fill="#F5F5F5" />
              </div>
            )}

            <div className="driver-info-wrapper">
              <div className="driver-name">
                {driverDetails.first_name} {driverDetails.last_name}
              </div>

              <ProgressBar
                width={214}
                height="5"
                scale={72}
                value={driverDetails?.driver_profile?.rolling_duty_time}
              />
              <div className="driver-hours">
                {driverDetails?.driver_profile?.rolling_duty_time}/72
              </div>

              <div className="driver-data-container">
                <div className="driver-data-column">
                  <div className="driver-data-column-item">
                    <SvgIcon icon="location" width={24} height={24} margin="0px 8px 0px 0px" />
                    {`${driverDetails.driver_profile?.location_details.city}, ${driverDetails.driver_profile?.location_details.iata}`}
                  </div>
                  <div className="driver-data-column-item">
                    <SvgIcon icon="phone" width={24} height={24} margin="0px 8px 0px 0px" />
                    {driverDetails.phone_number}
                  </div>
                  <div className="driver-data-column-item">
                    <SvgIcon icon="e-mail" width={24} height={24} margin="0px 8px 0px 0px" />
                    {driverDetails.email}
                  </div>
                </div>
                <div className="driver-data-column">
                  <div className="driver-data-column-item row">
                    <div className="driver-status">Employment status:</div>
                    <div>{EMPLOYMENT_STATUS[driverDetails.driver_profile?.employment_status]}</div>
                  </div>
                  <div className="driver-data-column-item row">
                    <div className="driver-status">Current status:</div>
                    <div>Available</div>
                  </div>
                  <div className="driver-data-column-item row">
                    <div>
                      {moment(driverDetails.driver_profile?.start_date).format("MMM DD, YYYY")} -{" "}
                      {driverDetails.driver_profile?.end_date
                        ? moment(driverDetails.driver_profile?.end_date).format("MMM DD, YYYY")
                        : " Indefinite"}
                    </div>
                  </div>
                </div>
                <div className="driver-data-column relative">
                  <div className="driver-data-column-label">Vehicle type:</div>
                  <div className="driver-data-column-item row">
                    <SvgIcon
                      icon={checkForLicense(BLACK_CAR_TYPE) ? "green-checkmark" : "x-mark"}
                      fill={checkForLicense(BLACK_CAR_TYPE) ? "#5CBB69" : "#CDCCC8"}
                      width="16"
                      height="16"
                      margin="0px 8px 0px 0px"
                    />
                    SUV
                  </div>

                  <div className="driver-data-column-item row">
                    <SvgIcon
                      icon={checkForLicense(EXPRESS_TYPE) ? "green-checkmark" : "x-mark"}
                      fill={checkForLicense(EXPRESS_TYPE) ? "#5CBB69" : "#CDCCC8"}
                      width="16"
                      height="16"
                      margin="0px 8px 0px 0px"
                    />
                    SPRINTER
                  </div>

                  <div className="driver-data-column-item row">
                    <SvgIcon
                      icon={checkForLicense(BUS_TYPE) ? "green-checkmark" : "x-mark"}
                      fill={checkForLicense(BUS_TYPE) ? "#5CBB69" : "#CDCCC8"}
                      width="16"
                      height="16"
                      margin="0px 8px 0px 0px"
                    />
                    BUS
                  </div>
                </div>
                <div className="driver-data-column relative max-height">
                  <div className="driver-data-column-label">Note:</div>
                  {driverDetails.driver_profile?.note ? (
                    <div
                      className="note-box"
                      onMouseEnter={() => setNotePopUpVisible(true)}
                      onMouseLeave={() => setNotePopUpVisible(false)}
                    >
                      {driverDetails.driver_profile?.note}
                      {notePopUpVisible && (
                        <div className="note-box-popup">{driverDetails.driver_profile?.note}</div>
                      )}
                    </div>
                  ) : (
                    <SvgIcon icon="note-empty-state" height="78" width="67" margin="4px 0 0 0" />
                  )}
                </div>
              </div>
            </div>
          </>
        )}

        <Modal
          shouldCloseOnOverlayClick
          style={{
            content: {
              position: "absolute",
              top: 0,
              right: -20,
              borderTopLeftRadius: 8,
              padding: 16,
              maxHeight: "unset",
              height: "100vh",
            },
          }}
          shouldCloseOnEsc
          isOpen={driverEditModalOpen}
          render={() => (
            <DriverEditModal
              setDriverEditModalOpen={setDriverEditModalOpen}
              driverDetails={driverDetails}
            />
          )}
          centered={false}
          onRequestClose={() => setDriverEditModalOpen(false)}
        />
      </div>

      <div className="charts-container">
        <div className="bar-chart-wrapper">
          <TabLayout
            tabTitleStyle="subtitle-style"
            tabIndex={barChartTabIndex}
            setTabIndex={setBarChartTabIndex}
            titles={["Time statistic", "Transferred passengers"]}
            contents={[
              <BarChart items={[]} valueType="min" />,
              <BarChart items={transferredPassengersData} labelWidth={60} />,
            ]}
          />
        </div>
        <div className="pie-chart-wrapper">
          <TabLayout
            tabTitleStyle="subtitle-style"
            tabIndex={pieChartTabIndex}
            setTabIndex={setPieChartTabIndex}
            titles={["Total hours", "Total rides"]}
            contents={[
              <PieChart data={hoursPerTransportationTypeData} loading={driverLoading} isDonut />,
              <PieChart data={ridesPerTransportationTypeData} loading={driverLoading} />,
            ]}
          />
        </div>
      </div>
      <div className="shiftSection__wrapper">
        <DriverTransportationStatistic driverDetails={driverDetails} loading={driverLoading} />
        <DriverShiftTable driverDetails={driverDetails} />
        <div className="limitationsBox">
          <DriversLimitations
            driverDetails={driverDetails}
            duration={driverDetails?.driver_profile?.duty_limit}
          />
          <DriversLimitations
            isDrive
            driverDetails={driverDetails}
            duration={driverDetails?.driver_profile?.drive_limit}
          />
        </div>
      </div>
    </div>
  )
}

DriverInfo.propTypes = {
  driverID: PropTypes.string.isRequired,
}

DriverInfo.defaultProps = {}

export default DriverInfo
