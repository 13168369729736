import "./SearchDropdown.scss"

import classNames from "classnames"
import PropTypes from "prop-types"
import React, { useRef, useState } from "react"
import InfiniteScroll from "react-infinite-scroller"

import { useOnClickOutside } from "../../../utils/hooks"
import Loading from "../Loading"
import SvgIcon from "../SvgIcon"
import TextInput from "../TextInput"

const SearchDropdown = ({
  onItemClick,
  value,
  setValue,
  items,
  showItemField,
  showSecondItemField,
  placeholder,
  className,
  onFocus,
  textInputStyle,
  field,
  noTopMargin,
  loadMore,
  hasMore,
}) => {
  const [isOpenState, setIsOpenState] = useState(false)
  const ref = useRef()

  useOnClickOutside(ref, () => setIsOpenState(false))

  const dropdownClass = classNames("search-dropdown-container", className, { noTopMargin })

  const arrowWrapperClass = classNames("arrow-wrapper", {
    isFilter: textInputStyle === "filter-search-style",
  })

  const getFormattedValue = valueItem => {
    if (field === "driver") {
      return `${valueItem[showItemField]} ${valueItem[showSecondItemField]}`
    }

    return showSecondItemField
      ? `${valueItem[showSecondItemField]} (${valueItem[showItemField]})`
      : valueItem[showItemField]
  }

  const returnOnChange = valueItem => {
    if (onItemClick) {
      onItemClick(valueItem)
    }
    setIsOpenState(false)
    setValue(getFormattedValue(valueItem))
  }

  const handleOnFocus = () => {
    if (!isOpenState) {
      onFocus()
      setIsOpenState(true)
    }
  }

  return (
    <div className={dropdownClass} ref={ref}>
      <TextInput
        fullWidth
        value={value}
        onChange={e => setValue(e.target.value)}
        placeholder={placeholder}
        onFocus={handleOnFocus}
        inputStyle={textInputStyle}
      />
      <div className={arrowWrapperClass}>
        <SvgIcon icon="medium-size-down-arrow" width="16" height="16" fill="#999999" />
      </div>
      {isOpenState && items && items.length > 0 && (
        <div className="activeList">
          <InfiniteScroll
            loadMore={loadMore}
            hasMore={hasMore}
            pageStart={0}
            initialLoad={false}
            loader={<Loading className="loading-dropdown" />}
            threshold={100}
            useWindow={false}
          >
            {items.map(item => {
              return (
                <div
                  onClick={() => {
                    returnOnChange(item)
                  }}
                  role="button"
                  className="item"
                  tabIndex={0}
                >
                  {getFormattedValue(item)}
                </div>
              )
            })}
          </InfiniteScroll>
        </div>
      )}
    </div>
  )
}

SearchDropdown.propTypes = {
  onItemClick: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  items: PropTypes.instanceOf(Array).isRequired,
  showItemField: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  showSecondItemField: PropTypes.string,
  onFocus: PropTypes.func,
  textInputStyle: PropTypes.string,
  field: PropTypes.string,
  noTopMargin: PropTypes.bool,
  loadMore: PropTypes.func,
  hasMore: PropTypes.bool,
}

SearchDropdown.defaultProps = {
  showItemField: "",
  placeholder: "",
  className: "",
  showSecondItemField: "",
  onFocus: () => {},
  textInputStyle: "",
  field: "",
  noTopMargin: false,
  loadMore: () => {},
  hasMore: false,
}

export default SearchDropdown
