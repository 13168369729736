import "./TopMenu.scss"

import classNames from "classnames"
import moment from "moment"
import PropTypes from "prop-types"
import React from "react"

const TopMenu = ({ history }) => {
  const topMenuClass = classNames("top-menu-container")

  const getTitle = () => {
    switch (history.location.pathname) {
      case "/":
        return "Dashboard"
      case "/planning":
        return "Planning"
      case "/control":
        return "Control panel"
      case "/security":
        return "Security"
      case "/drivers":
        return "Dashboard"
      case "/vehicles":
        return "Vehicles"

      default:
        return ""
    }
  }

  return (
    <div className={topMenuClass}>
      <h3>{getTitle()}</h3>
      <div>
        <h5>TIME AND DATE</h5>
        <p>{moment().format("LLL")}</p>
      </div>
    </div>
  )
}

TopMenu.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
}

TopMenu.defaultProps = {}

export default TopMenu
