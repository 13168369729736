import classNames from "classnames"
import PropTypes from "prop-types"
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import ManifestPassenger from "../../atoms/ManifestPassenger"

const ManifestTable = ({ manifest }) => {

    return (
        <>
            <div className="leg-data-container">
                <div>Depart: {manifest.leg.depart}</div>
                <div>Arrive: {manifest.leg.arrive}</div>
                <div>Departure Time: {manifest.leg.departure_time}</div>
                <div>Arrival Time: {manifest.leg.arrival_time}</div>
            </div>
            <div className="manifest-items-container">
            {manifest.passengers.length > 0 ?
                <table>
                    <tr className="manifest-headers">
                        <th className="first-element pnr-element">PNR</th>
                        <th className="second-element first-name-element">First Name</th>
                        <th className="third-element last-name-element">Last Name</th>
                        <th className="fourth-element arrive-element">Arrive</th>
                        <th className="last-element status-element">Status</th>
                    </tr>
                    {manifest.passengers.map(passenger => (
                        <ManifestPassenger passenger={passenger} />
                    ))
                    }
                </table>
            : <div className="flight-data-container">There are no passengers in this flight.</div>
            }
            </div>
        </>
    )
}

ManifestTable.propTypes = {
    manifest: PropTypes.instanceOf(Object).isRequired,
}

ManifestTable.defaultProps = {}

export default ManifestTable