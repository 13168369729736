import ACTIONS from "../../constants/ACTIONS"

const initialState = {
  distance: 0,
  pricePerMile: 0.0,
  priceDistance: 0.0,
  markup: 0.0,
  syRoyalty: 0.0,
  priceTotal: 0.0,
}

export default function quotingReducer(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.GET_QUOTE_SUCCESS:
      return {
        ...state,
        pointA: action.quote.pointA,
        pointB: action.quote.pointB,
        distance: action.quote.distance,
        pricePerMile: action.quote.pricePerMile,
        pricePerMinute: action.quote.pricePerMinute,
        priceRoundTrip: action.quote.priceRoundTrip,
        priceDistance: action.quote.priceDistance,
        durationInSeconds: action.quote.durationInSeconds,
        markup: action.quote.markup,
        syRoyalty: action.quote.syRoyalty,
        priceTotal: action.quote.priceTotal,
      }
    case ACTIONS.GET_QUOTE_START:
      return initialState
    default:
      return { ...state }
  }
}
