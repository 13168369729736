/* eslint-disable */
// _env_ - no-underscore-dangle problem

const getEnv = variable => {
  let value = false
  if (window._env_) {
    try {
      value = JSON.parse(window._env_[variable])
    } catch (error) {
      value = window._env_[variable]
    }
  }
  return value
}

export default {
  DEFAULT_COUNTRY: "US",
  DEFAULT_CURRENCY: "USD",
  API_BASE_URL: getEnv("API_BASE_URL"),
  PUBLIC_URL: getEnv("PUBLIC_URL"),
  LANDLINE_BOOKING_URL: getEnv("LANDLINE_BOOKING_URL"),
  GOOGLE_MAPS_API_KEY: getEnv("GOOGLE_MAPS_API_KEY"),
}
