import "./NotificationsEmptyState.scss"

import React from "react"

const NotificationsEmptyState = () => {
  return (
    <div className="notifications-empty-state">
      <div className="zero">0</div>
      <div className="label">Notifications</div>
      <img width={282} src="/media/notifications-empty-state.png" alt="0 Notifications" />
    </div>
  )
}

export default NotificationsEmptyState
