import moment from "moment"

import ACTIONS from "../../constants/ACTIONS"
import { MIDNIGHT_DATETIME } from "../../constants/dateFormats"

const initialState = {
  results: [],
  filters: {
    departure_time__gte: moment().format(MIDNIGHT_DATETIME),
    pnr: undefined,
    driver: undefined,
    depart: undefined,
    arrive: undefined,
    company: undefined,
    status: undefined,
    created__gte: undefined,
    vehicle__id__contains: undefined,
    departure_time__time: undefined,
    ofAllTimeEnabled: false,
  },
  initialFilters: undefined,
}

export default function tripReducer(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.GET_TRIPS_SUCCESS:
      if (action.timestamp >= state.requestTimestamp) {
        return {
          ...state,
          results: action.trips.results,
          next: action.trips.next,
        }
      }
      return { ...state }

    case ACTIONS.GET_TRIP_SUCCESS:
      return {
        ...state,
        tripDetails: {
          ...action.tripDetails,
        },
        externalTripDetailsFetch: action.externalTripDetailsFetch,
      }
    case ACTIONS.RESET_EXTERNAL_FETCH_SUCCESS: {
      return {
        ...state,
        externalTripDetailsFetch: false,
      }
    }
    case ACTIONS.GET_TRIP_REQUESTS_SUCCESS:
      if (action.offset) {
        return {
          ...state,
          [action.fieldToUpdate]: {
            ...action.data,
            results: state[action.fieldToUpdate]
              ? [...state[action.fieldToUpdate].results, ...action.data.results]
              : action.data.results,
          },
        }
      }
      return {
        ...state,
        [action.fieldToUpdate]: action.data,
      }

    case ACTIONS.GET_SUGGESTED_TRIPS_SUCCESS:
      if (action.offset) {
        return {
          ...state,
          [action.fieldToUpdate]: {
            ...action.data,
            results: state[action.fieldToUpdate]
              ? [...state[action.fieldToUpdate].results, ...action.data.results]
              : action.data.results,
          },
        }
      }
      return {
        ...state,
        [action.fieldToUpdate]: action.data,
      }
    case ACTIONS.GET_LAST_DAY_INFO_SUCCESS:
      return {
        ...state,
        ...action,
      }
    case ACTIONS.UPDATE_FILTERS_SUCCESS:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.filters,
        },
      }

    case ACTIONS.RESET_FILTERS_SUCCESS:
      return {
        ...state,
        filters: initialState.filters,
      }
    case ACTIONS.SET_INITIAL_FILTERS_SUCCESS:
      return {
        ...state,
        initialFilters: action.filters,
      }
    case ACTIONS.GET_NEXT_TRIPS_PAGE_SUCCESS:
      return {
        ...state,
        results: [...state.results, ...action.trips.results],
        next: action.trips.next,
      }
    case ACTIONS.GET_NEW_TRIP_REQUESTS_SUCCESS:
      if (action.offset) {
        return {
          ...state,
          NewTrips: {
            ...action.data,
            results: [...state.NewTrips.results, ...action.data.results],
          },
        }
      }
      return {
        ...state,
        NewTrips: action.data,
      }

    case ACTIONS.GET_PENDING_TRIP_SUGGESTIONS_SUCCESS:
      if (action.offset) {
        return {
          ...state,
          PendingTripSuggestions: {
            ...action.data,
            results: [...state.PendingTripSuggestions.results, ...action.data.results],
          },
        }
      }
      return {
        ...state,
        PendingTripSuggestions: action.data,
      }

    case ACTIONS.UPDATE_TRIP_ROW:
      return { ...state, results: action.payload.newTrips }

    case ACTIONS.PUSH_TRIP_TO_TOP_SUCESS:
      return {
        ...state,
        [action.table]: { ...state[action.table], results: action.data },
      }

    case ACTIONS.SET_INITIAL_TABLE_LOADING:
      return {
        ...state,
        [action.table]: { ...state[action.table], initialLoading: action.initialLoading },
      }

    case ACTIONS.SET_ROW_TO_HIGHLIGHT:
      return {
        ...state,
        rowToHighlight: action.id,
      }

    case ACTIONS.SET_TIMESTAMP:
      return {
        ...state,
        requestTimestamp: action.timestamp,
      }

    default:
      return { ...state }
  }
}
