import "./PlanningTable.scss"

import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"

import Loading from "../../atoms/Loading"
import TableHead from "../../atoms/TableHead"
import Table from "../Table"
import TableBody from "../TableBody"

const PlanningTable = ({
  planningData,
  columns,
  onRowClick,
  loadMore,
  hasMore,
  loading,
  showCheckbox,
  checked,
  onCheckboxClick,
  checkboxDisabled,
}) => {
  const planningTableClass = classNames("planning-table-container body2")

  return (
    <div className={planningTableClass}>
      <Table className="planning-table-class">
        <TableHead
          columns={columns}
          showCheckbox={showCheckbox}
          onCheckboxClick={onCheckboxClick}
          checked={checked}
          checkboxDisabled={checkboxDisabled}
        />
        {loading ? (
          <Loading className="loading-planning" />
        ) : (
          <>
            {planningData.length > 0 ? (
              <TableBody
                columns={columns}
                data={planningData}
                onRowClick={onRowClick}
                loadMore={loadMore}
                hasMore={hasMore}
                tableBodyType="planning"
                useWindow
              />
            ) : (
              <div className="planning-page-empty-state">
                <img
                  src="/media/planning-page-empty-state.png"
                  alt="There are no trips for the given filter"
                />
              </div>
            )}
          </>
        )}
      </Table>
    </div>
  )
}

PlanningTable.propTypes = {
  planningData: PropTypes.instanceOf(Array),
  columns: PropTypes.instanceOf(Array),
  onRowClick: PropTypes.func,
  loadMore: PropTypes.func,
  hasMore: PropTypes.bool,
  loading: PropTypes.bool,
  showCheckbox: PropTypes.bool,
  checked: PropTypes.bool,
  onCheckboxClick: PropTypes.func,
  checkboxDisabled: PropTypes.bool,
}

PlanningTable.defaultProps = {
  planningData: [],
  columns: [],
  onRowClick: () => {},
  loadMore: () => {},
  hasMore: false,
  loading: false,
  showCheckbox: false,
  checked: false,
  onCheckboxClick: () => {},
  checkboxDisabled: false,
}

export default PlanningTable
