import ACTIONS from "../../constants/ACTIONS"

const initialState = {
  predictions: [],
}

export default function addressAutocompleteReducer(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.GET_ADDRESS_AUTOCOMPLETE_SUCCESS:
      return {
        ...state,
        predictions: action.addressAutocomplete.predictions,
      }
    case ACTIONS.GET_ADDRESS_AUTOCOMPLETE_CLEAR_PREDICTIONS:
      return {
        ...state,
        predictions: [],
      }
    default:
      return { ...state }
  }
}
