import "./ItemForDriverCard.scss"

import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"

import SvgIcon from "../SvgIcon"

const ItemForDriverCard = ({ assignedTo, city, image, alt, tripNumber }) => {
  const itemForDriverCardClass = classNames("item-for-driver-card-container")
  return (
    <div className={itemForDriverCardClass}>
      <h5 className="assignedTo">{assignedTo}</h5>
      <div className="wrap">
        <img src={image} alt={alt} />

        <span className="wrapCity">
          <h5 className="city">{city}</h5>{" "}
          <span className="dotIcon">
            <SvgIcon icon="Dot" margin="0px" width="10px" height="10px" />
          </span>
          <h6 className="tripNumber">{tripNumber}</h6>
        </span>
        <SvgIcon icon="edit" margin="5px 10px" />
      </div>
    </div>
  )
}

ItemForDriverCard.propTypes = {
  assignedTo: PropTypes.string,
  city: PropTypes.string.isRequired,
  image: PropTypes.string,
  alt: PropTypes.string,
  tripNumber: PropTypes.string,
}

ItemForDriverCard.defaultProps = {
  assignedTo: "ASSIGNED TO",
  image: "",
  alt: "",
  tripNumber: "",
}

export default ItemForDriverCard
