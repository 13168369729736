export const NOTIFICATION_CODES = {
  MISSING_INFO: 1,
  NEW_TRIP_REQUEST: 2,
  NEW_TRIP_SUGGESTION: 3,
  APPROVED_TRIP_REQUEST: 4,
  DENIED_TRIP: 5,
  ACCEPTED_TRIP_SUGGESTION: 6,
  DENIED_TRIP_SUGGESTION: 7,
  NEW_PUBLIC_NOTE: 8,
  NEW_INTERNAL_NOTE: 9,
  CANCEL_TRIP: 10,
  ASSIGNED_TRIP: 11,
  UNASSIGNED_TRIP: 12,
  INVALID_PICK_UP_ADDRESS: 13,
  INVALID_DROP_OFF_ADDRESS: 14,
  D2D_ADDRESS_UPDATED: 15,
}

export default { NOTIFICATION_CODES }
