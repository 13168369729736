import { landlineApi } from "./calls"

export type LandlinePermission = {
  codename: string
}

export type LandlinePermissionMap = Record<string, boolean>

export async function getPermissions(): Promise<LandlinePermissionMap> {
  try {
    const { data } = await landlineApi.get<Array<LandlinePermission>>(`v2/bossadmin/permissions/`)

    const map: LandlinePermissionMap = data.reduce(
      (acc, perm) => ({
        ...acc,
        [perm.codename]: true,
      }),
      {},
    )

    return map
  } catch (e) {
    console.error("Failed to retrieve permissions", e)
    return {}
  }
}
