import "./NotifyModalItem.scss"

import classNames from "classnames"
import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"

import { PRE_TRIP_NOTIFICATION_FORMAT } from "../../../constants/dateFormats"
import {
  NOTIFICATION_FAILED,
  NOTIFICATION_QUEUED,
  NOTIFICATION_REQUEUED,
  NOTIFICATION_STRINGS,
} from "../../../constants/preTripNotifications"
import { getLocalDateTime } from "../../../utils/common"
import SvgIcon from "../SvgIcon"

const NotifyModalItem = ({
  item,
  notifyType,
  failedNotificationIds,
  isConfirmation,
  onRemovePnr,
  scrollRef,
}) => {
  const [iconPosition, setIconPosition] = useState({})
  const [showPopup, setShowPopup] = useState(false)

  const ref = useRef()

  const checkIfFailed = id => {
    return failedNotificationIds && failedNotificationIds.includes(id)
  }

  const itemField =
    notifyType === "drivers" ? "is_already_notified_driver" : "is_already_notified_customer"

  const getIconName = () => {
    if (isConfirmation) {
      return `email-notified-${checkIfFailed(item.id) ? NOTIFICATION_FAILED : NOTIFICATION_QUEUED}`
    }
    return `email-notified-${
      item[itemField].status === NOTIFICATION_REQUEUED
        ? NOTIFICATION_QUEUED
        : item[itemField].status
    }`
  }

  const getNotificationStatusText = () => {
    if (isConfirmation) {
      return checkIfFailed(item.id)
        ? NOTIFICATION_STRINGS[NOTIFICATION_FAILED]
        : NOTIFICATION_STRINGS[NOTIFICATION_QUEUED]
    }

    return NOTIFICATION_STRINGS[item[itemField].status]
  }

  const statusTextClass = isConfirmation
    ? classNames(
        "status-text",
        checkIfFailed(item.id)
          ? NOTIFICATION_STRINGS[NOTIFICATION_FAILED]
          : NOTIFICATION_STRINGS[NOTIFICATION_QUEUED],
      )
    : classNames("status-text", NOTIFICATION_STRINGS[item[itemField].status])

  useEffect(() => {
    const hidePopup = () => setShowPopup(false)
    if (scrollRef.current) {
      scrollRef.current.addEventListener("scroll", hidePopup)
    }

    return () => {
      if (scrollRef.current) {
        scrollRef.current.removeEventListener("scroll", hidePopup)
      }
    }
  }, [scrollRef.current])

  return (
    <div className="pnr-item">
      <div className="left-side-wrapper">
        <div className="pnr-text">{item.pnr}</div>
        {(isConfirmation || item[itemField].sent) && (
          <div
            ref={ref}
            onMouseEnter={() => {
              setShowPopup(true)
              setIconPosition(ref?.current?.getBoundingClientRect())
            }}
            onMouseLeave={() => setShowPopup(false)}
          >
            <SvgIcon icon={getIconName()} width="26" height="26" margin="5px 0px 0px 0px" />
          </div>
        )}
      </div>

      {showPopup && (
        <div
          className="email-notification-popup"
          style={{ top: iconPosition.top, left: iconPosition.left }}
        >
          <span className={statusTextClass}>{getNotificationStatusText()}</span>
          {!isConfirmation &&
            ` ${getLocalDateTime(item[itemField].datetime, PRE_TRIP_NOTIFICATION_FORMAT)}`}
        </div>
      )}

      {!isConfirmation && (
        <div
          className="remove-pnr-icon-wrapper"
          role="button"
          onClick={() => onRemovePnr(item.pnr)}
        >
          <SvgIcon icon="close" margin="8px 0px 0px 0px" />
        </div>
      )}

      {isConfirmation && checkIfFailed(item.id) && (
        <div className="error-text">{`${"Holder's email is missing"}`}</div>
      )}
    </div>
  )
}

NotifyModalItem.propTypes = {
  item: PropTypes.instanceOf(Object),
  notifyType: PropTypes.string,
  failedNotificationIds: PropTypes.instanceOf(Array),
  isConfirmation: PropTypes.bool,
  onRemovePnr: PropTypes.func,
  scrollRef: PropTypes.instanceOf(Element),
}

NotifyModalItem.defaultProps = {
  item: {},
  notifyType: "",
  failedNotificationIds: [],
  isConfirmation: false,
  onRemovePnr: () => {},
  scrollRef: null,
}

export default NotifyModalItem
