import { Box } from "grommet"
import React from "react"

interface LogoSquishedLoadingStateProps {
  scale?: number
}

export function LogoSquishedLoadingState({ scale = 1.15 }: LogoSquishedLoadingStateProps) {
  return (
    <Box width="full" height="small" align="center" justify="center">
      <img
        width={152}
        height={44}
        src="/media/landline-logo-squish-loading.gif"
        alt="Waiting for request to finish"
        style={{ transform: `scale(${scale})` }}
      />
    </Box>
  )
}
