import "./BookingNote.scss"

import classNames from "classnames"
import PropTypes from "prop-types"
import React, { useEffect, useMemo, useState } from "react"
import InfiniteScroll from "react-infinite-scroller"
import { useDispatch, useSelector } from "react-redux"

import API from "../../../constants/API"
import NOTES_CODE, { INTERNAL_NOTE, PUBLIC_NOTE } from "../../../constants/notes"
import { PAGE_LIMIT } from "../../../constants/offset"
import { NEW_STATUS } from "../../../constants/status"
import { getAgents, getCompanies, saveNote } from "../../../redux/actions"
import apiUtils from "../../../utils/api/api-utils"
import { getCompanyIcon, getUserAvatar } from "../../../utils/common"
import { useMounted } from "../../../utils/hooks"
import AvatarName from "../../atoms/AvatarName"
import Button from "../../atoms/Button"
import Checkbox from "../../atoms/Checkbox"
import Loading from "../../atoms/Loading"
import NewNote from "../../atoms/NewNote"
import SvgIcon from "../../atoms/SvgIcon"
import TextInput from "../../atoms/TextInput"
import UserAvatarName from "../../atoms/UserAvatarName"
import SavedNote from "../../molecules/SavedNote"
import Modal from "../Modal"

const BookingNote = ({ noteType, tripID, disabled, showSingleNote, setShowSingleNote }) => {
  const dispatch = useDispatch()
  const isMounted = useMounted()

  const [callsInProgress] = useSelector(({ api }) => [api.callsInProgress])

  const [allNotes] = useSelector(({ notes }) => [notes])
  const [allAgents] = useSelector(({ agents }) => [agents.agents])
  const [allCompanies] = useSelector(({ company }) => [company.allCompanies])
  const trip = useSelector(state => state.trip)
  const [addNewNote, setAddNewNote] = useState(false)
  const [newNoteValue, setnewNoteValue] = useState("")
  const [modalOpen, setModalOpen] = useState(false)
  const [search, setSearch] = useState("")
  const [selectAllChecked, setSelectAllChecked] = useState(false)
  const [assignedCompanyDetails, setAssignedCompanyDetails] = useState(false)
  const [agentsOffset, setAgentsOffset] = useState(0)
  const [selectedAgents, setSelectedAgents] = useState({})
  const [companiesOffset, setCompaniesOffset] = useState(0)
  const [selectedCompanies, setSelectedCompanies] = useState({})
  const noteCode = noteType === INTERNAL_NOTE ? NOTES_CODE.INTERNAL : NOTES_CODE.PUBLIC
  const internalNoteTitle = "Which user do you want to notify for the internal note?"
  const publicNoteTitle = "Which company do you want to notify for the public note?"
  const publicNoteIfAssigned = "Notify assigned company"
  const modalTitle = NOTES_CODE.INTERNAL === noteCode ? internalNoteTitle : publicNoteTitle
  const searchBoxText = NOTES_CODE.INTERNAL === noteCode ? "Search User" : "Search Company"

  const {
    tripDetails: { checked_baggage },
  } = trip

  useEffect(() => {
    if (noteCode === NOTES_CODE.INTERNAL) {
      dispatch(getAgents())
    } else {
      dispatch(getCompanies({ can_request_trip: true }))
    }
  }, [])

  const agentsLoading = useMemo(() => {
    return apiUtils.areCallsInProgress([API.GET_AGENTS], callsInProgress)
  }, [callsInProgress])

  const companiesLoading = useMemo(() => {
    return apiUtils.areCallsInProgress([API.GET_COMPANIES], callsInProgress)
  }, [callsInProgress])

  const loadMoreAgents = () => {
    if (!agentsLoading) {
      const currentOffset = agentsOffset + PAGE_LIMIT
      dispatch(getAgents({ offset: currentOffset, full_name: search }))
      setAgentsOffset(currentOffset)
    }
  }

  const loadMoreCompanies = () => {
    if (!companiesLoading) {
      const currentOffset = companiesOffset + PAGE_LIMIT
      dispatch(getCompanies({ offset: currentOffset, can_request_trip: true, name: search }))
      setCompaniesOffset(currentOffset)
    }
  }

  useEffect(() => {
    if (isMounted) {
      const delayedCall = setTimeout(() => {
        setSelectAllChecked(false)
        setSelectedAgents({})
        setSelectedCompanies({})
        if (NOTES_CODE.INTERNAL === noteCode) {
          dispatch(getAgents({ full_name: search }))
          setAgentsOffset(0)
        } else {
          dispatch(getCompanies({ name: search, can_request_trip: true }))
          setCompaniesOffset(0)
        }
      }, 500)

      return () => clearTimeout(delayedCall)
    }
    return undefined
  }, [search])

  useEffect(() => {
    const companyDetails = trip.tripDetails?.company_details
      ? trip.tripDetails.company_details
      : false
    setAssignedCompanyDetails(companyDetails)
    return function cleanup() {
      setAssignedCompanyDetails(false)
    }
  }, [])

  const changeSelectedAgents = params => {
    setSelectedAgents(prevState => ({ ...prevState, ...params }))
  }

  const changeSelectedCompanies = params => {
    setSelectedCompanies(prevState => ({ ...prevState, ...params }))
  }

  const getSelectedItems = itemsList => {
    return Object.keys(itemsList).filter(prop => itemsList[prop])
  }

  const handleSelectAll = () => {
    setSelectAllChecked(!selectAllChecked)
    if (NOTES_CODE.INTERNAL === noteCode) {
      const newSelectedAgents = {}

      allAgents.results.forEach(agent => {
        newSelectedAgents[agent.id] = !selectAllChecked
      })

      setSelectedAgents(newSelectedAgents)
    } else {
      const newSelectedCompanies = {}

      allCompanies.results.forEach(company => {
        newSelectedCompanies[company.id] = !selectAllChecked
      })

      setSelectedCompanies(newSelectedCompanies)
    }
  }

  useEffect(() => {
    if (!selectAllChecked) return

    if (NOTES_CODE.INTERNAL === noteCode) {
      const newSelectedAgents = {}

      allAgents.results.forEach(agent => {
        newSelectedAgents[agent.id] = selectAllChecked
      })

      setSelectedAgents(newSelectedAgents)
    } else {
      const newSelectedCompanies = {}

      allCompanies.results.forEach(company => {
        newSelectedCompanies[company.id] = selectAllChecked
      })

      setSelectedCompanies(newSelectedCompanies)
    }
  }, [allCompanies, allAgents])

  const onChangeHandler = evt => {
    setnewNoteValue(evt.target.value)
  }

  // reset selected agents or companies
  const resetSelection = () => {
    setSearch("")
    setSelectAllChecked(false)
    setSelectedAgents({})
    setSelectedCompanies({})
  }

  // cancel note
  const cancelNewNote = () => {
    setAddNewNote(false)
    setnewNoteValue("")
  }

  // save note
  const saveNewNote = () => {
    if (selectAllChecked) {
      dispatch(saveNote({ noteType, tripID, content: newNoteValue, selectAllChecked }))
    } else {
      const selectedToNotifyIds = []
      // set selected agents IDs
      if (NOTES_CODE.INTERNAL === noteCode) {
        selectedToNotifyIds.push(...getSelectedItems(selectedAgents))
      }
      // set selected companies IDs
      if (NOTES_CODE.PUBLIC === noteCode) {
        selectedToNotifyIds.push(...getSelectedItems(selectedCompanies))
      }

      dispatch(
        saveNote({ noteType, tripID, content: newNoteValue, recipients: selectedToNotifyIds }),
      )
    }

    setShowSingleNote(false)
    setAddNewNote(false)
    setnewNoteValue("")
    setModalOpen(false)
    resetSelection()
  }

  const openModal = () => {
    if (!newNoteValue.trim().length) return
    setModalOpen(true)
  }

  const selectionItemClass = classNames(`selection-item ${noteType}`)

  const filteredNotes = allNotes?.notes?.filter(note => note.note_type === noteCode)

  const noteToShow = showSingleNote && allNotes?.notes?.find(note => note.id === showSingleNote)

  return (
    <div className="booking-note">
      {!disabled ? (
        <div role="button" onClick={() => setAddNewNote(!addNewNote)} className="add-new-switch">
          <SvgIcon icon="add_circle_outline" fill="#2278B5" width="24" height="24" />
          <span className="label"> Add New </span>
        </div>
      ) : null}
      {filteredNotes.length === 0 && disabled ? <p>There are no public notes posted yet</p> : null}
      {addNewNote && (
        <NewNote
          onSaveClick={
            trip.tripDetails.status === NEW_STATUS && noteType === PUBLIC_NOTE
              ? saveNewNote
              : openModal
          }
          onCancelClick={cancelNewNote}
          onChange={onChangeHandler}
          value={newNoteValue}
        />
      )}
      <div className="saved-notes">
        {checked_baggage === 0 ? (
          <SavedNote
            description={
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <img
                  src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPHN2ZyB2aWV3Qm94PSIwIDAgNTAwIDUwMCIgd2lkdGg9IjUwMCIgaGVpZ2h0PSI1MDAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiAgPGcgdHJhbnNmb3JtPSJtYXRyaXgoMC43NzMyNTksIDAsIDAsIDAuNzczMjU5LCA1MS43NTY2NDEsIDQ0LjIxMjI2MSkiIHN0eWxlPSJvcGFjaXR5OiAwLjk7Ij4KICAgIDxnPgogICAgICA8cGF0aCBkPSJtMzkzLDE0Mi4xaC02MS44di05MC4zaDExLjZjMTEuNCwwIDIwLjYtOS4xIDIwLjYtMjAuNCAwLTExLjMtOS4yLTIwLjQtMjAuNi0yMC40aC0xNzMuNGMtMTEuNCwwLTIwLjYsOS4xLTIwLjYsMjAuNCAwLDExLjMgOS4yLDIwLjQgMjAuNiwyMC40aDExLjZ2OTAuM2gtNjEuOGMtMTEuNCwwLTIwLjYsOS4xLTIwLjYsMjAuNHYyODkuMmMwLDExLjMgOS4yLDIwLjQgMjAuNiwyMC40aDE1LjN2OC40YzAsMTEuMyA5LjIsMjAuNCAyMC42LDIwLjQgMTEuNCwwIDIwLjYtOS4xIDIwLjYtMjAuNHYtOC40aDE2MC43djguNGMwLDExLjMgOS4yLDIwLjQgMjAuNiwyMC40IDExLjQsMCAyMC42LTkuMSAyMC42LTIwLjR2LTguNGgxNS40YzExLjQsMCAyMC42LTkuMSAyMC42LTIwLjR2LTI4OS4xYzAtMTEuMy05LjMtMjAuNS0yMC42LTIwLjV6bS0xMDMtOTAuM3Y5MC4zaC02Ny44di05MC4zaDY3Ljh6bS0xNTAuMiwxMzEuMmgyMzIuNnYyNDguNGgtMjMyLjZ2LTI0OC40eiIvPgogICAgPC9nPgogIDwvZz4KICA8ZWxsaXBzZSBzdHlsZT0ic3Ryb2tlOiByZ2IoMCwgMCwgMCk7IGZpbGw6IHJnYmEoMjE2LCAyMTYsIDIxNiwgMCk7IHN0cm9rZS13aWR0aDogMjBweDsiIGN4PSIyNTAiIGN5PSIyNTAiIHJ4PSIyNDAiIHJ5PSIyNDAiLz4KICA8cGF0aCBzdHlsZT0iZmlsbDogcmdiKDIxNiwgMjE2LCAyMTYpOyBzdHJva2U6IHJnYigwLCAwLCAwKTsgc3Ryb2tlLWxpbmVjYXA6IHJvdW5kOyBzdHJva2Utd2lkdGg6IDIwcHg7IiBkPSJNIDgwLjYzNSA0MTkuMzY1IEwgNDE2LjU3NCA4Mi4wNzIiLz4KPC9zdmc+"
                  width="385"
                  height="387"
                  style={{
                    width: "25px",
                    height: "25px",
                    marginRight: "5px",
                  }}
                />
                <span>No baggage to check.</span>
              </div>
            }
            isSystemNote
          />
        ) : null}
        {showSingleNote && (
          <>
            <SavedNote
              userId={noteToShow.user_details.id}
              avatar={noteToShow.user_details.avatar}
              firstName={noteToShow.user_details.first_name}
              lastName={noteToShow.user_details.last_name}
              created={noteToShow.created}
              description={noteToShow.description}
              noteId={noteToShow.id}
              tripId={tripID}
            />
            {filteredNotes.length > 1 && (
              <div
                className="show-all-notes-button"
                role="button"
                onClick={() => setShowSingleNote(false)}
              >{`Show all ${
                noteToShow.note_type === NOTES_CODE.INTERNAL ? INTERNAL_NOTE : PUBLIC_NOTE
              } notes`}</div>
            )}
          </>
        )}
        {filteredNotes &&
          !showSingleNote &&
          filteredNotes.map(note => {
            return (
              <SavedNote
                userId={note.user_details.id}
                avatar={note.user_details.avatar}
                firstName={note.user_details.first_name}
                lastName={note.user_details.last_name}
                created={note.created}
                description={note.description}
                noteId={note.id}
                tripId={tripID}
              />
            )
          })}
      </div>

      <Modal
        customClass={noteType}
        onRequestClose={resetSelection}
        shouldCloseOnOverlayClick
        render={() => (
          <>
            {assignedCompanyDetails && noteCode !== NOTES_CODE.INTERNAL ? null : (
              <div className="search-select-all-container">
                <TextInput
                  value={search}
                  onChange={e => setSearch(e.target.value)}
                  placeholder={searchBoxText}
                  icon="search"
                  iconStyle="search-icon"
                  fullWidth
                  inputSize="search-size"
                  inputStyle="search-style"
                />
                <div
                  className="select-all-container"
                  role="button"
                  onClick={!search && handleSelectAll}
                >
                  <span>Select all</span>
                  <Checkbox disabled={!!search} checked={selectAllChecked} />
                </div>
              </div>
            )}

            <div className="inner-content">
              {noteCode === NOTES_CODE.INTERNAL ? (
                <InfiniteScroll
                  loadMore={loadMoreAgents}
                  hasMore={!!allAgents?.next}
                  pageStart={0}
                  initialLoad={false}
                  loader={<Loading className="loading-booking-note" />}
                  threshold={100}
                  useWindow={false}
                >
                  {allAgents?.results &&
                    noteCode === NOTES_CODE.INTERNAL &&
                    allAgents.results.map(agent => {
                      return (
                        <div
                          className={selectionItemClass}
                          role="button"
                          onClick={() => {
                            changeSelectedAgents({ [agent.id]: !selectedAgents[agent.id] })
                            setSelectAllChecked(false)
                          }}
                        >
                          <UserAvatarName
                            fname={agent.first_name}
                            lname={agent.last_name}
                            avatarType={getUserAvatar(agent.avatar)}
                            avatarURL={agent.avatar}
                            avatarWidth="32px"
                            avatarHeight="32px"
                            initialsFontSize="16px"
                            nameFontSize="14px"
                          />
                          <span className="checkbox-wrapper">
                            <Checkbox checked={selectedAgents[agent.id]} />
                          </span>
                        </div>
                      )
                    })}
                </InfiniteScroll>
              ) : (
                <InfiniteScroll
                  loadMore={loadMoreCompanies}
                  hasMore={!!allCompanies?.next}
                  pageStart={0}
                  initialLoad={false}
                  loader={!assignedCompanyDetails && <Loading className="loading-booking-note" />}
                  threshold={100}
                  useWindow={false}
                >
                  {!assignedCompanyDetails &&
                    allCompanies?.results &&
                    allCompanies.results.map(company => {
                      return (
                        <div
                          className={selectionItemClass}
                          role="button"
                          onClick={() => {
                            changeSelectedCompanies({
                              [company.id]: !selectedCompanies[company.id],
                            })
                            setSelectAllChecked(false)
                          }}
                        >
                          <AvatarName
                            fname={company.name}
                            avatarType={getCompanyIcon(company, company.logo)}
                            avatarURL={company.logo}
                            companyColor={company.color}
                            avatarWidth="32px"
                            avatarHeight="32px"
                          />

                          <span className="checkbox-wrapper">
                            <Checkbox checked={selectedCompanies[company.id]} />
                          </span>
                        </div>
                      )
                    })}
                  {assignedCompanyDetails && (
                    <div
                      className={selectionItemClass}
                      role="button"
                      onClick={() =>
                        changeSelectedCompanies({
                          [assignedCompanyDetails.id]: !selectedCompanies[
                            assignedCompanyDetails.id
                          ],
                        })
                      }
                    >
                      <AvatarName
                        fname={assignedCompanyDetails.name}
                        avatarType={getCompanyIcon(
                          assignedCompanyDetails,
                          assignedCompanyDetails?.logo,
                        )}
                        avatarURL={assignedCompanyDetails?.logo}
                        companyColor={assignedCompanyDetails?.color}
                        avatarWidth="32px"
                        avatarHeight="32px"
                      />

                      <span className="checkbox-wrapper">
                        <Checkbox checked={selectedCompanies[assignedCompanyDetails.id]} />
                      </span>
                    </div>
                  )}
                </InfiniteScroll>
              )}
            </div>
            <div className="buttonsContainer">
              <div>
                <Button
                  onClick={saveNewNote}
                  buttonType="landlineColor"
                  buttonSize="modalButtonSize"
                  type="button"
                  margin="44px 0px 0px 0px"
                >
                  Send
                </Button>
              </div>
              <div>
                <Button
                  onClick={() => {
                    setModalOpen(false)
                    resetSelection()
                  }}
                  buttonType="grayColor"
                  buttonSize="modalButtonSize"
                  type="button"
                  margin="44px 0px 0px 12px"
                >
                  Cancel
                </Button>
              </div>
            </div>
          </>
        )}
        isOpen={modalOpen}
        style={{
          overlay: {
            position: "fixed",
            backgroundColor: "rgba(0, 0, 0, 0.2)",
            zIndex: 99999,
            overflow: "none",
          },
          content: {
            width: "392px",
            padding: "24px 0px 24px 0px",
          },
        }}
        title={
          <>
            <div
              role="button"
              onClick={() => {
                setModalOpen(false)
                resetSelection()
              }}
              className="close-icon-wrapper"
            >
              <SvgIcon icon="x" fill="#2278B5" width="16" height="16" />
            </div>
            <div className="header-label">
              {assignedCompanyDetails && noteCode === NOTES_CODE.PUBLIC
                ? publicNoteIfAssigned
                : modalTitle}
            </div>
          </>
        }
      />
    </div>
  )
}

BookingNote.propTypes = {
  noteType: PropTypes.string.isRequired,
  tripID: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
  showSingleNote: PropTypes.number,
  setShowSingleNote: PropTypes.func,
}
BookingNote.defaultProps = {
  disabled: false,
  showSingleNote: false,
  setShowSingleNote: () => {},
}
export default BookingNote
