import "./PlanningPage.scss"

import classNames from "classnames"
import moment from "moment"
import PropTypes from "prop-types"
import queryString from "query-string"
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"

import API from "../../../constants/API"
import { MAX_LETTER_COMPANY, MAX_LETTER_DRIVER } from "../../../constants/common"
import {
  MIDNIGHT_DATETIME,
  MONTH_DAY_YEAR,
  TWELVE_HOUR_WITH_LEADING_ZERO,
} from "../../../constants/dateFormats"
import { TRIP_FILTERS } from "../../../constants/filterTypes"
import { PAGE_LIMIT } from "../../../constants/offset"
import { ROUTES } from "../../../constants/routes"
import {
  APPROVED_STATUS,
  CANCELLED_STATUS,
  NEW_STATUS,
  PENDING_STATUS,
  PLANNING_STATUS,
  SUGGESTED_STATUS,
  UNASSIGNED_STATUS,
} from "../../../constants/status"
import {
  BLACK_CAR_TYPE,
  EXPRESS_HOME_TYPE,
  EXPRESS_TYPE,
} from "../../../constants/transportationTypes"
import {
  COMPANY_DRIVER,
  COMPANY_REPRESENTATIVE,
  LANDLINE_AGENT,
} from "../../../constants/userRoles"
import {
  assignLandlineDriver,
  cancelTrip,
  getAllDrivers,
  getCompanies,
  getCompanyVehicles,
  getNextTripsPage,
  getTripDrivers,
  getTripFilterValue,
  getTrips,
  notifyCustomers,
  notifyDrivers,
  patchTrip,
  setRowToHighlight,
  unassignMultipleTrips,
} from "../../../redux/actions"
import { getAllAirports } from "../../../redux/airport/airportActions"
import {
  getUnreadNotifications,
  setActiveNotification,
} from "../../../redux/notifications/notificationsActions"
import apiUtils from "../../../utils/api/api-utils"
import { getCompanyIcon, getUserAvatar, isDoor2Door, isEmptyObject } from "../../../utils/common"
import config from "../../../utils/config"
import { useOnClickOutside } from "../../../utils/hooks"
import { getRLOCIcon } from "../../../utils/utilFunctions"
import ActiveFilterList from "../../atoms/ActiveFilterList"
import AssignDriverModal from "../../atoms/AssignDriverModal"
import AvatarName from "../../atoms/AvatarName"
import Button from "../../atoms/Button"
import CurrentDate from "../../atoms/CurrentDate"
import DateFilter from "../../atoms/DateFilter"
import DropDownCalendar from "../../atoms/DropDownCalendar"
import FormatedTableCell from "../../atoms/FormatedTableCell"
import InfoPopup from "../../atoms/InfoPopup"
import NotificationButton from "../../atoms/NotificationButton"
import NotifyModal from "../../atoms/NotifyModal"
import Popup from "../../atoms/Popup"
import RowItem from "../../atoms/RowItem"
import StatusTableCell from "../../atoms/StatusTableCell"
import SvgIcon from "../../atoms/SvgIcon"
import TableButtonDisabled from "../../atoms/TableButtonDisabled"
import TablePopup from "../../atoms/TablePopup"
import TransportationTypeCheckbox from "../../atoms/TransportationTypeCheckbox"
import UserAvatarName from "../../atoms/UserAvatarName"
import VehicleAvatarId from "../../atoms/VehicleAvatarId"
import VidecomReservation from "../../atoms/VidecomReservation"
import RegularLayout from "../../layouts/RegularLayout"
import BookingRequestModal from "../../molecules/BookingRequestModal"
import Modal from "../../organisms/Modal"
import PlanningFilters from "../../organisms/PlanningFilters"
import PlanningTable from "../../organisms/PlanningTable"
import TableDropDown from "../../organisms/TableDropDown"
import TableOperatorActions from "../../organisms/TableOperatorActions"
import TripDetailsModal from "../../organisms/TripDetailsModal"

const PlanningPage = ({ history }) => {
  const PlanningPageClass = classNames("planning-page-container")

  const formatDate = (date = moment()) => {
    return moment(date).format(MIDNIGHT_DATETIME)
  }
  const dispatch = useDispatch()

  const newBookingOverlayRef = useRef()
  const notifyDriversOverlayRef = useRef()
  const { tripFilters } = useParams()
  const tripFiltersObj = queryString.parse(tripFilters)

  const [
    callsInProgress,
    trips,
    drivers,
    errorHistory,
    vehicles,
    userRole,
    userData,
  ] = useSelector(({ api, trip, driver, vehicle, user }) => [
    api.callsInProgress,
    trip,
    driver,
    api.history,
    vehicle,
    user.jwtData?.role,
    user,
  ])

  const [activeNotification] = useSelector(({ notifications }) => [
    notifications.activeNotification,
  ])

  const getCheckedFilters = data => {
    return Object.fromEntries(Object.keys(data).map(key => [key, true]))
  }

  const [showRequestTripModal, setShowRequestTripModal] = useState(false)
  const [showNotifyButtons, setShowNotifyButtons] = useState(false)
  const [showNotifyModal, setShowNotifyModal] = useState(false)
  const [showNewBookingPopup, setShowNewBookingPopup] = useState(false)
  const [showEditTripModal, setShowEditTripModal] = useState(activeNotification?.showEditModal)
  const [tripDetailID, setTripDetailID] = useState(activeNotification?.relatedObjectId)
  const [showCancelTripModal, setShowCancelTripModal] = useState(false)
  const [selectedRow, setSelectedRow] = useState(null)
  const [chosenDate, setChosenDate] = useState(
    moment(tripFiltersObj?.departure_time__gte).toDate() || new Date(),
  )
  const [tripResultsOffset, setTripResultsOffset] = useState(0)
  const [vehiclesOffset, setVehiclesOffset] = useState(0)
  const [allTripsChecked, setAllTripsChecked] = useState(false)
  const [selectedTrips, setSelectedTrips] = useState([])
  const [shouldShowOnTop, setShouldShowOnTop] = useState(false)
  const [idToHighlight, setIdToHighlight] = useState(undefined)
  const [driverErrors, setDriverErrors] = useState()
  const [showAssignDriverModal, setShowAssignDriverModal] = useState(false)
  const [assignDriverCallback, setAssignDriverCallback] = useState()
  const [namePopupRowID, setNamePopupRowID] = useState(-1)
  const [selectChecked, setSelectChecked] = useState(true)
  const [expressChecked, setExpressChecked] = useState(true)
  const [isFilterChecked, setIsFilterChecked] = useState(getCheckedFilters({ ...tripFiltersObj }))
  const [initialRequestSent, setInitialRequestSent] = useState(false)
  const [firstLoad, setFirstLoad] = useState(true)
  const [notifyType, setNotifyType] = useState("")
  const [pnrValue, setPnrValue] = useState("")
  const [driverId, setDriverId] = useState(undefined)

  useEffect(() => {
    if (tripFiltersObj?.departure_time__gte) {
      setChosenDate(moment(tripFiltersObj?.departure_time__gte).toDate())
      setIsFilterChecked(prevState => ({ ...prevState, departure_time__gte: true }))
    }
  }, [tripFiltersObj?.departure_time__gte])

  useEffect(() => {
    if (
      tripFiltersObj.exclude_service_type &&
      tripFiltersObj.exclude_service_type.includes(EXPRESS_HOME_TYPE)
    ) {
      setExpressChecked(false)
    }
    if (
      tripFiltersObj.exclude_service_type &&
      tripFiltersObj.exclude_service_type.includes(BLACK_CAR_TYPE)
    ) {
      setSelectChecked(false)
    }
  }, [tripFiltersObj.exclude_service_type])

  const handleHighlightRow = () => {
    dispatch(setRowToHighlight(idToHighlight))
    setIdToHighlight(undefined)
  }

  const requestBookingPopupClass = classNames("request-booking-popup", {
    "show-on-top": shouldShowOnTop,
  })

  const changeSelectedTrips = row => {
    const alreadyExists = selectedTrips.find(item => item.id === row.id)
    let newSelectedTrips = [...selectedTrips]

    if (alreadyExists) {
      newSelectedTrips = selectedTrips.filter(item => item.id !== row.id)
    } else {
      newSelectedTrips.push(row)
    }

    setSelectedTrips(newSelectedTrips)
  }

  const onAllTripsCheckboxClick = () => {
    if (allTripsChecked) {
      setSelectedTrips([])
    } else {
      const allNewBookings = []

      trips.results.forEach(trip => {
        if (trip.status !== CANCELLED_STATUS) {
          allNewBookings.push(trip)
        }
      })

      setSelectedTrips(allNewBookings)
    }

    setAllTripsChecked(!allTripsChecked)
  }

  const notificationsBusy = useMemo(() => {
    return apiUtils.areCallsInProgress([API.MARK_NOTIFICATION_AS_READ], callsInProgress)
  }, [callsInProgress])

  useEffect(() => {
    dispatch(getCompanies())
    dispatch(getAllDrivers())
    dispatch(getAllAirports())
    if (!notificationsBusy) {
      dispatch(getUnreadNotifications())
    }
  }, [])

  const tripBusy = useMemo(() => {
    return apiUtils.areCallsInProgress([API.GET_TRIPS, API.GET_NEXT_TRIPS_PAGE], callsInProgress)
  }, [callsInProgress])

  const vehicleBusy = useMemo(() => {
    return apiUtils.areCallsInProgress([API.GET_COMPANY_VEHICLES], callsInProgress)
  }, [callsInProgress])

  const [lastUsedFilters, setLastUsedFilters] = useState(undefined)

  useEffect(() => {
    setIsFilterChecked({ ...tripFiltersObj })
    const parsedFilters = {}

    if (Object.keys(tripFiltersObj).length === 0 && !initialRequestSent && userRole) {
      if (userRole === COMPANY_DRIVER && userData.id) {
        parsedFilters.departure_time__gte = formatDate(chosenDate)
        parsedFilters.driver = userData.id
        setDriverId(userData.id)
        history.replace(`${ROUTES.PLANNING_PAGE}/${queryString.stringify(parsedFilters)}`)
        setIsFilterChecked(prevState => ({ ...prevState, ...parsedFilters }))
        setLastUsedFilters(parsedFilters)
        setInitialRequestSent(true)
      }

      if (userRole !== COMPANY_DRIVER) {
        parsedFilters.departure_time__gte = formatDate(chosenDate)
        history.replace(`${ROUTES.PLANNING_PAGE}/${queryString.stringify(parsedFilters)}`)
        setIsFilterChecked(prevState => ({ ...prevState, ...parsedFilters }))
        setInitialRequestSent(true)
      }
    }
    if (Object.keys(tripFiltersObj).length !== 0) {
      setInitialRequestSent(true)
    }
  }, [initialRequestSent, userData.id, userRole])

  const loadMore = () => {
    if (!tripBusy) {
      const currentOffset = tripResultsOffset + PAGE_LIMIT

      dispatch(getNextTripsPage({ ...tripFiltersObj, offset: currentOffset }))
      setTripResultsOffset(currentOffset)
    }
  }

  const loadMoreVehicles = (id, search) => {
    if (!vehicleBusy) {
      const currentOffset = vehiclesOffset + PAGE_LIMIT
      dispatch(
        getCompanyVehicles({ company: id, offset: currentOffset, vehicle_id__icontains: search }),
      )
      setVehiclesOffset(currentOffset)
    }
  }

  useEffect(() => {
    if (initialRequestSent && JSON.stringify(lastUsedFilters) !== JSON.stringify(tripFiltersObj)) {
      const handleGetTrips = async () => {
        await dispatch(getTrips(tripFiltersObj, idToHighlight && handleHighlightRow))
        if (firstLoad) setFirstLoad(false)
      }
      handleGetTrips()
      setLastUsedFilters(tripFiltersObj)
      setTripResultsOffset(0)
      if (idToHighlight) {
        window.scrollTo(0, 0)
      }
    }
  }, [
    tripFiltersObj.status,
    tripFiltersObj.pnr,
    tripFiltersObj.departure_time__gte,
    tripFiltersObj.depart,
    tripFiltersObj.arrive,
    tripFiltersObj.company,
    tripFiltersObj.driver,
    tripFiltersObj.vehicle__id__contains,
    tripFiltersObj.created__gte,
    tripFiltersObj.departure_time__time,
    tripFiltersObj.exclude_service_type,
    tripFiltersObj.ordering,
    initialRequestSent,
  ])

  useEffect(() => {
    if (tripFiltersObj.driver) {
      dispatch(getTripFilterValue("driver", tripFiltersObj.driver))
    }
  }, [tripFiltersObj.driver])

  useEffect(() => {
    if (tripFiltersObj.company) {
      dispatch(getTripFilterValue("company", tripFiltersObj.company))
    }
  }, [tripFiltersObj.company])

  const onDateChange = date => {
    const newFilters = {
      ...tripFiltersObj,
      departure_time__gte: moment(date).format(MIDNIGHT_DATETIME),
    }

    history.replace(`/planning/${queryString.stringify(newFilters)}`)
  }

  useEffect(() => {
    if (!tripFiltersObj.departure_time__gte) {
      setChosenDate(
        trips.results[0] && !tripBusy && moment(trips.results[0].departure_time).toDate(),
      )
    }
  }, [tripFiltersObj.departure_time__gte, trips.results[0]])

  useEffect(() => {
    const newFilters = {
      ...tripFiltersObj,
    }

    if (expressChecked && selectChecked) {
      delete newFilters.exclude_service_type
    } else if (!expressChecked && !selectChecked) {
      newFilters.exclude_service_type = `${EXPRESS_HOME_TYPE},${BLACK_CAR_TYPE}`
    } else if (!expressChecked) {
      newFilters.exclude_service_type = EXPRESS_HOME_TYPE
    } else if (!selectChecked) {
      newFilters.exclude_service_type = BLACK_CAR_TYPE
    }

    if (initialRequestSent) {
      history.replace(`/planning/${queryString.stringify(newFilters)}`)
    }
  }, [expressChecked, selectChecked])

  useOnClickOutside(notifyDriversOverlayRef, () => setShowNotifyButtons(false))

  const sortTrips = useCallback(
    (sortBy, sortDirection) => {
      const newFilters = {
        ...tripFiltersObj,
      }

      let parsedSortBy = sortBy
      if (sortDirection === "Descending") {
        parsedSortBy = `-${parsedSortBy}`
      }
      if (sortDirection === "None") {
        parsedSortBy = undefined
        delete newFilters.ordering
      }

      if (parsedSortBy) {
        newFilters.ordering = parsedSortBy
      }

      history.replace(`/planning/${queryString.stringify(newFilters)}`)
    },
    [tripFiltersObj],
  )

  const getCompanyName = (id, companyDetails) => {
    if (companyDetails) {
      return companyDetails.name
    }
    return PLANNING_STATUS[UNASSIGNED_STATUS]
  }

  const getVehicle = vehicle => {
    if (vehicle)
      return (
        <span>
          {vehicle.vehicle_id.length > 8
            ? `${vehicle.vehicle_id.substring(0, 8)}...`
            : vehicle.vehicle_id}
        </span>
      )
    return <span>Add vehicle</span>
  }
  const getShorterUserName = (fname, lname) => {
    const fullName = `${fname} ${lname}`
    if (fullName.length > MAX_LETTER_DRIVER) {
      return `${fullName.substring(0, MAX_LETTER_DRIVER)}...`
    }
    return fullName
  }
  const getFullName = (fname, lname) => {
    if (typeof fname === "undefined" || typeof lname === "undefined") {
      return ""
    }
    return `${fname} ${lname}`
  }

  const onRequestOperatorClick = () => {
    setShowRequestTripModal(true)
  }

  const getOperatorCustomRow = (toggleMenu, row) => {
    if (userRole === LANDLINE_AGENT) {
      return (
        <TableOperatorActions shouldShowOnTop={shouldShowOnTop} row={row} toggleMenu={toggleMenu} />
      )
    }

    if (userRole === COMPANY_REPRESENTATIVE) {
      return (
        <div role="button" onClick={e => e.stopPropagation()}>
          <Popup
            onConfirm={onRequestOperatorClick}
            onCancel={() => toggleMenu(false)}
            className={requestBookingPopupClass}
            confirmButtonDisabled={row.is_past_trip}
          >
            {row.is_past_trip ? (
              <div className="request-booking-text-row">
                You are not allowed to request dates in the past
              </div>
            ) : (
              <div className="request-booking-text-row">
                Request to operate booking <span className="body2-semibold">{row.pnr}</span>
              </div>
            )}
          </Popup>
          <Modal
            closeBtn="true"
            title={
              <div className="request-booking-title">
                {getRLOCIcon(row)}
                <span>{row.pnr}</span>
              </div>
            }
            style={{
              minHeight: 200,
              content: {
                width: 392,
              },
            }}
            onRequestClose={() => setShowRequestTripModal(false)}
            shouldCloseOnEsc
            isOpen={showRequestTripModal}
            render={() => (
              <BookingRequestModal
                row={row}
                toggleMenu={toggleMenu}
                onCancel={() => {
                  setShowRequestTripModal(false)
                  toggleMenu(false)
                }}
              />
            )}
          />
        </div>
      )
    }
    return null
  }

  const getPNRIcon = row => {
    if (userRole === LANDLINE_AGENT && row.non_select_legs?.length > 0) {
      if (row.non_select_legs[0].service_type === EXPRESS_TYPE) {
        return <SvgIcon icon="express-return-trip" width="16" height="16" />
      }
      return <SvgIcon icon="bus-return-trip" width="16" height="16" />
    }
    return getRLOCIcon(row)
  }

  const isOperatorDisabled = row => {
    if (
      (row.pending_trip_request.length > 0 && userRole === COMPANY_REPRESENTATIVE) ||
      userRole === COMPANY_DRIVER
    ) {
      return true
    }
    return null
  }
  const isOperatorInDisplayMode = row => {
    if (row.status === APPROVED_STATUS && userRole === COMPANY_REPRESENTATIVE) {
      return true
    }
    return false
  }

  const getAvatarName = (row, getShorterName) => {
    if (row.status === NEW_STATUS) {
      return PLANNING_STATUS[NEW_STATUS]
    }
    return getCompanyName(row.id, row.company_details) === PLANNING_STATUS[UNASSIGNED_STATUS]
      ? PLANNING_STATUS[UNASSIGNED_STATUS]
      : getShorterName()
  }

  const onRowClick = rowID => {
    setTripDetailID(rowID)
    setShowEditTripModal(true)
  }

  const getSelectedIds = () => selectedTrips.map(item => item.id)

  const handleUnassignTrips = () => {
    const selectedIds = selectedTrips
      .filter(item => item.status === NEW_STATUS)
      .map(item => item.id)
    setShowNewBookingPopup(false)
    dispatch(unassignMultipleTrips(selectedIds, tripFiltersObj))
    setSelectedTrips([])
    setAllTripsChecked(false)
    setTripResultsOffset(0)
  }

  const handleNotify = (type, callback) => {
    const selectedIds = getSelectedIds()
    if (type === "drivers") {
      dispatch(notifyDrivers(selectedIds, callback))
    }
    if (type === "customers") {
      dispatch(notifyCustomers(selectedIds, callback))
    }
    setSelectedTrips([])
    setAllTripsChecked(false)
  }

  const isNotifyDriversEnabled = () => {
    return selectedTrips.find(item => item.driver_details)
  }

  const notifyDriverClass = classNames("notify-button", {
    disabled: !isNotifyDriversEnabled(),
  })

  const columns = [
    {
      id: 1,
      name: "status",
      onClick: sortDirection => sortTrips("status", sortDirection),
      component: row => {
        const showCheckbox = userRole === LANDLINE_AGENT && row.status !== CANCELLED_STATUS

        return (
          <StatusTableCell
            status={row.status}
            statusConst={PLANNING_STATUS}
            missingInfo={row.is_missing_info}
            showCheckbox={showCheckbox}
            isChecked={selectedTrips.find(item => item.id === row.id)}
            onCheckClick={() => {
              if (selectedTrips.find(item => item.id === row.id)) {
                setAllTripsChecked(false)
              }

              changeSelectedTrips(row)
            }}
          />
        )
      },
    },
    {
      name: "RLOC(PNR)",
      onClick: sortDirection => sortTrips("ticket__booking_reference", sortDirection),
      component: row => {
        return (
          <FormatedTableCell
            status={row.status}
            label={row.pnr}
            className="body2-semibold"
            icon={getPNRIcon(row)}
            mixedPnrLegs={(userRole === LANDLINE_AGENT && row.non_select_legs) || []}
            isExpressHome={row.service_type === EXPRESS_HOME_TYPE}
            isSelectDoor2Door={isDoor2Door(row)}
            customerNotified={row.is_already_notified_customer.sent}
            notificationStatus={row.is_already_notified_customer.status}
            notificationDatetime={row.is_already_notified_customer.datetime}
          />
        )
      },
    },
    {
      id: 2,
      name: "D.DATE",
      onClick: sortDirection => sortTrips("departure_time__date", sortDirection),
      component: row => (
        <TablePopup popupLabel={moment(row.departure_time).format("dddd")}>
          <FormatedTableCell
            status={row.status}
            label={moment(row.departure_time).format(MONTH_DAY_YEAR)}
            icon={<SvgIcon icon="calendar" width="14" height="14" fill="#3c385" />}
            isExpressHome={row.service_type === EXPRESS_HOME_TYPE}
            isSelectDoor2Door={isDoor2Door(row)}
          />
        </TablePopup>
      ),
    },
    {
      id: 3,
      name: "D.TIME",
      onClick: sortDirection => sortTrips("departure_time__time", sortDirection),
      component: row => {
        return (
          <TablePopup popupLabel={row.depart.time_zone} icon={<SvgIcon icon="global-time" />}>
            <FormatedTableCell
              status={row.status}
              label={moment(row.departure_time).format(TWELVE_HOUR_WITH_LEADING_ZERO)}
              icon={<SvgIcon icon="clock" width="14" height="14" fill="#3c385" />}
              isExpressHome={row.service_type === EXPRESS_HOME_TYPE}
              isSelectDoor2Door={isDoor2Door(row)}
            />
          </TablePopup>
        )
      },
    },
    {
      id: 4,
      name: "D.ARP",
      onClick: sortDirection => sortTrips("depart", sortDirection),
      component: row => (
        <FormatedTableCell
          status={row.status}
          label={row.depart?.iata}
          isExpressHome={row.service_type === EXPRESS_HOME_TYPE}
          isSelectDoor2Door={isDoor2Door(row)}
        />
      ),
    },
    {
      id: 5,
      name: "A.ARP",
      onClick: sortDirection => sortTrips("arrive", sortDirection),
      component: row => (
        <FormatedTableCell
          status={row.status}
          label={row.arrive?.iata}
          isExpressHome={row.service_type === EXPRESS_HOME_TYPE}
          isSelectDoor2Door={isDoor2Door(row)}
        />
      ),
    },
    {
      id: 6,
      name: "OPERATOR",
      onClick: sortDirection => sortTrips("company", sortDirection),
      component: row => {
        const getShorterName = () => {
          const shorterName =
            getCompanyName(row.id, row.company_details).length > MAX_LETTER_COMPANY
              ? `${getCompanyName(row.id, row.company_details).substring(0, MAX_LETTER_COMPANY)}...`
              : row.company_details?.name
          return shorterName
        }

        if (row.service_type === 5) {
          return (
            <RowItem>
              <div className="express-wrapper">
                <AvatarName
                  fname="Express"
                  avatarType="express"
                  companyColor={row.company_details?.color}
                />
              </div>
            </RowItem>
          )
        }

        return (
          <TableDropDown
            shouldShowOnTop={shouldShowOnTop}
            setShouldShowOnTop={setShouldShowOnTop}
            rowType="operator"
            display={isOperatorInDisplayMode(row)}
            status={row.status}
            disabled={isOperatorDisabled(row)}
            onOutsideClickClose={!showRequestTripModal}
            companySelected={row.company_details}
            popupText={row.company_details?.name}
            popupLetterLimit={MAX_LETTER_COMPANY}
            value={
              <AvatarName
                fname={getAvatarName(row, getShorterName)}
                avatarURL={row.company_details?.logo}
                avatarType={getCompanyIcon(
                  row.company_details,
                  row.company_details?.logo,
                  row.status,
                )}
                companyColor={row.company_details?.color}
              />
            }
            style={{ width: 153 }}
            customDropDownContent={(toggleMenu, ref) => getOperatorCustomRow(toggleMenu, row, ref)}
          />
        )
      },
    },
    {
      id: 7,
      name: "DRIVER",
      onClick: sortDirection => sortTrips("driver", sortDirection),
      component: row => {
        return (
          <TableDropDown
            shouldShowOnTop={shouldShowOnTop}
            setShouldShowOnTop={setShouldShowOnTop}
            style={{ width: 184 }}
            rowType="driver"
            status={row.status}
            popupText={getFullName(row.driver_details?.first_name, row.driver_details?.last_name)}
            popupLetterLimit={MAX_LETTER_DRIVER}
            value={
              row.driver_details ? (
                <UserAvatarName
                  fname={getShorterUserName(
                    row.driver_details.first_name,
                    row.driver_details.last_name,
                  )}
                  avatarURL={row.driver_details?.avatar}
                  avatarType={getUserAvatar(row.driver_details?.avatar)}
                  avatarWidth="24px"
                  avatarHeight="24px"
                  initialsFontSize="14px"
                  nameFontSize="14px"
                  onAvatarClick={() => {
                    window.open(
                      `${config.PUBLIC_URL}/operators/${row.company_details.id}/drivers/${
                        row.driver_details.id
                      }/availability/${moment(row.departure_time).format("YYYY-MM")}`,
                      "_blank",
                    )
                    localStorage.setItem("selectedEvent", row.event)
                  }}
                  valueDisplay
                  setNamePopupRowID={setNamePopupRowID}
                  rowID={row.id}
                  customCursor
                />
              ) : (
                <AvatarName
                  fname="Add"
                  lname="Driver"
                  avatarURL={undefined}
                  avatarType="driverWithoutImage"
                />
              )
            }
            disabled={
              (userRole === COMPANY_REPRESENTATIVE && !row.company_details) ||
              userRole === COMPANY_DRIVER ||
              row.status === CANCELLED_STATUS ||
              row.status === PENDING_STATUS ||
              row.status === SUGGESTED_STATUS
            }
            onToggle={() => {
              dispatch(getTripDrivers(row.id))
            }}
            content={drivers.tripDrivers}
            fieldKey="id"
            onRowClick={driverID => {
              if (!row.company_details) {
                return dispatch(assignLandlineDriver(row.id, { driver: driverID }))
              }

              return dispatch(patchTrip(row.id, { driver: driverID }))
            }}
            error={errorHistory[0] === `${API.PATCH_TRIP}_ERROR`}
            rowRender={(
              rowData,
              setPopupVisible,
              setWrapperPosition,
              setErrorToShow,
              setAvatarPopupVisible,
              setAvatarPosition,
            ) => {
              return (
                <UserAvatarName
                  fname={rowData.driver?.first_name}
                  lname={rowData.driver?.last_name}
                  avatarURL={rowData.driver?.avatar}
                  avatarType={getUserAvatar(rowData.driver?.avatar)}
                  avatarWidth="24px"
                  avatarHeight="24px"
                  initialsFontSize="14px"
                  nameFontSize="14px"
                  withIcon
                  driverErrors={rowData.is_valid}
                  setPopupVisible={setPopupVisible}
                  setWrapperPosition={setWrapperPosition}
                  setErrorToShow={setErrorToShow}
                  onAvatarClick={() => {
                    window.open(
                      `${config.PUBLIC_URL}/operators/${
                        rowData.driver.company_details.id
                      }/drivers/${rowData.driver.id}/availability/${moment(
                        row.departure_time,
                      ).format("YYYY-MM")}`,
                      "_blank",
                    )
                  }}
                  shouldShowOnTop={shouldShowOnTop}
                  setAvatarPopupVisible={setAvatarPopupVisible}
                  setAvatarPosition={setAvatarPosition}
                  customCursor
                />
              )
            }}
            showCustomFirstRow={row.driver_details}
            firstRowRender={() => {
              return (
                <UserAvatarName
                  fname="Unassigned"
                  avatarURL={undefined}
                  avatarType="unassignedAvatar"
                  avatarWidth="24px"
                  avatarHeight="24px"
                  initialsFontSize="14px"
                  nameFontSize="14px"
                />
              )
            }}
            onFirstRowClick={() => dispatch(patchTrip(row.id, { driver: null }))}
            searchCallback={value => {
              dispatch(getTripDrivers(row.id, value))
            }}
            searchPlaceholder="Search driver"
            setShowAssignDriverModal={setShowAssignDriverModal}
            setDriverErrors={setDriverErrors}
            setAssignDriverCallback={setAssignDriverCallback}
            assignDriverModalVisible={showAssignDriverModal}
            customNamePopup
            namePopupRowID={namePopupRowID}
            rowID={row.id}
            driverNotified={row.is_already_notified_driver.sent}
            notificationStatus={row.is_already_notified_driver.status}
            notificationDatetime={row.is_already_notified_driver.datetime}
          />
        )
      },
    },
    {
      id: 8,
      name: "VEHICLE#",
      onClick: sortDirection => sortTrips("vehicle", sortDirection),
      component: row => (
        <TableDropDown
          shouldShowOnTop={shouldShowOnTop}
          setShouldShowOnTop={setShouldShowOnTop}
          rowType="vehicle#"
          disabled={
            !row.company_details || row.status === CANCELLED_STATUS || userRole === COMPANY_DRIVER
          }
          status={row.status}
          style={{ width: 99 }}
          onToggle={() => {
            dispatch(getCompanyVehicles({ company: row.company_details?.id }))
            setVehiclesOffset(0)
          }}
          rowRender={rowData => {
            return (
              <VehicleAvatarId
                vehicleId={rowData.vehicle_id}
                avatarURL={rowData.vehicle_image}
                avatarType={rowData.vehicle_image ? "withAvatar" : "defaultAvatar"}
              />
            )
          }}
          fieldKey="id"
          content={vehicles[row.company_details?.id]?.results}
          value={getVehicle(row.vehicle_details)}
          onRowClick={vehicleID => {
            return dispatch(patchTrip(row.id, { vehicle: vehicleID }))
          }}
          error={errorHistory[0] === `${API.PATCH_TRIP}_ERROR`}
          showCustomFirstRow={row.vehicle_details}
          firstRowRender={() => {
            return <VehicleAvatarId vehicleId="Unassigned" avatarType="unassignedAvatar" />
          }}
          onFirstRowClick={() => {
            return dispatch(patchTrip(row.id, { vehicle: null }))
          }}
          loadMore={search => loadMoreVehicles(row.company_details?.id, search)}
          hasMore={!!vehicles[row.company_details?.id]?.next}
          offset={vehiclesOffset}
          setOffset={setVehiclesOffset}
          searchCallback={value => {
            dispatch(
              getCompanyVehicles({
                vehicle_id__icontains: value,
                company: row.company_details?.id,
              }),
            )
          }}
          searchPlaceholder="Search vehicle"
        />
      ),
    },
    {
      id: 9,
      width: 16,
      component: row => {
        return (
          <>
            {userRole === COMPANY_REPRESENTATIVE && !isOperatorDisabled(row) ? (
              <TableButtonDisabled
                message={`Please contact admin for canceling the booking ${row.pnr}`}
                label={
                  <InfoPopup popupText="Cancel" position={{ right: "21px", top: "-5px" }}>
                    <SvgIcon icon="close" width="14" height="14" />
                  </InfoPopup>
                }
              />
            ) : (
              <RowItem>
                <div
                  className="btn"
                  role="button"
                  onClick={e => {
                    e.stopPropagation()
                    setSelectedRow(row)
                    setShowCancelTripModal(!isOperatorDisabled(row))
                  }}
                >
                  {isOperatorDisabled(row) || row.status === CANCELLED_STATUS ? null : (
                    <InfoPopup popupText="Cancel" position={{ right: "21px", top: "-5px" }}>
                      <SvgIcon icon="row-close" width="14" height="14" />
                    </InfoPopup>
                  )}
                </div>
              </RowItem>
            )}
          </>
        )
      },
    },
  ]

  const checkForActiveFilters = () => {
    const parsedFilters = { ...tripFiltersObj }
    delete parsedFilters.departure_time__gte

    return !isEmptyObject(parsedFilters)
  }

  return (
    <RegularLayout withTopMenu={false} history={history} backgroundColor="#ffffff">
      <div className={PlanningPageClass}>
        <div className="planning-container">
          <div className="planning-text">Planning</div>
          <div className="right-side-content">
            {userRole !== COMPANY_DRIVER && (
              <div className="notification-button">
                <NotificationButton onClick={() => history.push("/")} />
              </div>
            )}
            <div className="current-date">
              <CurrentDate />
            </div>
          </div>
        </div>
        <div className="upper-row-container">
          <DropDownCalendar
            onDateChange={onDateChange}
            value={chosenDate}
            showDateChange
            calendarClassName="planning-page-calendar"
          />

          <div className="upper-row-right-side">
            {userRole === LANDLINE_AGENT && (
              <>
                <VidecomReservation
                  requestCallback={pnr => {
                    setPnrValue(pnr)
                    setIsFilterChecked({ pnr })
                  }}
                />
                <div className="separation-line" />
                <TransportationTypeCheckbox
                  name="SUV"
                  checked={selectChecked}
                  setChecked={setSelectChecked}
                />
                <TransportationTypeCheckbox
                  name="Express"
                  checked={expressChecked}
                  setChecked={setExpressChecked}
                />
                <div className="upper-row-button-wrapper" ref={notifyDriversOverlayRef}>
                  <Button
                    type="secondary"
                    buttonType="newBookingColor"
                    buttonSize="newBookingSize"
                    onClick={() => setShowNotifyButtons(!showNotifyButtons)}
                    disabled={selectedTrips.length < 1}
                  >
                    Notify
                  </Button>
                  {showNotifyButtons && (
                    <div className="notify-buttons-container">
                      <div
                        className={notifyDriverClass}
                        role="button"
                        onClick={() => {
                          if (isNotifyDriversEnabled()) {
                            setShowNotifyModal(true)
                            setNotifyType("drivers")
                          }
                        }}
                      >
                        Notify driver
                      </div>
                      <div className="notify-buttons-divider" />
                      <div
                        className="notify-button"
                        role="button"
                        onClick={() => {
                          setShowNotifyModal(true)
                          setNotifyType("customers")
                        }}
                      >
                        Notify customer
                      </div>
                    </div>
                  )}
                </div>
                <div className="upper-row-button-wrapper" ref={newBookingOverlayRef}>
                  <Button
                    type="secondary"
                    buttonType="newBookingColor"
                    buttonSize="newBookingSize"
                    onClick={() => setShowNewBookingPopup(!showNewBookingPopup)}
                    disabled={!selectedTrips.find(item => item.status === NEW_STATUS)}
                  >
                    Make public
                  </Button>
                  {showNewBookingPopup && (
                    <Popup
                      shouldCloseOnOverlayClick
                      overlayRef={newBookingOverlayRef}
                      onConfirm={handleUnassignTrips}
                      onCancel={() => setShowNewBookingPopup(false)}
                      className="upper-row-button-popup"
                      confirmButtonText="Yes"
                    >
                      <div className="upper-row-popup-text">
                        Make selected booking/s visible to Company representatives?
                      </div>
                    </Popup>
                  )}
                </div>
              </>
            )}

            <PlanningFilters
              label="Filter by"
              isFilterChecked={isFilterChecked}
              setIsFilterChecked={setIsFilterChecked}
              history={history}
              setCurrentDate={() => onDateChange(new Date())}
              pnrValue={pnrValue}
              driverId={driverId}
              setDriverId={setDriverId}
              initialRequestSent={initialRequestSent}
            />
          </div>
        </div>

        <DateFilter
          tripFilters={tripFiltersObj}
          onDateClose={() => {
            if (!checkForActiveFilters()) {
              onDateChange(new Date())
            } else {
              setIsFilterChecked(prevState => ({ ...prevState, departure_time__gte: false }))
            }
          }}
          onAllTimeClick={() =>
            setIsFilterChecked(prevState => ({ ...prevState, departure_time__gte: false }))
          }
          onTodayClick={() => onDateChange(new Date())}
        />

        <ActiveFilterList
          isFilterChecked={isFilterChecked}
          setIsFilterChecked={setIsFilterChecked}
          filterType={TRIP_FILTERS}
          tripFilters={tripFiltersObj}
          changeToCurrentDay={() => onDateChange(new Date())}
        />

        <PlanningTable
          planningData={trips.results}
          columns={columns}
          onRowClick={onRowClick}
          hasMore={!!trips.next}
          loadMore={loadMore}
          showCheckbox={userRole === LANDLINE_AGENT}
          checked={allTripsChecked}
          onCheckboxClick={onAllTripsCheckboxClick}
          loading={(tripBusy || firstLoad) && !tripResultsOffset}
        />

        <Modal
          shouldCloseOnOverlayClick
          style={{
            content: {
              position: "absolute",
              top: 0,
              right: -20,
              borderTopLeftRadius: 8,
              padding: 16,
              maxHeight: "unset",
              height: "100vh",
            },
          }}
          onRequestClose={() => {
            setShowEditTripModal(false)
            dispatch(setActiveNotification({}))
          }}
          shouldCloseOnEsc
          isOpen={showEditTripModal}
          render={() => (
            <TripDetailsModal
              tripID={tripDetailID}
              setShowEditTripModal={setShowEditTripModal}
              setTripDetailID={setTripDetailID}
              setIdToHighlight={setIdToHighlight}
            />
          )}
          centered={false}
        />
      </div>
      <div role="button" onClick={e => e.stopPropagation()}>
        <Modal
          title={
            <div className="modal-title-text">
              Are you sure you want to cancel the booking
              <span className="pnr-text"> {selectedRow?.pnr}?</span>
            </div>
          }
          shouldCloseOnOverlayClick
          onRequestClose={() => {
            setShowCancelTripModal(false)
          }}
          render={() => (
            <div className="buttonsContainer">
              <div>
                <Button
                  onClick={() => {
                    dispatch(cancelTrip(selectedRow.id))
                    setShowCancelTripModal(false)
                  }}
                  buttonType="landlineColor"
                  buttonSize="modalButtonSize"
                  type="button"
                  margin="44px 0px 0px 0px"
                >
                  Yes
                </Button>
              </div>
              <div>
                <Button
                  onClick={() => {
                    setShowCancelTripModal(false)
                  }}
                  buttonType="grayColor"
                  buttonSize="modalButtonSize"
                  type="button"
                  margin="44px 0px 0px 12px"
                >
                  No
                </Button>
              </div>
            </div>
          )}
          isOpen={showCancelTripModal}
          style={{
            overlay: {
              position: "fixed",
              display: "flex",
              backgroundColor: "rgba(0, 0, 0, 0.2)",
              zIndex: 99999,
              overflow: "none",
            },
            content: {
              width: 318,
              height: 160,
              display: "flex",
              overflow: "none",
              boxShadow: "0px 0px 16px 4px rgba(66, 64, 62, 0.12)",
              borderRadius: "4px",
              border: "none",
              alignItems: "center",
            },
          }}
        />

        <Modal
          onClick={e => e.stopPropagation()}
          render={() => (
            <AssignDriverModal
              errors={driverErrors}
              onConfirmClick={() => {
                setShowAssignDriverModal(false)
                assignDriverCallback()
              }}
              onCancelClick={() => setShowAssignDriverModal(false)}
            />
          )}
          shouldCloseOnOverlayClick
          isOpen={showAssignDriverModal}
          onRequestClose={() => setShowAssignDriverModal(false)}
          style={{
            overlay: {
              position: "fixed",
              display: "flex",
              backgroundColor: "rgba(0, 0, 0, 0.2)",
              zIndex: 99999,
              overflow: "none",
            },
            content: {
              width: 392,
              display: "flex",
              overflow: "none",
              boxShadow: "0px 0px 16px 4px rgba(66, 64, 62, 0.12)",
              borderRadius: "4px",
              border: "none",
              alignItems: "center",
              height: "auto",
              padding: 0,
            },
          }}
        />

        <Modal
          onClick={e => e.stopPropagation()}
          render={() => (
            <NotifyModal
              itemList={selectedTrips}
              onCloseModal={() => setShowNotifyModal(false)}
              onRemovePnr={pnr => {
                const newSelectedTrips = selectedTrips.filter(item => item.pnr !== pnr)
                setSelectedTrips(newSelectedTrips)
                if (!newSelectedTrips.length) {
                  setShowNotifyModal(false)
                  setAllTripsChecked(false)
                }
              }}
              onConfirm={callback =>
                handleNotify(notifyType, () => {
                  setTripResultsOffset(0)
                  dispatch(getTrips(tripFiltersObj))
                  callback()
                })
              }
              notifyType={notifyType}
            />
          )}
          shouldCloseOnOverlayClick
          isOpen={showNotifyModal}
          onRequestClose={() => setShowNotifyModal(false)}
          style={{
            overlay: {
              position: "fixed",
              display: "flex",
              backgroundColor: "rgba(0, 0, 0, 0.2)",
              zIndex: 99999,
              overflow: "none",
            },
            content: {
              width: 392,
              height: 568,
              display: "flex",
              overflow: "none",
              boxShadow: "0px 0px 16px 4px rgba(66, 64, 62, 0.12)",
              borderRadius: "4px",
              border: "none",
              alignItems: "center",
              padding: 0,
            },
          }}
        />
      </div>
    </RegularLayout>
  )
}

PlanningPage.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
}

PlanningPage.defaultProps = {}

export default PlanningPage
