import "./ControlPage.scss"

import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"

import RegularLayout from "../../layouts/RegularLayout"
import GoogleMap from "../../organisms/GoogleMap"
import Table from "../../organisms/Table"

const ControlPage = ({ history }) => {
  const controlPageClass = classNames("control-page-container")
  return (
    <RegularLayout history={history}>
      <div className={controlPageClass}>
        <GoogleMap />
        <Table
          columns={["LL#", "Driver", "Vehicle", "Date", "Origin", "Depart", "Arrival", "Manifest"]}
          data={[
            ["1", "maja", "car", "17/02/20,20", "blabla", "00:15", "02:15", "blabla"],
            ["2", "stefan", "car", "17/02/20,20", "blabla", "00:15", "02:15", "blabla"],
            ["3", "marko", "car", "17/02/20,20", "blabla", "00:15", "02:15", "blabla"],
          ]}
        />
      </div>
    </RegularLayout>
  )
}

ControlPage.propTypes = {
  history: PropTypes.instanceOf(Object).isRequired,
}

ControlPage.defaultProps = {}

export default ControlPage
