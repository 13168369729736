import "./VehicleAvatarId.scss"

import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"

import SvgIcon from "../SvgIcon"

const VehicleAvatarId = ({ avatarURL, vehicleId, className, avatarType }) => {
  const avatarNameClass = classNames(["avatar-vehicle-container", className])

  const avatar = {
    defaultAvatar: <SvgIcon icon="landline-select" width="24" height="24" />,
    withAvatar: <img src={avatarURL} width="24" height="24" alt="" />,
    unassignedAvatar: <SvgIcon icon="unassigned-car" width="24" height="24" />,
  }[avatarType]

  return (
    <div className={avatarNameClass}>
      {avatar}
      <span>{vehicleId}</span>
    </div>
  )
}

VehicleAvatarId.propTypes = {
  avatarURL: PropTypes.string,
  className: PropTypes.string,
  avatarType: PropTypes.oneOf(["defaultAvatar", "withAvatar", "unassignedAvatar"]),
  vehicleId: PropTypes.string,
}

VehicleAvatarId.defaultProps = {
  avatarURL: "",
  className: "",
  avatarType: "",
  vehicleId: "",
}

export default VehicleAvatarId
