import "./AdditionalDriverEvents.scss"

import classNames from "classnames"
import PropTypes from "prop-types"
import React, { useRef, useState } from "react"

import { useOnClickOutside } from "../../../utils/hooks"
import DriverEvent from "../DriverEvent"

const AdditionalDriverEvents = ({ events, history }) => {
  const [additionalEventsVisible, setAdditionalEventsVisible] = useState(false)
  const [shouldShowOnTop, setShouldShowOnTop] = useState(false)
  const ref = useRef()

  useOnClickOutside(ref, () => setAdditionalEventsVisible(false))

  const additionalEventsPopupClass = classNames("additional-events-popup", { shouldShowOnTop })

  return (
    <div
      role="button"
      onClick={() => {
        setAdditionalEventsVisible(!additionalEventsVisible)
        setShouldShowOnTop(
          window.innerHeight - ref.current.getBoundingClientRect().bottom < window.innerHeight / 2,
        )
      }}
      className="additional-events"
      ref={ref}
    >
      <div>+{events.length} more</div>

      {additionalEventsVisible && (
        <div
          className={additionalEventsPopupClass}
          role="button"
          onClick={e => e.stopPropagation()}
        >
          {events.map(event => (
            <DriverEvent event={event} history={history} />
          ))}
        </div>
      )}
    </div>
  )
}

AdditionalDriverEvents.propTypes = {
  events: PropTypes.instanceOf(Array),
  history: PropTypes.instanceOf(Object),
}

AdditionalDriverEvents.defaultProps = {
  events: [],
  history: {},
}

export default AdditionalDriverEvents
