import "./AssignDriverModal.scss"

import PropTypes from "prop-types"
import React from "react"

import Button from "../Button"
import SvgIcon from "../SvgIcon"

const AssignDriverModal = ({ onConfirmClick, onCancelClick, errors }) => {
  return (
    <div className="assign-driver-modal" role="button" onClick={e => e.stopPropagation()}>
      <div className="close-button" role="button" onClick={onCancelClick}>
        <SvgIcon icon="close" width="16" height="16" />
      </div>
      <div
        className="title-wrapper"
        style={{
          padding: "56px 50px 32px 50px",
        }}
      >
        <div>
          <SvgIcon icon="warning-solid" width="26" height="26" margin="0 12px 0 0" />
        </div>

        <div className="title">Are you sure you want to assign this driver?</div>
      </div>

      <div className="driver-errors-wrapper">
        {errors.map(error => (
          <div className="driver-error">
            <div className="error-dot" />
            <div className="driver-error-text">{error[1]}</div>
          </div>
        ))}
      </div>

      <div className="buttons-container">
        <div>
          <Button
            onClick={onConfirmClick}
            buttonType="landlineColor"
            buttonSize="driverStatusModalSize confirmation"
            type="button"
          >
            Yes
          </Button>
        </div>
        <div>
          <Button
            buttonType="grayColor"
            buttonSize="driverStatusModalSize confirmation"
            type="button"
            onClick={onCancelClick}
            margin="0px 0px 0px 12px"
          >
            No
          </Button>
        </div>
      </div>
    </div>
  )
}

AssignDriverModal.propTypes = {
  onConfirmClick: PropTypes.func,
  onCancelClick: PropTypes.func,
  errors: PropTypes.instanceOf(Array),
}

AssignDriverModal.defaultProps = {
  onConfirmClick: () => {},
  onCancelClick: () => {},
  errors: [],
}

export default AssignDriverModal
