import "./VehiclesTab.scss"

import PropTypes from "prop-types"
import React, { useEffect, useMemo, useRef, useState } from "react"
import InfiniteScroll from "react-infinite-scroller"
import { useDispatch, useSelector } from "react-redux"
import { toast } from "react-toastify"

import API from "../../../constants/API"
import { PAGE_LIMIT } from "../../../constants/offset"
import { COMPANY_DRIVER, LANDLINE_AGENT } from "../../../constants/userRoles"
import { VEHICLE_AVAILABLE, VEHICLE_OUT_OF_SERVICE } from "../../../constants/vehicles"
import { getVehicle, setVehicleStatus } from "../../../redux/actions"
import apiUtils from "../../../utils/api/api-utils"
import { useMounted, useOnClickOutside } from "../../../utils/hooks"
import Button from "../../atoms/Button"
import Loading from "../../atoms/Loading"
import OperatorsEmptyState from "../../atoms/OperatorsEmptyState"
import SvgIcon from "../../atoms/SvgIcon"
import TextInput from "../../atoms/TextInput"
import VehicleCard from "../../atoms/VehicleCard"
import VehicleCardFieldList from "../../atoms/VehicleCardFieldList"
import AddVehicleModal from "../AddVehicleModal"

const VehiclesTab = ({ selectedCompany, selectedCompanyVehicles, isLandline }) => {
  const dispatch = useDispatch()
  const isMounted = useMounted()

  const ref = useRef()

  const [search, setSearch] = useState("")
  const [offset, setOffset] = useState(0)
  const [chooseStatusPopupVisible, setChooseStatusPopupVisible] = useState(false)

  useOnClickOutside(ref, () => setChooseStatusPopupVisible(false))

  const [callsInProgress, userRole] = useSelector(({ api, user }) => [
    api.callsInProgress,
    user.jwtData?.role,
  ])

  const isLandlineAgent = userRole === LANDLINE_AGENT
  const withoutCheckbox = (isLandline && !isLandlineAgent) || userRole === COMPANY_DRIVER

  useEffect(() => {
    if (isLandline) {
      dispatch(getVehicle({ company__is_landline: true }))
    } else {
      dispatch(getVehicle({ company: selectedCompany.id }))
    }
  }, [isLandline, selectedCompany.id])

  const loading = useMemo(() => {
    return apiUtils.areCallsInProgress([API.GET_VEHICLE], callsInProgress)
  }, [callsInProgress])

  const loadMore = () => {
    if (!loading) {
      const currentOffset = offset + PAGE_LIMIT
      dispatch(getVehicle({ company: selectedCompany.id, offset: currentOffset }))
      setOffset(currentOffset)
    }
  }

  useEffect(() => {
    if (isMounted) {
      const delayedCall = setTimeout(() => {
        dispatch(getVehicle({ company: selectedCompany.id, vehicle_id__icontains: search }))
        setOffset(0)
      }, 500)

      return () => clearTimeout(delayedCall)
    }
    return undefined
  }, [search])

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [editVehicleData, setEditVehicleData] = useState({})

  const getInitialCheckedState = () => {
    const initialCheckedState = {}

    if (selectedCompanyVehicles.length > 0) {
      selectedCompanyVehicles.results.forEach(vehicle => {
        initialCheckedState[vehicle.id] = false
      })
    }

    return initialCheckedState
  }

  const [checkedVehicles, setCheckedVehicles] = useState(getInitialCheckedState())
  const [checkedStatus, setCheckedStatus] = useState(null)

  const changeCheckedVehicles = params => {
    setCheckedVehicles(prevState => ({ ...prevState, ...params }))
  }

  const getCheckedVehicles = () => {
    return Object.keys(checkedVehicles).filter(prop => checkedVehicles[prop])
  }

  const handlePatchVehicle = status => {
    const chosenVehicles = getCheckedVehicles()

    if (checkedStatus === status) {
      toast.warning(
        `${chosenVehicles.length > 1 ? "These vehicles are" : "This vehicle is"} already ${
          status === VEHICLE_AVAILABLE ? "available" : "out of service"
        }`,
      )
      return
    }

    dispatch(setVehicleStatus(chosenVehicles, status, selectedCompany.id))
    setChooseStatusPopupVisible(false)
    setCheckedStatus(null)
    changeCheckedVehicles(getInitialCheckedState())
  }

  const showStatusChangeButton = () => {
    if (getCheckedVehicles().length > 0) {
      return (
        <div ref={ref}>
          <div
            className="status-button noselect"
            role="button"
            onClick={() => {
              setChooseStatusPopupVisible(!chooseStatusPopupVisible)
            }}
          >
            <SvgIcon icon="edit-black-pen" width={16} height={16} margin="2px 6px 0 0" />
            <span className="status-button-text">Change status</span>
          </div>
          {chooseStatusPopupVisible && (
            <div className="choose-vehicle-popup">
              <div
                role="button"
                className="choose-status-available"
                onClick={() => {
                  handlePatchVehicle(VEHICLE_AVAILABLE)
                }}
              >
                Available
              </div>
              <div
                role="button"
                className="choose-status-out-of-service"
                onClick={() => {
                  handlePatchVehicle(VEHICLE_OUT_OF_SERVICE)
                }}
              >
                Out of service
              </div>
            </div>
          )}
        </div>
      )
    }

    return null
  }

  const onEditVehicleClick = vehicle => {
    setIsModalOpen(true)
    setEditVehicleData(vehicle)
  }

  return (
    <div>
      <div className="vehicle-input-container">
        <TextInput
          onlyNumbers
          onChange={e => setSearch(e.target.value)}
          value={search}
          inputSize="search-size"
          inputStyle="search-style"
          icon="search"
          iconStyle="search-icon"
          placeholder="Search by ID"
        />

        {((isLandline && isLandlineAgent) || (!isLandline && userRole !== COMPANY_DRIVER)) && (
          <div className="top-row-buttons-wrapper">
            {showStatusChangeButton()}

            <Button buttonType="landlineColor" type="button" onClick={() => setIsModalOpen(true)}>
              + Add Vehicle
            </Button>
          </div>
        )}
      </div>
      {loading && !offset ? (
        <Loading className="loading-operators" />
      ) : (
        <>
          {selectedCompanyVehicles && selectedCompanyVehicles.results?.length > 0 ? (
            <>
              <VehicleCardFieldList withoutCheckbox={withoutCheckbox} />
              <InfiniteScroll
                loadMore={loadMore}
                hasMore={!!selectedCompanyVehicles.next}
                pageStart={0}
                loader={<Loading className="loading-operators" />}
                threshold={100}
                useWindow
              >
                {selectedCompanyVehicles.results.map(vehicle => (
                  <VehicleCard
                    withoutCheckbox={withoutCheckbox}
                    disabledCheckbox={checkedStatus && checkedStatus !== vehicle.status}
                    vehicle={vehicle}
                    checked={checkedVehicles[vehicle.id]}
                    setChecked={() => {
                      const chosenVehicles = getCheckedVehicles()

                      if (chosenVehicles.length === 0) {
                        setCheckedStatus(vehicle.status)
                      }

                      if (chosenVehicles.length === 1 && checkedVehicles[vehicle.id]) {
                        setCheckedStatus(null)
                      }

                      changeCheckedVehicles({
                        [vehicle.id]: !checkedVehicles[vehicle.id],
                      })
                    }}
                    onEditClick={e => {
                      e.stopPropagation()
                      onEditVehicleClick(vehicle)
                    }}
                    showEditVehicleButton={
                      (!isLandline && userRole !== COMPANY_DRIVER) ||
                      (isLandline && isLandlineAgent)
                    }
                  />
                ))}
              </InfiniteScroll>
            </>
          ) : (
            <OperatorsEmptyState
              afterSearch={!!search}
              searchedItems="vehicles"
              searchedField="ID"
            />
          )}
        </>
      )}

      <AddVehicleModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        companyId={selectedCompany.id}
        isLandlineAgent={isLandlineAgent}
        editVehicleData={editVehicleData}
        onClose={() => setEditVehicleData({})}
      />
    </div>
  )
}

VehiclesTab.propTypes = {
  selectedCompany: PropTypes.instanceOf(Object),
  selectedCompanyVehicles: PropTypes.instanceOf(Object),
  isLandline: PropTypes.bool,
}

VehiclesTab.defaultProps = {
  selectedCompany: {},
  selectedCompanyVehicles: {},
  isLandline: false,
}

export default VehiclesTab
