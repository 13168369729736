import { landlineApi } from "./calls"

export type RouteDelay = {
  id: number
  route_uuid: string
  code: number
  delay_amount: number
  delay_type: "departure" | "arrival"
  note: string
}

export async function upsertRouteDelay(update: RouteDelay) {
  try {
    await landlineApi.post<RouteDelay>(`v2/bossadmin/route_delays/`, update)
    return true
  } catch (e) {
    console.error("Failed to update route delay", e)
    return false
  }
}
