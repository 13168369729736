import PropTypes from "prop-types"
import React from "react"
import { Route } from "react-router"

const GuestUserRoute = ({ exact, path, component }) => {
  return <Route exact={exact} path={path} component={component} />
}

GuestUserRoute.propTypes = {
  exact: PropTypes.bool.isRequired,
  path: PropTypes.string.isRequired,
  component: PropTypes.node.isRequired,
}

export default GuestUserRoute
