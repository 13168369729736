import "./MixedPnrContent.scss"

import moment from "moment"
import PropTypes from "prop-types"
import React from "react"

import { MONTH_DAY_YEAR, TWELVE_HOUR_WITH_LEADING_ZERO } from "../../../constants/dateFormats"
import { BUS_TYPE, EXPRESS_TYPE } from "../../../constants/transportationTypes"
import LegConnection from "../LegConnection"

const MixedPnrContent = ({ data }) => {
  const title = `Landline ${data.service_type === BUS_TYPE ? "Bus" : "Express"}`

  return (
    <div className="mixed-pnr-content-container">
      <div className="title-wraper">{title}</div>
      <LegConnection
        iconName={
          data.service_type === EXPRESS_TYPE
            ? "leg-connection-express-return"
            : "leg-connection-bus-return"
        }
        arrivalData={data.arrive}
        departureData={data.depart}
      />
      <div className="date-time-container">
        <div>
          <div className="date-time-label">Departure date</div>
          <div className="date-time-value">
            {moment(data.departure_time).format(MONTH_DAY_YEAR)}
          </div>
        </div>
        <div className="right-container">
          <div className="date-time-label">Departure time</div>
          <div className="date-time-value">
            {moment(data.departure_time).format(TWELVE_HOUR_WITH_LEADING_ZERO)}
          </div>
        </div>
      </div>
      <div className="date-time-container">
        <div>
          <div className="date-time-label">Arrival date</div>
          <div className="date-time-value">{moment(data.arrival_time).format(MONTH_DAY_YEAR)}</div>
        </div>
        <div className="right-container">
          <div className="date-time-label">Arrival time</div>
          <div className="date-time-value">
            {moment(data.arrival_time).format(TWELVE_HOUR_WITH_LEADING_ZERO)}
          </div>
        </div>
      </div>
    </div>
  )
}

MixedPnrContent.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
}

MixedPnrContent.defaultProps = {}

export default MixedPnrContent
