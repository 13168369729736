import "./TableTwoRow.scss"

import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"

import RowItem from "../RowItem"

const TableTwoRow = ({ firstRow, secondRow, className }) => {
  const tableTwoRowClass = classNames(["table-two-row-container", className])
  return (
    <RowItem>
      <div className={tableTwoRowClass}>
        <span className="first-row">{firstRow}</span>
        <span className="label second-row">{secondRow}</span>
      </div>
    </RowItem>
  )
}

TableTwoRow.propTypes = {
  firstRow: PropTypes.string,
  secondRow: PropTypes.string,
  className: PropTypes.string,
}

TableTwoRow.defaultProps = {
  firstRow: "",
  secondRow: "",
  className: "",
}

export default TableTwoRow
