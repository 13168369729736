import ACTIONS from "../../constants/ACTIONS"
import API from "../../constants/API"
import { landlineApi } from "../../utils/api/calls"
import { apiError, startApiCall } from "../actions"

const updateFilters = (filterType, filters) => {
  return async dispatch => {
    dispatch({ type: ACTIONS.UPDATE_FILTERS_SUCCESS, filterType, filters })
  }
}

const setInitialFilters = (filterType, filters) => {
  return async dispatch => {
    dispatch({ type: ACTIONS.SET_INITIAL_FILTERS_SUCCESS, filterType, filters })
  }
}

const resetFilters = filterType => {
  return async dispatch => {
    dispatch({ type: ACTIONS.RESET_FILTERS_SUCCESS, filterType })
  }
}

const getTripFilterValue = (type, id) => {
  return async dispatch => {
    try {
      dispatch(startApiCall(API.GET_TRIP_FILTER_VALUE))
      const response =
        type === "driver"
          ? await landlineApi.get(`v2/bossadmin/users/company_drivers/${id}/`)
          : await landlineApi.get(`v2/bossadmin/companies/${id}/`)

      dispatch({
        type: ACTIONS.GET_TRIP_FILTER_VALUE_SUCCESS,
        field: type,
        value:
          type === "driver"
            ? `${response.data.first_name} ${response.data.last_name}`
            : `${response.data.name}`,
      })
    } catch (error) {
      dispatch(apiError(API.GET_TRIP_FILTER_VALUE, error))
    }
  }
}

export { updateFilters, setInitialFilters, resetFilters, getTripFilterValue }
