import React, { useState, useEffect, useMemo } from "react"
import { useCallback } from "react"
import { SortingRule } from "react-table"

import { RegularLayoutWithHeader } from "../../layouts/RegularLayout/WithHeader"
import { DataTable } from "../../tables/DataTable"
import { getRoutesWithDelay, RoutesWithDelayResult } from "../../../utils/api/getRoutesWithDelay"
import { calculateDelay, getRouteDelayTableColumns } from "./columns/DelayRoutesTableColumns"
import { PaginationEnvelope } from "../../../utils/api/types"
import { DelayEntryModal } from "./modals/DelayEntryModal"
import { LogoSquishedLoadingState } from "../../states/LogoSquishedLoadingState"
import { EmptyState } from "../../states/EmptyState"
import { getAirports, LandlineAirport } from "../../../utils/api/getAirports"

export function RoutesPage() {
  const [data, setData] = useState<PaginationEnvelope<RoutesWithDelayResult>>()
  const [editingDelay, setEditingDelay] = useState<RoutesWithDelayResult>()
  const [initialDelayType, setDelayType] = useState<"arrival" | "departure">("departure")
  const [currentPage, setPage] = useState(1)
  const [sort, setSort] = useState<SortingRule<RoutesWithDelayResult>>({
    id: "scheduled_start",
    desc: true,
  })
  const [loading, setLoading] = useState(true)
  const [airports, setAirports] = useState<Array<LandlineAirport>>()
  const [useLocalTZ, setUseLocalTZ] = useState(false)

  const handleGetData = useCallback(
    async (nextSort?: SortingRule<RoutesWithDelayResult>) => {
      setData(
        await getRoutesWithDelay({
          page: currentPage,
          sort: nextSort ?? sort,
        }),
      )

      setLoading(false)
    },
    [setData, sort, currentPage, setLoading],
  )

  const handleGetAirports = useCallback(async () => {
    const airports = await getAirports()
    setAirports(airports)
  }, [setAirports])

  const airportMap = useMemo<Record<string, string>>(() => {
    const map: Record<string, string> = {}
    airports?.forEach(({ iata, time_zone }) => {
      map[iata] = time_zone
    })

    return map
  }, [airports])

  const handleSetEditingDelay = useCallback(
    (data: RoutesWithDelayResult, type: "arrival" | "departure") => {
      setDelayType(type)
      setEditingDelay(data)
    },
    [setEditingDelay, setDelayType],
  )

  const handleCloseModal = useCallback(() => {
    setEditingDelay(null)
    handleGetData()
  }, [setEditingDelay])

  useEffect(() => {
    handleGetAirports()
    handleGetData()
  }, [setData, currentPage])

  return (
    <RegularLayoutWithHeader headerTitle="Route Delays">
      {loading ? <LogoSquishedLoadingState /> : null}
      {!loading && !data ? <EmptyState title="No Samsara routes found" /> : null}
      {data ? (
        <DataTable
          columns={getRouteDelayTableColumns(
            handleSetEditingDelay,
            airportMap,
            useLocalTZ,
            setUseLocalTZ,
          )}
          data={{
            count: data.count,
            next: data.next,
            previous: data.previous,
            results: data.results,
          }}
          showPagination
          rowHeight={56}
          paginationProps={{
            onChange: ({ page }) => setPage(page),
          }}
          sortable
          initialSortBy={{
            id: "scheduled_start",
            desc: true,
          }}
          onSortColumn={(column, desc, setSortBy) => {
            const nextSort = {
              id: column.id,
              desc: !desc,
            }

            setSort(nextSort)
            handleGetData(nextSort)
            setSortBy([nextSort])
          }}
        />
      ) : null}
      {editingDelay ? (
        <DelayEntryModal
          data={editingDelay.delays}
          routeUuid={editingDelay.uuid}
          departureTotalDelay={calculateDelay(
            editingDelay.scheduled_start,
            editingDelay.actual_start,
          )}
          arrivalTotalDelay={calculateDelay(editingDelay.scheduled_stop, editingDelay.actual_stop)}
          flight={editingDelay.flight}
          origin={editingDelay.origin}
          destination={editingDelay.destination}
          open
          initialTab={initialDelayType}
          onRequestClose={handleCloseModal}
        />
      ) : null}
    </RegularLayoutWithHeader>
  )
}
