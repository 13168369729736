import "./UserProfileName.scss"

import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"

import { getFirstLetter } from "../../../utils/common"

const UserProfileName = ({ firstName, lastName, image, description, openCollapse }) => {
  const userProfileClass = classNames("profile-wrapper", { openCollapse })

  return (
    <div className={userProfileClass}>
      {image ? (
        <img className="img" width="48" height="48" src={image} alt="profile" />
      ) : (
        <div className="capital-letters-container">{`${getFirstLetter(firstName)}${getFirstLetter(
          lastName[0],
        )}`}</div>
      )}
      {!openCollapse && (
        <div className="name-wrapper">
          <div className="name">{`${firstName} ${lastName}`}</div>
          <div className="description">{description}</div>
        </div>
      )}
    </div>
  )
}

UserProfileName.propTypes = {
  image: PropTypes.string,
  description: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  openCollapse: PropTypes.bool,
}

UserProfileName.defaultProps = {
  firstName: "",
  lastName: "",
  image: "",
  description: "",
  openCollapse: false,
}

export default UserProfileName
