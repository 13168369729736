import ACTIONS from "../../constants/ACTIONS"

const initialState = {}

export default function companyReducer(state = initialState, action) {
  switch (action.type) {
    case ACTIONS.GET_CITIES_SUCCESS:
      return {
        ...state,
        departureCities: action.departureCities,
        arrivalCities: action.arrivalCities,
      }

    default:
      return { ...state }
  }
}
