export const NOTIFICATION_SENT = 0
export const NOTIFICATION_FAILED = 1
export const NOTIFICATION_QUEUED = 2
export const NOTIFICATION_REQUEUED = 3

export const NOTIFICATION_STRINGS = {
  [NOTIFICATION_SENT]: "Sent",
  [NOTIFICATION_FAILED]: "Failed",
  [NOTIFICATION_QUEUED]: "Pending",
  [NOTIFICATION_REQUEUED]: "Pending",
}
