import classNames from "classnames"
import PropTypes from "prop-types"
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import Button from "../../atoms/Button"

const ManifestPassenger = ({ passenger }) => {

    const [boarded, setBoarded] = useState(false)

    function boardPassenger() {
        setBoarded(prev => !prev)
    }

    return (
        <>
            <tr className="manifest-passengers">
                <td className="first-element">{passenger.pnr}</td>
                <td>{passenger.first_name}</td>
                <td>{passenger.last_name}</td>
                <td>{passenger.arrive}</td>
                <td className="last-element">
                    {boarded ?
                        <Button
                            type="primary"
                            buttonType="grayColor"
                            buttonSize="newBookingSize"
                            onClick={() => { boardPassenger() }}
                        >Unboard</Button> :
                        <Button
                            type="primary"
                            buttonType="primaryColor"
                            buttonSize="newBookingSize"
                            onClick={() => { boardPassenger() }}
                        >Board</Button>
                    }
                </td>
            </tr>
        </>
    )
}

ManifestPassenger.propTypes = {
    manifest: PropTypes.instanceOf(Object).isRequired,
}

ManifestPassenger.defaultProps = {}

export default ManifestPassenger