import "./RadioButton.scss"

import classNames from "classnames"
import PropTypes from "prop-types"
import React, { useState } from "react"

const RadioButton = ({ checked, onChange, value, disabled, margin, id }) => {
  const radioButtonClass = classNames("radio-button-container")

  const [isChecked, setChecked] = useState(checked)

  const controlledChecked = typeof checked === "undefined" ? isChecked : checked

  const handleChange = e => {
    if (disabled) return
    if (onChange) {
      onChange(e)
      return
    }
    setChecked(e.currentTarget.checked)
  }

  return (
    <div className={radioButtonClass} style={{ margin }}>
      <input
        type="radio"
        checked={controlledChecked}
        onChange={handleChange}
        id={id}
        className="input-radio"
      />
      <label htmlFor={id} />
      {value && (
        <label htmlFor={id} className="radio-button-text">
          {value}
        </label>
      )}
    </div>
  )
}

RadioButton.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.string,
  margin: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string,
}

RadioButton.defaultProps = {
  checked: undefined,
  onChange: () => null,
  disabled: false,
  margin: undefined,
  value: "",
  id: "radio",
}

export default RadioButton
