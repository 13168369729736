import "./LastDayInfo.scss"

import classNames from "classnames"
import moment from "moment"
import PropTypes from "prop-types"
import React, { useCallback } from "react"
import { useSelector } from "react-redux"
import { useHistory } from "react-router"

import { DEFAULT_DATETIME } from "../../../constants/dateFormats"
import {
  APPROVED_STATUS,
  NEW_STATUS,
  PENDING_STATUS,
  UNASSIGNED_STATUS,
} from "../../../constants/status"
import { COMPANY_REPRESENTATIVE, LANDLINE_AGENT } from "../../../constants/userRoles"
import { redirectToPlanning } from "../../../utils/commonFunctionality"

const LastDayInfo = ({ lastDayData }) => {
  const [userRole] = useSelector(({ user }) => [user.jwtData?.role])
  const history = useHistory()

  const LastDayInfoClass = classNames("background-container")

  const handleSetInitialFilters = useCallback(status => {
    redirectToPlanning(history, {
      created__gte: moment()
        .subtract(24, "hours")
        .format(DEFAULT_DATETIME),
      status,
    })
  })

  return (
    <div>
      <div className="last24hoursContainer">
        <h5>Last 24 hours</h5>
      </div>
      <div>
        <div className={LastDayInfoClass}>
          <div className="bookingContainer" role="button" onClick={() => handleSetInitialFilters()}>
            <div className="bookingInfo">
              {lastDayData?.count < 10 ? `0${lastDayData.count}` : lastDayData?.count}
            </div>
            <div className="bookingText">{"total \nbookings"}</div>
          </div>
          {userRole === LANDLINE_AGENT && (
            <div>
              <div className="containerBox">
                <div
                  className="dataContainer"
                  role="button"
                  onClick={() => handleSetInitialFilters(NEW_STATUS)}
                >
                  <h4 className="dataInfo">{lastDayData?.new}</h4>
                  <div className="newBookingText">new booking</div>
                </div>

                <div
                  className="dataContainer second-item"
                  role="button"
                  onClick={() => handleSetInitialFilters(APPROVED_STATUS)}
                >
                  <h4 className="dataInfo">{lastDayData?.approved}</h4>
                  <div className="textInfo">approved</div>
                </div>
              </div>

              <div className="containerBox">
                <div
                  className="dataContainer second-row"
                  role="button"
                  onClick={() => handleSetInitialFilters(UNASSIGNED_STATUS)}
                >
                  <h4 className="dataInfo">{lastDayData?.unassigned}</h4>
                  <div className="textInfo">unassigned</div>
                </div>

                <div
                  className="dataContainer second-row second-item"
                  role="button"
                  onClick={() => handleSetInitialFilters(PENDING_STATUS)}
                >
                  <h4 className="dataInfo">{lastDayData?.pending}</h4>
                  <div className="textInfo">pending</div>
                </div>
              </div>
            </div>
          )}

          {userRole === COMPANY_REPRESENTATIVE && (
            <div className="containerBox">
              <div
                className="dataContainer-cr"
                role="button"
                onClick={() => handleSetInitialFilters(APPROVED_STATUS)}
              >
                <h4 className="dataInfo-cr">{lastDayData?.approved}</h4>
                <div className="textInfo-cr">approved</div>
              </div>
              <div
                className="dataContainer-cr"
                role="button"
                onClick={() => handleSetInitialFilters(UNASSIGNED_STATUS)}
              >
                <h4 className="dataInfo-cr">{lastDayData?.unassigned}</h4>
                <div className="textInfo-cr">unassigned</div>
              </div>
              <div
                className="dataContainer-cr"
                role="button"
                onClick={() => handleSetInitialFilters(PENDING_STATUS)}
              >
                <h4 className="dataInfo-cr">{lastDayData?.pending}</h4>
                <div className="textInfo-cr">pending</div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

LastDayInfo.propTypes = {
  lastDayData: PropTypes.instanceOf(Object).isRequired,
}

LastDayInfo.defaultProps = {}

export default LastDayInfo
