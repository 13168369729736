// eslint-disable-next-line
export const UNASSIGNED_STATUS = 1
export const SUGGESTED_STATUS = 2
export const PENDING_STATUS = 3
export const APPROVED_STATUS = 4
export const CANCELLED_STATUS = 5
export const NEW_STATUS = 6

export const PLANNING_STATUS = {
  [UNASSIGNED_STATUS]: "Unassigned",
  [SUGGESTED_STATUS]: "Suggested",
  [PENDING_STATUS]: "Pending",
  [APPROVED_STATUS]: "Approved",
  [CANCELLED_STATUS]: "Cancelled",
  [NEW_STATUS]: "New booking",
}

export const TRIP_REQUEST_PENDING_STATUS = 1
export const TRIP_REQUEST_APPROVED_STATUS = 2
export const TRIP_REQUEST_DENIED_STATUS = 3
export const TRIP_REQUEST_CANCELLED_STATUS = 4

export const TRIP_SUGGESTION_PENDING_STATUS = 1
export const TRIP_SUGGESTION_APPROVED_STATUS = 2
export const TRIP_SUGGESTION_DENIED_STATUS = 3
export const TRIP_SUGGESTION_CANCELLED_STATUS = 4

export const TRIP_REQUEST_STATUS = {
  [TRIP_REQUEST_PENDING_STATUS]: "Pending",
  [TRIP_REQUEST_APPROVED_STATUS]: "Approved",
  [TRIP_REQUEST_DENIED_STATUS]: "Denied",
  [TRIP_REQUEST_CANCELLED_STATUS]: "Cancelled",
}

export const TRIP_SUGGESTION_STATUS = {
  [TRIP_SUGGESTION_PENDING_STATUS]: "Pending",
  [TRIP_SUGGESTION_APPROVED_STATUS]: "Approved",
  [TRIP_SUGGESTION_DENIED_STATUS]: "Denied",
  [TRIP_SUGGESTION_CANCELLED_STATUS]: "Cancelled",
}
