import "./ChangePasswordTab.scss"

import React, { useState } from "react"
import { useDispatch } from "react-redux"
import { toast } from "react-toastify"

import { changeUserPassword } from "../../../redux/actions"
import Button from "../../atoms/Button"
import SvgIcon from "../../atoms/SvgIcon"
import TextInput from "../../atoms/TextInput"

const ChangePasswordTab = () => {
  const dispatch = useDispatch()
  const [passwordData, setPasswordData] = useState({})

  const changePasswordData = params => {
    setPasswordData(prevState => ({ ...prevState, ...params }))
  }

  const [showPassword, setShowPassword] = useState({})
  const changeShowPassword = params => {
    setShowPassword(prevState => ({ ...prevState, ...params }))
  }

  const handlePasswordChange = () => {
    if (
      !passwordData.current_password ||
      !passwordData.new_password ||
      !passwordData.repeated_password
    ) {
      toast.warning("All fields are required")
    } else if (
      passwordData.current_password.length < 6 ||
      passwordData.new_password.length < 6 ||
      passwordData.repeated_password.length < 6
    ) {
      toast.warning("Password needs to be at least 6 characters long")
    } else {
      dispatch(changeUserPassword(passwordData))
    }
  }

  return (
    <div className="change-password-container">
      <div className="change-password-text">Change password</div>

      <div className="input-row">
        <div className="pw-input-wrapper no-margin">
          <TextInput
            inputStyle="settings-style no-margin"
            value={passwordData.current_password}
            onChange={e => changePasswordData({ current_password: e.target.value })}
            label="Current password"
            inputType={showPassword.current_password ? "text" : "password"}
            fullWidth
          />

          <div
            className="pw-icon-wrapper"
            role="button"
            onClick={() => changeShowPassword({ current_password: !showPassword.current_password })}
          >
            <SvgIcon icon={showPassword.current_password ? "eye-show" : "eye-hide"} />
          </div>
        </div>

        <div className="pw-input-wrapper">
          <TextInput
            inputStyle="settings-style no-margin"
            value={passwordData.new_password}
            onChange={e => changePasswordData({ new_password: e.target.value })}
            label="New password"
            inputType={showPassword.new_password ? "text" : "password"}
            fullWidth
          />

          <div
            className="pw-icon-wrapper"
            role="button"
            onClick={() => changeShowPassword({ new_password: !showPassword.new_password })}
          >
            <SvgIcon icon={showPassword.new_password ? "eye-show" : "eye-hide"} />
          </div>
        </div>

        <div className="pw-input-wrapper">
          <TextInput
            inputStyle="settings-style no-margin"
            value={passwordData.repeated_password}
            onChange={e => changePasswordData({ repeated_password: e.target.value })}
            label="Confirm password"
            inputType={showPassword.repeated_password ? "text" : "password"}
            fullWidth
          />

          <div
            className="pw-icon-wrapper"
            role="button"
            onClick={() =>
              changeShowPassword({ repeated_password: !showPassword.repeated_password })
            }
          >
            <SvgIcon icon={showPassword.repeated_password ? "eye-show" : "eye-hide"} />
          </div>
        </div>
      </div>

      <div className="buttons-wrapper">
        <Button buttonType="grayColor" buttonSize="settings-size" margin="0 16px 0 0">
          Cancel
        </Button>
        <Button
          buttonType="landlineColor"
          buttonSize="settings-size"
          onClick={handlePasswordChange}
        >
          Save changes
        </Button>
      </div>
    </div>
  )
}

ChangePasswordTab.propTypes = {}

ChangePasswordTab.defaultProps = {}

export default ChangePasswordTab
