import moment from "moment"
import PropTypes from "prop-types"
import queryString from "query-string"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router-dom"

import API from "../../../constants/API"
import {
  DEFAULT_DATETIME,
  MIDNIGHT_DATETIME,
  TWELVE_HOUR_WITH_LEADING_ZERO,
  TWELVE_HOUR_WITHOUT_LEADING_ZERO,
  TWENTY_FOUR_HOUR_WITH_LEADING_ZERO,
} from "../../../constants/dateFormats"
import { ROUTES } from "../../../constants/routes"
import {
  APPROVED_STATUS,
  CANCELLED_STATUS,
  NEW_STATUS,
  PENDING_STATUS,
  PLANNING_STATUS,
  SUGGESTED_STATUS,
  UNASSIGNED_STATUS,
} from "../../../constants/status"
import { LANDLINE_AGENT } from "../../../constants/userRoles"
import { getAllDrivers, getCompanies } from "../../../redux/actions"
import { getAllAirports } from "../../../redux/airport/airportActions"
import { updateFilters } from "../../../redux/filter/filterActions"
import apiUtils from "../../../utils/api/api-utils"
import { useDebounce, useMounted } from "../../../utils/hooks"
import Dropdown from "../../atoms/Dropdown"
import DropDownCalendar from "../../atoms/DropDownCalendar"
import FiltersDropdown from "../../atoms/FiltersDropdown"
import RadioButton from "../../atoms/RadioButton"
import SearchDropdown from "../../atoms/SearchDropdown"
import TextInput from "../../atoms/TextInput"
import TimeInputPicker from "../../atoms/TimeInputPicker"

const PlanningFilters = ({
  isFilterChecked,
  setIsFilterChecked,
  history,
  pnrValue,
  setPnrValue,
  driverId,
  initialRequestSent,
}) => {
  const dispatch = useDispatch()
  const isMounted = useMounted()
  const { tripFilters } = useParams()
  const tripFiltersObj = queryString.parse(tripFilters)

  const [
    allCompanies,
    allAirports,
    drivers,
    userRole,
    callsInProgress,
    filtersData,
  ] = useSelector(({ company, airport, driver, user, api, filter }) => [
    company.allCompanies,
    airport.allAirports,
    driver.allDrivers,
    user.jwtData?.role,
    api.callsInProgress,
    filter,
  ])

  const operatorBusy = useMemo(() => {
    return apiUtils.areCallsInProgress([API.GET_COMPANIES], callsInProgress)
  }, [callsInProgress])

  const driverBusy = useMemo(() => {
    return apiUtils.areCallsInProgress([API.GET_ALL_DRIVERS], callsInProgress)
  }, [callsInProgress])

  const airportItems = allAirports?.flatMap(item => item.airports.map(airport => airport))

  const statusItems = [
    {
      name: PLANNING_STATUS[UNASSIGNED_STATUS],
      value: UNASSIGNED_STATUS,
    },
    {
      name: PLANNING_STATUS[SUGGESTED_STATUS],
      value: SUGGESTED_STATUS,
    },
    {
      name: PLANNING_STATUS[PENDING_STATUS],
      value: PENDING_STATUS,
    },
    {
      name: PLANNING_STATUS[APPROVED_STATUS],
      value: APPROVED_STATUS,
    },
    {
      name: PLANNING_STATUS[CANCELLED_STATUS],
      value: CANCELLED_STATUS,
    },
  ]

  if (userRole === LANDLINE_AGENT) {
    statusItems.push({ name: PLANNING_STATUS[NEW_STATUS], value: NEW_STATUS })
  }

  const [isFilterOpen, setIsFilterOpen] = useState({})
  const [filterValues, setFilterValues] = useState({
    ...tripFiltersObj,
    pnr: pnrValue || tripFiltersObj.pnr,
  })

  useEffect(() => {
    if (pnrValue) {
      setFilterValues(prevState => ({ ...prevState, pnr: pnrValue }))
      setPnrValue("")
    }
  }, [pnrValue])

  useEffect(() => {
    if (driverId) {
      setFilterValues(prevState => ({ ...prevState, driver: driverId }))
    }
  }, [driverId])

  const [departureDate, setDepartureDate] = useState(
    tripFiltersObj.departure_time__gte
      ? moment(tripFiltersObj.departure_time__gte).toDate()
      : moment().format(MIDNIGHT_DATETIME),
  )
  const [createdDate, setCreatedDate] = useState(new Date())
  const [createdRadioChecked, setCreatedRadioChecked] = useState(false)
  const [departAirportValue, setDepartAirportValue] = useState(tripFiltersObj.depart)
  const [arriveAirportValue, setArriveAirportValue] = useState("")
  const [driverValue, setDriverValue] = useState(filtersData.tripFiltersValues.driver)
  const [companiesOffset, setCompaniesOffset] = useState(0)
  const [driversOffset, setDriversOffset] = useState(0)

  const loadMoreCompanies = () => {
    if (!operatorBusy) {
      const newOffset = companiesOffset + 10
      dispatch(getCompanies({ offset: newOffset }))
      setCompaniesOffset(newOffset)
    }
  }

  const loadMoreDrivers = () => {
    if (!driverBusy && !!drivers?.next) {
      const newOffset = driversOffset + 10
      dispatch(getAllDrivers({ offset: newOffset, full_name: driverValue }))
      setDriversOffset(newOffset)
    }
  }
  const [operatorValue, setOperatorValue] = useState("")
  const [ofAllTimeSearch, setOfAllTimeSearch] = useState(false)
  const [dateShouldReset, setDateShouldReset] = useState(false)

  const getActiveFilterLength = () => {
    const isFilterCheckedClone = { ...isFilterChecked }
    delete isFilterCheckedClone.departure_time__gte
    let checkedFilters = 0
    Object.values(isFilterCheckedClone).forEach(filter => {
      checkedFilters += filter ? 1 : 0
    })

    return checkedFilters
  }

  useEffect(() => {
    if (initialRequestSent && getActiveFilterLength() < 1) {
      setOfAllTimeSearch(true)
    }
  }, [
    isFilterChecked.status,
    isFilterChecked.pnr,
    isFilterChecked.departure_time__gte,
    isFilterChecked.depart,
    isFilterChecked.arrive,
    isFilterChecked.company,
    isFilterChecked.driver,
    isFilterChecked.vehicle__id__contains,
    isFilterChecked.created__gte,
    isFilterChecked.departure_time__time,
  ])

  useEffect(() => {
    if (tripFiltersObj.departure_time__gte) {
      setDepartureDate(moment(tripFiltersObj.departure_time__gte).toDate() || new Date())
    }
  }, [tripFiltersObj.departure_time__gte])

  useEffect(() => {
    if (filtersData.tripFiltersValues.driver && tripFiltersObj.driver) {
      setDriverValue(filtersData.tripFiltersValues.driver)
    }
  }, [filtersData.tripFiltersValues.driver])

  useEffect(() => {
    if (filtersData.tripFiltersValues.company && tripFiltersObj.company) {
      setOperatorValue(filtersData.tripFiltersValues.company)
    }
  }, [filtersData.tripFiltersValues.company])

  const changeModalState = params => setIsFilterOpen(prevState => ({ ...prevState, ...params }))
  const changeCheckedState = params => {
    setIsFilterChecked(prevState => ({ ...prevState, ...params }))
  }
  const changeFilterValues = params => setFilterValues(prevState => ({ ...prevState, ...params }))

  const handleGetAirports = cityCode => {
    dispatch(getAllAirports({ search: cityCode }))
  }

  const handleGetDrivers = fullName => {
    setDriversOffset(0)
    dispatch(getAllDrivers({ full_name: fullName }))
  }

  useDebounce({
    value: departAirportValue,
    callback: () => handleGetAirports(departAirportValue),
    noValueCallback: () => changeFilterValues({ depart: undefined }),
  })

  useDebounce({
    value: arriveAirportValue,
    callback: () => handleGetAirports(arriveAirportValue),
    noValueCallback: () => changeFilterValues({ arrive: undefined }),
  })

  useDebounce({
    value: driverValue,
    callback: () => handleGetDrivers(driverValue),
    noValueCallback: () => changeFilterValues({ driver: undefined }),
  })

  const changeCheckedFilter = key => {
    if (isFilterChecked[key]) {
      setDateShouldReset(true)
    }

    changeCheckedState({
      [key]: !isFilterChecked[key],
    })
  }

  const filters = [
    {
      name: "Date of booking",
      key: "created__gte",
      value: filterValues.created__gte,
      content: (
        <>
          <div
            className="filters-radio-button"
            role="button"
            onClick={() => {
              setCreatedRadioChecked(true)
              changeFilterValues({
                created__gte: moment()
                  .add(-24, "hours")
                  .format(MIDNIGHT_DATETIME),
              })
            }}
          >
            <RadioButton checked={createdRadioChecked} />
            <div className="radio-button-text">Last 24 hours</div>
          </div>

          <DropDownCalendar
            textInputStyle="filter-calendar-style"
            containerClassName="filter-dropdown-calendar"
            value={createdDate}
            onDateChange={dateV => {
              setCreatedDate(dateV)
              setCreatedRadioChecked(false)
              changeFilterValues({ created__gte: moment(dateV).format(MIDNIGHT_DATETIME) })
            }}
          />
        </>
      ),
      isOpen: isFilterOpen.isDateOfBookingOpen,
      isChecked: isFilterChecked.created__gte,
      setIsOpen: () => changeModalState({ isDateOfBookingOpen: !isFilterOpen.isDateOfBookingOpen }),
      setIsChecked: () => changeCheckedFilter("created__gte"),
      isNextFilterOpen: isFilterOpen.isStatusOpen,
    },
    {
      name: "Status",
      key: "status",
      value: filterValues.status,
      content: (
        <Dropdown
          placeholder="Choose status"
          className="filter-dropdown"
          onClick={val => changeFilterValues({ status: val.value })}
          value={statusItems.find(status => status.value === +filterValues.status)?.name}
          items={statusItems}
          showItemField="name"
          label=""
        />
      ),
      isOpen: isFilterOpen.isStatusOpen,
      isChecked: isFilterChecked.status,
      setIsOpen: () => changeModalState({ isStatusOpen: !isFilterOpen.isStatusOpen }),
      setIsChecked: () => changeCheckedFilter("status"),
      isNextFilterOpen: isFilterOpen.isPnrOpen,
    },
    {
      name: "PNR",
      key: "pnr",
      value: filterValues.pnr,
      content: (
        <TextInput
          value={filterValues.pnr}
          onChange={val => changeFilterValues({ pnr: val.target.value })}
          field="filterPnr"
          inputStyle="filter-style"
          fullWidth
          placeholder="Search by PNR"
        />
      ),
      isOpen: isFilterOpen.isPnrOpen,
      isChecked: isFilterChecked.pnr,
      setIsOpen: () => changeModalState({ isPnrOpen: !isFilterOpen.isPnrOpen }),
      setIsChecked: () => changeCheckedFilter("pnr"),
      isNextFilterOpen: isFilterOpen.isDepartureDateOpen,
    },
    {
      name: "Departure date",
      value: filterValues.departure_time__gte,
      key: "departure_time__gte",
      content: (
        <>
          <DropDownCalendar
            containerClassName="filter-dropdown-calendar"
            textInputStyle="filter-calendar-style"
            value={departureDate}
            onDateChange={dateV => {
              setDepartureDate(dateV)
              changeFilterValues({
                departure_time__gte: moment(dateV).format(MIDNIGHT_DATETIME),
                ofAllTimeEnabled: false,
              })
            }}
          />
        </>
      ),
      isOpen: isFilterOpen.isDepartureDateOpen,
      isChecked: isFilterChecked.departure_time__gte,
      setIsOpen: () => changeModalState({ isDepartureDateOpen: !isFilterOpen.isDepartureDateOpen }),
      setIsChecked: () => {
        setOfAllTimeSearch(false)
        changeCheckedFilter("departure_time__gte")
      },

      isNextFilterOpen: isFilterOpen.isDepartureTimeOpen,
    },
    {
      name: "Departure time",
      key: "departure_time__time",
      value: filterValues.departure_time__time,
      content: (
        <TimeInputPicker
          className="filterStyle"
          value={
            filterValues.departure_time__time &&
            moment(filterValues.departure_time__time, [TWENTY_FOUR_HOUR_WITH_LEADING_ZERO]).format(
              TWELVE_HOUR_WITH_LEADING_ZERO,
            )
          }
          onChange={value => {
            if (value) {
              const newValue = moment(value, [TWELVE_HOUR_WITHOUT_LEADING_ZERO]).format(
                TWENTY_FOUR_HOUR_WITH_LEADING_ZERO,
              )

              changeFilterValues({
                departure_time__time: newValue,
              })
            }
          }}
        />
      ),
      isOpen: isFilterOpen.isDepartureTimeOpen,
      isChecked: isFilterChecked.departure_time__time,
      setIsOpen: () => changeModalState({ isDepartureTimeOpen: !isFilterOpen.isDepartureTimeOpen }),
      setIsChecked: () => changeCheckedFilter("departure_time__time"),
      isNextFilterOpen: isFilterOpen.isDepartureAirportOpen,
    },
    {
      name: "Departure Airport",
      key: "depart",
      value: filterValues.depart,
      content: (
        <SearchDropdown
          placeholder="Choose airport"
          onItemClick={item => {
            changeFilterValues({ depart: item.CityCode })
          }}
          value={departAirportValue}
          onFocus={() => handleGetAirports(departAirportValue)}
          setValue={setDepartAirportValue}
          items={airportItems}
          showItemField="CityCode"
          showSecondItemField="AirportName"
          textInputStyle="filter-search-style"
        />
      ),
      isOpen: isFilterOpen.isDepartureAirportOpen,
      isChecked: isFilterChecked.depart,
      setIsOpen: () =>
        changeModalState({ isDepartureAirportOpen: !isFilterOpen.isDepartureAirportOpen }),
      setIsChecked: () => changeCheckedFilter("depart"),
      isNextFilterOpen: isFilterOpen.isArrivalAirportOpen,
    },
    {
      name: "Arrival Airport",
      key: "arrive",
      value: filterValues.arrive,
      content: (
        <SearchDropdown
          placeholder="Choose airport"
          onItemClick={item => {
            changeFilterValues({ arrive: item.CityCode })
            handleGetAirports(arriveAirportValue)
          }}
          onFocus={() => handleGetAirports(arriveAirportValue)}
          value={arriveAirportValue}
          setValue={setArriveAirportValue}
          items={airportItems}
          showItemField="CityCode"
          showSecondItemField="AirportName"
          textInputStyle="filter-search-style"
        />
      ),
      isOpen: isFilterOpen.isArrivalAirportOpen,
      isChecked: isFilterChecked.arrive,
      setIsOpen: () =>
        changeModalState({ isArrivalAirportOpen: !isFilterOpen.isArrivalAirportOpen }),
      setIsChecked: () => changeCheckedFilter("arrive"),
      isNextFilterOpen: isFilterOpen.isOperatorOpen,
    },
    {
      name: "Operator",
      value: filterValues.company,
      key: "company",
      content: (
        <Dropdown
          placeholder="Choose operator"
          className="filter-dropdown"
          onClick={val => {
            changeFilterValues({ company: val.id })
            setOperatorValue(val.name)
          }}
          value={operatorValue}
          items={allCompanies?.results}
          showItemField="name"
          label=""
          scrollable
          loadMore={loadMoreCompanies}
          hasMore={!!allCompanies?.next}
        />
      ),
      isOpen: isFilterOpen.isOperatorOpen,
      isChecked: isFilterChecked.company,
      setIsOpen: () => changeModalState({ isOperatorOpen: !isFilterOpen.isOperatorOpen }),
      setIsChecked: () => changeCheckedFilter("company"),

      isNextFilterOpen: isFilterOpen.isDriverOpen,
    },
    {
      name: "Driver",
      key: "driver",
      value: filterValues.driver,
      content: (
        <SearchDropdown
          placeholder="Choose a driver"
          onItemClick={item => {
            changeFilterValues({ driver: item.id })
            handleGetDrivers(driverValue)
          }}
          onFocus={() => handleGetDrivers(driverValue)}
          value={driverValue}
          setValue={setDriverValue}
          items={drivers?.results}
          showItemField="first_name"
          showSecondItemField="last_name"
          textInputStyle="filter-search-style"
          field="driver"
          loadMore={loadMoreDrivers}
          hasMore={!!drivers?.next}
        />
      ),
      isOpen: isFilterOpen.isDriverOpen,
      isChecked: isFilterChecked.driver,
      setIsOpen: () => changeModalState({ isDriverOpen: !isFilterOpen.isDriverOpen }),
      setIsChecked: () => changeCheckedFilter("driver"),
      isNextFilterOpen: isFilterOpen.isVehicleOpen,
    },
    {
      name: "Vehicle #",
      key: "vehicle__id__contains",
      value: filterValues.vehicle__id__contains,
      content: (
        <div>
          <TextInput
            value={filterValues.vehicle__id__contains}
            onChange={val => changeFilterValues({ vehicle__id__contains: val.target.value })}
            field="filterVehicle"
            inputStyle="filter-style"
            fullWidth
            placeholder="Search Vehicle"
          />
        </div>
      ),
      isOpen: isFilterOpen.isVehicleOpen,
      isChecked: isFilterChecked.vehicle__id__contains,
      setIsOpen: () => changeModalState({ isVehicleOpen: !isFilterOpen.isVehicleOpen }),
      setIsChecked: () => changeCheckedFilter("vehicle__id__contains"),

      isNextFilterOpen: false,
    },
  ]

  const getQueryFilters = () => {
    const filtersObj = {}
    let filterWithValueExists = false

    filters.forEach(filter => {
      if (filter.isChecked && filter.value) {
        if (filter.name !== "Departure date") {
          filtersObj[filter.key] = filter.value
          filterWithValueExists = true
        } else {
          filtersObj[filter.key] = moment(departureDate).format(DEFAULT_DATETIME)
        }
      } else {
        filtersObj[filter.key] = undefined
      }

      if (filter.key === "pnr" && (!filter.value || !filter.isChecked)) {
        filtersObj[filter.key] = undefined
      }
      if (filter.key === "vehicle__id__contains" && (!filter.value || !filter.isChecked)) {
        filtersObj[filter.key] = undefined
      }
    })

    if (isFilterChecked.departure_time__gte) {
      filtersObj.departure_time__gte = moment(departureDate).format(DEFAULT_DATETIME)
    }

    if (ofAllTimeSearch && filterWithValueExists) {
      delete filtersObj.departure_time__gte
      changeCheckedState({ departure_time__gte: false })
      setOfAllTimeSearch(false)
    }

    if (dateShouldReset && !filterWithValueExists) {
      changeCheckedState({ departure_time__gte: true })
      filtersObj.departure_time__gte = moment().format(MIDNIGHT_DATETIME)
      setDateShouldReset(false)
    }

    if (tripFiltersObj.exclude_service_type) {
      filtersObj.exclude_service_type = tripFiltersObj.exclude_service_type
    }

    if (tripFiltersObj.ordering) {
      filtersObj.ordering = tripFiltersObj.ordering
    }

    return filtersObj
  }

  const formatQueryFilters = obj => {
    const parsedFilters = Object.fromEntries(Object.entries(obj).filter(([, v]) => v != null))

    return parsedFilters
  }

  const handleUpdateFilters = useCallback(() => {
    const queryFilters = formatQueryFilters(getQueryFilters())

    history.replace(
      `${ROUTES.PLANNING_PAGE}${queryString.stringify(queryFilters) &&
        `/${queryString.stringify(queryFilters)}`}`,
    )
  }, [getQueryFilters, dispatch, updateFilters])

  useDebounce({
    value: filterValues.pnr,
    callback: handleUpdateFilters,
    condition: isFilterChecked.pnr,
  })

  useDebounce({
    value: filterValues.vehicle__id__contains,
    callback: handleUpdateFilters,
    condition: isFilterChecked.vehicle__id__contains,
  })

  useEffect(() => {
    if (isMounted) {
      handleUpdateFilters()
    }
  }, [
    isFilterChecked.status && filterValues.status,
    isFilterChecked.pnr,
    isFilterChecked.departure_time__gte && filterValues.departure_time__gte,
    isFilterChecked.depart && filterValues.depart,
    isFilterChecked.arrive && filterValues.arrive,
    isFilterChecked.company && filterValues.company,
    isFilterChecked.driver && filterValues.driver,
    isFilterChecked.vehicle__id__contains,
    isFilterChecked.created__gte && filterValues.created__gte,
    isFilterChecked.departure_time__time && filterValues.departure_time__time,
  ])

  const initialCheckedState = {
    status: false,
    pnr: false,
    departure_time__gte: true,
    depart: false,
    arrive: false,
    company: false,
    driver: false,
    vehicle__id__contains: false,
    created__gte: false,
    departure_time__time: false,
  }

  const clearAllFilters = () => {
    const initialFilters = {
      departure_time__gte: moment().format(MIDNIGHT_DATETIME),
    }
    if (tripFiltersObj.exclude_service_type) {
      initialFilters.exclude_service_type = tripFiltersObj.exclude_service_type
    }
    if (tripFiltersObj.ordering) {
      initialFilters.ordering = tripFiltersObj.ordering
    }
    history.replace(`${ROUTES.PLANNING_PAGE}/${queryString.stringify(initialFilters)}`)

    setIsFilterChecked(initialCheckedState)
    setFilterValues({})
    setDepartureDate(moment(moment().format(MIDNIGHT_DATETIME)).toDate())
    setCreatedDate(new Date())
    setCreatedRadioChecked(false)
    if (departAirportValue) {
      setDepartAirportValue("")
    }
    if (arriveAirportValue) {
      setArriveAirportValue("")
    }
  }

  return (
    <FiltersDropdown
      filterItems={filters}
      clearAllFilters={clearAllFilters}
      clearFiltersText="Reset filters"
    />
  )
}

PlanningFilters.propTypes = {
  isFilterChecked: PropTypes.instanceOf(Object).isRequired,
  setIsFilterChecked: PropTypes.func.isRequired,
  history: PropTypes.instanceOf(Object).isRequired,
  pnrValue: PropTypes.string,
  setPnrValue: PropTypes.func,
  driverId: PropTypes.oneOfType([PropTypes.number, undefined]),
  initialRequestSent: PropTypes.bool,
}

PlanningFilters.defaultProps = {
  pnrValue: "",
  setPnrValue: () => {},
  driverId: undefined,
  initialRequestSent: false,
}

export default PlanningFilters
