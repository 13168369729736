import * as React from "react"
import { DateTime } from "luxon"
import { Cell, CellProps } from "./Cell"

export interface DateTimeCellProps extends Omit<CellProps, "value"> {
  value: DateTime
  format?: string
  tz?: string
}

export function DateTimeCell({ value, format = "MM/dd/yy", boxProps, tz }: DateTimeCellProps) {
  let d = value
  if (tz) {
    d = d.setZone(tz)
  }

  return <Cell boxProps={boxProps} value={d.isValid ? d.toFormat(format) : "---"} />
}
