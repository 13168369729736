import "./RightSideBar.scss"

import classNames from "classnames"
import moment from "moment"
import PropTypes from "prop-types"
import React, { useEffect, useMemo, useState } from "react"
import InfiniteScroll from "react-infinite-scroller"
import { useDispatch, useSelector } from "react-redux"

import API from "../../../constants/API"
import { NOTIFICATIONS_LOADING_LIMIT } from "../../../constants/offset"
import {
  getUnreadNotifications,
  markAllNotificationsAsRead,
  resetHighlightNotifications,
} from "../../../redux/notifications/notificationsActions"
import apiUtils from "../../../utils/api/api-utils"
import CurrentDate from "../../atoms/CurrentDate"
import LastDayInfo from "../../atoms/LastDayInfo"
import Loading from "../../atoms/Loading"
import NotificationButton from "../../atoms/NotificationButton"
import NotificationItem from "../../atoms/NotificationItem"
import NotificationsEmptyState from "../../atoms/NotificationsEmptyState"

const RightSideBar = ({
  lastDayData,
  changeExcludeId,
  resetTripRequestsTab,
  resetTripSuggestionsTab,
  tripRequestsScrollRef,
  newRequestsScrollRef,
  pendingSuggestionsScrollRef,
  tripSuggestionsScrollRef,
  tripRequestsShouldReset,
  tripSuggestionsShouldReset,
}) => {
  const dispatch = useDispatch()

  const rightSideBarClass = classNames("right-side-bar-container")
  const [offset, setOffset] = useState(0)
  const [, setCurrentDate] = useState(moment().format("hh:mm A dddd, DD MMM yyyy"))

  useEffect(() => {
    setInterval(() => {
      setCurrentDate(moment().format("hh:mm A dddd, DD MMM yyyy"))
    }, 5000)
  })

  const [notificationsData, callsInProgress] = useSelector(({ notifications, api }) => [
    notifications,
    api.callsInProgress,
  ])

  const notificationsClass = classNames("notifications", {
    "highlight-notifications": notificationsData.highlightNotifications,
  })

  useEffect(() => {
    if (notificationsData.highlightNotifications) {
      setTimeout(() => {
        dispatch(resetHighlightNotifications())
      }, 3000)
    }
  }, [notificationsData.highlightNotifications])

  const notificationsBusy = useMemo(() => {
    return apiUtils.areCallsInProgress([API.GET_UNREAD_NOTIFICATIONS], callsInProgress)
  }, [callsInProgress])

  const loadMoreNotifications = () => {
    if (!notificationsBusy) {
      const newOffset = offset + NOTIFICATIONS_LOADING_LIMIT
      dispatch(getUnreadNotifications(newOffset))
      setOffset(newOffset)
    }
  }

  return (
    <div className={rightSideBarClass}>
      <div className="notificationWrapper">
        <NotificationButton shouldGetNotifications />
        <div className="dateContainer">
          <CurrentDate />
        </div>
      </div>
      <div className="right-collapse-sidebar-container">
        <LastDayInfo lastDayData={lastDayData} />
      </div>
      <div className="notification-container">
        <h5 className="notification-label">Notifications</h5>
        <div
          className="mark-all-container"
          onClick={() => {
            dispatch(markAllNotificationsAsRead())
          }}
          role="button"
        >
          Mark all as read
        </div>
      </div>
      <div className={notificationsClass}>
        <InfiniteScroll
          loadMore={loadMoreNotifications}
          hasMore={notificationsData?.unread?.next}
          useWindow={false}
          threshold={1}
          pageStart={0}
          loader={<Loading className="loading-dropdown" />}
        >
          {notificationsData?.unread?.results?.map((item, index) => {
            return (
              <NotificationItem
                index={index}
                notificationItem={item}
                changeExcludeId={changeExcludeId}
                resetTripRequestsTab={resetTripRequestsTab}
                resetTripSuggestionsTab={resetTripSuggestionsTab}
                tripRequestsScrollRef={tripRequestsScrollRef}
                newRequestsScrollRef={newRequestsScrollRef}
                pendingSuggestionsScrollRef={pendingSuggestionsScrollRef}
                tripSuggestionsScrollRef={tripSuggestionsScrollRef}
                tripRequestsShouldReset={tripRequestsShouldReset}
                tripSuggestionsShouldReset={tripSuggestionsShouldReset}
              />
            )
          })}
        </InfiniteScroll>
        {notificationsData?.unread?.count === 0 && <NotificationsEmptyState />}
      </div>
    </div>
  )
}

RightSideBar.propTypes = {
  lastDayData: PropTypes.instanceOf(Object).isRequired,
  changeExcludeId: PropTypes.func,
  resetTripRequestsTab: PropTypes.func,
  resetTripSuggestionsTab: PropTypes.func,
  tripRequestsScrollRef: PropTypes.instanceOf(Element),
  newRequestsScrollRef: PropTypes.instanceOf(Element),
  pendingSuggestionsScrollRef: PropTypes.instanceOf(Element),
  tripSuggestionsScrollRef: PropTypes.instanceOf(Element),
  tripRequestsShouldReset: PropTypes.bool,
  tripSuggestionsShouldReset: PropTypes.bool,
}

RightSideBar.defaultProps = {
  changeExcludeId: () => {},
  resetTripRequestsTab: () => {},
  resetTripSuggestionsTab: () => {},
  tripRequestsScrollRef: null,
  newRequestsScrollRef: null,
  pendingSuggestionsScrollRef: null,
  tripSuggestionsScrollRef: null,
  tripRequestsShouldReset: false,
  tripSuggestionsShouldReset: false,
}

export default RightSideBar
