import queryString from "query-string"

import colors from "../constants/colors"
import { ROUTES } from "../constants/routes"
import { getTrip } from "../redux/actions"
import { setActiveNotification } from "../redux/notifications/notificationsActions"

export const redirectToPlanning = (history, filters, shouldReplace = false) => {
  const parsedFilters = queryString.stringify(filters)
  const url = `${ROUTES.PLANNING_PAGE}/${parsedFilters}`

  if (shouldReplace) {
    history.replace(url)
  } else {
    history.push(url)
  }
}

export const redirectDetails = (tripDetails, history, dispatch) => {
  dispatch(
    getTrip({
      tripID: tripDetails.id,
      externalTripDetailsFetch: true,
      callback: () => {
        dispatch(
          setActiveNotification({
            showEditModal: true,
            relatedObjectId: tripDetails.id,
          }),
        )
        redirectToPlanning(history, { departure_time__gte: tripDetails.departure_time })
      },
    }),
  )
}

// changes color of element depending on lightness level of argument color
export const changeColor = arg => {
  if (!arg) {
    return colors.white
  }
  const hexColor = arg?.replace("#", "")
  const r = parseInt(hexColor.substr(0, 2), 16)
  const g = parseInt(hexColor.substr(2, 2), 16)
  const b = parseInt(hexColor.substr(4, 2), 16)
  const calcColor = (r * 299 + g * 587 + b * 114) / 1000
  return calcColor >= 128 ? colors.darkLandline : colors.white
}
export const isFirefox = typeof InstallTrigger !== "undefined"

export default { redirectDetails, changeColor, isFirefox }
