export const MIDNIGHT_DATETIME = "YYYY-MM-DDT00:00:00"
export const END_OF_DAY_DATETIME = "YYYY-MM-DDT23:59:59"
export const DEFAULT_DATETIME = "YYYY-MM-DDTHH:mm:ss"

export const DEFAULT_TIME = "HH:mm:ss"

export const MONTH_DAY_YEAR = "MMM DD YYYY"

export const TWELVE_HOUR_WITH_LEADING_ZERO = "hh:mm A"
export const TWELVE_HOUR_WITHOUT_LEADING_ZERO = "h:mm A"
export const TWENTY_FOUR_HOUR_WITH_LEADING_ZERO = "HH:mm"

export const DATE_WITHOUT_TIME = "YYYY-MM-DD"

export const PRE_TRIP_NOTIFICATION_FORMAT = "MMM, DD YYYY, hh:mm A"
