import ACTIONS from "../../constants/ACTIONS"

const initialState = {}

export default function manifestReducer (state = initialState, action) {
    switch(action.type) {
        case ACTIONS.GET_MANIFEST_SUCCESS:
            return {
                ...state,
                manifest: action.data
            }
        default:
            return { ...state }
    }
}