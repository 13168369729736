export const VEHICLE_AVAILABLE = 1
export const VEHICLE_OUT_OF_SERVICE = 2

export const VEHICLE_STATUS = {
  [VEHICLE_AVAILABLE]: "Available",
  [VEHICLE_OUT_OF_SERVICE]: "Out of service",
}

export const SUV = 1
export const SEDAN = 2
export const COUPE = 3
export const SPORTS_CAR = 4
export const STATION_WAGON = 5
export const HATCHBACK = 6
export const CONVERTIBLE = 7
export const SPRINTER = 8
export const PICKUP_TRUCK = 9

export const VEHICLE_TYPE = {
  [SUV]: "SUV",
  [SEDAN]: "Sedan",
  [COUPE]: "Coupe",
  [SPORTS_CAR]: "Sports car",
  [STATION_WAGON]: "Station wagon",
  [HATCHBACK]: "Hatchback",
  [CONVERTIBLE]: "Convertible",
  [SPRINTER]: "Sprinter",
  [PICKUP_TRUCK]: "Pickup truck",
}
