import * as React from "react"
import { Box, BoxExtendedProps, Text } from "grommet"

export interface CellProps {
  value: string | number | React.ReactNode
  boxProps?: BoxExtendedProps
  wrap?: boolean
}

export function Cell({ value, boxProps, wrap = false }: CellProps) {
  return (
    <Box data-testid="cell" {...boxProps} title={typeof value === "string" ? value : null}>
      <Text
        style={{
          whiteSpace: wrap ? "normal" : "nowrap",
          fontSize: "14px",
        }}
      >
        {value}
      </Text>
    </Box>
  )
}
