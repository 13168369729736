import React, { useCallback, useState } from "react"
import { LandlinePermissionMap } from "../../utils/api/getPermissions"

export type PermissionContextState = {
  permissions: LandlinePermissionMap
  setPermissions: (map: LandlinePermissionMap) => void
  hasPermission: (perm: string) => boolean
}

export const PermissionContext = React.createContext<PermissionContextState>({
  permissions: {},
  setPermissions: (_map: LandlinePermissionMap) => {},
  hasPermission: (_perm: string) => false,
})

interface PermissionContextProviderProps {
  children: React.ReactNode
}

export function PermissionContextProvider({ children }: PermissionContextProviderProps) {
  const [permissions, setPermissions] = useState<LandlinePermissionMap>()

  const hasPermission = useCallback(
    (perm: string) => {
      return permissions?.[perm] ?? false
    },
    [permissions],
  )

  return (
    <PermissionContext.Provider
      value={{
        permissions,
        setPermissions,
        hasPermission,
      }}
    >
      {children}
    </PermissionContext.Provider>
  )
}
