import { toast } from "react-toastify"

import { firstCharToUpperCase } from "./common"

export const showError = (error = {}) => {
  const errorCodes = {
    404: "Not Found",
    500: "Server error",
  }

  // temporary solution
  if (error.response.data.errors?.length > 0) {
    toast.error(error.response.data.errors[0][1])
    return
  }

  // if backend returns HTML response
  if (errorCodes[error.response?.status] && !error.response?.data?.detail) {
    toast.error(errorCodes[error.response?.status])
    return
  }

  if (error.response.data.pick_up_details || error.response.data.holder_passenger) {
    return
  }

  Object.keys(error.response?.data).forEach(key => {
    if (typeof error.response?.data[key] === "string") {
      return toast.error(firstCharToUpperCase(error.response?.data[key]))
    }

    return error.response?.data[key].forEach(errorString => {
      toast.error(firstCharToUpperCase(errorString))
    })
  })
}

export default { showError }
