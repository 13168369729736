import queryString from "query-string"
import { toast } from "react-toastify"

import ACTIONS from "../../constants/ACTIONS"
import API from "../../constants/API"
import { landlineApi } from "../../utils/api/calls"
import { updateRow } from "../actions"
import { apiError, startApiCall } from "../api/apiActions"
import { store } from "../store"

const getCompanyDrivers = queryParams => {
  return async dispatch => {
    try {
      const urlQuery = queryString.stringify(queryParams)

      dispatch(startApiCall(API.GET_COMPANY_DRIVERS))
      const drivers = await landlineApi.get(`v2/bossadmin/users/company_drivers/?${urlQuery}`)

      dispatch({
        type: ACTIONS.GET_COMPANY_DRIVERS_SUCCESS,
        drivers: drivers.data,
        companyId: queryParams?.company,
        offset: queryParams?.offset,
      })
    } catch (error) {
      dispatch(apiError(API.GET_COMPANY_DRIVERS, error))
    }
  }
}

const getAllDrivers = queryParams => {
  return async dispatch => {
    try {
      const urlQuery = queryString.stringify(queryParams)
      dispatch(startApiCall(API.GET_ALL_DRIVERS))
      const drivers = await landlineApi.get(`v2/bossadmin/users/company_drivers/?${urlQuery}`)

      dispatch({
        type: ACTIONS.GET_ALL_DRIVERS_SUCCESS,
        drivers: drivers.data,
        offset: queryParams?.offset,
      })
    } catch (error) {
      dispatch(apiError(API.GET_ALL_DRIVERS, error))
    }
  }
}

const getDriver = queryParams => {
  return async dispatch => {
    try {
      const urlQuery = queryString.stringify(queryParams)
      dispatch(startApiCall(API.GET_DRIVER))
      const driver = await landlineApi.get(`v2/bossadmin/users/company_drivers/?${urlQuery}`)

      dispatch({
        type: ACTIONS.GET_DRIVER_SUCCESS,
        drivers: driver.data,
        offset: queryParams?.offset,
      })
    } catch (error) {
      dispatch(apiError(API.GET_DRIVER, error))
    }
  }
}

const notifyDrivers = (trips, callback) => {
  return async dispatch => {
    try {
      const data = { trips }
      dispatch(startApiCall(API.NOTIFY_DRIVERS))
      await landlineApi.post(`v2/bossadmin/trips/notify_drivers/`, data)

      callback()

      dispatch({ type: ACTIONS.NOTIFY_DRIVERS_SUCCESS })
    } catch (error) {
      dispatch(apiError(API.NOTIFY_DRIVERS, error))
    }
  }
}

const notifyCustomers = (trips, callback) => {
  return async dispatch => {
    try {
      const data = { trips }
      dispatch(startApiCall(API.NOTIFY_CUSTOMERS))
      const response = await landlineApi.post(`v2/bossadmin/trips/notify_customers/`, data)

      callback()

      dispatch({ type: ACTIONS.NOTIFY_CUSTOMERS_SUCCESS, data: response.data })
    } catch (error) {
      dispatch(apiError(API.NOTIFY_CUSTOMERS, error))
    }
  }
}

const assignLandlineDriver = (tripId, data) => {
  return async dispatch => {
    try {
      dispatch(startApiCall(API.ASSIGN_LANDLINE_DRIVER))
      await landlineApi.post(`v2/bossadmin/trips/${tripId}/assign_landline_driver/`, data)
      await dispatch(updateRow(tripId))

      dispatch({ type: ACTIONS.ASSIGN_LANDLINE_DRIVER_SUCCESS })
      toast.success("Trip updated successfully.")
      return true
    } catch (error) {
      dispatch(apiError(API.ASSIGN_LANDLINE_DRIVER, error))
      return false
    }
  }
}

const getDriverDetails = id => {
  return async dispatch => {
    try {
      dispatch(startApiCall(API.GET_DRIVER_DETAILS))
      const response = await landlineApi.get(`v2/bossadmin/users/company_drivers/${id}/`)

      dispatch({ type: ACTIONS.GET_DRIVER_DETAILS_SUCCESS, driverDetails: response.data })
    } catch (error) {
      dispatch(apiError(API.GET_DRIVER_DETAILS, error))
    }
  }
}

const updateDriverDetails = (id, data) => {
  return async dispatch => {
    try {
      dispatch(startApiCall(API.UPDATE_DRIVER_DETAILS))
      const response = await landlineApi.patch(`v2/bossadmin/users/company_drivers/${id}/`, data)

      dispatch({ type: ACTIONS.UPDATE_DRIVER_DETAILS_SUCCESS, driverDetails: response.data })
      toast.success("Driver details updated successfully")
    } catch (error) {
      dispatch(apiError(API.UPDATE_DRIVER_DETAILS, error))
    }
  }
}

const updateDriverVehicleLicence = (id, isActive) => {
  return async dispatch => {
    try {
      const newLicences = [...store.getState().driver.driverDetails.driver_profile.vehicle_licences]

      dispatch(startApiCall(API.UPDATE_DRIVER_VEHICLE_LICENCE))
      const data = { active: isActive }

      const response = await landlineApi.patch(`v2/bossadmin/vehicle_licences/${id}/`, data)
      const licenceIndex = newLicences.findIndex(
        licence => licence.vehicle_type === response.data.vehicle_type,
      )
      newLicences[licenceIndex] = response.data

      dispatch({
        type: ACTIONS.UPDATE_DRIVER_VEHICLE_LICENCE_SUCCESS,
        newLicences,
      })
      toast.success("Vehicle licence updated successfully")
    } catch (error) {
      dispatch(apiError(API.UPDATE_DRIVER_VEHICLE_LICENCE, error))
    }
  }
}

const clearDriverDetails = () => {
  return async dispatch => {
    dispatch({ type: ACTIONS.CLEAR_DRIVER_DETAILS })
  }
}

const setShift = (driverId, data) => {
  const newData = {
    start_time_write: data.start_time,
    end_time_write: data.end_time,
    preferable_shift: data.preferable_shift,
    off_duty: data.off_duty,
  }
  return async dispatch => {
    try {
      await landlineApi.patch(`/v2/bossadmin/shifts/${data.id}/`, newData)

      dispatch(getDriverDetails(driverId))
      toast.success("Successfully changed shift")
    } catch (error) {
      toast.error("Shift was not changed")
    }
  }
}

const resetDriverEvents = () => {
  return async dispatch => {
    dispatch({ type: ACTIONS.RESET_DRIVER_EVENTS_SUCCESS })
  }
}

const getDriverEvents = queryParams => {
  return async dispatch => {
    try {
      await dispatch(resetDriverEvents())
      const urlQuery = queryString.stringify({ ...queryParams, limit: 1000 })
      dispatch(startApiCall(API.GET_DRIVER_EVENTS))
      const response = await landlineApi.get(`v2/bossadmin/events/?${urlQuery}`)

      dispatch({ type: ACTIONS.GET_DRIVER_EVENTS_SUCCESS, driverEvents: response.data.results })
    } catch (error) {
      dispatch(apiError(API.UPDATE_DRIVER_DETAILS, error))
    }
  }
}

const createDriverEvent = (data, callback) => {
  return async dispatch => {
    try {
      dispatch(startApiCall(API.CREATE_DRIVER_EVENT))
      await landlineApi.post(`v2/bossadmin/events/`, data)

      callback()
      toast.success("Event created successfully")
    } catch (error) {
      dispatch(apiError(API.CREATE_DRIVER_EVENT, error))
    }
  }
}

const checkDriverEvent = (data, callback) => {
  return async dispatch => {
    try {
      dispatch(startApiCall(API.CHECK_DRIVER_EVENT))
      const response = await landlineApi.post(`v2/bossadmin/events/is_valid/`, data)

      if (response.data.is_valid && response.data.overlapping_events.length === 0) {
        await dispatch(createDriverEvent(data, callback))
        dispatch({ type: ACTIONS.CHECK_DRIVER_EVENT_SUCCESS })
        return { shouldModalClose: true }
      }

      if (!response.data.is_valid) {
        dispatch({ type: ACTIONS.CHECK_DRIVER_EVENT_SUCCESS })
        toast.warning("Event is not valid")
        return { shouldModalClose: true }
      }

      dispatch({ type: ACTIONS.CHECK_DRIVER_EVENT_SUCCESS })
      return { shouldModalSwap: true, affectedEvents: response.data.overlapping_events }
    } catch (error) {
      dispatch(apiError(API.CHECK_DRIVER_EVENT, error))
      return { shouldModalClose: true }
    }
  }
}

const cancelDriverEvent = (id, callback) => {
  return async dispatch => {
    try {
      dispatch(startApiCall(API.CANCEL_DRIVER_EVENT))
      await landlineApi.delete(`v2/bossadmin/events/${id}/`)

      callback()

      toast.success("Event successfully cancelled")
    } catch (error) {
      dispatch(apiError(API.CANCEL_DRIVER_EVENT, error))
    }
  }
}

const getTripDrivers = (id, search) => {
  return async dispatch => {
    try {
      dispatch(startApiCall(API.GET_TRIP_DRIVERS))
      const drivers = await landlineApi.get(
        `v2/bossadmin/trips/${id}/get_drivers/${search ? `?full_name=${search}` : ""}`,
      )

      dispatch({
        type: ACTIONS.GET_TRIP_DRIVERS_SUCCESS,
        tripDrivers: drivers.data.data,
      })
    } catch (error) {
      dispatch(apiError(API.GET_TRIP_DRIVERS, error))
    }
  }
}

export {
  getCompanyDrivers,
  getAllDrivers,
  getDriver,
  notifyDrivers,
  getDriverDetails,
  updateDriverDetails,
  updateDriverVehicleLicence,
  assignLandlineDriver,
  clearDriverDetails,
  setShift,
  getDriverEvents,
  checkDriverEvent,
  createDriverEvent,
  cancelDriverEvent,
  resetDriverEvents,
  getTripDrivers,
  notifyCustomers,
}
